import React from "react";

export const DeleteIcon = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.875 2.875H9.125C9.125 2.57663 9.00647 2.29048 8.7955 2.0795C8.58452 1.86853 8.29837 1.75 8 1.75C7.70163 1.75 7.41548 1.86853 7.2045 2.0795C6.99353 2.29048 6.875 2.57663 6.875 2.875ZM5.75 2.875C5.75 2.27826 5.98705 1.70597 6.40901 1.28401C6.83097 0.862053 7.40326 0.625 8 0.625C8.59674 0.625 9.16903 0.862053 9.59099 1.28401C10.0129 1.70597 10.25 2.27826 10.25 2.875H14.75C14.8992 2.875 15.0423 2.93426 15.1477 3.03975C15.2532 3.14524 15.3125 3.28832 15.3125 3.4375C15.3125 3.58668 15.2532 3.72976 15.1477 3.83525C15.0423 3.94074 14.8992 4 14.75 4H14.1155L12.7599 13.9427C12.6679 14.6165 12.335 15.2342 11.8226 15.6814C11.3103 16.1286 10.6533 16.375 9.97325 16.375H6.02675C5.34671 16.375 4.6897 16.1286 4.17736 15.6814C3.66503 15.2342 3.33207 14.6165 3.24013 13.9427L1.8845 4H1.25C1.10082 4 0.957742 3.94074 0.852252 3.83525C0.746763 3.72976 0.6875 3.58668 0.6875 3.4375C0.6875 3.28832 0.746763 3.14524 0.852252 3.03975C0.957742 2.93426 1.10082 2.875 1.25 2.875H5.75ZM6.875 6.8125C6.875 6.66332 6.81574 6.52024 6.71025 6.41475C6.60476 6.30926 6.46168 6.25 6.3125 6.25C6.16332 6.25 6.02024 6.30926 5.91475 6.41475C5.80926 6.52024 5.75 6.66332 5.75 6.8125V12.4375C5.75 12.5867 5.80926 12.7298 5.91475 12.8352C6.02024 12.9407 6.16332 13 6.3125 13C6.46168 13 6.60476 12.9407 6.71025 12.8352C6.81574 12.7298 6.875 12.5867 6.875 12.4375V6.8125ZM9.6875 6.25C9.53832 6.25 9.39524 6.30926 9.28975 6.41475C9.18426 6.52024 9.125 6.66332 9.125 6.8125V12.4375C9.125 12.5867 9.18426 12.7298 9.28975 12.8352C9.39524 12.9407 9.53832 13 9.6875 13C9.83668 13 9.97976 12.9407 10.0852 12.8352C10.1907 12.7298 10.25 12.5867 10.25 12.4375V6.8125C10.25 6.66332 10.1907 6.52024 10.0852 6.41475C9.97976 6.30926 9.83668 6.25 9.6875 6.25Z"
        fill="#E11D48"
      />
    </svg>
  );
};
