import React from 'react'
import PositiveArrow from '../../../components/icons/PositiveArrow'
import NegativeArrow from '../../../components/icons/NegativeArrow'
import { useNavigate } from 'react-router-dom'

const DashboardStatus = ({ status }) => {
    const navigate = useNavigate();

    return (
        <>
            <div className="flex-1 border border-1 border-[#ECF7FF] p-5 py-[22px] rounded-xl min-w-[264px] cursor-pointer" onClick={() => { navigate('/dealership/?tab=allUsers') }}>
                <div className="flex justify-between">

                    <div className="flex flex-col gap-2">
                        <span className="text-[#414552] font-semibold">Users</span>
                        <h1 className="font-semibold text-xl mt-1">{status.online} / {status.userCount}</h1>
                    </div>

                    <div className="flex flex-col justify-between">
                        <div className="flex justify-end">
                            <div className="bg-[#1A9882] rounded-full p-[6px]"></div>
                        </div>


                        <div className="flex gap-2">
                            <span className="flex items-center gap-1 text-[#1A9882] font-normal text-sm">{Math.round((status.online / status.userCount * 100))}%
                                <PositiveArrow />
                            </span>
                        </div>

                    </div>

                </div>
            </div>

            <div className="flex-1 border border-1 border-[#ECF7FF] p-5 py-[22px] rounded-xl min-w-[264px]">
                <div className="flex justify-between">

                    <div className="flex flex-col gap-2">
                        <span className="text-[#414552] font-semibold">Offline</span>
                        <h1 className="font-semibold text-xl mt-1">{status.offline}</h1>
                    </div>

                    <div className="flex flex-col justify-between">
                        <div className="flex justify-end">
                            <div className="bg-[#E53E3E] rounded-full p-[6px]"></div>
                        </div>


                        <div className="flex gap-2">
                            <span className="flex items-center gap-1 text-[#E53E3E] font-normal text-sm">{Math.round((status.offline / status.userCount * 100))}%
                                <NegativeArrow />

                            </span>
                        </div>

                    </div>

                </div>
            </div>

            <div className="flex-1 border border-1 border-[#ECF7FF] p-5 py-[22px] rounded-xl min-w-[264px] cursor-pointer" onClick={() => { navigate('/dealership/?tab=allUsers&mode=frozen') }}>
                <div className="flex justify-between">

                    <div className="flex flex-col gap-2">
                        <span className="text-[#414552] font-semibold">Frozen</span>
                        <h1 className="font-semibold text-xl mt-1">{status.freeze}</h1>
                    </div>

                    <div className="flex flex-col justify-between">
                        <div className="flex justify-end">
                            <div className="bg-[#F98550] rounded-full p-[6px]"></div>
                        </div>


                        <div className="flex gap-2">
                            <span className="flex items-center gap-1 text-[#0E1213] font-normal text-sm">{status.freeze} / {status.userCount}
                            </span>
                        </div>

                    </div>

                </div>
            </div>

            <div className="flex-1 border border-1 border-[#ECF7FF] p-5 py-[22px] rounded-xl min-w-[264px]">
                <div className="flex justify-between">

                    <div className="flex flex-col gap-2">
                        <span className="text-[#414552] font-semibold">Ticket</span>
                        <h1 className="font-semibold text-xl mt-1">3</h1>
                    </div>

                    <div className="flex flex-col justify-between">
                        <div className="flex justify-end">
                            <div className="bg-[#BE6AFF] rounded-full p-[6px]"></div>
                        </div>


                        <div className="flex gap-2">
                            <span className="flex items-center gap-1 text-[#0E1213] font-normal text-sm">Support
                            </span>
                        </div>

                    </div>

                </div>
            </div>

            <div className="flex-1 border border-1 border-[#ECF7FF] p-5 py-[22px] rounded-xl min-w-[264px] cursor-pointer" onClick={() => { navigate('/dealership/?tab=allCustomers&mode=onboarding') }}>
                <div className="flex justify-between">

                    <div className="flex flex-col gap-2">
                        <span className="text-[#414552] font-semibold">Onboarding</span>
                        <h1 className="font-semibold text-xl mt-1">{status.onboarding}</h1>
                    </div>

                    <div className="flex flex-col justify-between">
                        <div className="flex justify-end">
                            <div className="bg-[#00ACF5] rounded-full p-[6px]"></div>
                        </div>


                        <div className="flex gap-2">
                            <span className="flex items-center gap-1 text-[#0E1213] font-normal text-sm">New Customers
                            </span>
                        </div>

                    </div>

                </div>
            </div>

            <div className="flex-1 border border-1 border-[#ECF7FF] p-5 py-[22px] rounded-xl min-w-[264px]">
                <div className="flex justify-between">

                    <div className="flex flex-col gap-2">
                        <span className="text-[#414552] font-semibold">Facebook</span>
                        <h1 className="font-semibold text-xl mt-1">9</h1>
                    </div>

                    <div className="flex flex-col justify-between">
                        <div className="flex justify-end">
                            <div className="bg-[#046AEA] rounded-full p-[6px]"></div>
                        </div>


                        <div className="flex gap-2">
                            <span className="flex items-center gap-1 text-[#0E1213] font-normal text-sm">Campaigns
                            </span>
                        </div>

                    </div>

                </div>
            </div>

            <div className="flex-1 border border-1 border-[#ECF7FF] p-5 py-[22px] rounded-xl min-w-[264px]">
                <div className="flex justify-between">

                    <div className="flex flex-col gap-2">
                        <span className="text-[#414552] font-semibold">Google</span>
                        <h1 className="font-semibold text-xl mt-1">4</h1>
                    </div>

                    <div className="flex flex-col justify-between">
                        <div className="flex justify-end">
                            <div className="bg-[#1EB81B] rounded-full p-[6px]"></div>
                        </div>


                        <div className="flex gap-2">
                            <span className="flex items-center gap-1 text-[#0E1213] font-normal text-sm">Vehicle Listing Ads
                            </span>
                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}

export default DashboardStatus