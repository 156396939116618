import React from 'react'

export const SmallHorizontalMenuIcon = ({ color }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 8.125C8.29837 8.125 8.58452 8.00647 8.7955 7.7955C9.00647 7.58452 9.125 7.29837 9.125 7C9.125 6.70163 9.00647 6.41548 8.7955 6.2045C8.58452 5.99353 8.29837 5.875 8 5.875C7.70163 5.875 7.41548 5.99353 7.2045 6.2045C6.99353 6.41548 6.875 6.70163 6.875 7C6.875 7.29837 6.99353 7.58452 7.2045 7.7955C7.41548 8.00647 7.70163 8.125 8 8.125ZM12.125 8.125C12.4234 8.125 12.7095 8.00647 12.9205 7.7955C13.1315 7.58452 13.25 7.29837 13.25 7C13.25 6.70163 13.1315 6.41548 12.9205 6.2045C12.7095 5.99353 12.4234 5.875 12.125 5.875C11.8266 5.875 11.5405 5.99353 11.3295 6.2045C11.1185 6.41548 11 6.70163 11 7C11 7.29837 11.1185 7.58452 11.3295 7.7955C11.5405 8.00647 11.8266 8.125 12.125 8.125ZM3.875 8.125C4.17337 8.125 4.45952 8.00647 4.6705 7.7955C4.88147 7.58452 5 7.29837 5 7C5 6.70163 4.88147 6.41548 4.6705 6.2045C4.45952 5.99353 4.17337 5.875 3.875 5.875C3.57663 5.875 3.29048 5.99353 3.0795 6.2045C2.86853 6.41548 2.75 6.70163 2.75 7C2.75 7.29837 2.86853 7.58452 3.0795 7.7955C3.29048 8.00647 3.57663 8.125 3.875 8.125Z"
                fill={`${color === undefined ? "#404452" : color}`}
            />
        </svg>

    )
}
