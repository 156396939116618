import React, { useEffect, useRef, useState } from 'react'
import PlusIcon from 'components/icons/PlusIcon'
import AllCustomerList from './AllCustomerList'
import { useLocation, useNavigate } from 'react-router-dom'
import { useCustomerContext } from 'Context/CustomerContext'
import { useSearchContext } from 'Context/SearchContext'
import NIcon from 'components/icons/NIcon'
import MainFilters from './Features/MainFilters'
import SecondaryFilters from './Features/SecondaryFilters'

export default function AllCustomers({
    loading,
}) {
    const navigate = useNavigate();
    const location = useLocation();

    const {
        search,
    } = useSearchContext();

    const {
        setToggleAddCustomer,
        customerList,
    } = useCustomerContext();

    const [moreFilterIsOpen, setMoreFilterIsOpen] = useState(false);
    const [editColumnIsOpen, setEditColumnIsOpen] = useState(false);
    const [moveUser, setMoveUser] = useState(false);
    const moreFilterRef = useRef(null);
    const editColumnRef = useRef(null);
    const moveUserRef = useRef(null)


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (moreFilterRef.current && !moreFilterRef.current.contains(event.target)) {
                setMoreFilterIsOpen(false);
            }

            if (editColumnRef.current && !editColumnRef.current.contains(event.target)) {
                setEditColumnIsOpen(false);
            }

            if (moveUserRef.current && !moveUserRef.current.contains(event.target)) {
                setMoveUser(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const [customerMode, setCustomerMode] = useState("all");

    const modifiedCustomer = (mode) => {
        navigate(`/dealership/?tab=allCustomers&mode=${mode}`);
        setCustomerMode(`${mode}`);
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const redirectStatus = queryParams.get("mode");
        // modes :
        // all, support, canceled, onboarding, package all_in-basic-pro

        if (redirectStatus !== "" & redirectStatus !== null & redirectStatus !== undefined) {
            setCustomerMode(redirectStatus);
        }

    }, [location.search]);

    const [columns, setColumns] = useState(localStorage.getItem('customerColumns') ? JSON.parse(localStorage.getItem('customerColumns')) : [
        { id: 1, name: 'EMAIL', active: true, width: '253px', spanWidth: '253px', dbKey: 'email' },
        { id: 2, name: 'WEBSITE', active: true, width: '194px', spanWidth: '194px', dbKey: 'website' },
        { id: 3, name: 'STATE', active: true, width: '125px', spanWidth: '125px', dbKey: 'state' },
        { id: 4, name: 'CITY', active: true, width: '105px', spanWidth: '105px', dbKey: 'city' },
        { id: 5, name: 'PHONE NUMBER', active: true, width: '169px', spanWidth: '169px', dbKey: 'phone' },
        { id: 6, name: 'PACKAGE', active: true, width: '106px', spanWidth: '106px', dbKey: 'package' },
        { id: 7, name: 'USERS', active: true, width: '104px', spanWidth: '104px', dbKey: 'user_count' },
        { id: 8, name: 'FIRST NAME', active: true, width: '126px', spanWidth: '126px', dbKey: 'first_name' },
        { id: 9, name: 'LAST NAME', active: true, width: '113px', spanWidth: '113px', dbKey: 'last_name' },
    ]);

    return (
        <div className=''>
            {/* Heads */}

            <div className='flex items-center justify-between'>
                <div className='flex flex-col gap-2 pb-[34px]'>
                    <h1 className='text-[28px] font-bold leading-9 font-roboto'>Customers
                    </h1>
                </div>
                <div className='flex items-center gap-5'>
                    <button className="bg-buttonPrimary pt-[5px] pr-[8px] pb-[5px] pl-[7px] rounded-lg shadow-md items-center text-white text-[14px] flex item-center gap-[8px]"
                        onClick={() => { setToggleAddCustomer(true) }}>
                        <span className='flex items-center gap-[2px]'> <PlusIcon /> Add customer</span>
                        <NIcon />
                    </button>
                </div>
            </div>

            <div className=''>
                <div className="flex gap-1 border-b border-borderPrimary">
                    <button className='font-semibold text-[#1B74E4] border-b-2 border-[#1B74E4] pb-1' onClick={() => {
                        navigate("/dealership/?tab=allCustomers")
                    }}>
                        Customers
                    </button>

                    <span className='text-[#D7D7D7] px-3'>|</span>

                    <button className='font-semibold text-[#596171] pb-1' onClick={() => {
                        navigate("/dealership/?tab=allUsers")
                    }}>
                        Users
                    </button>
                </div>
            </div>

            {/* Filter Section */}

            <MainFilters
                modifiedCustomer={modifiedCustomer}
                customerMode={customerMode}
            />

            <SecondaryFilters
                moreFilterIsOpen={moreFilterIsOpen}
                setMoreFilterIsOpen={setMoreFilterIsOpen}
                editColumnIsOpen={editColumnIsOpen}
                setEditColumnIsOpen={setEditColumnIsOpen}
                moreFilterRef={moreFilterRef}
                editColumnRef={editColumnRef}
                columns={columns}
                setColumns={setColumns}
                moveUserRef={moveUserRef}
                moveUser={moveUser}
                setMoveUser={setMoveUser}
            />

            {/* Sortable Table */}

            {loading ? (

                <div className="flex justify-center items-center mt-60">
                    <div className="w-12 h-12 border-t-4 border-buttonPrimary border-solid border-opacity-80 rounded-full animate-spin"></div>
                </div>

            ) : (
                <AllCustomerList
                    allCustomers={customerList[customerMode]}
                    search={search}
                    columns={columns}
                />
            )}

        </div>
    );


}