import React from 'react'

export const SettingSolidIcon = ({ color, height, width }) => {
    return (
        <svg
            width={width === undefined ? "12" : width}
            height={height === undefined ? "12" : height}
            viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M5.05495 11.9257L4.8847 10.1017C4.53619 10.0074 4.20121 9.86874 3.88795 9.6892L2.47795 10.8577C1.9654 10.4856 1.51463 10.035 1.1422 9.5227L2.3107 8.11195C2.13117 7.7987 1.99254 7.46372 1.8982 7.1152L0.074203 6.94495C-0.0247343 6.31934 -0.0247343 5.68207 0.074203 5.05645L1.8982 4.88545C1.99237 4.53688 2.131 4.20188 2.3107 3.8887L1.1422 2.4787C1.51434 1.96615 1.96486 1.51538 2.4772 1.14295L3.8887 2.30995C4.20196 2.13042 4.53694 1.99179 4.88545 1.89745L5.05495 0.074203C5.68057 -0.0247343 6.31784 -0.0247343 6.94345 0.074203L7.11445 1.8982C7.46695 1.99345 7.80145 2.13295 8.1112 2.3107L9.5212 1.1422C10.0338 1.51434 10.4845 1.96486 10.857 2.4772L9.68995 3.8887C9.8677 4.19845 10.0072 4.53295 10.1025 4.88545L11.9265 5.05645C12.0254 5.68207 12.0254 6.31934 11.9265 6.94495L10.1025 7.11595C10.0083 7.46453 9.86965 7.79953 9.68995 8.1127L10.8585 9.5227C10.4863 10.0353 10.0358 10.486 9.52345 10.8585L8.1127 9.68995C7.79945 9.86949 7.46447 10.0081 7.11595 10.1025L6.94495 11.9265C6.31934 12.0254 5.68207 12.0254 5.05645 11.9265L5.05495 11.9257ZM5.99995 8.24995C6.59669 8.24995 7.16899 8.0129 7.59094 7.59094C8.0129 7.16899 8.24995 6.59669 8.24995 5.99995C8.24995 5.40322 8.0129 4.83092 7.59094 4.40896C7.16899 3.98701 6.59669 3.74995 5.99995 3.74995C5.40322 3.74995 4.83092 3.98701 4.40896 4.40896C3.98701 4.83092 3.74995 5.40322 3.74995 5.99995C3.74995 6.59669 3.98701 7.16899 4.40896 7.59094C4.83092 8.0129 5.40322 8.24995 5.99995 8.24995Z"
                fill={color === undefined ? "#545969" : color} />
        </svg>
    )
}
