import { SmallHorizontalMenuIcon } from 'components/icons/SmallHorizontalMenuIcon'
import DateFormat from 'Utils/DateFormat'
import { DownArrowIcon } from 'components/icons/DownArrowIcon'
import { useEffect, useRef, useState } from 'react'
import { EditIcon } from 'components/icons/EditIcon'
import { DeleteIcon } from 'components/icons/DeleteIcon'
import { useTeamsContext } from 'Context/TeamsContext/TeamsContext'

const TeamRow = ({ key, item }) => {
    const { handleDelete, setToggleEdit } = useTeamsContext();

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const menuRef = useRef(null);
    const [menuToggle, setMenuToggle] = useState({ isOpen: false, id: null });

    const handleToggle = (id) => {
        setMenuToggle({ isOpen: !menuToggle.isOpen, id: id });
    }

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                setDropdownOpen(false);
            }
            if (menuRef.current && !menuRef.current.contains(e.target)) {
                setMenuToggle({ isOpen: false, id: null });
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    }, []);

    return (
        <tr key={key}>
            <td className='py-3 flex items-center gap-3'>
                <div className='font-bold text-center text-white text-xl bg-[#F03E3E] rounded-full p-2 px-[15px]'>{item.username.split('')[0]}</div>
                <div>
                    <h1 className='font-normal text-sm text-[#181818]'>{item.username}</h1>
                    <span className='font-normal text-xs text-[#999999]'>{item.email}</span>
                </div>
            </td>
            <td className='py-3 font-normal text-sm text-[#181818]'>{item.username}</td>
            <td className='py-3 font-normal text-sm text-[#181818]'>Active</td>
            <td className='py-3 font-normal text-sm text-[#181818]'>{DateFormat(item.created_at)}</td>
            <td className='py-3 font-normal text-sm text-[#2b2b2b55]'>
                <div className='relative cursor-pointer'>
                    <div className={`flex items-center justify-between gap-2 border hover:border-[#10111a7e] duration-300 max-w-[150px] rounded-lg px-3 py-[4px] ${dropdownOpen ? "border-[#10111a7e]" : "border-[#10111A29]"}`}
                        onClick={() => setDropdownOpen(!dropdownOpen)}>
                        <h1 className='text-[#2b2b2b55] font-semibold text-sm capitalize'>{item.role}</h1>
                        <DownArrowIcon color="#7C8689" />
                        {dropdownOpen && (
                            <div ref={dropdownRef} className='absolute bg-white z-20 top-10 border border-gray-200 rounded-lg shadow-md p-2 w-full max-w-[200px] max-h-[150px] overflow-y-auto left-0'>
                                <div className='py-1'>
                                    <ul>
                                        <li className='hover:text-buttonPrimary py-[2px] text-[16px] text-gray-600'>Admin</li>
                                        <li className='hover:text-buttonPrimary py-[2px] text-[16px] text-gray-600'>Editor</li>
                                    </ul>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </td>
            <td className='py-3 font-normal text-sm text-[#181818]'>Owner</td>
            <td className='py-3 font-normal text-sm text-[#181818] cursor-pointer relative' onClick={() => { handleToggle(item.id) }}>
                <SmallHorizontalMenuIcon />
                {menuToggle.isOpen && menuToggle.id === item.id && (
                    <div ref={menuRef} className='absolute bg-white z-20 top-10 border border-gray-200 rounded-lg shadow-md p-2  w-[100px] max-w-[200px] max-h-[150px] overflow-y-auto right-[15px]'>
                        <div className='py-1'>
                            <ul className='flex flex-col gap-4'>
                                <li className='hover:text-buttonPrimary py-[2px] text-[16px] text-gray-600 flex items-center gap-2' onClick={() => { setToggleEdit({ isOpen: true, data: item }) }}><EditIcon /> Edit</li>
                                <li className='hover:text-buttonPrimary py-[2px] text-[16px] text-gray-600 flex items-center gap-2' onClick={() => { handleDelete(item.id) }}><DeleteIcon /> Delete</li>
                            </ul>
                        </div>
                    </div>
                )}
            </td>
        </tr>
    )
}

export default TeamRow;
