import React from 'react'

export const ExteriorIcon = ({ color, height, width }) => {
    return (
        <svg
            width={width != undefined ? width : "40"}
            height={height != undefined ? height : "40"}
            viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_634_3325)">
                <path d="M22.1482 10.3601L30.3798 3.95841C30.7003 3.70944 31.1006 3.58596 31.5057 3.61112C31.9107 3.63628 32.2926 3.80836 32.5798 4.09507L37.5715 9.08507C37.8587 9.37237 38.031 9.75464 38.0562 10.1601C38.0814 10.5655 37.9576 10.9661 37.7082 11.2867L31.3082 19.5167C31.7594 21.3574 31.7865 23.2766 31.3874 25.1292C30.9883 26.9819 30.1734 28.7197 29.0043 30.2113C27.8352 31.7029 26.3424 32.9093 24.6387 33.7395C22.9351 34.5697 21.065 35.0019 19.1698 35.0034C13.3332 35.0034 5.83317 32.5001 1.6665 30.0001C8.29984 25.0001 6.74484 21.9834 7.49984 19.1667C9.26317 12.5834 15.5698 8.73841 22.1482 10.3601ZM27.8365 13.4917C27.9448 13.5967 28.0532 13.7051 28.1582 13.8151L30.0498 15.7051L34.1748 10.4017L31.2648 7.49174L25.9615 11.6167L27.8365 13.4917Z"
                    fill={color != undefined ? color : "#7F7F7F"} />
            </g>
            <defs>
                <clipPath id="clip0_634_3325">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}
