import { useAlertContext } from "Context/AlertContext";
import axiosClient from "axios.client";
import { createContext, useContext, useEffect, useState } from "react";

export const TeamsContext = createContext();

export const TeamsContextProvider = ({ children }) => {
    const { SetAlert } = useAlertContext();

    const [team, setTeam] = useState([]);

    const [toggleEdit, setToggleEdit] = useState({ isOpen: false, date: [] });
    const [addTeamToggle, setAddTeamToggle] = useState(false);
    const [addTeamLoading, setAddTeamLoading] = useState(false);
    const [TeamForm, setTeamForm] = useState([]);

    const handleInputChange = (e) => {
        setTeamForm({ ...TeamForm, [e.target.name]: e.target.value });
    };

    const addTeam = async (e) => {
        e.preventDefault();

        setAddTeamLoading(true);
        try {
            const res = await axiosClient.post('signup', TeamForm);
            setTeam(res.data);
            if (res.status === 200) {
                SetAlert(true, 'success', 'Team Added Successfully');
                setAddTeamToggle(false);
                setTeamForm([]);
            } else {
                SetAlert(true, 'failed', 'Something went wrong! Please try again.')
            }
        } catch (error) {
            SetAlert(true, 'failed', 'Failed to add Team')
        }

        setAddTeamLoading(false);
    };

    const updateTeam = async (id) => {

        setAddTeamLoading(true);
        try {
            const res = await axiosClient.post(`updateTeam/${id}`, TeamForm);
            setTeam(res.data);
            if (res.status === 200) {
                SetAlert(true, 'success', 'Team Updated Successfully');
                setToggleEdit(false);
                setTeamForm([]);
            } else {
                SetAlert(true, 'failed', 'Something went wrong! Please try again.')
            }
        } catch (error) {
            SetAlert(true, 'failed', 'Failed to update Team')
        }

        setAddTeamLoading(false);
    };

    const [loading, setLoading] = useState(false);

    const getTeam = async () => {
        setLoading(true);

        try {
            const res = await axiosClient.get('getTeam');
            setTeam(res.data);
        } catch (error) {
            SetAlert(true, 'failed', 'Failed to fetch Team')
        }
        setLoading(false);
    }

    const handleDelete = async (id) => {
        try {
            const res = await axiosClient.get(`deleteTeam/${id}`);
            setTeam(res.data);
            SetAlert(true, 'success', 'Team Deleted Successfully');
        } catch (error) {
            SetAlert(true, 'failed', 'Failed to delete Team')
        }
    }

    useEffect(() => {
        getTeam();
    }, []);

    return (
        <TeamsContext.Provider value={{
            loading,
            team,
            setAddTeamToggle,
            addTeamToggle,
            addTeam,
            addTeamLoading,
            handleInputChange,
            TeamForm,
            setTeamForm,
            handleDelete,
            toggleEdit,
            setToggleEdit,
            updateTeam,
        }}>
            {children}
        </TeamsContext.Provider>
    );
};

export const useTeamsContext = () => {
    return useContext(TeamsContext);
};