import React from 'react';

export const Input = ({ value, inputName, errors, handleInputChange, formData }) => {
    return (
        <div className="flex-1">
            <div className="relative">
                <input
                    type="text"
                    id={inputName}
                    aria-describedby={`${inputName}_help`}
                    className="block px-2.5 pb-3 pt-5 w-full text-[16px] text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white gray:border-gray-300 gray:focus:border-gray-300 focus:outline-none focus:ring-0 focus:border-gray-300 peer"
                    placeholder=""
                    name={inputName}
                    value={formData[inputName]}
                    onChange={handleInputChange}
                />
                <label
                    htmlFor={inputName}
                    className="absolute text-[16px] text-gray-600 dark:text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                >
                    {value}
                </label>
            </div>

            {errors[inputName] && (
                <p id={`${inputName}_help`} className="mt-2 text-[16px] text-red-600 dark:text-gray-400">
                    <span className="font-medium">{errors[inputName]}</span>
                </p>
            )}
        </div>
    );
};
