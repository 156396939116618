import React from 'react'

export const Transmition = ({ color, height, width }) => {
    return (
        <svg
            width={width != undefined ? width : "40"}
            height={height != undefined ? height : "40"}
            viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_634_3338)">
                <path d="M19.9998 1.6665L35.8332 10.8332V29.1665L19.9998 38.3332L4.1665 29.1665V10.8332L19.9998 1.6665ZM19.9998 24.9998C21.3259 24.9998 22.5977 24.4731 23.5354 23.5354C24.4731 22.5977 24.9998 21.3259 24.9998 19.9998C24.9998 18.6738 24.4731 17.402 23.5354 16.4643C22.5977 15.5266 21.3259 14.9998 19.9998 14.9998C18.6738 14.9998 17.402 15.5266 16.4643 16.4643C15.5266 17.402 14.9998 18.6738 14.9998 19.9998C14.9998 21.3259 15.5266 22.5977 16.4643 23.5354C17.402 24.4731 18.6738 24.9998 19.9998 24.9998Z" fill={color != undefined ? color : "#7F7F7F"} />
            </g>
            <defs>
                <clipPath id="clip0_634_3338">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}
