import React, { useState, useEffect, useRef } from 'react';

const SearchInput = ({ list, userName, setSelectedDealershipName }) => {
    const [searchTerm, setSearchTerm] = useState(null);
    const [searchResults, setSearchResults] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const inputRef = useRef(null);

    // Function to handle input change
    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
    };

    // Function to handle input focus
    const handleInputFocus = () => {
        setShowResults(true);
    };

    // Function to handle selecting a user
    const handleUserSelect = (user) => {
        setSelectedDealershipName(user.name);
        setSearchTerm(user.name);
        setShowResults(false);
    };

    // Effect to filter search results based on search term
    useEffect(() => {
        const results = list?.filter(user =>
            user?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
        );
        setSearchResults(results);
    }, [searchTerm]);

    // Effect to handle click outside of the input field
    useEffect(() => {
        if (userName !== undefined || userName !== "" || userName !== null) {
            setSelectedDealershipName(userName);
            setSearchTerm(userName);
        }

        const handleOutsideClick = (e) => {
            if (inputRef.current && !inputRef.current.contains(e.target)) {
                setShowResults(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    return (
        <div className='relative' ref={inputRef}>
            <input
                type="search"
                name="dealerShip"
                value={searchTerm || ""}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                placeholder="Search users..."
                className='w-full border border-gray-200 rounded-md placeholder:text-sm placeholder:font-[400] px-2 py-[3px] focus:shadow-blue-100 focus:shadow-md outline-none duration-300'
            />
            {showResults && (
                <ul className='absolute bg-white w-full border border-gray-200 rounded-md mt-2 p-2 max-h-[150px] overflow-y-auto'>
                    {searchResults.map(user => (
                        <li className='hover:bg-slate-100 rounded-sm p-[1px] cursor-pointer' key={user.id} onClick={() => handleUserSelect(user)}>
                            <span className='px-2'>{user.name}</span>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default SearchInput;
