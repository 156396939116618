import { useCustomerDetailContext } from 'Context/customerDetailContext/CustomerDetailContext';
import React from 'react'

export const MetaData = () => {
    const {
        customerDetail,
    } = useCustomerDetailContext();

    return (
        <>
            <div className='py-2 flex flex-col gap-3 text-sm'>
                <div className='flex items-center justify-between'>
                    <h1 className='text-[#687385] font-[400] text-sm'>Source</h1>
                    <h2 className='text-[#414552] font-[400] text-sm'>{customerDetail.inventory_source ? customerDetail.inventory_source : "-"}</h2>
                </div>

                <div className='flex items-center justify-between'>
                    <h1 className='text-[#687385] font-[400] text-sm'>Package</h1>
                    <h2 className='text-[#414552] font-[400] text-sm'>{customerDetail.package || "-"}</h2>
                </div>

                <div className='flex items-center justify-between'>
                    <h1 className='text-[#687385] font-[400] text-sm'>Users</h1>
                    <h2 className='text-[#414552] font-[400] text-sm'>{customerDetail.user_count || "-"}</h2>
                </div>
            </div>
        </>
    )
}
