import axiosClient from "axios.client";
import { createContext, useContext, useState } from "react";
import { useCustomerContext } from "./CustomerContext";

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
    const {
        allCustomers,
        setAllUsers,
        setUserList,
        SetAlert,
        setCheckedStatus,
    } = useCustomerContext();

    const [userCheckedStatus, setUserCheckedStatus] = useState([]);
    const [toggleAddUser, setToggleAddUser] = useState(false);
    const [toggleFtpModel, setToggleFtpModel] = useState({ id: "", isBool: false });

    const [selectedDealershipName, setSelectedDealershipName] = useState(
        allCustomers.length > 0 ? allCustomers[0].name : ""
    );

    const [formData, setFormData] = useState({
        name: selectedDealershipName,
        first_name: "",
        last_name: "",
        username: "",
        email: "",
        password: "",
        user_id: "",
        phone: "",
        address: "",
        status: "",
        inventory_source: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormData({ ...formData, [name]: value });
    };

    const formSubmit = async (e) => {
        e.preventDefault();

        if (selectedDealershipName !== "") {
            formData.name = selectedDealershipName;
        } else if (formData.name === "") {
            formData.name = allCustomers[0].name;
        }

        try {
            const UsersData = await axiosClient.post("/addUser", formData);

            if (UsersData.status === 200) {
                setUserCheckedStatus([]);
                setAllUsers(UsersData?.data?.data || []);

                const allUser = UsersData.data.data.filter(user => (user.status === "active") || (user.status === null));

                const frozen = UsersData?.data?.data.filter(user => user.freeze === 1);

                const supportUser = UsersData.data.data.filter(user => user.status === "support" || user.package === "Support");

                const canceledUser = UsersData.data.data.filter(user => user.status === "canceled" || user.package === "Canceled");

                setUserList({
                    all: allUser,
                    frozen: frozen,
                    support: supportUser,
                    canceled: canceledUser,
                });

                setToggleAddUser(false);
                setFormData({
                    name: formData.name,
                    first_name: "",
                    last_name: "",
                    username: "",
                    email: "",
                    password: "",
                    user_id: "",
                    phone: "",
                    address: "",
                    status: "",
                    inventory_source: "",
                })

                SetAlert(true, "success", "Successfully added user");
                window.location.reload();
            } else {
                SetAlert(true, "error", "Failed to add user");
            }

        } catch (e) {
            console.error("Error submitting form:", e);
            SetAlert(true, "error", "Failed to add user");
        }
    };

    return (
        <UserContext.Provider value={{
            userCheckedStatus,
            setUserCheckedStatus,

            toggleAddUser,
            setToggleAddUser,
            formData,
            handleInputChange,
            selectedDealershipName,
            setSelectedDealershipName,
            formSubmit,

            toggleFtpModel,
            setToggleFtpModel,
        }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUserContext = () => {
    return useContext(UserContext);
};