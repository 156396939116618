import React from 'react'

export default function CloseIcon() {
  return (
    <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="#fff"
        xmlns="http://www.w3.org/2000/svg"
        className="mr-2"
      >
        <path
          d="M1.28534 10.7133L10.7147 1.28662M1.28534 1.28662L10.7147 10.7133"
          stroke="#fff"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
  )
}