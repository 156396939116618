import { MagnifyingGlassIcon } from 'components/icons/MagnifyingGlassIcon';
import { ActiveIcon } from 'components/icons/ActiveIcon';
import { PendingIcon } from 'components/icons/PendingIcon';
import { CruiseIcon } from 'components/icons/CruiseIcon';
import { ListedVehicleIcon } from 'components/icons/ListedVehicleIcon';
import ProfileImage from 'assets/images/CarImage.jpg';
import { useCustomerDetailContext } from 'Context/customerDetailContext/CustomerDetailContext';
import Listed from './components/Listed';
import Pending from './components/Pending';
import Advertised from './components/Advertised';

export default function ManageVehicleMenu() {
    const {
        manageVehicleData,
        type,
        setType,
        vehicleSearch,
        setVehicleSearch,
    } = useCustomerDetailContext();

    return (
        <div className='w-[330px] border-r border-[#E4E8ED] pt-[18px]'>
            <div className='flex items-center justify-between px-[15px]'>
                <div className='flex items-center gap-2'>
                    <img src={ProfileImage} alt="demo_profile_image" className='rounded-full w-10 h-10' />
                    <h1 className='font-semibold'>{manageVehicleData?.user?.username}</h1>
                </div>
                <button className='text-buttonPrimary font-semibold'>Restart App</button>
            </div>

            <div className='flex items-center gap-2 pt-5 px-[15px]'>
                <MagnifyingGlassIcon />
                <input type="search" className='outline-none p-2 w-full' placeholder='search a vehicle...'
                    autoComplete='off'
                    name='search'
                    value={vehicleSearch.value}
                    onChange={(e) => { setVehicleSearch({ page: type, value: e.target.value }) }}
                />
            </div>

            <div className='flex items-center justify-between border-b py-5 font-medium px-[15px]'>
                <button className={`${type === "listed" ? "text-buttonPrimary" : "#1B74E4"} flex flex-col items-center`}
                    onClick={() => { setType('listed') }}>
                    <ListedVehicleIcon color={`${type === "listed" ? "#1B74E4" : "#8E93A6"}`} width={24} height={24} /> <span>Vehicles</span>
                </button>

                <button className={`${type === "active" ? "text-buttonPrimary" : "#1B74E4"} flex flex-col items-center`}
                    onClick={() => { setType('active') }}>
                    <ActiveIcon width={24} height={24} color={`${type === "active" ? "#1B74E4" : "#8E93A6"}`} /> <span>Active</span>
                </button>

                <button className={`${type === "pending" ? "text-buttonPrimary" : "#1B74E4"} flex flex-col items-center`}
                    onClick={() => { setType('pending') }}>
                    <PendingIcon width={24} height={24} color={`${type === "pending" ? "#1B74E4" : "#8E93A6"}`} /> <span>Pending</span>
                </button>

                <button className={`${type === "cruise" ? "text-buttonPrimary" : "#1B74E4"} flex flex-col items-center`}
                    onClick={() => { setType('cruise') }}>
                    <CruiseIcon width={25} height={25} color={`${type === "cruise" ? "#1B74E4" : "#8E93A6"}`} /> <span>Cruise</span>
                </button>
            </div>

            <div>
                <div className='p-1 px-[15px]' style={{
                    overflowY: "auto",
                    height: "700px"
                }}>

                    <>
                        {type === "listed" &&
                            <Listed />}

                        {type === "pending" &&
                            <Pending />}

                        {type === "active" &&
                            <Advertised />}

                        {/* {type === "cruise" &&
                            <Advertised />} */}
                    </>

                </div>
            </div>

        </div>
    );
}