import React from "react";
import { ExitIcon } from "../../../../components/icons/ExitIcon";

const BulkDescriptionEditModel = ({
  description,
  setOpenDescriptionModel,
  handleBulkDescription,
  handleBulkDescriptionUpdate,
}) => {
  return (
    <div className="block fixed z-[51] w-full h-full bg-black bg-opacity-30">
      <div className="overflow-y-auto overflow-x-hidden flex items-center justify-center h-screen">
        <div className="relative p-4 w-full max-w-xl h-full md:h-auto">
          <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
            <div className="flex items-center justify-between mb-2">
              <h1 className="font-semibold text-lg">Edit Description</h1>
              <button
                onClick={() =>
                  setOpenDescriptionModel({
                    isOpen: false,
                    data: [],
                  })
                }
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-[16px] p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              >
                <ExitIcon />
              </button>
            </div>

            <form onSubmit={handleBulkDescriptionUpdate}>
              <div>
                <textarea
                  name="description"
                  cols="30"
                  rows="10"
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  placeholder="description..."
                  value={description}
                  onChange={(e) => handleBulkDescription(e)}
                ></textarea>
              </div>

              <div className="flex justify-center items-center pt-5">
                <button
                  type="submit"
                  className="font-normal flex items-center gap-1 bg-blue-500 text-white px-4 py-2 rounded-md text-[16px] text-center cursor-pointer"
                >
                  Confirm
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkDescriptionEditModel;
