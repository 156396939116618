import { useCallback, useEffect, useRef, useState } from "react";
import FormatID from "Utils/FormatID";
import { CountPending } from "components/Button/CountPending";
import { FrozenIcon } from "components/icons/FrozenIcon";
import { SortingIcon } from "components/icons/SortingIcon";
import { useNavigate } from "react-router-dom";
import { MenuIcon } from "components/icons/MenuIcon";
import PlusIcon from "components/icons/PlusIcon";
import NIcon from "components/icons/NIcon";
import { DropdownItem } from "./components/DropDownItem";
import { useUserContext } from "Context/UserContext";
import { SettingSolidIcon } from "components/icons/SettingSolidIcon";
import { SettingIcon } from "components/icons/SettingIcon";


export default function AllUserList({
    allUsers,
    search,
    vehicleCountLoading,
    userVehicleCount,
    columns,
}) {
    const navigate = useNavigate();

    const {
        setToggleFtpModel,
        userCheckedStatus,
        setUserCheckedStatus,
    } = useUserContext();

    const getCount = useCallback(
        (userId, status) => {
            if (vehicleCountLoading) {
                return <CountPending />;
            } else {
                if (status === "listed")
                    return userVehicleCount.listed[userId]
                        ? userVehicleCount.listed[userId]
                        : "0";
                else if (status === "pending")
                    return userVehicleCount.pending[userId]
                        ? userVehicleCount.pending[userId]
                        : "0";
                else if (status === "advertised")
                    return userVehicleCount.advertised[userId]
                        ? userVehicleCount.advertised[userId]
                        : "0";
            }
        },
        [userVehicleCount, vehicleCountLoading]
    );

    const handleCheckboxChange = (id) => {
        setUserCheckedStatus((prevUserCheckedStatus) => {
            const updatedUserCheckedStatus = { ...prevUserCheckedStatus };

            if (updatedUserCheckedStatus[id]) {
                delete updatedUserCheckedStatus[id];
            } else {
                updatedUserCheckedStatus[id] = true;
            }
            return updatedUserCheckedStatus;
        });
    };

    // Apply search filter
    const filteredUsers = allUsers.filter((user) => {
        if (search.pageName === "allUsers") {
            return (
                search.value.toLowerCase() === "" ||
                user.username.toLowerCase().includes(search.value.toLowerCase()) ||
                user.id.toString().toLowerCase().includes(search.value.toLowerCase())
            );
        }
        return user;
    });

    // Paginate the filtered results
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 20;

    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const displayedUsers = filteredUsers.slice(startIndex, endIndex);

    const totalPages = Math.ceil(filteredUsers.length / pageSize);

    const handlePrevious = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const handleNext = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    const [rowMenu, setRowMenu] = useState({ isOpen: false, id: null });
    const dropdownRef = useRef(null);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setRowMenu(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="border-t pt-4 min-h-[100%] w-full">
            <div className=''>
                <table className="w-full">
                    <thead className="sticky-top-4 bg-white z-10 text-[12px]">
                        <tr>
                            <th className='font-semibold text-left py-2 flex items-center gap-[12px]'>
                                <input type="checkbox" className="w-[14px] rounded border-gray-300"
                                    onChange={() => {
                                        if (Object.keys(userCheckedStatus).length === filteredUsers.length) {
                                            setUserCheckedStatus({});
                                        } else {
                                            const newCheckedStatus = {};
                                            filteredUsers.forEach((user) => {
                                                newCheckedStatus[user.id] = true;
                                            });
                                            setUserCheckedStatus(newCheckedStatus);
                                        }
                                    }}
                                    checked={Object.keys(userCheckedStatus).length === filteredUsers.length}
                                />

                                ID</th>
                            {
                                columns.map((col, index) => {
                                    if (col.active) {
                                        return (
                                            <th key={index}
                                                className={`font-semibold text-left`}>
                                                {
                                                    col.id === 1 ? col.name :
                                                        <span className={`flex items-center gap-3`}> {col.name} <SortingIcon /> </span>
                                                }
                                            </th>
                                        )
                                    }
                                })
                            }
                            <th className="font-semibold text-left py-2">
                                <span className="flex items-center gap-3">MANAGE</span>
                            </th>
                            <th className="font-semibold text-left py-2">
                                <span className="flex items-center gap-3">Edit</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {allUsers.length > 0 ?
                            displayedUsers.map((user, index) => (
                                <tr className={`border-y cursor-pointer text-[14px] ${user.freeze === 1 && "bg-rose-50"} hover:bg-gray-100 duration-300`} key={user.id}>
                                    <td className="px-1 py-2 font-semibold text-left flex items-center gap-[12px]">
                                        <input
                                            type="checkbox"
                                            className="w-[14px] cursor-pointer rounded border-gray-300"
                                            checked={userCheckedStatus[user.id] || false}
                                            onChange={() => handleCheckboxChange(user.id)}
                                        />
                                        {FormatID(user.id)}
                                    </td>
                                    {
                                        columns.map((col, index) => {
                                            if (col.active) {
                                                if (col.dbKey === "status") {
                                                    return (
                                                        <td key={index} className="px-1 py-2 text-left">
                                                            {(user[col.dbKey] === 'active' || user[col.dbKey] === null) ?
                                                                <div className="flex gap-[5px]">
                                                                    <div className="h-3 w-3 bg-green-500 rounded-full mt-1"></div>
                                                                    Online
                                                                </div> :
                                                                <div className="flex gap-[5px]">
                                                                    {user[col.dbKey] === "support" && "Support"}
                                                                    {user[col.dbKey] === "canceled" && "Canceled"}
                                                                </div>
                                                            }
                                                        </td>
                                                    )
                                                }
                                                if (col.dbKey === "listed" || col.dbKey === "pending" || col.dbKey === "advertised") {
                                                    return (
                                                        <td key={index} className={`px-1 py-2 text-left ${col.dbKey === "pending" ? "text-blue-500" : "text-[#596171]"}`}>
                                                            {getCount(user.id, col.dbKey)}
                                                        </td>
                                                    )
                                                }
                                                return (
                                                    <td key={index} className={`px-1 py-2 text-left text-[#596171]`}>
                                                        {user[col.dbKey] ? user[col.dbKey] : "-"}
                                                    </td>
                                                )
                                            }
                                        })
                                    }

                                    <td className="px-1 py-2 flex gap-2 items-center">

                                        <button
                                            className="bg-[#EAF3FF] pt-[5px] pr-[8px] pb-[5px] pl-[7px] rounded-lg items-center text-black text-[14px] flex gap-[8px] hover:bg-[#DDE8FF] duration-200"
                                            onClick={() => navigate(`/dealership/manage-vehicles/${user.id}`)}>
                                            <span className='flex items-center gap-[4px]'>
                                                <SettingIcon color="black" /> Manage
                                            </span>
                                        </button>

                                        <button
                                            className="bg-[#EAF3FF] pt-[5px] pr-[8px] pb-[5px] pl-[7px] rounded-lg items-center text-black text-[14px] flex gap-[8px] hover:bg-[#DDE8FF] duration-200"
                                            onClick={() => navigate(`/connect/${user.id}`)}>
                                            <span className='flex items-center gap-[4px]'>
                                                Connect <PlusIcon color={"#000"} />
                                            </span>
                                        </button>

                                        {/* <button
                                            className="border border-gray-300 bg-white text-black rounded-md p-[7px] px-3"
                                            onClick={() => navigate(`/dealership/view-logs/${user.id}`)}
                                        >
                                            View Logs
                                        </button> */}
                                    </td>
                                    <td className="px-1 py-2 text-left text-[#596171]">
                                        <div className="flex items-center gap-5" onClick={() => {
                                            setRowMenu({
                                                isOpen: !rowMenu.isOpen,
                                                id: user.id
                                            })
                                        }}>
                                            <div className="relative" ref={dropdownRef}>
                                                <button>
                                                    <MenuIcon color="#666666" />
                                                </button>

                                                <div className={`dropdown-menu ${rowMenu.isOpen && rowMenu.id === user.id ? 'active' : 'inactive'} border border-gray-200 rounded-md shadow-md text-xs`} >
                                                    <ul>
                                                        <span onClick={() =>
                                                            setToggleFtpModel({
                                                                id: user.id,
                                                                isBool: true,
                                                                ftpPath: user.ftp_file_path,
                                                                FTPaccount: user.FTPaccount,
                                                                assignedFtpAccounts: user.assignedFtpAccounts
                                                            })
                                                        }>
                                                            <DropdownItem text={"FTP"} />
                                                        </span>
                                                        {/* <DropdownItem text={"Edit"} /> */}
                                                    </ul>
                                                </div>
                                            </div>

                                            {user.freeze === 1 &&
                                                <FrozenIcon />
                                            }
                                        </div>
                                    </td>
                                </tr>
                            )) :
                            <tr>
                                <td colSpan="10" className="text-center py-20">
                                    Nothing to show here
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>

            <div className="flex items-center justify-between my-4">
                <div className="">
                    <span className="font-semibold text-[14px]">{filteredUsers.length}</span> {filteredUsers.length > 1 ? "results" : "result"}
                </div>

                <div>
                    {totalPages > 1 && (
                        <div className="flex items-center gap-4">
                            <button onClick={handlePrevious} disabled={currentPage === 1}
                                className={`h-[26px] w-[70px] text-sm border border-gray-300 rounded-lg font-semibold ${currentPage === 1 && "text-[#9a9ca1]"}`}>
                                Previous
                            </button>

                            <button onClick={handleNext} disabled={currentPage === totalPages}
                                className={`h-[26px] w-[70px] text-sm border border-gray-300 rounded-lg font-semibold ${currentPage === totalPages && "text-[#9a9ca1]"}`}>
                                Next
                            </button>
                        </div>
                    )}
                </div>
            </div>

        </div>
    );
}