import { createContext, useContext, useState } from "react";

export const SearchContext = createContext();

export const SearchContextProvider = ({ children }) => {
    const [search, setSearch] = useState({ pageName: "", value: "" });

    const [selectedPage, setSelectedPage] = useState("allCustomers");

    return (
        <SearchContext.Provider value={{
            search,
            setSearch,
            selectedPage,
            setSelectedPage,
        }}>
            {children}
        </SearchContext.Provider>
    );
};

export const useSearchContext = () => {
    return useContext(SearchContext);
};
