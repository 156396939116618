import { useCustomerContext } from 'Context/CustomerContext'
import { useUserContext } from 'Context/UserContext'
import axiosClient from 'axios.client'
import SmallLoading from 'components/Loadings/SmallLoading'
import { CopyIcon } from 'components/icons/CopyIcon'
import { DownArrowIcon } from 'components/icons/DownArrowIcon'
import { DragIcon } from 'components/icons/DragIcon'
import { ExportIcon } from 'components/icons/ExportIcon'
import { PlusDashIcon } from 'components/icons/PlusDashIcon'
import PlusIcon from 'components/icons/PlusIcon'
import { RightMarkToggleIcon } from 'components/icons/RightMarkToggleIcon'
import { SettingIcon } from 'components/icons/SettingIcon'
import { SettingSolidIcon } from 'components/icons/SettingSolidIcon'
import React, { useEffect, useRef, useState } from 'react'

const SecondaryFilters = ({
    moreFilterIsOpen,
    setMoreFilterIsOpen,
    editColumnIsOpen,
    setEditColumnIsOpen,
    moreFilterRef,
    editColumnRef,
    columns,
    setColumns,
}) => {
    const {
        userCheckedStatus,
        setUserCheckedStatus,
    } = useUserContext();

    const {
        setUserList,
    } = useCustomerContext();

    const changeStatusRef = useRef(null);
    const [changeStatusToggle, setChangeStatusToggle] = useState(false);
    const [changingStatusLoading, setChangeStatusLoading] = useState(false);

    const dragColumn = useRef(0)
    const draggedOverColumn = useRef(0)

    function handleSort() {
        const columnsClone = [...columns];
        const draggedColumn = columnsClone[dragColumn.current];
        columnsClone.splice(dragColumn.current, 1);
        columnsClone.splice(draggedOverColumn.current, 0, draggedColumn);

        setColumns(columnsClone);
        localStorage.setItem('userColumns', JSON.stringify(columnsClone));
    }

    function handleCheckboxChange(e, index) {
        const columnsClone = [...columns];
        columnsClone[index].active = e.target.checked;
        setColumns(columnsClone);
        localStorage.setItem('userColumns', JSON.stringify(columnsClone));
    }

    // handling selected rows and changing the status to frozen etc
    const handlingSelectedRowsStatus = async (status) => {
        setChangeStatusLoading(true)
        let res = [];
        if (status === 'freeze') {
            res = await axiosClient.post('/update-multiple-freeze', {
                freeze: 1,
                ids: Object.keys(userCheckedStatus)
            })

            if (res.status === 200) {
                setUserCheckedStatus([]);
            }

        } else if (status === 'unfreeze') {
            res = await axiosClient.post('/update-multiple-freeze', {
                freeze: 0,
                ids: Object.keys(userCheckedStatus)
            })

            if (res.status === 200) {
                setUserCheckedStatus([]);
            }
        }

        if (res.data.data.length > 0) {
            const allUser = res.data.data.filter(user => (user.status === "active") || (user.status === null));

            const frozen = res.data.data.filter(user => user.freeze === 1);

            const supportUser = res.data.data.filter(user => user.status === "support" || user.package === "Support");

            const canceledUser = res.data.data.filter(user => user.status === "canceled" || user.package === "Canceled");

            setUserList({
                all: allUser,
                frozen: frozen,
                support: supportUser,
                canceled: canceledUser,
            });

            setChangeStatusToggle(false);
        }
        setChangeStatusLoading(false)
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (changeStatusRef.current && !changeStatusRef.current.contains(event.target)) {
                setChangeStatusToggle(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className='flex items-center justify-between pt-[24px] pb-[20px]'>
            <div className='flex items-center gap-3 flex-wrap'>
                <button className='flex items-center gap-2 py-[1px] px-2 text-[14px] text-[#687385] font-semibold border border-dashed hover:border-[#10111a7e] border-[#C0C8D2] rounded-full hover:bg-slate-50 duration-300'> <PlusDashIcon /> Email</button>

                <button className='flex items-center gap-2 py-[1px] px-2 text-[14px] text-[#687385] font-semibold border border-dashed hover:border-[#10111a7e] border-[#C0C8D2] rounded-full hover:bg-slate-50 duration-300'> <PlusDashIcon /> Card</button>

                <div className='relative' ref={moreFilterRef}>
                    <button onClick={() => setMoreFilterIsOpen(!moreFilterIsOpen)} className={`flex items-center gap-2 py-[1px] px-2 text-[14px] text-[#687385] font-semibold border border-dashed hover:border-[#10111a7e]  ${moreFilterIsOpen ? "border-[#10111a7e]" : "border-[#C0C8D2]"} rounded-full hover:bg-slate-50 duration-300`}> <PlusDashIcon /> Created date</button>
                    {moreFilterIsOpen && <>
                        <div className='rotate-[270deg] absolute left-5 top-[20px] z-30'>
                            <RightMarkToggleIcon color="#e7e7e761" width={32} height={32} />
                        </div>
                        <div className='absolute top-10 left-0 w-72 bg-white border border-gray-100 rounded-md shadow-xl p-3 z-20'>
                            <div className='flex flex-col gap-2'>
                                <button className='text-[#1B74E4] text-[14px] font-semibold flex items-center hover:bg-gray-100 rounded-md'><PlusIcon color={'#1B74E4'} /> Discount</button>
                                <button className='text-[#1B74E4] text-[14px] font-semibold flex items-center hover:bg-gray-100 rounded-md'><PlusIcon color={'#1B74E4'} /> Delinquent</button>
                                <button className='text-[#1B74E4] text-[14px] font-semibold flex items-center hover:bg-gray-100 rounded-md'><PlusIcon color={'#1B74E4'} /> Total Spend</button>
                                <button className='text-[#1B74E4] text-[14px] font-semibold flex items-center hover:bg-gray-100 rounded-md'><PlusIcon color={'#1B74E4'} /> Payments</button>
                                <button className='text-[#1B74E4] text-[14px] font-semibold flex items-center hover:bg-gray-100 rounded-md'><PlusIcon color={'#1B74E4'} /> Refund</button>
                                <button className='text-[#1B74E4] text-[14px] font-semibold flex items-center hover:bg-gray-100 rounded-md'><PlusIcon color={'#1B74E4'} /> Dispute Losses</button>
                                <button className='text-[#1B74E4] text-[14px] font-semibold flex items-center hover:bg-gray-100 rounded-md'><PlusIcon color={'#1B74E4'} /> First Payment</button>
                                <button className='text-[#1B74E4] text-[14px] font-semibold flex items-center hover:bg-gray-100 rounded-md'><PlusIcon color={'#1B74E4'} /> Last Payment</button>
                                <button className='text-[#1B74E4] text-[14px] font-semibold flex items-center hover:bg-gray-100 rounded-md'><PlusIcon color={'#1B74E4'} /> In recovery</button>
                            </div>
                        </div>
                    </>}
                </div>

                <button className='flex items-center gap-2 py-[1px] px-2 text-[14px] text-[#687385] font-semibold border border-dashed hover:border-[#10111a7e] border-[#C0C8D2] rounded-full hover:bg-slate-50 duration-300'> <PlusDashIcon /> Type</button>

                <button className='flex items-center gap-2 py-[1px] px-2 text-[14px] text-[#687385] font-semibold border border-dashed hover:border-[#10111a7e] border-[#C0C8D2] rounded-full hover:bg-slate-50 duration-300'> <PlusDashIcon /> More filters</button>

            </div>

            <div className='flex items-center gap-2 text-[14px]'>
                {/* <button className='border border-[#10111A29] hover:border-[#10111a7e] duration-300 hover: px-2 py-1 rounded-md flex items-center font-medium gap-1'><CopyIcon /> Copy</button>

                <button className='border border-[#10111A29] hover:border-[#10111a7e] duration-300 px-2 py-1 rounded-md flex items-center font-medium gap-1'><ExportIcon /> Export</button> */}

                {Object.keys(userCheckedStatus).length > 0 &&
                    <div className='relative' ref={changeStatusRef}>
                        {changingStatusLoading ?
                            <button className={`border border-[#10111A29] hover:border-[#10111a7e] duration-300 px-2 py-1 rounded-md flex items-center font-medium gap-1 ${changeStatusToggle && "border-[#10111a7e]"}`}> <SmallLoading /> &nbsp; Change Status</button>
                            :
                            <button className={`border border-[#10111A29] hover:border-[#10111a7e] duration-300 px-2 py-1 rounded-md flex items-center font-medium gap-1 ${changeStatusToggle && "border-[#10111a7e]"}`} onClick={() => setChangeStatusToggle(!changeStatusToggle)}>Change Status</button>
                        }

                        {changeStatusToggle && <>
                            <div className='rotate-[270deg] absolute right-5 top-[20px] z-30'>
                                <RightMarkToggleIcon color="#e7e7e761" width={32} height={32} />
                            </div><div className='absolute top-10 right-0 w-40 bg-white border border-gray-100 rounded-md shadow-xl p-3 z-20'>
                                <div className='flex flex-col gap-2'>
                                    <div className='text-[14px] font-semibold'>
                                        <ul className='flex flex-col gap-2'>
                                            <li className='hover:bg-blue-50 duration-300 rounded-lg px-2 cursor-pointer' onClick={() => { handlingSelectedRowsStatus('freeze') }}>Freeze</li>
                                            <li className='hover:bg-blue-50 duration-300 rounded-lg px-2 cursor-pointer' onClick={() => { handlingSelectedRowsStatus('unfreeze') }}>UnFreeze</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </>
                        }

                    </div>}

                <div className='relative' ref={editColumnRef}>
                    <button className={`border border-[#10111A29] hover:border-[#10111a7e] duration-300 px-2 py-1 rounded-md flex items-center font-medium gap-1 ${editColumnIsOpen && "border-[#10111a7e]"}`} onClick={() => setEditColumnIsOpen(!editColumnIsOpen)}><SettingIcon /> Edit columns</button>

                    {editColumnIsOpen && <>
                        <div className='rotate-[270deg] absolute right-5 top-[20px] z-30'>
                            <RightMarkToggleIcon color="#e7e7e761" width={32} height={32} />
                        </div><div className='absolute top-10 right-0 w-72 bg-white border border-gray-100 rounded-md shadow-xl p-3 z-20'>
                            <div className='flex flex-col gap-2'>
                                <div className='border-b pb-2'>
                                    <h1 className='text-[14px] font-semibold mb-2'>Fixed columns</h1>
                                    <input className='text-[14px] py-1 outline-none' type='name' placeholder='ID' disabled />
                                </div>
                                <div>
                                    <h1 className='font-semibold text-[16px] mb-2'>Active columns</h1>
                                    {
                                        columns.map((column, index) => (
                                            <div key={column.name}>
                                                <div className='flex items-center justify-between'
                                                    draggable
                                                    onDragStart={() => (dragColumn.current = index)}
                                                    onDragEnter={() => (draggedOverColumn.current = index)}
                                                    onDragEnd={handleSort}
                                                    onDragOver={(e) => e.preventDefault()}
                                                >
                                                    <div className='flex items-center gap-2'>
                                                        <input
                                                            type="checkbox"
                                                            checked={column.active}
                                                            onChange={(e) => { handleCheckboxChange(e, index) }}
                                                            name={column.name}
                                                            id={column.name}
                                                        />

                                                        <label htmlFor={column.name} className='text-gray-400 cursor-pointer'>{column.name}</label>
                                                    </div>
                                                    <div

                                                    >
                                                        <DragIcon />
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                    }

                </div>
            </div>
        </div>
    )
}

export default SecondaryFilters