import React from "react";

export const ExitIcon = ({ width, height }) => {
  return (
    <svg
      width={width === undefined ? "12" : width}
      height={height === undefined ? "12" : height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.28534 10.7133L10.7147 1.28662M1.28534 1.28662L10.7147 10.7133"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
