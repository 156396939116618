import { useCustomerDetailContext } from 'Context/customerDetailContext/CustomerDetailContext';
import SmallLoading from 'components/Loadings/SmallLoading';
import { useAlertContext } from 'Context/AlertContext';
import axiosClient from 'axios.client';
import { useState } from 'react';

const AddUserInCustomerDetailModel = () => {
    const { SetAlert } = useAlertContext();

    const {
        editCustomerDetail,
        setEditCustomerDetail,
    } = useCustomerDetailContext();

    const [customerData, setCustomerData] = useState(editCustomerDetail.customer || {
        id: "",
        name: "",
        website: "",
        state: "",
        city: "",
        phone: "",
        first_name: "",
        last_name: "",
        email: "",
        mobile: "",
        formPackage: "",
        user_count: "",
        amount: "",
        inventory_source: "",
        vehicles: "",
        fb_ads: "",
        fb_ads_budget: "",
        FTPaccount: "",
    });

    const [loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setCustomerData({ ...customerData, [name]: value });
    };

    const formSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);
        try {
            const response = await axiosClient.post(
                "/V2/updateDealerShip",
                customerData
            );
            // .then((res) => console.log(res));

            if (response.data.message === "DealerShip was updated successfully") {
                SetAlert(true, "success", 'Customer updated successfully')
                window.location.reload();
            } else {
                SetAlert(true, "failed", 'Failed to update customer')
            }
        } catch (error) {
            SetAlert(true, "failed", 'Failed to update customer')
        }
        setEditCustomerDetail({ customer: [], isOpen: false })
        setLoading(false);
    }

    return (
        <div className="fixed inset-0 z-[110] bg-[#D3D9DF] bg-opacity-50 flex justify-center items-center font-roboto">
            <div className="bg-white w-[500px] h-[fit-content] max-h-[100vh]  rounded-lg shadow-md overflow-y-auto">
                <div className="flex justify-between items-center p-5">
                    <h1 className="font-[500]">Edit customer</h1>
                </div>
                <hr />
                {/* <h1 className="font-[600] mt-5 px-5">User Information</h1> */}

                <form onSubmit={formSubmit}>
                    <div className="flex flex-col gap-3 px-5 py-5">
                        <div className="flex flex-col gap-2">
                            <label htmlFor="dealerShip" className="font-[500] text-sm">Dealership</label>
                            <input type="name"
                                name="name"
                                onChange={handleInputChange}
                                value={customerData.name}
                                required
                                id="dealerShip"
                                className="border border-gray-200 rounded-md placeholder:text-sm placeholder:font-[400] px-2 py-[3px] focus:shadow-blue-100 focus:shadow-md outline-none duration-300"
                                placeholder="customer name"
                                autoComplete="off"
                            />
                        </div>

                        <div className="flex flex-col gap-2">
                            <label htmlFor="first_name" className="font-[500] text-sm">First Name</label>
                            <input type="name"
                                name="first_name"
                                onChange={handleInputChange}
                                value={customerData.first_name}
                                required
                                id="first_name"
                                className="border border-gray-200 rounded-md placeholder:text-sm placeholder:font-[400] px-2 py-[3px] focus:shadow-blue-100 focus:shadow-md outline-none duration-300"
                                placeholder="Jenny"
                                autoComplete="off"
                            />

                        </div>

                        <div className="flex flex-col gap-2">
                            <label htmlFor="last_name" className="font-[500] text-sm">Last Name</label>
                            <input type="text"
                                name="last_name"
                                id="last_name"
                                className="border border-gray-200 rounded-md placeholder:text-sm placeholder:font-[400] px-2 py-[3px] focus:shadow-blue-100 focus:shadow-md outline-none duration-300"
                                placeholder="Rosen"
                                autoComplete="off"
                                required
                                onChange={handleInputChange}
                                value={customerData.last_name}
                            />
                        </div>

                        <div className="flex flex-col gap-2">
                            <label htmlFor="username" className="font-[500] text-sm">User Name</label>
                            <input type="text"
                                name="username"
                                id="username"
                                className="border border-gray-200 rounded-md placeholder:text-sm placeholder:font-[400] px-2 py-[3px] focus:shadow-blue-100 focus:shadow-md outline-none duration-300"
                                placeholder="JennyRosen"
                                autoComplete="off"
                                required
                                value={customerData.username}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="flex flex-col gap-2">
                            <label htmlFor="website" className="font-[500] text-sm">Website</label>
                            <input type="text"
                                name="website"
                                id="website"
                                className="border border-gray-200 rounded-md placeholder:text-sm placeholder:font-[400] px-2 py-[3px] focus:shadow-blue-100 focus:shadow-md outline-none duration-300"
                                placeholder="https://www.example.com/"
                                autoComplete="off"
                                value={customerData.website}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="flex flex-col gap-2">
                            <label htmlFor="password" className="font-[500] text-sm">Password</label>
                            <input type="text"
                                name="password"
                                id="password"
                                className="border border-gray-200 rounded-md placeholder:text-sm placeholder:font-[400] px-2 py-[3px] focus:shadow-blue-100 focus:shadow-md outline-none duration-300"
                                placeholder="Admin@1122"
                                autoComplete="off"
                                required
                                value={customerData.password}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="flex flex-col gap-2">
                            <label htmlFor="phone" className="font-[500] text-sm">Phone Number</label>
                            <input type="text"
                                name="phone"
                                id="phone"
                                className="border border-gray-200 rounded-md placeholder:text-sm placeholder:font-[400] px-2 py-[3px] focus:shadow-blue-100 focus:shadow-md outline-none duration-300"
                                placeholder="555 555-1234"
                                autoComplete="off"
                                required
                                value={customerData.phone}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="flex flex-col gap-2">
                            <label htmlFor="email" className="font-[500] text-sm">Email</label>
                            <input type="text"
                                name="email"
                                id="email"
                                className="border border-gray-200 rounded-md placeholder:text-sm placeholder:font-[400] px-2 py-[3px] focus:shadow-blue-100 focus:shadow-md outline-none duration-300"
                                placeholder="jenny.rosen@gmail.com"
                                autoComplete="off"
                                required
                                value={customerData.email}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="flex flex-col gap-2">
                            <label htmlFor="address" className="font-[500] text-sm">Address</label>
                            <input type="text"
                                name="address"
                                id="address"
                                className="border border-gray-200 rounded-md placeholder:text-sm placeholder:font-[400] px-2 py-[3px] focus:shadow-blue-100 focus:shadow-md outline-none duration-300"
                                placeholder="Michigan Detroit"
                                autoComplete="off"
                                required
                                value={customerData.address}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="flex flex-col gap-2">
                            <label htmlFor="inventory_source" className="font-[500] text-sm">Source</label>
                            <input type="text"
                                name="inventory_source"
                                id="inventory_source"
                                className="border border-gray-200 rounded-md placeholder:text-sm placeholder:font-[400] px-2 py-[3px] focus:shadow-blue-100 focus:shadow-md outline-none duration-300"
                                placeholder="Inventory source"
                                autoComplete="off"
                                required
                                value={customerData.inventory_source}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="flex flex-col gap-2">
                            <label htmlFor="status" className="font-[500] text-sm">Status</label>
                            <select
                                name="status"
                                onChange={handleInputChange}
                                required
                                id='status'
                                className="border border-gray-200 rounded-md placeholder:text-sm placeholder:font-[400] px-2 py-[3px] focus:shadow-blue-100 focus:shadow-md outline-none font-[500] text-sm duration-300"
                                defaultValue={customerData.status}
                            >
                                <option value="" className="text-gray-200 font-[500] text-sm" disabled>
                                    select option
                                </option>
                                <option value="active" className="text-gray-700 font-[500] text-sm">
                                    Active
                                </option>
                                <option value="inactive" className="text-gray-700 font-[500] text-sm">
                                    Inactive
                                </option>
                                <option value="canceled" className="text-gray-700 font-[500] text-sm">
                                    Canceled
                                </option>
                            </select>
                        </div>
                    </div>

                    <hr />

                    <div className="flex items-center justify-end gap-2 p-5">
                        <span className="bg-white border border-gray-300 rounded-md py-[2px] px-2 font-[500] text-sm hover:shadow-md duration-300 cursor-default" onClick={() => { setEditCustomerDetail({ customer: [], isOpen: false }) }}>Cancel</span>

                        {loading ?
                            <span className="bg-buttonPrimary rounded-md py-[4px] px-2 text-white font-[500] text-sm hover:shadow-md duration-300 flex items-center gap-2">Update user <SmallLoading /></span>
                            :
                            <button className="bg-buttonPrimary rounded-md py-[4px] px-2 text-white font-[500] text-sm hover:shadow-md duration-300">Update user</button>}
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AddUserInCustomerDetailModel