import React from 'react'

export const SortingIcon = () => {
    return (
        <svg className='cursor-pointer' width="12" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_430_25430)">
                <path fillRule="evenodd" clipRule="evenodd" d="M1.95599 10.2429C2.10374 10.0907 2.30589 10.0035 2.51798 10.0003C2.73006 9.99709 2.93474 10.0783 3.08699 10.2259L6.02999 13.0849L8.97299 10.2259C9.12619 10.0833 9.32917 10.0064 9.53841 10.0117C9.74764 10.0171 9.94646 10.1042 10.0922 10.2544C10.238 10.4046 10.3191 10.6059 10.3181 10.8152C10.3171 11.0245 10.2341 11.2251 10.087 11.3739L6.587 14.7739C6.43797 14.9192 6.2381 15.0005 6.02999 15.0005C5.82189 15.0005 5.62202 14.9192 5.47299 14.7739L1.97299 11.3739C1.8208 11.2262 1.73352 11.024 1.73033 10.812C1.72714 10.5999 1.80831 10.3952 1.95599 10.2429ZM5.47299 3.22594C5.62202 3.08068 5.82189 2.99939 6.02999 2.99939C6.2381 2.99939 6.43797 3.08068 6.587 3.22594L10.087 6.62594C10.2341 6.77478 10.3171 6.97536 10.3181 7.18466C10.3191 7.39396 10.238 7.5953 10.0922 7.74551C9.94646 7.89572 9.74764 7.98282 9.53841 7.98813C9.32917 7.99345 9.12619 7.91655 8.97299 7.77394L6.02999 4.91494L3.08699 7.77394C3.01215 7.84964 2.92295 7.90964 2.82462 7.95043C2.72629 7.99122 2.62081 8.01198 2.51436 8.01148C2.40791 8.01098 2.30263 7.98925 2.20468 7.94754C2.10674 7.90584 2.0181 7.845 1.94397 7.76861C1.86984 7.69221 1.8117 7.60179 1.77295 7.50263C1.73421 7.40348 1.71565 7.29759 1.71835 7.19117C1.72105 7.08476 1.74497 6.97995 1.7887 6.88289C1.83242 6.78583 1.89508 6.69847 1.97299 6.62594L5.47299 3.22594Z" fill="#545969" />
            </g>
            <defs>
                <clipPath id="clip0_430_25430">
                    <rect width="12" height="12" fill="white" transform="translate(0.0299988 3)" />
                </clipPath>
            </defs>
        </svg>
    )
}
