import React, { useEffect, useRef, useState } from 'react'
import SideMenu from '../../../../components/sidemenu/SideMenu'
import Header from '../../../../layout/Header/Header'
import { SettingSolidIcon } from '../../../../components/icons/SettingSolidIcon'
import PlusIcon from '../../../../components/icons/PlusIcon'
import { DownArrowIcon } from '../../../../components/icons/DownArrowIcon'
import { PlusDashIcon } from '../../../../components/icons/PlusDashIcon'
import { CopyIcon } from '../../../../components/icons/CopyIcon'
import { ExportIcon } from '../../../../components/icons/ExportIcon'
import { DragIcon } from '../../../../components/icons/DragIcon'
import { SortingIcon } from '../../../../components/icons/SortingIcon'
import { SettingIcon } from '../../../../components/icons/SettingIcon'
import { EditIcon } from '../../../../components/icons/EditIcon'
import { FrozenIcon } from '../../../../components/icons/FrozenIcon'

const Frozen = () => {
    const [moreFilterIsOpen, setMoreFilterIsOpen] = useState(false);
    const [editColumnIsOpen, setEditColumnIsOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const moreFilterRef = useRef(null);
    const editColumnRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (moreFilterRef.current && !moreFilterRef.current.contains(event.target)) {
                setMoreFilterIsOpen(false);
            }
            if (editColumnRef.current && !editColumnRef.current.contains(event.target)) {
                setEditColumnIsOpen(false);
            }
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="px-10 py-5 w-[1300px] text-[16px]">
            {/* Heads */}
            <div className='flex items-center justify-between'>
                <div className='flex flex-col gap-2'>
                    <h1 className='font-semibold text-3xl flex items-center gap-3'>Frozen Users
                        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="0.5" width="12" height="12" rx="6" fill="#F98550" />
                        </svg>
                    </h1>

                    <p className='text-lg text-gray-400'>Showing Users <span className='text-gray-500 font-semibold'>6/6</span></p>
                </div>
                <div className='flex items-center gap-3'>
                    <button className='border border-[#10111A29] px-2 py-1 rounded-md shadow-md flex items-center gap-1'><SettingSolidIcon /> Select Accounts</button>

                    <button className='border border-[#10111A29] px-2 py-1 rounded-md shadow-md flex items-center'><PlusIcon color={'#545969'} /> Filter</button>

                    <button className='bg-buttonPrimary px-2 py-1 rounded shadow-md flex items-center text-white'><PlusIcon /> Add Customer</button>
                </div>
            </div>

            {/* Filter Section */}
            <div className='flex items-center justify-between gap-5 mt-5'>
                <input type="text" value="All Users" className='p-3 pr-6 py-3 flex-1 border border-[#D5DBE1] rounded-xl focus:border-[#1B74E4] focus:outline-buttonPrimary focus:text-[#1B74E4] focus:font-semibold text-[#414552] cursor-pointer' />

                <input type="text" value="Frozen Users" className='p-3 pr-6 py-3 flex-1 border border-[#D5DBE1] rounded-xl focus:border-[#1B74E4] focus:outline-buttonPrimary focus:text-[#1B74E4] focus:font-semibold text-[#414552] cursor-pointer' />

                <input type="text" value="Flagged Users" className='p-3 pr-6 py-3 flex-1 border border-[#D5DBE1] rounded-xl focus:border-[#1B74E4] focus:outline-buttonPrimary focus:text-[#1B74E4] focus:font-semibold text-[#414552] cursor-pointer' />

                <input type="text" value="Repeat Customers" className='p-3 pr-6 py-3 flex-1 border border-[#D5DBE1] rounded-xl focus:border-[#1B74E4] focus:outline-buttonPrimary focus:text-[#1B74E4] focus:font-semibold text-[#414552] cursor-pointer' />

                <div className='border border-[#10111A29] p-3 pr-2 py-3 rounded-xl flex-1 flex items-center'>
                    <button className='text-start text-[#414552] px-3 border-r flex-1'> Recent Customers</button>
                    <button className='text-end text-[#414552] px-1 flex items-center gap-2'>  <DownArrowIcon /></button>
                </div>
            </div>

            <div className='flex items-center justify-between my-5'>
                <div className='flex items-center gap-3'>
                    <button className='flex items-center gap-2 p-1 text-[16px] text-[#687385] font-semibold border border-dashed border-[#C0C8D2] px-2 pr-3 rounded-full'> <PlusDashIcon /> Created date</button>

                    <button className='flex items-center gap-2 p-1 text-[16px] text-[#687385] font-semibold border border-dashed border-[#C0C8D2] px-2 pr-3 rounded-full'> <PlusDashIcon /> Type</button>

                    <div className='border border-[#10111A29] px-l-4 p-1 rounded-full flex items-center gap-1'>
                        <button className='text-end text-[16px] text-[#687385] font-semibold px-3 border-r'> Refunds</button>
                        <button className='text-start text-[16px] text-[#1B74E4] font-semibold px-1 flex items-center gap-2'> More than $0 <DownArrowIcon /></button>
                    </div>

                    <div className='relative' ref={moreFilterRef}>
                        <button onClick={() => setMoreFilterIsOpen(!moreFilterIsOpen)} className='flex items-center gap-2 p-1 text-[16px] text-[#687385] font-semibold border border-dashed border-[#C0C8D2] px-2 pr-3 rounded-full'> <PlusDashIcon /> More filters</button>
                        {moreFilterIsOpen && (
                            <div className='absolute top-10 left-0 w-72 bg-white border border-gray-100 rounded-md shadow-xl p-3'>
                                <div className='flex flex-col gap-2'>
                                    <button className='text-[#1B74E4] text-[16px] font-semibold flex items-center hover:bg-gray-100 rounded-md'><PlusIcon color={'#1B74E4'} /> Discount</button>
                                    <button className='text-[#1B74E4] text-[16px] font-semibold flex items-center hover:bg-gray-100 rounded-md'><PlusIcon color={'#1B74E4'} /> Delinquent</button>
                                    <button className='text-[#1B74E4] text-[16px] font-semibold flex items-center hover:bg-gray-100 rounded-md'><PlusIcon color={'#1B74E4'} /> Total Spend</button>
                                    <button className='text-[#1B74E4] text-[16px] font-semibold flex items-center hover:bg-gray-100 rounded-md'><PlusIcon color={'#1B74E4'} /> Payments</button>
                                    <button className='text-[#1B74E4] text-[16px] font-semibold flex items-center hover:bg-gray-100 rounded-md'><PlusIcon color={'#1B74E4'} /> Refund</button>
                                    <button className='text-[#1B74E4] text-[16px] font-semibold flex items-center hover:bg-gray-100 rounded-md'><PlusIcon color={'#1B74E4'} /> Dispute Losses</button>
                                    <button className='text-[#1B74E4] text-[16px] font-semibold flex items-center hover:bg-gray-100 rounded-md'><PlusIcon color={'#1B74E4'} /> First Payment</button>
                                    <button className='text-[#1B74E4] text-[16px] font-semibold flex items-center hover:bg-gray-100 rounded-md'><PlusIcon color={'#1B74E4'} /> Last Payment</button>
                                    <button className='text-[#1B74E4] text-[16px] font-semibold flex items-center hover:bg-gray-100 rounded-md'><PlusIcon color={'#1B74E4'} /> In recovery</button>
                                </div>
                            </div>
                        )}
                    </div>

                    <button className='text-[#1B74E4] font-semibold'> Clear filters</button>
                </div>

                <div className='flex items-center gap-2'>
                    <button className='border border-[#10111A29] px-2 py-1 rounded-md shadow-md flex items-center gap-1 font-semibold'><CopyIcon /> Copy</button>

                    <button className='border border-[#10111A29] px-2 py-1 rounded-md shadow-md flex items-center gap-1 font-semibold'><ExportIcon /> Export</button>

                    <div className='relative' ref={editColumnRef}>
                        <button className='border border-[#10111A29] px-2 py-1 rounded-md shadow-md flex items-center gap-1 font-semibold' onClick={() => setEditColumnIsOpen(!editColumnIsOpen)} ><SettingSolidIcon /> Edit columns</button>

                        {editColumnIsOpen && (
                            <div className='absolute top-10 right-0 w-72 bg-white border border-gray-100 rounded-md shadow-xl p-3'>
                                <div className='flex flex-col gap-2'>
                                    <div className='border-b pb-2'>
                                        <h1 className='text-[16px] font-semibold mb-2'>Fixed columns</h1>
                                        <input className='text-[16px] py-1 outline-none' type='name' placeholder='Dealership Name' />
                                    </div>
                                    <div>
                                        <h1 className='font-semibold text-[16px] mb-2'>Active columns</h1>
                                        <div className='flex flex-col gap-2'>
                                            <div className='flex items-center justify-between'>
                                                <div className='flex items-center gap-2'>
                                                    <input type="checkbox" name="email" id="email0" />
                                                    <label htmlFor="email0" className='text-gray-400 cursor-pointer'>Email</label>
                                                </div>
                                                <DragIcon />
                                            </div>

                                            <div className='flex items-center justify-between'>
                                                <div className='flex items-center gap-2'>
                                                    <input type="checkbox" name="email" id="email1" />
                                                    <label htmlFor="email1" className='text-gray-400 cursor-pointer'>Email</label>
                                                </div>
                                                <DragIcon />
                                            </div>

                                            <div className='flex items-center justify-between'>
                                                <div className='flex items-center gap-2'>
                                                    <input type="checkbox" name="email" id="email2" />
                                                    <label htmlFor="email2" className='text-gray-400 cursor-pointer'>Email</label>
                                                </div>
                                                <DragIcon />
                                            </div>

                                            <div className='flex items-center justify-between'>
                                                <div className='flex items-center gap-2'>
                                                    <input type="checkbox" name="email" id="email3" />
                                                    <label htmlFor="email3" className='text-gray-400 cursor-pointer'>Email</label>
                                                </div>
                                                <DragIcon />
                                            </div>

                                            <div className='flex items-center justify-between'>
                                                <div className='flex items-center gap-2'>
                                                    <input type="checkbox" name="email" id="email4" />
                                                    <label htmlFor="email4" className='text-gray-400 cursor-pointer'>Email</label>
                                                </div>
                                                <DragIcon />
                                            </div>

                                            <div className='flex items-center justify-between'>
                                                <div className='flex items-center gap-2'>
                                                    <input type="checkbox" name="email" id="email5" />
                                                    <label htmlFor="email5" className='text-gray-400 cursor-pointer'>Email</label>
                                                </div>
                                                <DragIcon />
                                            </div>

                                            <div className='flex items-center justify-between'>
                                                <div className='flex items-center gap-2'>
                                                    <input type="checkbox" name="email" id="email6" />
                                                    <label htmlFor="email6" className='text-gray-400 cursor-pointer'>Email</label>
                                                </div>
                                                <DragIcon />
                                            </div>

                                            <div className='flex items-center justify-between'>
                                                <div className='flex items-center gap-2'>
                                                    <input type="checkbox" name="email" id="email7" />
                                                    <label htmlFor="email7" className='text-gray-400 cursor-pointer'>Email</label>
                                                </div>
                                                <DragIcon />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
            </div>

            {/* Sortable Table */}
            <div className='border-t pt-4'>
                <table className='w-full'>
                    <thead>
                        <th className='font-semibold text-left pb-2 flex items-center gap-5'> <input type="checkbox" className='h-4 w-4 cursor-pointer' /> ID</th>

                        <th className='font-semibold text-left pb-2'>User Status</th>

                        <th className='font-semibold text-left pb-2'> <span className='flex items-center gap-3'> Vehicles <SortingIcon /> </span> </th>

                        <th className='font-semibold text-left pb-2'> <span className='flex items-center gap-3'> Pending <SortingIcon /> </span>  </th>

                        <th className='font-semibold text-left pb-2'><span className='flex items-center gap-3'> Active <SortingIcon /> </span>  </th>

                        <th className='font-semibold text-left pb-2'><span className='flex items-center gap-3'> Last Active <SortingIcon /> </span>  </th>

                        <th className='font-semibold text-left pb-2'><span className='flex items-center gap-3'> Last Posted <SortingIcon /> </span>  </th>

                        <th className='font-semibold text-left pb-2'><span className='flex items-center gap-3'> Manage <SortingIcon /> </span>  </th>

                        <th className='font-semibold text-left pb-2'><span className='flex items-center gap-3'> Action <SortingIcon /> </span>  </th>
                    </thead>

                    <tbody>
                        <tr className='bg-red-50 border-t border-b'>
                            <td className='px-3 py-2 font-semibold text-left flex items-center gap-4'><input type="checkbox" className='h-4 w-4 cursor-pointer rounded-lg' /> 0001</td>

                            <td className='px-3 py-2 font-semibold text-left text-[#596171]'>Online</td>

                            <td className='px-3 py-2 font-semibold text-left text-[#596171]'>120</td>

                            <td className='px-3 py-2 font-semibold text-left text-[#596171]'>120</td>

                            <td className='px-3 py-2 font-semibold text-left text-[#596171]'>120</td>

                            <td className='px-3 py-2 font-semibold text-left text-[#596171]'>Aug, 12, 2023</td>

                            <td className='px-3 py-2 font-semibold text-left text-[#596171]'>Aug, 12, 2023</td>

                            <td className='px-3 py-2 flex items-center gap-3'>
                                <button className='text-white bg-buttonPrimary rounded-md p-2 px-3'>Manage Vehicles</button>
                                <button className='border border-gray-300 bg-white text-black rounded-md p-2 px-3'>View Logs</button>
                            </td>

                            <td className='px-3 py-2'>
                                <div className='flex items-center justify-between'>
                                    <SettingIcon />
                                    <div className='relative' ref={dropdownRef}>
                                        <button onClick={() => setDropdownOpen(!dropdownOpen)}>
                                            <EditIcon />
                                        </button>
                                        {dropdownOpen && (
                                            <div className='absolute top-6 right-0 w-max bg-white border border-gray-100 rounded-md shadow-xl p-3 flex flex-col text-start gap-2'>
                                                <h1 className='font-semibold text-[16px]'>More</h1>
                                                <div className='flex flex-col gap-2 items-start'>
                                                    <button className='text-gray-500 hover:bg-gray-100 px-1 w-full text-start rounded-md'>Manage Vehicles</button>
                                                    <button className='text-gray-500 hover:bg-gray-100 px-1 w-full text-start rounded-md'>Freeze user</button>
                                                    <button className='text-gray-500 hover:bg-gray-100 px-1 w-full text-start rounded-md'>Manage Vehicles</button>
                                                    <button className='text-gray-500 hover:bg-gray-100 px-1 w-full text-start rounded-md'>Freeze user</button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <FrozenIcon />
                                </div>
                            </td>
                        </tr>

                        <tr className='bg-red-50 border-t border-b'>
                            <td className='px-3 py-2 font-semibold text-left flex items-center gap-4'><input type="checkbox" className='h-4 w-4 cursor-pointer rounded-lg' /> 0001</td>

                            <td className='px-3 py-2 font-semibold text-left text-[#596171]'>Online</td>

                            <td className='px-3 py-2 font-semibold text-left text-[#596171]'>120</td>

                            <td className='px-3 py-2 font-semibold text-left text-[#596171]'>120</td>

                            <td className='px-3 py-2 font-semibold text-left text-[#596171]'>120</td>

                            <td className='px-3 py-2 font-semibold text-left text-[#596171]'>Aug, 12, 2023</td>

                            <td className='px-3 py-2 font-semibold text-left text-[#596171]'>Aug, 12, 2023</td>

                            <td className='px-3 py-2 flex items-center gap-3'>
                                <button className='text-white bg-buttonPrimary rounded-md p-2 px-3'>Manage Vehicles</button>
                                <button className='border border-gray-300 bg-white text-black rounded-md p-2 px-3'>View Logs</button>
                            </td>

                            <td className='px-3 py-2'>
                                <div className='flex items-center justify-between'>
                                    <SettingIcon />
                                    <EditIcon />
                                    <FrozenIcon />
                                </div>
                            </td>
                        </tr>

                        <tr className='bg-red-50 border-t border-b'>
                            <td className='px-3 py-2 font-semibold text-left flex items-center gap-4'><input type="checkbox" className='h-4 w-4 cursor-pointer rounded-lg' /> 0001</td>

                            <td className='px-3 py-2 font-semibold text-left text-[#596171]'>Online</td>

                            <td className='px-3 py-2 font-semibold text-left text-[#596171]'>120</td>

                            <td className='px-3 py-2 font-semibold text-left text-[#596171]'>120</td>

                            <td className='px-3 py-2 font-semibold text-left text-[#596171]'>120</td>

                            <td className='px-3 py-2 font-semibold text-left text-[#596171]'>Aug, 12, 2023</td>

                            <td className='px-3 py-2 font-semibold text-left text-[#596171]'>Aug, 12, 2023</td>

                            <td className='px-3 py-2 flex items-center gap-3'>
                                <button className='text-white bg-buttonPrimary rounded-md p-2 px-3'>Manage Vehicles</button>
                                <button className='border border-gray-300 bg-white text-black rounded-md p-2 px-3'>View Logs</button>
                            </td>

                            <td className='px-3 py-2'>
                                <div className='flex items-center justify-between'>
                                    <SettingIcon />
                                    <EditIcon />
                                    <FrozenIcon />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    )
}

export default Frozen