import React, { useEffect, useState } from "react";

const ViewCSV = ({ loadingCSV, csvDataHeader, csvData }) => {
  const descriptionThreshold = 50;

  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(
    Array(csvDataHeader && csvDataHeader.length).fill(false)
  );

  const toggleDescription = (index) => {
    const newIsDescriptionExpanded = [...isDescriptionExpanded];
    newIsDescriptionExpanded[index] = !newIsDescriptionExpanded[index];
    setIsDescriptionExpanded(newIsDescriptionExpanded);
  };

  useEffect(() => { }, []);

  if (loadingCSV) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-12 h-12 border-t-4 border-buttonPrimary border-solid border-opacity-80 rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <table className="border-b min-w-full divide-y divide-gray-200">
      <thead>
        <tr className="sticky top-0 bg-white">
          {csvDataHeader &&
            csvDataHeader.map((item, index) => (
              <th
                key={index}
                scope="col"
                className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
              >
                {item}
              </th>
            ))}
        </tr>
      </thead>
      <tbody className="border divide-y divide-gray-200">
        {csvData ?
          csvData.map(
            (row, rowIndex) =>
              rowIndex !== 0 && (
                <tr
                  key={rowIndex}
                  className={
                    rowIndex % 2 === 0
                      ? "divide-x h-6 bg-backgroudSecondary"
                      : "divide-x h-6 bg-backgroudPrimary"
                  }
                >
                  {[...Array(csvDataHeader.length)].map((_, i) => (
                    <td
                      key={i}
                      className="px-3 whitespace-wrap text-textTableHeader dark:text-gray-200"
                    >
                      <p
                        className={`text-[#606060] max-w-xs ${isDescriptionExpanded[rowIndex] ? "" : "line-clamp-1"
                          }`}
                        dangerouslySetInnerHTML={{
                          __html: row[i],
                        }}
                      ></p>

                      {row[i] && row[i].length > descriptionThreshold && (
                        <button
                          className="text-blue-500 pt-2 cursor-pointer"
                          onClick={() => toggleDescription(rowIndex)}
                        >
                          {isDescriptionExpanded[rowIndex] ? "Show Less" : "Read More"}
                        </button>
                      )}
                    </td>
                  ))}
                </tr>
              )
          ) : <tr><td><h1 className="text-center">File not found</h1></td></tr>}
      </tbody>
    </table>
  );
};

export default ViewCSV;
