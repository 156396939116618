import React from "react";

export const ActiveIcon = ({ color, height, width }) => {
  return (
    <svg
      width={`${width === undefined ? "24" : width}`}
      height={`${height === undefined ? "24" : height}`}
      viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.5 11L11.3571 14.2143L16.5 7.78571M12 20C10.8181 20 9.64778 19.7672 8.55585 19.3149C7.46392 18.8626 6.47177 18.1997 5.63604 17.364C4.80031 16.5282 4.13738 15.5361 3.68508 14.4442C3.23279 13.3522 3 12.1819 3 11C3 9.8181 3.23279 8.64778 3.68508 7.55585C4.13738 6.46392 4.80031 5.47177 5.63604 4.63604C6.47177 3.80031 7.46392 3.13738 8.55585 2.68508C9.64778 2.23279 10.8181 2 12 2C14.3869 2 16.6761 2.94821 18.364 4.63604C20.0518 6.32387 21 8.61305 21 11C21 13.3869 20.0518 15.6761 18.364 17.364C16.6761 19.0518 14.3869 20 12 20Z" stroke={`${color === undefined || "" ? "#8E93A6" : color}`} strokeWidth="2" />
    </svg>
  );
};
