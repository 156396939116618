import React from "react";

export const PendingIcon = ({ color, height, width }) => {
  return (
    <svg
      width={`${width === undefined ? "14" : width}`}
      height={`${height === undefined ? "17" : height}`}
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2137 4.44359L12.3034 3.35384L13.3639 4.41434L12.2742 5.50409C13.351 6.85197 13.8709 8.561 13.727 10.2802C13.5831 11.9994 12.7864 13.5982 11.5005 14.7484C10.2147 15.8985 8.53719 16.5127 6.81266 16.4647C5.08812 16.4166 3.44742 15.7101 2.22752 14.4902C1.00762 13.2703 0.301113 11.6296 0.253105 9.9051C0.205097 8.18057 0.81923 6.5031 1.96938 5.21722C3.11952 3.93134 4.71837 3.13465 6.43757 2.99078C8.15676 2.8469 9.86579 3.36676 11.2137 4.44359ZM7.00017 14.9676C7.68961 14.9676 8.3723 14.8318 9.00925 14.568C9.64621 14.3041 10.225 13.9174 10.7125 13.4299C11.2 12.9424 11.5867 12.3636 11.8505 11.7267C12.1144 11.0897 12.2502 10.407 12.2502 9.71759C12.2502 9.02815 12.1144 8.34546 11.8505 7.7085C11.5867 7.07154 11.2 6.49279 10.7125 6.00528C10.225 5.51777 9.64621 5.13106 9.00925 4.86722C8.3723 4.60339 7.68961 4.46759 7.00017 4.46759C5.60778 4.46759 4.27242 5.02071 3.28786 6.00528C2.30329 6.98985 1.75017 8.3252 1.75017 9.71759C1.75017 11.11 2.30329 12.4453 3.28786 13.4299C4.27242 14.4145 5.60778 14.9676 7.00017 14.9676ZM6.25017 5.96759H7.75017V10.4676H6.25017V5.96759ZM4.00017 0.71759H10.0002V2.21759H4.00017V0.71759Z"
        fill={`${color === undefined ? "#6C6C6C" : color}`}
      />
    </svg>
  );
};
