import React from 'react'

export const HelpIcon = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
			<g clipPath="url(#clip0_1979_19498)">
				<path fillRule="evenodd" clipRule="evenodd" d="M6.886 4.923C6.654 5.173 6.5 5.553 6.5 6.045C6.5 6.24391 6.42098 6.43468 6.28033 6.57533C6.13968 6.71598 5.94891 6.795 5.75 6.795C5.55109 6.795 5.36032 6.71598 5.21967 6.57533C5.07902 6.43468 5 6.24391 5 6.045C5 5.242 5.254 4.475 5.788 3.901C6.33 3.32 7.1 3 8 3C8.9 3 9.67 3.32 10.212 3.901C10.746 4.475 11 5.242 11 6.045C11 7.279 10.131 7.967 9.564 8.377C9.47 8.445 9.386 8.504 9.309 8.558C9.119 8.692 8.979 8.791 8.86 8.901C8.81831 8.93646 8.78134 8.97713 8.75 9.022V9.5C8.75 9.69891 8.67098 9.88968 8.53033 10.0303C8.38968 10.171 8.19891 10.25 8 10.25C7.80109 10.25 7.61032 10.171 7.46967 10.0303C7.32902 9.88968 7.25 9.69891 7.25 9.5V9C7.25 8.437 7.576 8.044 7.843 7.798C8.041 7.615 8.3 7.434 8.515 7.282L8.685 7.161C9.244 6.757 9.5 6.468 9.5 6.045C9.5 5.553 9.346 5.173 9.114 4.923C8.888 4.68 8.533 4.5 8 4.5C7.467 4.5 7.112 4.68 6.886 4.923Z" fill="#545969" />
				<path d="M9 12C8.98848 12.2574 8.87809 12.5006 8.69182 12.6787C8.50554 12.8568 8.25774 12.9562 8 12.9562C7.74226 12.9562 7.49446 12.8568 7.30818 12.6787C7.12191 12.5006 7.01152 12.2574 7 12C7.01152 11.7425 7.12191 11.4994 7.30818 11.3212C7.49446 11.1431 7.74226 11.0437 8 11.0437C8.25774 11.0437 8.50554 11.1431 8.69182 11.3212C8.87809 11.4994 8.98848 11.7425 9 12Z" fill="#545969" />
				<path fillRule="evenodd" clipRule="evenodd" d="M8 14.5C9.28558 14.5 10.5423 14.1188 11.6112 13.4046C12.6801 12.6903 13.5132 11.6752 14.0052 10.4874C14.4972 9.29973 14.6259 7.99279 14.3751 6.73192C14.1243 5.47104 13.5052 4.31285 12.5962 3.40381C11.6872 2.49477 10.529 1.8757 9.26809 1.6249C8.00721 1.3741 6.70028 1.50282 5.51256 1.99479C4.32484 2.48676 3.30968 3.31988 2.59545 4.3888C1.88122 5.45772 1.5 6.71443 1.5 8C1.5 9.022 1.679 9.608 2.103 10.399C2.419 10.989 2.51 11.712 2.281 12.402L1.621 14.378L3.598 13.719C3.92656 13.6107 4.27377 13.5706 4.61837 13.6012C4.96296 13.6318 5.29768 13.7325 5.602 13.897C6.392 14.321 6.978 14.5 8 14.5ZM8 16C9.58225 16 11.129 15.5308 12.4446 14.6518C13.7602 13.7727 14.7855 12.5233 15.391 11.0615C15.9965 9.59966 16.155 7.99113 15.8463 6.43928C15.5376 4.88743 14.7757 3.46197 13.6569 2.34315C12.538 1.22433 11.1126 0.462403 9.56072 0.153721C8.00887 -0.15496 6.40034 0.00346625 4.93853 0.608967C3.47672 1.21447 2.22729 2.23985 1.34824 3.55544C0.469192 4.87103 0 6.41775 0 8C0 9.29 0.25 10.117 0.78 11.107C0.916 11.359 0.948 11.656 0.858 11.927L0.051 14.347C0.0172487 14.4486 2.95664e-05 14.555 0 14.662L0 15C0 15.2652 0.105357 15.5196 0.292893 15.7071C0.48043 15.8946 0.734784 16 1 16H1.338C1.44539 16.0001 1.55209 15.9829 1.654 15.949L4.073 15.142C4.344 15.052 4.641 15.085 4.893 15.22C5.883 15.75 6.71 16 8 16Z" fill="#545969" />
			</g>
			<defs>
				<clipPath id="clip0_1979_19498">
					<rect width="16" height="16" fill="white" />
				</clipPath>
			</defs>
		</svg>
	)
}
