import SideMenu from 'components/sidemenu/SideMenu'
import Header from '../../layout/Header/Header'
import React, { useEffect, useRef, useState } from 'react'
import AllCustomers from './components/AllCustomers/AllCustomers'
import AllUsers from './components/AllUsers/AllUsers'
import NotificationMsg from 'components/Alerts/NotificationMsg'
import { useLocation } from 'react-router-dom'
import { useCustomerContext } from 'Context/CustomerContext'
import { useSearchContext } from 'Context/SearchContext'

const Dealership = () => {
    const location = useLocation();

    const {
        selectedPage,
        setSelectedPage,
    } = useSearchContext();

    const {
        allCustomers,
        notification,
        setNotification,
        allUsers,
        userVehicleCount,
        vehicleCountLoading,
        loading,
        fetchCustomerAndUser,
    } = useCustomerContext();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const redirectStatus = queryParams.get("tab");

        if (redirectStatus === "allUsers") {
            setSelectedPage("allUsers");
        }

        if (redirectStatus === "allCustomers") {
            setSelectedPage("allCustomers");
        }
    }, [location.search]);

    // useEffect(() => {
    //     fetchCustomerAndUser();
    // }, []);

    return (
        <>
            {/* Notification Msg Start */}
            <NotificationMsg
                toggle={notification}
                Toggler={setNotification}
                message={notification.msg}
            ></NotificationMsg>
            {/* Notification Msg End */}


            {selectedPage === "allCustomers" ? (
                <AllCustomers
                    allCustomers={allCustomers}
                    loading={loading}
                />
            ) : (
                <AllUsers
                    loading={loading}
                    allUsers={allUsers}
                    vehicleCountLoading={vehicleCountLoading}
                    userVehicleCount={userVehicleCount}
                />
            )}
        </>
    )
}



export default Dealership