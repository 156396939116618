import React, { useState } from "react";
import { ExitIcon } from "../../../components/icons/ExitIcon";
import { useEffect } from "react";
import axiosClient from "../../../axios.client";
import axios from "axios";
import { LoadingBtn } from '../../../components/Button/LoadingBtn';

export const FTPModel = ({ setToggleFtpModel, toggleFtpModel, SetAlert }) => {
  const [isLoading, setIsLoading] = useState(false); // loading
  const [ftpData, setFtpData] = useState([]); // ftp user name list
  const [ftpList, setFtpList] = useState(""); // ftp select user name paths
  const [ftpSearch, setFtpSearch] = useState({ isOpen: false, phrase: "" }); // ftp search
  const [selectedFTP, setSelectedFTP] = useState(""); // selected ftp user name
  const [selectedPath, setSelectedPath] = useState("");
  const [openSearch, setOpenSearch] = useState(false);

  const handleSelectedUser = (user) => {
    const path = ftpData.find((data) => data.user === user).homedir;

    axios
      .get(`https://uniqueleverage.com/ul-backend/public/api/get-file-list`, {
        params: { ftpPath: path },
      })
      .then((res) => {
        setFtpList(res.data);
      })
      .catch((e) => SetAlert(true, "failed", "Failed to fetch ftp path"));
  };

  const [submitLoading, setSubmitLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitLoading(true);

    const payload = { ftp_file_path: selectedPath, user: selectedFTP };

    axiosClient
      .post(`/ftp-account-update/${toggleFtpModel.id}`, payload)
      .then((res) => {
        // console.log(res);

        if (res.status === 200) {
          SetAlert(true, "success", "FTP Account Successfully Updated");
        }
      })
      .catch((e) => SetAlert(true, "failed", "FTP Account Failed to Update"))
      .finally(() => {
        setSubmitLoading(false);
        setToggleFtpModel({ id: toggleFtpModel.id, isBool: false });
      });
  };

  const fetchFTP = () => {
    setIsLoading(true);

    axiosClient
      .get("https://uniqueleverage.com/ul-backend/public/api/getFtp")
      .then((res) => setFtpData(res.data.data))
      .catch((e) => console.log(e));

    setIsLoading(false);
  };

  useEffect(() => {
    fetchFTP();
  }, []);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-12 h-12 border-t-4 border-buttonPrimary border-solid border-opacity-80 rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div className="p-5 bg-white rounded-md">
      <div className="flex items-center justify-between">
        <div></div>
        <button
          className="font-bold"
          onClick={() =>
            setToggleFtpModel({ id: toggleFtpModel.id, isBool: false })
          }
        >
          <ExitIcon />
        </button>
      </div>

      <form onSubmit={handleSubmit}>
        <div
          className="py-8 flex flex-col gap-4"
          onClick={() => {
            openSearch === true && setOpenSearch(false);
          }}
        >
          <div className="flex flex-col gap-5">
            <div className="w-[350px] relative">
              <label className="font-bold text-[#404040] block">FTP</label>
              <input
                type="search"
                required
                autoComplete="off"
                name="ftpSearch"
                value={selectedFTP}
                placeholder="search dealership"
                className="border border-[#EAEAEA] rounded-md p-2 w-full"
                onClick={() => setOpenSearch(true)}
                onChange={(e) => {
                  setFtpSearch({ isOpen: true, phrase: e.target.value });
                  setSelectedFTP(e.target.value);
                }}
              />

              {ftpSearch.phrase !== "" && ftpSearch.isOpen && (
                <div className="fixed bg-white rounded-lg shadow-lg w-[350px] p-2 max-h-[200px] overflow-y-auto z-[51] text-[16px]">
                  <ul>
                    {ftpData &&
                      ftpData
                        .filter((ftp) => {
                          return ftpSearch.phrase.toLowerCase() === ""
                            ? ftp
                            : ftp.user.toLowerCase().includes(ftpSearch.phrase);
                        })
                        .map((ftp, index) => {
                          return (
                            <li
                              key={index}
                              className="hover:bg-gray-100 cursor-pointer p-2"
                              onClick={() => {
                                setSelectedFTP(ftp.user);
                                handleSelectedUser(ftp.user);
                                setFtpSearch({
                                  isOpen: false,
                                  phrase: ftpSearch.phrase,
                                });
                                setOpenSearch(false);
                              }}
                            >
                              {ftp.user}
                            </li>
                          );
                        })}
                  </ul>
                </div>
              )}

              {openSearch && ftpSearch.phrase === "" && (
                <div className="fixed bg-white rounded-lg shadow-lg w-[350px] p-2 max-h-[200px] overflow-y-auto z-[51] text-[16px]">
                  <ul>
                    {ftpData &&
                      ftpData
                        .filter((ftp) => {
                          return ftpSearch.phrase.toLowerCase() === ""
                            ? ftp
                            : ftp.user.toLowerCase().includes(ftpSearch.phrase);
                        })
                        .map((ftp, index) => {
                          return (
                            <li
                              key={index}
                              className="hover:bg-gray-100 cursor-pointer p-2"
                              onClick={() => {
                                setSelectedFTP(ftp.user);
                                handleSelectedUser(ftp.user);
                                setFtpSearch({
                                  isOpen: false,
                                  phrase: ftpSearch.phrase,
                                });
                                setOpenSearch(false);
                              }}
                            >
                              {ftp.user}
                            </li>
                          );
                        })}
                  </ul>
                </div>
              )}
            </div>
            <div className="w-[350px] block">
              <label htmlFor="folder" className="font-bold text-[#404040]">
                File
              </label>
              <ul className="border border-[#EAEAEA] p-2 max-w-[350px] max-h-[180px] rounded-lg overflow-auto text-[16px]">
                {ftpList === "" ? (
                  <li className="text-gray-400 w-full whitespace-normal">
                    ./root/
                  </li>
                ) : (
                  ftpList.map((ftp, index) => {
                    return (
                      <li
                        key={index}
                        className={
                          selectedPath === ftp.fullPath
                            ? "bg-gray-200 cursor-pointer py-2 border-b whitespace-normal"
                            : "hover:bg-gray-300 cursor-pointer py-2 border-b whitespace-normal"
                        }
                        onClick={() => setSelectedPath(ftp.fullPath)}
                      >
                        {ftp.fileName}
                      </li>
                    );
                  })
                )}
              </ul>
            </div>
          </div>
        </div>

        {submitLoading ? <LoadingBtn /> :
          <button
            type="submit"
            className="w-full bg-buttonPrimary hover:bg-blue-600 duration-300 text-white rounded-md py-2"
          >
            Add FTP
          </button>}

      </form>
    </div>
  );
};
