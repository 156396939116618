import React from 'react'
import { EditIcon } from '../../components/icons/EditIcon';
import { useNavigate } from 'react-router-dom';

const RenderCsvList = ({ loadingCSV, csvData, setEditToggle }) => {
    const navigate = useNavigate();

    const DateFormat = (data) => {
        const months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];

        const date = new Date(data);
        const year = date.getFullYear();
        const monthIndex = date.getMonth();
        const day = date.getDate();

        const formattedDate = `${months[monthIndex]}, ${day}, ${year}`;

        return formattedDate;
    };


    if (loadingCSV) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="w-12 h-12 border-t-4 border-buttonPrimary border-solid border-opacity-80 rounded-full animate-spin"></div>
            </div>
        );
    }

    return (
        <>
            <table className="border-b min-w-full divide-y divide-gray-200">
                <thead>
                    <tr className="sticky top-0 bg-white">
                        <th scope="col" className="px-2 py-3 text-left text-[16px] font-semibold text-textTableHeader" >
                            User
                        </th>
                        <th scope="col" className="px-2 py-3 text-left text-[16px] font-semibold text-textTableHeader" >
                            File name
                        </th>
                        <th scope="col" className="px-2 py-3 text-left text-[16px] font-semibold text-textTableHeader" >
                            Last Processing Time
                        </th>
                        <th scope="col" className="px-2 py-3 text-left text-[16px] font-semibold text-textTableHeader" >
                            Vehicles Count
                        </th>
                        <th scope="col" className="px-2 py-3 text-left text-[16px] font-semibold text-textTableHeader" >
                            Status
                        </th>
                        <th scope="col" className="px-2 py-3 text-left text-[16px] font-semibold text-textTableHeader" >
                            Created At
                        </th>
                        <th scope="col" className="px-2 py-3 text-left text-[16px] font-semibold text-textTableHeader" >
                            Action
                        </th>
                    </tr>
                </thead>

                <tbody className="border divide-y divide-gray-200">
                    {
                        csvData && csvData.map((item, index) => {
                            return (
                                <tr
                                    key={index}
                                    className={
                                        index % 2 === 0
                                            ? "divide-x h-6 bg-backgroudSecondary"
                                            : "divide-x h-6 bg-backgroudPrimary"
                                    }
                                >
                                    <td className="px-6 py-4 whitespace-nowrap text-[16px] text-gray-800 dark:text-gray-200">
                                        {item.ftp_account}
                                    </td>

                                    <td className="px-6 py-4 whitespace-nowrap text-[16px] text-gray-800 dark:text-gray-200">
                                        {item.ftp_file_path}
                                    </td>

                                    <td className="px-6 py-4 whitespace-nowrap text-[16px] text-gray-800 dark:text-gray-200">
                                        Last Processing Time
                                    </td>

                                    <td className="px-6 py-4 whitespace-nowrap text-[16px] text-gray-800 dark:text-gray-200">
                                        Vehicles Count
                                    </td>

                                    <td className="px-6 py-4 whitespace-nowrap text-[16px] text-gray-800 dark:text-gray-200">
                                        Status
                                    </td>

                                    <td className="px-6 py-4 whitespace-nowrap text-[16px] text-gray-800 dark:text-gray-200">
                                        {DateFormat(item.created_at)}
                                    </td>

                                    <td className="px-6 py-4 whitespace-nowrap text-[16px] text-gray-800 dark:text-gray-200">
                                        <button className="pl-1"
                                            onClick={() => navigate(`/ftp-update-list/${item.ftp_account}`)}
                                        >
                                            <EditIcon width={20} height={20} />
                                        </button>
                                    </td>
                                </tr>
                            )
                        })
                    }

                </tbody>
            </table >
        </>
    )
}

export default RenderCsvList