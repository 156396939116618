import React from "react";
import { ExitIcon } from "../../../../components/icons/ExitIcon";

const VehicleEdit = ({
  option,
  data,
  setVehicleEditToggle,
  handleEditVehicle,
  EditVehicle,
}) => {
  return (
    <div className="block fixed z-[51] w-full h-full bg-black bg-opacity-30">
      <div className="overflow-y-auto overflow-x-hidden flex items-center justify-center h-screen">
        <div className="relative p-4 w-full max-w-2xl h-full md:h-auto">
          <div className="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
            <div className="flex items-center justify-between mb-2">
              <h1 className="font-semibold text-lg">
                Edit Vehicle - {data.id}
              </h1>
              <button
                onClick={() =>
                  setVehicleEditToggle({
                    isOpen: false,
                    data: [],
                  })
                }
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-[16px] p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              >
                <ExitIcon />
              </button>
            </div>

            <form onSubmit={EditVehicle}>
              <input type="hidden" name="option" value={option} />

              <div className="mb-2 flex gap-2">
                <div className="flex-1">
                  <label className="block font-semibold">Title</label>
                  <input
                    type="title"
                    name="title"
                    className="border border-gray-300 rounded-lg p-2 w-full"
                    placeholder="title..."
                    value={data.title}
                    onChange={(e) => handleEditVehicle(e)}
                  />
                </div>
                <div className="flex-1">
                  <label className="block font-semibold">Model</label>
                  <input
                    type="model"
                    name="model"
                    className="border border-gray-300 rounded-lg p-2 w-full"
                    placeholder="model..."
                    value={data.model}
                    onChange={(e) => handleEditVehicle(e)}
                  />
                </div>
              </div>

              <div className="mb-2 flex gap-2">
                <div className="flex-1">
                  <label className="block font-semibold">Body Style</label>
                  <input
                    type="bodystyle"
                    name="bodystyle"
                    className="border border-gray-300 rounded-lg p-2 w-full"
                    placeholder="body style..."
                    value={data.bodystyle}
                    onChange={(e) => handleEditVehicle(e)}
                  />
                </div>

                <div className="flex-1">
                  <label className="block font-semibold">Trim</label>
                  <input
                    type="trim"
                    name="trim"
                    className="border border-gray-300 rounded-lg p-2 w-full"
                    placeholder="trim..."
                    value={data.trim}
                    onChange={(e) => handleEditVehicle(e)}
                  />
                </div>
              </div>

              <div className="mb-2 flex gap-2">
                <div className="flex-1">
                  <label className="block font-semibold">Transmission</label>
                  <input
                    type="transmission"
                    name="transmission"
                    className="border border-gray-300 rounded-lg p-2 w-full"
                    placeholder="transmission..."
                    value={data.transmission}
                    onChange={(e) => handleEditVehicle(e)}
                  />
                </div>

                <div className="flex-1">
                  <label className="block font-semibold">Make</label>
                  <input
                    type="make"
                    name="make"
                    className="border border-gray-300 rounded-lg p-2 w-full"
                    placeholder="make..."
                    value={data.make}
                    onChange={(e) => handleEditVehicle(e)}
                  />
                </div>
              </div>

              <div className="mb-2 flex gap-2">
                <div className="flex-1">
                  <label className="block font-semibold">Interior Color</label>
                  <input
                    type="interiorcolor"
                    name="interiorcolor"
                    className="border border-gray-300 rounded-lg p-2 w-full"
                    placeholder="interior Color..."
                    value={data.interiorcolor}
                    onChange={(e) => handleEditVehicle(e)}
                  />
                </div>

                <div className="flex-1">
                  <label className="block font-semibold">Exterior Color</label>
                  <input
                    type="exteriorcolor"
                    name="exteriorcolor"
                    className="border border-gray-300 rounded-lg p-2 w-full"
                    placeholder="exterior color..."
                    value={data.exteriorcolor}
                    onChange={(e) => handleEditVehicle(e)}
                  />
                </div>
              </div>

              <div className="mb-2 flex gap-2">
                <div className="flex-1">
                  <label className="block font-semibold">Fuel Type</label>
                  <input
                    type="fueltype"
                    name="fueltype"
                    className="border border-gray-300 rounded-lg p-2 w-full"
                    placeholder="fuel type..."
                    value={data.fueltype}
                    onChange={(e) => handleEditVehicle(e)}
                  />
                </div>

                <div className="flex-1">
                  <label className="block font-semibold">Condition</label>
                  <input
                    type="condition"
                    name="condition"
                    className="border border-gray-300 rounded-lg p-2 w-full"
                    placeholder="condition..."
                    value={data.condition}
                    onChange={(e) => handleEditVehicle(e)}
                  />
                </div>
              </div>

              <div className="mb-2 flex gap-2">
                <div className="flex-1">
                  <label className="block font-semibold">Mileage</label>
                  <input
                    type="mileage"
                    name="mileage"
                    className="border border-gray-300 rounded-lg p-2 w-full"
                    placeholder="mileage..."
                    value={data.mileage}
                    onChange={(e) => handleEditVehicle(e)}
                  />
                </div>

                <div className="flex-1">
                  <label className="block font-semibold">Price</label>
                  <input
                    type="price"
                    name="price"
                    className="border border-gray-300 rounded-lg p-2 w-full"
                    placeholder="price..."
                    value={data.price}
                    onChange={(e) => handleEditVehicle(e)}
                  />
                </div>
              </div>

              <div className="mb-2 flex gap-2">
                <div className="flex-1">
                  <label className="block font-semibold">Year</label>
                  <input
                    type="year"
                    name="year"
                    className="border border-gray-300 rounded-lg p-2 w-full"
                    placeholder="year..."
                    value={data.year}
                    onChange={(e) => handleEditVehicle(e)}
                  />
                </div>

                <div className="flex-1">
                  <label className="block font-semibold">Vin</label>
                  <input
                    type="vin"
                    name="vin"
                    className="border border-gray-300 rounded-lg p-2 w-full"
                    placeholder="vin..."
                    value={data.vin}
                    onChange={(e) => handleEditVehicle(e)}
                  />
                </div>
              </div>

              <div className="mb-2 flex gap-2">
                <div className="flex-1">
                  <label className="block font-semibold">URL</label>
                  <input
                    type="url"
                    name="url"
                    className="border border-gray-300 rounded-lg p-2 w-full"
                    placeholder="url..."
                    value={data.url}
                    onChange={(e) => handleEditVehicle(e)}
                  />
                </div>

                {/* <div className="flex-1">
                  <label className="block font-semibold">URL</label>
                  <input
                    type="url"
                    name="url"
                    className="border border-gray-300 rounded-lg p-2 w-full"
                    placeholder="url..."
                    value={data.url}
                    onChange={(e) => handleEditVehicle(e)}
                  />
                </div> */}
              </div>

              <div className="mb-1">
                <label className="block font-semibold">Description</label>
                <div>
                  <textarea
                    name="description"
                    cols="30"
                    rows="10"
                    className="border border-gray-300 rounded-lg p-2 w-full"
                    placeholder="description..."
                    value={data.description}
                    onChange={(e) => handleEditVehicle(e)}
                  ></textarea>
                </div>
              </div>

              <div className="flex justify-center items-center">
                <button
                  type="submit"
                  className="font-normal flex items-center gap-1 bg-blue-500 text-white px-4 py-2 rounded-md text-[16px] text-center cursor-pointer"
                >
                  Confirm
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleEdit;
