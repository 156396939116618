import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import GuestLayout from "layout/GuestLayout";
import DefaultLayout from "layout/DefaultLayout";
import Login from "./pages/Auth/Login";
import Signup from "./pages/Auth/Signup";
import ListDealership from "./pages/dealership_old/listDealership";
import AddDealerShip from "./pages/dealership_old/AddDealerShip/AddDealerShip";
import AddUser from "./pages/dealership_old/AddUser/AddUser";
import DealerShipDetail from "./pages/dealership_old/DealerShipDetail/DealerShipDetail";
import UsedCars from "./pages/dealership_old/DealerShipDetail/UsedCars/UsedCars";
import ManageVehicles from "./pages/dealership_old/ManageVehicles/ManageVehicles";
import Dashboard from "./pages/dashboard/Dashboard";
import FTP_old from "./pages/FTP_old/FTP";
import Reports from "./pages/Reports/Reposts";
import Tools from "./pages/Tools/Tools";
import ViewLogs from "./pages/dealership_old/ViewLogs/ViewLogs";
import TableMapping from "./pages/FTP_old/Mapping/TableMapping";
import UpdateMapping from "./pages/FTP_old/Mapping/UpdateMapping";
import FTP_facebook from "./pages/FTP_facebook/FTP_facebook";
import FB_TableMapping from "./pages/FTP_facebook/Mapping/TableMapping";
import FB_UpdateMapping from "./pages/FTP_facebook/Mapping/UpdateMapping";
import Frozen from "./pages/dashboard/Features/Frozen/Frozen";
import Onboarding from "./pages/dashboard/Features/Onboarding/Onboarding";
import PageNotFound from "PageNotFound";

// New Dealership Imports
import Dealership from "./pages/dealership/Dealership";
import CustomerDetail from "pages/dealership/customerDetail/CustomerDetail";
import UserDetail from "pages/dealership/customerDetail/overview/customerRendering/UserDetail";
import Teams from "pages/teams/Teams";
import Connect from "pages/connect/Connect";

// New FTP Imports
import FTP from "pages/File_Transfer_Protocol/FTP";
import Data from "pages/Data/Data";
// import Test from './Testing';

function App() {

  return (
    <>
      <Routes>

        <Route path="*" element={<PageNotFound />} />

        {/* <Route path="/" element={<GuestLayout />}> */}
        <Route path="/login" element={<Login />} />
        {/* <Route path="/signup" element={<Signup />} /> */}
        {/* </Route> */}

        <Route path="/" element={<DefaultLayout />}>
          {/* <Route path="/test" element={<Test />} /> */}
          {/* Dashboard Routes Start */}
          <Route path="/" element={<Dashboard />} />
          <Route path="/frozen" element={<Frozen />} />
          <Route path="/onboarding" element={<Onboarding />} />
          {/* Dashboard Routes End */}

          {/* New Dealership Routes Start */}
          <Route path="/dealerShip" element={<Dealership />} />
          <Route path="/dealerShip/customer-detail/:customerID" element={<CustomerDetail />} />
          <Route path="/dealership/customer-detail/:customerID/user-detail/:userID" element={<UserDetail />} />
          {/* New Dealership Routes End */}

          {/* Teams Routes Start */}
          <Route path="/teams" element={<Teams />} />
          {/* Teams Routes End */}

          {/* Connect Routes Start */}
          <Route path="/connect/:dealerShipID" element={<Connect />} />
          {/* Teams Connect End */}

          {/* New FTP Routes Start */}
          <Route path="/ftp" element={<FTP />} />
          {/* New FTP Routes End */}

          {/* Data Routes Start */}
          <Route path="/data" element={<Data />} />
          {/* Data Routes End */}

          {/* Old DealerShip Routes Start */}
          <Route path="/old" element={<ListDealership />} />
          <Route path="/add-dealerShip" element={<AddDealerShip />} />
          <Route path="/add-user" element={<AddUser />} />
          <Route path="/old_dealerShip-detail/:dealerShipID" element={<DealerShipDetail />} />
          <Route path="/dealerShip/users/:DealerShipUserName" element={<UsedCars />} />

          <Route path="/dealership/manage-vehicles/:VehicleId" element={<ManageVehicles />} />
          <Route path="/dealership/view-logs/:userId" element={<ViewLogs />} />
          {/* Old DealerShip Routes End */}

          {/* Old FTP Routes Start */}
          <Route path="/ftp_old" element={<FTP_old />} />
          <Route path="/ftp/create-ftp" element={<TableMapping />} />
          <Route path="/ftp-update-list/:ftpaccount" element={<UpdateMapping />} />

          <Route path="/ftp-facebook" element={<FTP_facebook />} />
          <Route path="/ftp-facebook/create-ftp" element={<FB_TableMapping />} />
          <Route path="/ftp-facebook-update-list/:ftpaccount" element={<FB_UpdateMapping />} />
          {/* Old FTP Routes End */}

          <Route path="/reports" element={<Reports />} />
          <Route path="/tools" element={<Tools />} />

        </Route>

      </Routes>
    </>
  );
}

export default App;
