import { useEffect, useState, useCallback } from "react";
import Header from "../../layout/Header/Header";
import SideMenu from "../../components/sidemenu/SideMenu";

import { AddUserBtn } from "../../components/Button/AddUserBtn";
import {
  AddDealersShipBtn,
  SubmitDealerShipBtn,
  CancelDealerShipBtn,
} from "../../components/Button/AddDealersShipBtn";
import UserTable from "./UserTable";
import AccountTable from "./AccountTable";
import axiosClient from "../../axios.client";
import AddUserModel from "./AddUser/AddUserDesktop";
import EditUserModel from "./AddUser/UpdateUserDesktop";
import { FTPModel } from "./FTPModel/FTPModel";
import NotificationMsg from "../../components/Alerts/NotificationMsg";
import axios from "axios";
import { ProcessDataModel } from "../../components/Alerts/ProcessDataModel";
import { useLocation } from "react-router-dom";

const ListDealership = () => {
  const location = useLocation();
  const [users, setUsers] = useState([]); // DealerShip
  const [allUsersList, setAllUsersList] = useState({
    listed: "",
    advertised: "",
    pending: "",
  }); // All Users
  const [userVehicleCount, setUserVehicleCount] = useState([]); // All Users

  const [loading, setLoading] = useState(false); // Page Loading

  const [formToggler, setFormToggler] = useState(false); // DealerShip Form Toggler

  const [account_userToggler, setAccount_UserToggler] = useState("account"); //Tab Switch Account || User
  const [addUserBtnToggle, setAddUserBtnToggle] = useState(false); // Btn Display Account or User

  const [addUserModel, setAddUserModel] = useState(false); // All User Page Add Btn Toggler
  const [toggleFtpModel, setToggleFtpModel] = useState({
    id: "",
    isBool: false,
  }); // FTP model Toggler

  // Account Storing variables
  const [formData, setFormData] = useState({
    dealerShip: "",
    website: "",
    state: "",
    city: "",
    phoneNo: "",
    cellNo: "",
    formPackage: "",
    formUser: "",
    firstName: "",
    lastName: "",
  });

  // Account Input Handler
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Account Post
  const formSubmit = async () => {
    await axiosClient
      .post("/addDealerShip", formData)
      // .then((res) => console.log(res))
      .catch((error) => {
        console.log(error);
      });

    setFormToggler(!formToggler);
    fetchUser();
  };

  // All User Storing Variables
  const [userFormData, setUserFormData] = useState({
    id: "",
    status: "",
    username: "",
    vehicles: "",
    pending: "",
    active: "",
    last_active: "",
    last_post: "",
  });

  // User Input Handler
  const handleUserInputChange = (e) => {
    const { name, value } = e.target;

    setUserFormData({
      ...userFormData,
      [name]: value,
    });
  };

  const [editToggle, setEditToggle] = useState({ isOpen: false, data: [] }); // User list edit Model Toggler

  // Fetch Account & User Data
  const fetchUser = async () => {
    try {
      setLoading(true);

      const [userData, allUsersData] = await Promise.all([
        axiosClient.get("/getUsers"),
        axiosClient.get("/getAllUsers"),
      ]);

      setUsers(userData.data || []);

      setAllUsersList(allUsersData.data.data);
    } catch (e) {
      console.error(e);
      SetAlert(true, "failed", "Failed to fetch data");
    } finally {
      setLoading(false);
    }

    fetchVehicleCount();
  };

  const [isLoading, setIsLoading] = useState(false);

  const fetchVehicleCount = async () => {
    setIsLoading(true);

    try {
      axios.get("https://uniqueleverage.com/ul-backend/public/api/get-car-count")
        .then((res) => {

          setUserVehicleCount({
            listed: res.data.listed,
            advertised: res.data.active,
            pending: res.data.pending,
          });

          setIsLoading(false)
        });

    } catch (e) {
      console.error(e);
      SetAlert(true, "failed", "Failed to Fetch Vehicle Count");
    }

  }

  const [notification, setNotification] = useState({
    isOpen: false,
    status: "",
    msg: "",
  });

  const SetAlert = (bool, status, msg) => {
    setNotification({ isOpen: bool, status: status, msg: msg });

    setTimeout(() => {
      setNotification({ isOpen: false, status: "", msg: "" });
    }, 5000);
  };

  // Search State
  const [search, setSearch] = useState({ pageName: "", value: "" });


  const [processDataModel, setProcessDataAlert] = useState({ isOpen: false, data: [] });
  const [processLoader, setProcessLoader] = useState({ isOpen: false, ftp: "" });

  const handleUserProcessData = (FTPaccount) => {
    setProcessLoader({ isOpen: true, ftp: FTPaccount });

    axiosClient.get(`/process-ftp-account/${FTPaccount}`)
      .then((res) => { })
      .catch((e) => console.log(e))
      .finally(() => { setProcessLoader({ isOpen: false, ftp: "" }) });
  };

  useEffect(() => {
    fetchUser();

    const queryParams = new URLSearchParams(location.search);
    const redirectStatus = queryParams.get("tab");

    if (redirectStatus === "users") {
      setAccount_UserToggler("users");
      setAddUserBtnToggle(true);
    }

    if (redirectStatus === "account") {
      setAccount_UserToggler("account");
      setAddUserBtnToggle(false);
    }

  }, []);

  return (
    <>
      {/* Notification Msg Start */}
      <NotificationMsg
        toggle={notification}
        Toggler={setNotification}
        message={notification.msg}
      ></NotificationMsg>
      {/* Notification Msg End */}

      {/* Process Data Alert Start */}
      {processDataModel.isOpen && (
        <div className="block fixed w-full h-full bg-black bg-opacity-30 z-[51]">
          <div className="flex items-center justify-center h-screen">
            <ProcessDataModel
              setProcessDataAlert={setProcessDataAlert}
              processDataModel={processDataModel}
              SetAlert={SetAlert}
              handleUserProcessData={handleUserProcessData}
            />
          </div>
        </div>)}

      {/* Process Data Alert End */}

      {/* Add User Model Start */}
      {addUserModel && (
        <div className="block fixed w-full h-full bg-black bg-opacity-30 z-[51]">
          <div className="flex items-center justify-center h-screen">
            <AddUserModel setAddUserModel={setAddUserModel} />
          </div>
        </div>
      )}
      {/* Add User Model End */}

      {/* Update User Model Start */}
      {editToggle.isOpen && (
        <div className="block fixed w-full h-full bg-black bg-opacity-30 z-[51]">
          <div className="flex items-center justify-center h-screen">
            <EditUserModel
              setAddUserModel={setEditToggle}
              editToggle={editToggle}
              usersList={allUsersList}
              setUsersList={setAllUsersList}
              SetAlert={SetAlert}
            />
          </div>
        </div>
      )}
      {/* Update User Model End */}

      {/* FTP Model Start */}
      {toggleFtpModel.isBool && (
        <div className="block fixed w-full h-full bg-black bg-opacity-30 z-[51]">
          <div className="flex items-center justify-center h-screen">
            <FTPModel
              setToggleFtpModel={setToggleFtpModel}
              toggleFtpModel={toggleFtpModel}
              SetAlert={SetAlert}
            />
          </div>
        </div>
      )}
      {/* FTP Model End */}
      <div className="wrapper bg-backgroudWrapper">
        <div className="md:flex w-full h-screen">
          <SideMenu addUserBtnToggle={addUserBtnToggle} />

          <div
            className="w-full bg-backgroudPrimary overflow-x-auto"
            id="main-content"
          >
            <Header pageName={account_userToggler} setSearch={setSearch} />

            <div className="px-[18px] md:px-[24px] md:mx-[14px]  md:h-[calc(100vh-120px)] bg-white">
              <div className="hidden md:flex justify-between items-center my-[30px]">
                <h2 className="text-xl font-semibold">
                  {account_userToggler === "account" ? "Dealership" : "Users"}
                </h2>

                {account_userToggler === "account" ? (
                  <>
                    <AddDealersShipBtn
                      setFormToggler={setFormToggler}
                      formToggler={formToggler}
                    />

                    <div className={formToggler ? "flex gap-2" : "hidden"}>
                      <CancelDealerShipBtn
                        formToggler={formToggler}
                        setFormToggler={setFormToggler}
                      />

                      <SubmitDealerShipBtn
                        formToggler={formToggler}
                        formSubmit={formSubmit}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <AddUserBtn setAddUserModel={setAddUserModel} />
                  </>
                )}
              </div>

              <div id="tabWrapper">
                <div id="tabs">
                  <div className="flex gap-1 border-b border-borderPrimary pt-2 pb-2">
                    <span
                      className={
                        account_userToggler === "account"
                          ? "text-textSecondary border-r font-medium border-borderPrimary pr-3 cursor-pointer"
                          : "font-medium text-[#8F8F8F] border-r pr-3 cursor-pointer"
                      }
                      onClick={() => {
                        setAccount_UserToggler("account");
                        setAddUserBtnToggle(false);
                      }}
                    >
                      Accounts{" "}
                      <span className="text-[16px]">
                        {users.length !== 0 && `(${users.data.length})`}
                      </span>
                    </span>
                    <span
                      className={
                        account_userToggler === "account"
                          ? "font-medium text-[#8F8F8F] pl-3 cursor-pointer"
                          : "text-textSecondary font-medium border-borderPrimary  pl-3 cursor-pointer"
                      }
                      onClick={() => {
                        setAccount_UserToggler("users");
                        setAddUserBtnToggle(true);
                      }}
                    >
                      Users{" "}
                      <span className="text-[16px]">
                        {allUsersList.length > 0 &&
                          `(${allUsersList.length})`}
                      </span>
                    </span>
                  </div>
                </div>
                {loading ? (
                  <div className="flex justify-center items-center h-screen">
                    <div className="w-12 h-12 border-t-4 border-buttonPrimary border-solid border-opacity-80 rounded-full animate-spin"></div>
                  </div>
                ) : account_userToggler === "account" ? (
                  <AccountTable
                    search={search}
                    loading={loading}
                    users={users}
                    formToggler={formToggler}
                    formData={formData}
                    handleInputChange={handleInputChange}
                  />
                ) : (
                  <UserTable
                    search={search}
                    allUsersList={allUsersList}
                    setAllUsersList={setAllUsersList}
                    userVehicleCount={userVehicleCount}
                    userFormData={userFormData}
                    handleUserInputChange={handleUserInputChange}
                    setToggleFtpModel={setToggleFtpModel}
                    toggleFtpModel={toggleFtpModel}
                    setEditToggle={setEditToggle}
                    setProcessDataAlert={setProcessDataAlert}
                    processLoader={processLoader}
                    isLoading={isLoading}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListDealership;
