import axios from "axios";
import axiosClient from "axios.client";
import { createContext, useContext, useEffect, useState } from "react";

export const CustomerContext = createContext();

export const CustomerContextProvider = ({ children }) => {

    const [allCustomers, setAllCustomers] = useState([]); // All Customer
    const [customerList, setCustomerList] = useState({
        onboarding: [],
        all: [],
        top: [],
        repeat: [],
    }); // used for modification of customer list
    const [checkedStatus, setCheckedStatus] = useState([]); // All Customer Checked Status
    const [allUsers, setAllUsers] = useState([]); // All Users
    const [userList, setUserList] = useState({
        all: [],
        frozen: [],
        flagged: [],
        repeat: [],
    }); // used for modification of Users list
    const [toggleAddCustomer, setToggleAddCustomer] = useState(false);
    const [formData, setFormData] = useState({
        dealerShip: "",
        email: "",
        website: "",
        state: "",
        city: "",
        phoneNo: "",
        formPackage: "",
        formUser: "",
        firstName: "",
        lastName: "",
    });

    // customer handle input change 
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // submit customer form data
    const formSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axiosClient.post("/addDealerShip", formData);

            if (response.status === 200) {
                setCheckedStatus([]);
                setAllCustomers(response.data.data);

                // modes :
                // all, support, canceled, onboarding, package: all_in-basic-pro

                const currentDate = new Date();
                currentDate.setHours(0, 0, 0, 0);

                let onboarding = [];

                response.data.data.forEach(user => {
                    const createdAt = new Date(user.created_at);
                    createdAt.setHours(0, 0, 0, 0);

                    const differenceInMs = currentDate.getTime() - createdAt.getTime();
                    const differenceInDays = Math.ceil(differenceInMs / (1000 * 60 * 60 * 24));

                    if (differenceInDays <= 7) {
                        onboarding.push(user);
                    }
                });

                const allCustomer = response.data.data.filter(customer => (customer.status === "active") || (customer.status === null));

                const all_in = response.data.data.filter(customer => customer.package !== "");

                const basic = response.data.data.filter(customer => customer.package === "basic" || customer.package === "Basic");

                const pro = response.data.data.filter(customer => customer.package === "pro" || customer.package === "Pro");

                const supportCustomer = response.data.data.filter(customer => customer.status === "support" || customer.package === "Support");

                const canceledCustomer = response.data.data.filter(customer => customer.status === "canceled" || customer.package === "Canceled");

                setCustomerList({
                    all: allCustomer,
                    onboarding: onboarding,
                    all_in: all_in,
                    basic: basic,
                    pro: pro,
                    support: supportCustomer,
                    canceled: canceledCustomer,
                });

                setToggleAddCustomer(false);
                setFormData({
                    dealerShip: "",
                    email: "",
                    website: "",
                    state: "",
                    city: "",
                    phoneNo: "",
                    formPackage: "",
                    formUser: "",
                    firstName: "",
                    lastName: "",
                });

                SetAlert(true, "success", "Successfully added customer");
            } else {
                SetAlert(true, "error", "Failed to add customer");
            }
        } catch (error) {
            // console.error("Error submitting form:", error);
            SetAlert(true, "error", "Failed to add customer");
        }
    };

    const [loading, setLoading] = useState(false); // Customer and User Loading
    // Fetch Account & User Data
    const fetchCustomerAndUser = async () => {
        try {
            setLoading(true);

            const [customerData, UsersData] = await Promise.all([
                axiosClient.get("/getUsers"),
                axiosClient.get("/getAllUsers"),
            ]);

            setAllCustomers(customerData.data.data);

            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);

            let onboarding = [];

            customerData.data.data.forEach(user => {
                const createdAt = new Date(user.created_at);
                createdAt.setHours(0, 0, 0, 0);

                const differenceInMs = currentDate.getTime() - createdAt.getTime();
                const differenceInDays = Math.ceil(differenceInMs / (1000 * 60 * 60 * 24));

                if (differenceInDays <= 7) {
                    onboarding.push(user);
                }
            });

            const allCustomer = customerData.data.data.filter(customer => (customer.status === "active") || (customer.status === null));

            const all_in = customerData.data.data.filter(customer => customer.package !== "");

            const basic = customerData.data.data.filter(customer => customer.package === "basic" || customer.package === "Basic");

            const pro = customerData.data.data.filter(customer => customer.package === "pro" || customer.package === "Pro");

            const supportCustomer = customerData.data.data.filter(customer => customer.status === "support" || customer.package === "Support");

            const canceledCustomer = customerData.data.data.filter(customer => customer.status === "canceled" || customer.package === "Canceled");

            setCustomerList({
                all: allCustomer,
                onboarding: onboarding,
                all_in: all_in,
                basic: basic,
                pro: pro,
                support: supportCustomer,
                canceled: canceledCustomer,
            });

            setAllUsers(UsersData?.data?.data || []);

            const allUser = UsersData.data.data.filter(user => (user.status === "active") || (user.status === null));

            const frozen = UsersData?.data?.data.filter(user => user.freeze === 1);

            const supportUser = UsersData.data.data.filter(user => user.status === "support" || user.package === "Support");

            const canceledUser = UsersData.data.data.filter(user => user.status === "canceled" || user.package === "Canceled");

            setUserList({
                all: allUser,
                frozen: frozen,
                support: supportUser,
                canceled: canceledUser,
            });
        } catch (e) {
            console.error(e);
            SetAlert(true, "failed", "Failed to fetch data");
        } finally {
            setLoading(false);
        }

        fetchVehicleCount();
    };

    const [userVehicleCount, setUserVehicleCount] = useState([]); // All Vehicle Count
    const [vehicleCountLoading, setVehicleCountLoading] = useState(false); // Vehicle Count Loading
    const fetchVehicleCount = async () => {
        setVehicleCountLoading(true);

        try {
            axios.get("https://uniqueleverage.com/ul-backend/public/api/get-car-count")
                .then((res) => {

                    setUserVehicleCount({
                        listed: res.data.listed,
                        advertised: res.data.active,
                        pending: res.data.pending,
                    });

                    setVehicleCountLoading(false)
                });

        } catch (e) {
            console.error(e);
            SetAlert(true, "failed", "Failed to Fetch Vehicle Count");
        }

    }

    const [notification, setNotification] = useState({
        isOpen: false,
        status: "",
        msg: "",
    });

    const SetAlert = (bool, status, msg) => {
        setNotification({ isOpen: bool, status: status, msg: msg });

        setTimeout(() => {
            setNotification({ isOpen: false, status: "", msg: "" });
        }, 5000);
    };

    // <--------- Move User Status ---------->
    const [changeStatusLoading, setChangeStatusLoading] = useState(false);
    const handleStatus = async (status) => {
        setChangeStatusLoading(true);
        let statusToBeChanged = [];

        Object.entries(checkedStatus).forEach((customer) => {
            if (customer[1]) {
                statusToBeChanged.push(customer[0]);
            }
        });
        if (statusToBeChanged.length === 0) {
            SetAlert(true, "failed", "No customer selected");
            setChangeStatusLoading(false);
            return;
        }

        try {
            const [response, UsersData] = await Promise.all([
                axiosClient.post("/moveUserCustomer", { status: status, users: statusToBeChanged }),
                axiosClient.get("/getAllUsers"),
            ]);

            if (response.status === 200) {
                SetAlert(true, "success", "Successfully moved user");

                const currentDate = new Date();
                currentDate.setHours(0, 0, 0, 0);

                let onboarding = [];

                response.data.data.forEach(user => {
                    const createdAt = new Date(user.created_at);
                    createdAt.setHours(0, 0, 0, 0);

                    const differenceInMs = currentDate.getTime() - createdAt.getTime();
                    const differenceInDays = Math.ceil(differenceInMs / (1000 * 60 * 60 * 24));

                    if (differenceInDays <= 7) {
                        onboarding.push(user);
                    }
                });

                const allCustomer = response.data.data.filter(customer => (customer.status === "active") || (customer.status === null));

                const all_in = response.data.data.filter(customer => customer.package !== "");

                const basic = response.data.data.filter(customer => customer.package === "basic" || customer.package === "Basic");

                const pro = response.data.data.filter(customer => customer.package === "pro" || customer.package === "Pro");

                const supportCustomer = response.data.data.filter(customer => customer.status === "support" || customer.package === "Support");

                const canceledCustomer = response.data.data.filter(customer => customer.status === "canceled" || customer.package === "Canceled");

                setCustomerList({
                    all: allCustomer,
                    onboarding: onboarding,
                    all_in: all_in,
                    basic: basic,
                    pro: pro,
                    support: supportCustomer,
                    canceled: canceledCustomer,
                });

                // <--------- User List ---------->

                setAllUsers(UsersData?.data?.data || []);

                const allUser = UsersData.data.data.filter(user => (user.status === "active") || (user.status === null));

                const frozen = UsersData?.data?.data.filter(user => user.freeze === 1);

                const supportUser = UsersData.data.data.filter(user => user.status === "support" || user.package === "Support");

                const canceledUser = UsersData.data.data.filter(user => user.status === "canceled" || user.package === "Canceled");

                setUserList({
                    all: allUser,
                    frozen: frozen,
                    support: supportUser,
                    canceled: canceledUser,
                });

            } else {
                SetAlert(true, "failed", "Failed to move user");
            }

        } catch (error) {
            SetAlert(true, "failed", "Failed to move user");
        }
        setChangeStatusLoading(false);
    }

    useEffect(() => {
        fetchCustomerAndUser();
    }, []);

    return (
        <CustomerContext.Provider value={{
            allCustomers,
            setAllCustomers,
            customerList,
            setCustomerList,

            toggleAddCustomer,
            setToggleAddCustomer,
            formData,
            setFormData,
            handleInputChange,
            formSubmit,
            loading,

            notification,
            setNotification,
            SetAlert,

            allUsers,
            setAllUsers,
            setUserList,
            userList,

            userVehicleCount,
            setUserVehicleCount,
            vehicleCountLoading,
            setVehicleCountLoading,
            checkedStatus,
            setCheckedStatus,
            fetchCustomerAndUser,

            handleStatus,
            changeStatusLoading,
        }}>
            {children}
        </CustomerContext.Provider>
    );
};

export const useCustomerContext = () => {
    return useContext(CustomerContext);
};
