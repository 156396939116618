import { useCustomerContext } from 'Context/CustomerContext';
import SmallLoading from 'components/Loadings/SmallLoading';
import { CopyIcon } from 'components/icons/CopyIcon';
import CursorDragIcon from 'components/icons/CursorDragIcon';
import { DragIcon } from 'components/icons/DragIcon'
import { ExportIcon } from 'components/icons/ExportIcon';
import { PlusDashIcon } from 'components/icons/PlusDashIcon';
import PlusIcon from 'components/icons/PlusIcon';
import { RightMarkToggleIcon } from 'components/icons/RightMarkToggleIcon';
import { SettingIcon } from 'components/icons/SettingIcon';
import { SettingSolidIcon } from 'components/icons/SettingSolidIcon';
import { useRef } from 'react';

const SecondaryFilters = ({
    moreFilterIsOpen,
    setMoreFilterIsOpen,
    editColumnIsOpen,
    setEditColumnIsOpen,
    moreFilterRef,
    editColumnRef,
    columns,
    setColumns,
    moveUserRef,
    moveUser,
    setMoveUser,
}) => {

    const { handleStatus, changeStatusLoading } = useCustomerContext();

    const dragColumn = useRef(0)
    const draggedOverColumn = useRef(0)


    function handleSort() {
        const columnsClone = [...columns];
        const draggedColumn = columnsClone[dragColumn.current];
        columnsClone.splice(dragColumn.current, 1);
        columnsClone.splice(draggedOverColumn.current, 0, draggedColumn);

        setColumns(columnsClone);
        localStorage.setItem('customerColumns', JSON.stringify(columnsClone));
    }

    function handleCheckboxChange(e, index) {
        const columnsClone = [...columns];
        columnsClone[index].active = e.target.checked;
        setColumns(columnsClone);
        localStorage.setItem('customerColumns', JSON.stringify(columnsClone));
    }

    return (
        <div className='flex items-center justify-between pt-[24px] pb-[20px]'>
            <div className='flex items-center gap-3 flex-wrap'>
                <button className='flex items-center gap-2 py-[1px] px-2 text-[14px] text-[#687385] font-semibold border border-dashed hover:border-[#10111a7e] border-[#C0C8D2] rounded-full hover:bg-slate-50 duration-300'> <PlusDashIcon /> Email</button>

                <button className='flex items-center gap-2 py-[1px] px-2 text-[14px] text-[#687385] font-semibold border border-dashed hover:border-[#10111a7e] border-[#C0C8D2] rounded-full hover:bg-slate-50 duration-300'> <PlusDashIcon /> Card</button>

                <div className='relative' ref={moreFilterRef}>
                    <button onClick={() => setMoreFilterIsOpen(!moreFilterIsOpen)} className={`flex items-center gap-2 py-[1px] px-2 text-[14px] text-[#687385] font-semibold border border-dashed hover:border-[#10111a7e] rounded-full hover:bg-slate-50 duration-300 ${moreFilterIsOpen ? "border-[#10111a7e] bg-slate-50" : "border-[#C0C8D2]"}`}> <PlusDashIcon />Created date</button>
                    {moreFilterIsOpen && <>
                        {/* <div className='triangle absolute left-5 top-[27px] border border-gray-100 rounded-md shadow-xl z-20'></div> */}
                        <div className='rotate-[270deg] absolute left-5 top-[20px] z-30'>
                            <RightMarkToggleIcon color="#e7e7e761" width={32} height={32} />
                        </div>
                        <div className='absolute top-10 left-0 w-72 bg-white border border-gray-100 rounded-lg shadow-xl p-3 z-20'>
                            <div className='flex flex-col gap-2'>
                                <button className='text-[#1B74E4] text-[14px] font-semibold flex items-center hover:bg-gray-100 rounded-md'><PlusIcon color={'#1B74E4'} /> Discount</button>
                                <button className='text-[#1B74E4] text-[14px] font-semibold flex items-center hover:bg-gray-100 rounded-md'><PlusIcon color={'#1B74E4'} /> Delinquent</button>
                                <button className='text-[#1B74E4] text-[14px] font-semibold flex items-center hover:bg-gray-100 rounded-md'><PlusIcon color={'#1B74E4'} /> Total Spend</button>
                                <button className='text-[#1B74E4] text-[14px] font-semibold flex items-center hover:bg-gray-100 rounded-md'><PlusIcon color={'#1B74E4'} /> Payments</button>
                                <button className='text-[#1B74E4] text-[14px] font-semibold flex items-center hover:bg-gray-100 rounded-md'><PlusIcon color={'#1B74E4'} /> Refund</button>
                                <button className='text-[#1B74E4] text-[14px] font-semibold flex items-center hover:bg-gray-100 rounded-md'><PlusIcon color={'#1B74E4'} /> Dispute Losses</button>
                                <button className='text-[#1B74E4] text-[14px] font-semibold flex items-center hover:bg-gray-100 rounded-md'><PlusIcon color={'#1B74E4'} /> First Payment</button>
                                <button className='text-[#1B74E4] text-[14px] font-semibold flex items-center hover:bg-gray-100 rounded-md'><PlusIcon color={'#1B74E4'} /> Last Payment</button>
                                <button className='text-[#1B74E4] text-[14px] font-semibold flex items-center hover:bg-gray-100 rounded-md'><PlusIcon color={'#1B74E4'} /> In recovery</button>
                            </div>
                        </div>
                    </>}
                </div>

                <button className='flex items-center gap-2 py-[1px] px-2 text-[14px] text-[#687385] font-semibold border border-dashed hover:border-[#10111a7e] border-[#C0C8D2] rounded-full hover:bg-slate-50 duration-300'> <PlusDashIcon /> Type</button>

                <button className='flex items-center gap-2 py-[1px] px-2 text-[14px] text-[#687385] font-semibold border border-dashed hover:border-[#10111a7e] border-[#C0C8D2] rounded-full hover:bg-slate-50 duration-300'> <PlusDashIcon /> More filters </button>

            </div>

            <div className='flex items-center gap-2 text-[14px]'>

                <div className='relative' ref={moveUserRef}>
                    {changeStatusLoading ?
                        <button className={`hover:bg-[#1b75e4de] duration-300 px-3 py-1 rounded-md text-white font-medium ${moveUser ? "bg-[#1b75e4de]" : "bg-[#1B74E4]"}`}>
                            <span className='flex items-center gap-2'>
                                <SmallLoading /> <span className='flex items-center gap-2'> <SettingIcon /> Move User </span>
                            </span>
                        </button>
                        :
                        <button className={`hover:bg-[#1b75e4de] duration-300 px-3 py-1 rounded-md flex items-center text-white font-medium gap-2 ${moveUser ? "bg-[#1b75e4de]" : "bg-[#1B74E4]"}`} onClick={() => setMoveUser(!moveUser)}><CursorDragIcon /> Move User</button>
                    }

                    {moveUser && <>
                        <div className='rotate-[270deg] absolute right-5 top-[20px] z-30'>
                            <RightMarkToggleIcon color="#e7e7e761" width={32} height={32} />
                        </div>
                        <div className='absolute top-10 right-0 w-[260px] bg-white border border-gray-100 rounded-lg shadow-xl p-3 z-20'>
                            <div className='flex flex-col gap-2'>
                                <div className='pb-1'>
                                    <h1 className='text-[12px] font-[700] leading-[14px]'>Move User</h1>
                                </div>
                                <div>
                                    <ul className='flex flex-col gap-2'>
                                        <li className='font-[500] text-[14px] leading-[16px] text-[#69727D] hover:bg-[#F1F8FF] p-1 cursor-pointer rounded-md' onClick={() => { handleStatus('support') }}>Support</li>
                                        <li className='font-[500] text-[14px] leading-[16px] text-[#69727D] hover:bg-[#F1F8FF] p-1 cursor-pointer rounded-md' onClick={() => { handleStatus('canceled') }}>Canceled</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </>}
                </div>

                <div className='relative' ref={editColumnRef}>
                    <button className={`border border-[#10111A29] hover:border-[#10111a7e] px-2 py-1 duration-300 rounded-md flex items-center font-medium gap-1 ${editColumnIsOpen && "border-[#10111a7e]"}`}
                        onClick={() => setEditColumnIsOpen(!editColumnIsOpen)}><SettingIcon /> Edit columns</button>

                    {editColumnIsOpen && <>
                        <div className='rotate-[270deg] absolute right-5 top-[20px] z-30'>
                            <RightMarkToggleIcon color="#e7e7e761" width={32} height={32} />
                        </div>
                        <div className='absolute top-10 right-0 w-72 bg-white border border-gray-100 rounded-lg shadow-xl p-3 z-20'>
                            <div className='flex flex-col gap-2'>
                                <div className='border-b pb-2'>
                                    <h1 className='text-[14px] font-semibold mb-2'>Fixed columns</h1>
                                    <input className='text-[14px] py-1 outline-none' type='name' placeholder='Dealership Name' disabled />
                                </div>
                                <div>
                                    <h1 className='font-semibold text-[16px] mb-2'>Active columns</h1>
                                    <div className='flex flex-col gap-2'>

                                        {
                                            columns.map((column, index) => (
                                                <div key={column.name}>
                                                    <div draggable
                                                        onDragStart={() => (dragColumn.current = index)}
                                                        onDragEnter={() => (draggedOverColumn.current = index)}
                                                        onDragEnd={handleSort}
                                                        onDragOver={(e) => e.preventDefault()} className='flex items-center justify-between'>
                                                        <div className='flex items-center gap-2'>
                                                            <input
                                                                type="checkbox"
                                                                checked={column.active}
                                                                onChange={(e) => { handleCheckboxChange(e, index) }}
                                                                name={column.name}
                                                                id={column.name}
                                                            />

                                                            <label htmlFor={column.name} className='text-gray-400 cursor-pointer'>{column.name}</label>
                                                        </div>
                                                        <div

                                                        >
                                                            <DragIcon />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </>}

                </div>

                {/* <button className='border border-[#10111A29] hover:border-[#10111a7e] duration-300 px-2 py-1 rounded-md flex items-center font-medium gap-1'><CopyIcon /> Copy</button> */}
            </div>
        </div>
    )
}

export default SecondaryFilters