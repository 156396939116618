import React, { useState } from "react";
import { EditIcon } from "../../components/icons/EditIcon";
import { MenuIcon } from "../../components/icons/MenuIcon";
import axiosClient from "../../axios.client";
import { DeleteIcon } from "../../components/icons/DeleteIcon";
import NotificationMsg from "../../components/Alerts/NotificationMsg";
import UpdateUserDesktop from "./AddUser/UpdateUserDesktop";

const UserTableMobileView = (props) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [menuOpen, SetMenuOpen] = useState({ isOpen: false, data: [] }); // User Edit Model

  const updateCruise = async (data) => {
    const index = props.allUsersList.findIndex((item) => item.id === data.id);

    if (index !== -1) {
      try {
        await axiosClient.post("/updateCruise", data).then((res) => {
          if (res.status === 200) {
            const newArray = [...props.allUsersList];
            newArray[index] = { ...newArray[index], cruise_active: data.data };
            props.setAllUsersList(newArray);
          }
        });
      } catch (e) {
        console.log(e);
      }
    } else {
      console.log(`User with id ${data.id} not found.`);
    }
  };

  const deleteUser = async (id) => {
    const userResponse = window.confirm("Are you sure you want to Delete?");

    if (userResponse) {
      try {
        await axiosClient.post(`/deleteDealerShipUser/${id}`).then((res) => {
          if (res.status === 200) {
            SetAlert(true, "success", "User was deleted");

            const updatedList = props.allUsersList.filter(
              (user) => user.id !== id
            );

            props.setSelectedUser(updatedList[0].id);
            props.setAllUsersList(updatedList);
          }
        });
      } catch (e) {
        SetAlert(true, "failed", "Unable to delete user");
      }
    } else {
      setDeleteModal(false);
    }
  };

  const [notification, setNotification] = useState({
    isOpen: false,
    status: "",
    msg: "",
  });

  const SetAlert = (bool, status, msg) => {
    setNotification({ isOpen: bool, status: status, msg: msg });

    setTimeout(() => {
      setNotification({ isOpen: false, status: "", msg: "" });
    }, 5000);
  };

  function updateUserList(data) {
    props.setSelectedUser(data[0].id);
    props.setAllUsersList(data);
  }

  return (
    <>
      {/* Notification Msg Start */}
      <NotificationMsg
        toggle={notification}
        Toggler={setNotification}
        message={notification.msg}
      ></NotificationMsg>
      {/* Notification Msg End */}

      {/* Update user CourtesyMitsubishi */}
      {menuOpen.isOpen && (
        <div className="block fixed top-0 left-0 z-[52] w-full h-full bg-black bg-opacity-30">
          <div className="flex items-center justify-center h-screen">
            <UpdateUserDesktop
              setAddUserModel={SetMenuOpen}
              editToggle={menuOpen}
              updatedUserList={updateUserList}
              list={props.allUsersList}
              SetAlert={SetAlert}
            />
          </div>
        </div>
      )}

      <div className="md:hidden pt-5">
        <label className="font-bold block mb-2">Select User</label>
        <select
          name="user"
          id="user"
          className="border border-[#EDEDED] rounded-sm p-2 w-full font-semibold"
          value={props.selectedUser}
          onChange={(e) => {
            props.setSelectedUser(e.target.value);
          }}
        >
          {props.allUsersList &&
            props.allUsersList.map((user) => {
              return (
                <option value={user.id} key={user.id}>
                  {user.username}
                </option>
              );
            })}
        </select>

        {props.allUsersList
          .filter((user) => user.id == props.selectedUser)
          .map((user) => {
            return (
              <div className="pt-10" key={user.id}>
                <div className="flex justify-between border-b pb-4">
                  <h4 className="font-semibold text-md">{user.id}</h4>
                  <h4 className="font-semibold text-md">{user.username}</h4>
                </div>
                <div className="flex justify-between mt-5 bg-[#FBFBFB]">
                  <h3 className="font-semibold text-md text-[#3D3D3D]">
                    Status
                  </h3>
                  <h3 className="font-semibold text-md text-[#3F3F3F]">
                    {user.status === "active" ? "Online" : "Offline"}
                  </h3>
                </div>
                <div className="flex justify-between mt-5">
                  <h3 className="font-semibold text-md text-[#3D3D3D]">
                    Password
                  </h3>
                  <h3 className="font-semibold text-md text-[#3F3F3F]">
                    {user.pass}
                  </h3>
                </div>
                <div className="flex justify-between mt-5 bg-[#FBFBFB]">
                  <h3 className="font-semibold text-md text-[#3D3D3D]">
                    Phone Number
                  </h3>
                  <a
                    href={`tel:${user.password}`}
                    className="font-semibold text-md text-buttonPrimary"
                  >
                    {user.phone}
                  </a>
                </div>
                <div className="flex justify-between mt-5 bg-[#FBFBFB]">
                  <h3 className="font-semibold text-md text-[#3D3D3D]">
                    Vehicles
                  </h3>
                  <h3 className="font-semibold text-md text-[#3F3F3F]">
                    {user.listed}
                  </h3>
                </div>
                <div className="flex justify-between mt-5">
                  <h3 className="font-semibold text-md text-[#3D3D3D]">
                    Pending
                  </h3>
                  <h3 className="font-semibold text-md text-[#3F3F3F]">
                    {user.pending}
                  </h3>
                </div>
                <div className="flex justify-between mt-5 bg-[#FBFBFB]">
                  <h3 className="font-semibold text-md text-[#3D3D3D]">
                    Active
                  </h3>
                  <h3 className="font-semibold text-md text-[#3F3F3F]">
                    {user.advertised}
                  </h3>
                </div>
                <div className="flex justify-between mt-5">
                  <h3 className="font-semibold text-md text-[#3D3D3D]">
                    Cruise
                  </h3>
                  <h3
                    className={
                      user.cruise_active === 1
                        ? "bg-[#06C753] rounded-xl w-10 h-5 p-[1.5px] flex items-center duration-300"
                        : "bg-[#b1b1b1] rounded-xl w-10 h-5 p-[1.5px] flex items-center duration-300"
                    }
                    onClick={() =>
                      updateCruise({
                        id: user.id,
                        data: user.cruise_active === 1 ? 0 : 1,
                      })
                    }
                  >
                    <span
                      className={
                        user.cruise_active === 1
                          ? "h-4 w-4 bg-white block rounded-xl duration-300  translate-x-5"
                          : "h-4 w-4 bg-white block rounded-xl duration-300  translate-x-0"
                      }
                    ></span>
                  </h3>
                </div>
                <div className="flex justify-between mt-5 bg-[#FBFBFB]">
                  <h3 className="font-semibold text-md text-[#3D3D3D]">
                    Last Active
                  </h3>
                  <h3 className="font-semibold text-md text-[#3F3F3F]">
                    {props.DateFormat(user.created_at)}
                  </h3>
                </div>
                <div className="flex justify-between mt-5">
                  <h3 className="font-semibold text-md text-[#3D3D3D]">
                    Last Post
                  </h3>
                  <h3 className="font-semibold text-md text-[#3F3F3F]">
                    {props.DateFormat(user.created_at)}
                  </h3>
                </div>
                <div className="flex justify-between mt-5 bg-[#FBFBFB]">
                  <h3 className="font-semibold text-md text-[#3D3D3D]">
                    Action
                  </h3>
                  <h3 className="font-semibold text-md text-[#3F3F3F] text-center flex gap-3">
                    <button
                      className="pr-1"
                      onClick={() =>
                        SetMenuOpen({
                          isOpen: true,
                          data: {
                            id: user.id || "",
                            name: user.name || "",
                            password: user.pass || "",
                            phone: user.phone || "",
                            first_name: user.first_name || "",
                            last_name: user.last_name || "",
                            email: user.email || "",
                            status: user.status || "",
                          },
                        })
                      }
                    >
                      <EditIcon />
                    </button>

                    <button
                      className="pl-1 relative"
                      onClick={() => {
                        setDeleteModal(!deleteModal);
                      }}
                    >
                      <MenuIcon />
                      <div
                        className={
                          deleteModal
                            ? "absolute right-0 top-9 bg-white z-10 shadow-lg rounded-lg"
                            : "hidden"
                        }
                      >
                        <span
                          className="text-red-500 p-3 px-4 font-semibold flex items-center gap-2 border border-gray-100"
                          onClick={() => deleteUser(user.id)}
                        >
                          <DeleteIcon />
                          Delete
                        </span>
                      </div>
                    </button>
                  </h3>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default UserTableMobileView;
