import React, { useEffect } from 'react';
import { MagnifyingGlassIcon } from 'components/icons/MagnifyingGlassIcon';
import { ActiveIcon } from 'components/icons/ActiveIcon';
import { PendingIcon } from 'components/icons/PendingIcon';
import { CruiseIcon } from 'components/icons/CruiseIcon';
import { ListedVehicleIcon } from 'components/icons/ListedVehicleIcon';
import ProfileImage from 'assets/images/CarImage.jpg';
import { useCustomerDetailContext } from 'Context/customerDetailContext/CustomerDetailContext';
import { NumberToPrice } from 'Utils/NumberToPrice';
import SmallLoading from 'components/Loadings/SmallLoading';
import { useParams } from 'react-router-dom';
import Listed from './components/Listed';
import Pending from './components/Pending';
import Advertised from './components/Advertised';
import Cruise from './components/Cruise';

export default function ManageVehicleMenu() {
  const {
    manageVehicleData,
    manageVehicleLoading,

    type,
    setType,
    vehicleSearch,
    setVehicleSearch,
    fetchManageVehicleData,

    setListedCheckedValues,

    setActiveCheckedValues,

    setPendingCheckedValues,

    setCruiseCheckedValues,
  } = useCustomerDetailContext();

  const { dealerShipID } = useParams();

  useEffect(() => {
    setActiveCheckedValues([]);
    setListedCheckedValues([]);
    setPendingCheckedValues([]);
    setCruiseCheckedValues([]);
  }, [type]);

  useEffect(() => {
    fetchManageVehicleData(dealerShipID);
  }, [dealerShipID]);

  return (
    <div className='max-w-[330px] w-full pt-[18px]'>
      <div className='flex items-center justify-between px-[15px]'>
        <div className='flex items-center gap-2'>
          <img src='/profileImage.png' alt="demo_profile_image" className='rounded-full w-[36px] h-[36px] object-cover' />
          <h1 className='font-bold text-sm'>{manageVehicleData?.user?.username}</h1>
        </div>
      </div>

      <div className='flex items-center gap-2 mt-5 px-[15px] bg-[#FAFAFA]'>
        <MagnifyingGlassIcon />
        <input
          type="search"
          className='outline-none p-2 w-full bg-[#FAFAFA] text-sm'
          placeholder='search a vehicle...'
          autoComplete='off'
          name='search'
          value={vehicleSearch.value || ""}
          onChange={(e) => { setVehicleSearch({ page: type, value: e.target.value }) }}
        />
      </div>

      <div className='flex items-center justify-between border-b py-7 font-medium text-xs px-[15px]'>
        <button
          className={`${type === "listed" ? "text-buttonPrimary" : "#1B74E4"} flex flex-col items-center`}
          onClick={() => { setType('listed') }}>
          <ListedVehicleIcon color={`${type === "listed" ? "#1B74E4" : "#8E93A6"}`} width={24} height={24} /> <span>Vehicles</span>
        </button>

        <button
          className={`${type === "active" ? "text-buttonPrimary" : "#1B74E4"} flex flex-col items-center`}
          onClick={() => { setType('active') }}>
          <ActiveIcon width={24} height={24} color={`${type === "active" ? "#1B74E4" : "#8E93A6"}`} /> <span>Active</span>
        </button>

        <button
          className={`${type === "pending" ? "text-buttonPrimary" : "#1B74E4"} flex flex-col items-center`}
          onClick={() => { setType('pending') }}>
          <PendingIcon width={24} height={24} color={`${type === "pending" ? "#1B74E4" : "#8E93A6"}`} /> <span>Pending</span>
        </button>

        <button
          className={`${type === "cruise" ? "text-buttonPrimary" : "#1B74E4"} flex flex-col items-center`}
          onClick={() => { setType('cruise') }}>
          <CruiseIcon width={25} height={25} color={`${type === "cruise" ? "#1B74E4" : "#8E93A6"}`} /> <span>Cruise</span>
        </button>
      </div>

      <div>
        <div className='p-1 px-[15px]' style={{ overflowY: "auto", height: "700px" }}>
          {manageVehicleLoading ? <div className='flex justify-center items-center min-h-[30vh]'><SmallLoading /></div> :
            <>
              {type === "listed" &&
                <Listed />}

              {type === "pending" &&
                <Pending />}

              {type === "active" &&
                <Advertised />}

              {type === "cruise" &&
                <Cruise />}
            </>
          }

        </div>
      </div>

    </div>
  );
}
