import React, { useEffect, useState } from "react";
import axiosClient from "../../../axios.client";
import { ExitIcon } from "../../../components/icons/ExitIcon";
import axios from "axios";
import { LoadingBtn } from "../../../components/Button/LoadingBtn";

const UpdateUserDesktop = ({
  setAddUserModel,
  editToggle,
  setUsersList,
  usersList,
  SetAlert,
  setDealerShipForm,
  dealerShipForm,
}) => {
  // Edit User Functions
  const [option, setOption] = useState("user");
  const [formDate, setFormDate] = useState({
    id: editToggle.data.id,
    name: editToggle.data.name,
    first_name: editToggle.data.first_name,
    last_name: editToggle.data.last_name,
    username: editToggle.data.username,
    email: editToggle.data.email,
    address: editToggle.data.address,
    password: editToggle.data.password,
    user_id: "",
    phone: editToggle.data.phone,
    status: editToggle.data.status,
    inventory_source: editToggle.data.inventory_source,
    FTPaccount: editToggle.data.FTPaccount,
  });

  const handleInput = (e) => {
    const { name, value } = e.target;

    setFormDate({ ...formDate, [name]: value });
  };

  const submitUser = async (e) => {
    e.preventDefault();

    try {
      await axiosClient
        .post("/updateUser", formDate)
        .then((res) => {
          if (res.status === 200) {
            const index = usersList.findIndex(
              (user) => user.id === formDate.id
            );

            if (index !== -1) {
              const newArray = [...usersList];
              newArray[index] = {
                ...newArray[index],
                first_name: formDate.first_name,
                last_name: formDate.last_name,
                username: formDate.username,
                pass: formDate.password,
                email: formDate.email,
                address: formDate.address,
                status: formDate.status,
                phone: formDate.phone,
                inventory_source: formDate.inventory_source,
                FTPaccount: formDate.FTPaccount,
              };

              if (setDealerShipForm !== undefined) {
                setDealerShipForm({
                  ...dealerShipForm,
                  first_name: newArray[0].first_name,
                  last_name: newArray[0].last_name,
                  username: newArray[0].username,
                  email: newArray[0].email,
                  address: newArray[0].address,
                  phone: newArray[0].phone,
                  inventory_source: newArray[0].inventory_source,
                  FTPaccount: newArray[0].FTPaccount,
                });
              }

              setUsersList(newArray);
            }
            SetAlert(true, "success", "User updated");
          }
        })
        .catch((e) => SetAlert(true, "failed", "User failed to update"));
    } catch (e) {
      SetAlert(true, "failed", "User failed to update");
    }

    setAddUserModel({ isOpen: false, data: [] });
  };

  // FTP Functions
  const [isLoading, setIsLoading] = useState(false); // loading
  const [ftpData, setFtpData] = useState([]); // ftp user name list
  const [ftpList, setFtpList] = useState(""); // ftp select user name paths
  const [ftpSearch, setFtpSearch] = useState({ isOpen: false, phrase: "" }); // ftp search
  const [selectedFTP, setSelectedFTP] = useState(""); // selected ftp user name
  const [selectedPath, setSelectedPath] = useState("");
  const [openSearch, setOpenSearch] = useState(false);

  const handleSelectedUser = (user) => {
    const path = ftpData.find((data) => data.user === user).homedir;

    axios
      .get(`https://uniqueleverage.com/ul-backend/public/api/get-file-list`, {
        params: { ftpPath: path },
      })
      .then((res) => {
        setFtpList(res.data);
      })
      .catch((e) => SetAlert(true, "failed", "Failed to fetch ftp path"));
  };

  const [submitLoading, setSubmitLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitLoading(true);

    const payload = { ftp_file_path: selectedPath, ftp_account: selectedFTP, ...formDate };

    axiosClient
      .post(`/ftp-user-account-update/${editToggle.data.id}`, payload)
      .then((res) => {
        if (res.status === 200) {
          const index = usersList.findIndex(
            (user) => user.id === formDate.id
          );

          if (index !== -1) {
            const newArray = [...usersList];
            newArray[index] = {
              ...newArray[index],
              first_name: formDate.first_name,
              last_name: formDate.last_name,
              username: formDate.username,
              pass: formDate.password,
              email: formDate.email,
              address: formDate.address,
              status: formDate.status,
              phone: formDate.phone,
              inventory_source: formDate.inventory_source,
              FTPaccount: selectedFTP,
            };

            if (setDealerShipForm !== undefined) {
              setDealerShipForm({
                ...dealerShipForm,
                first_name: newArray[0].first_name,
                last_name: newArray[0].last_name,
                username: newArray[0].username,
                email: newArray[0].email,
                address: newArray[0].address,
                phone: newArray[0].phone,
                inventory_source: newArray[0].inventory_source,
                FTPaccount: newArray[0].FTPaccount,
              });
            }

            setUsersList(newArray);
          }
        }

        if (res.status === 200) {
          SetAlert(true, "success", "FTP Account and User Details Successfully Updated");
        }
      })
      .catch((e) => SetAlert(true, "failed", "FTP Account and User Details Failed to Update"))
      .finally(() => {
        setSubmitLoading(false);
        setAddUserModel({ isOpen: false, data: [] });
      });
  };

  const fetchFTP = () => {
    setIsLoading(true);

    axiosClient
      .get("https://uniqueleverage.com/ul-backend/public/api/getFtp")
      .then((res) => {
        setFtpData(res.data.data);
      })
      .catch((e) => console.log(e));

    setIsLoading(false);
  };

  useEffect(() => {
    fetchFTP();
  }, []);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-12 h-12 border-t-4 border-buttonPrimary border-solid border-opacity-80 rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div className="p-5 bg-white rounded-md">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <button
            className={
              option === "user"
                ? "w-[100px] bg-buttonPrimary hover:bg-blue-600 duration-300 text-white rounded-md py-2"
                : "w-[100px] bg-backgroudSecondary hover:bg-blue-200 duration-300 text-black rounded-md py-2 border border-gray-300 hover:border-blue-200"
            }
            onClick={() => setOption("user")}
          >
            Edit User
          </button>
          <button
            className={
              option === "ftp"
                ? "w-[100px] bg-buttonPrimary hover:bg-blue-600 duration-300 text-white rounded-md py-2"
                : "w-[100px] bg-backgroudSecondary hover:bg-blue-200 duration-300 text-black rounded-md py-2 border border-gray-300 hover:border-blue-200"
            }
            onClick={() => setOption("ftp")}
          >
            FTP
          </button>
        </div>
        <button
          className="font-bold"
          onClick={() => setAddUserModel({ isOpen: false, data: [] })}
        >
          <ExitIcon />
        </button>
      </div>

      {/* User Edit */}
      {option === "user" && (
        <form onSubmit={submitUser}>
          <div className="py-8 flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <label className="font-bold">Name</label>
              <div className="flex">
                <input
                  type="name"
                  name="first_name"
                  onChange={(e) => handleInput(e)}
                  value={formDate.first_name}
                  required
                  placeholder="First Name"
                  className="p-2 border border-[#EAEAEA] rounded-sm flex-1 border-r-0 w-full"
                />
                <input
                  type="text"
                  name="last_name"
                  onChange={(e) => handleInput(e)}
                  value={formDate.last_name}
                  required
                  placeholder="Last Name"
                  className="p-2 border border-[#EAEAEA] rounded-sm flex-1 border-l-0 w-full"
                />
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <label className="font-bold">User Name</label>
              <input
                type="text"
                name="username"
                required
                onChange={(e) => handleInput(e)}
                value={formDate.username}
                placeholder="username..."
                className="p-2 border border-[#EAEAEA] rounded-sm"
              />
            </div>

            <div className="flex flex-col gap-2">
              <label className="font-bold">Password</label>
              <input
                type="text"
                name="password"
                required
                onChange={(e) => handleInput(e)}
                value={formDate.password}
                placeholder="Admin@1122"
                className="p-2 border border-[#EAEAEA] rounded-sm"
              />
            </div>

            {/* <div className="hidden flex flex-col gap-2">
            <label className="font-bold">User ID</label>
            <input
              type="number"
              name="user_id"
              required
              onChange={(e) => handleInput(e)}
              value={formDate.user_id}
              placeholder="100"
              className="p-2 border border-[#EAEAEA] rounded-sm"
            />
          </div> */}

            <div className="flex flex-col gap-2">
              <label className="font-bold">Phone Number</label>
              <input
                type="text"
                name="phone"
                onChange={(e) => handleInput(e)}
                value={formDate.phone}
                required
                placeholder="+1 313-530-6666"
                className="p-2 border border-[#EAEAEA] rounded-sm"
              />
            </div>

            <div className="flex flex-col gap-2">
              <label className="font-bold">Email ID</label>
              <input
                type="email"
                name="email"
                onChange={(e) => handleInput(e)}
                value={formDate.email}
                required
                placeholder="admin@uniqueleverage.com"
                className="p-2 border border-[#EAEAEA] rounded-sm"
              />
            </div>

            <div className="flex flex-col gap-2">
              <label className="font-bold">Address</label>
              <input
                type="text"
                name="address"
                onChange={(e) => handleInput(e)}
                value={formDate.address}
                required
                placeholder="address..."
                className="p-2 border border-[#EAEAEA] rounded-sm"
              />
            </div>

            <div className="flex flex-col gap-2">
              <label className="font-bold">Source</label>
              <input
                type="text"
                name="inventory_source"
                onChange={(e) => handleInput(e)}
                value={formDate.inventory_source}
                required
                placeholder="inventory source..."
                className="p-2 border border-[#EAEAEA] rounded-sm"
              />
            </div>

            <div className="flex flex-col gap-2">
              <label className="font-bold">Status</label>
              <select
                name="status"
                onChange={(e) => handleInput(e)}
                required
                className="border border-[#EAEAEA] p-2 rounded-sm"
                defaultValue={formDate.status}
              >
                <option
                  value={formDate.status}
                  className="text-gray-200"
                  disabled
                >
                  {formDate.status !== ""
                    ? formDate.status === "inactive"
                      ? "Inactive"
                      : "Active"
                    : "Select Option"}
                </option>
                <option value="active" className="text-gray-700">
                  Active
                </option>
                <option value="inactive" className="text-gray-700">
                  Inactive
                </option>
              </select>
            </div>

            <div className="hidden flex gap-2">
              <span className="bg-buttonPrimary rounded-full w-5 h-5 text-white text-lg text-center flex align-middle items-center cursor-pointer">
                <span className="ml-[3.7px] mb-[2px]">+</span>
              </span>

              <h3 className="font-bold text-[16px]  cursor-pointer">
                Assign Feed Data
              </h3>
            </div>
          </div>

          <button
            type="submit"
            className="w-full bg-buttonPrimary hover:bg-blue-600 duration-300 text-white rounded-md py-2"
          >
            Update User
          </button>
        </form>
      )}

      {/* FTP */}
      {option === "ftp" && (
        <form onSubmit={handleSubmit}>
          <div
            className="py-8 flex flex-col gap-4"
            onClick={() => {
              openSearch === true && setOpenSearch(false);
            }}
          >
            <div className="flex flex-col gap-5">
              <div className="w-[350px] relative">
                <label className="font-bold text-[#404040] block">FTP</label>
                <input
                  type="search"
                  required
                  autoComplete="off"
                  name="ftpSearch"
                  value={selectedFTP}
                  placeholder="search dealership"
                  className="border border-[#EAEAEA] rounded-md p-2 w-full"
                  onClick={() => setOpenSearch(true)}
                  onChange={(e) => {
                    setFtpSearch({ isOpen: true, phrase: e.target.value });
                    setSelectedFTP(e.target.value);
                  }}
                />

                {ftpSearch.phrase !== "" && ftpSearch.isOpen && (
                  <div className="fixed bg-white rounded-lg shadow-lg w-[350px] p-2 max-h-[200px] overflow-y-auto z-[51] text-[16px]">
                    <ul>
                      {ftpData &&
                        ftpData
                          .filter((ftp) => {
                            return ftpSearch.phrase.toLowerCase() === ""
                              ? ftp
                              : ftp.user
                                .toLowerCase()
                                .includes(ftpSearch.phrase);
                          })
                          .map((ftp, index) => {
                            return (
                              <li
                                key={index}
                                className="hover:bg-gray-100 cursor-pointer p-2"
                                onClick={() => {
                                  setSelectedFTP(ftp.user);
                                  handleSelectedUser(ftp.user);
                                  setFtpSearch({
                                    isOpen: false,
                                    phrase: ftpSearch.phrase,
                                  });
                                  setOpenSearch(false);
                                }}
                              >
                                {ftp.user}
                              </li>
                            );
                          })}
                    </ul>
                  </div>
                )}

                {openSearch && ftpSearch.phrase === "" && (
                  <div className="fixed bg-white rounded-lg shadow-lg w-[350px] p-2 max-h-[200px] overflow-y-auto z-[51] text-[16px]">
                    <ul>
                      {ftpData &&
                        ftpData
                          .filter((ftp) => {
                            return ftpSearch.phrase.toLowerCase() === ""
                              ? ftp
                              : ftp.user
                                .toLowerCase()
                                .includes(ftpSearch.phrase);
                          })
                          .map((ftp, index) => {
                            return (
                              <li
                                key={index}
                                className="hover:bg-gray-100 cursor-pointer p-2"
                                onClick={() => {
                                  setSelectedFTP(ftp.user);
                                  handleSelectedUser(ftp.user);
                                  setFtpSearch({
                                    isOpen: false,
                                    phrase: ftpSearch.phrase,
                                  });
                                  setOpenSearch(false);
                                }}
                              >
                                {ftp.user}
                              </li>
                            );
                          })}
                    </ul>
                  </div>
                )}
              </div>
              <div className="w-[350px] block">
                <label htmlFor="folder" className="font-bold text-[#404040]">
                  File
                </label>
                <ul className="border border-[#EAEAEA] p-2 max-w-[350px] max-h-[180px] rounded-lg overflow-auto text-[16px]">
                  {ftpList === "" ? (
                    <li className="text-gray-400 w-full whitespace-normal">
                      ./root/
                    </li>
                  ) : (
                    ftpList.map((ftp, index) => {
                      return (
                        <li
                          key={index}
                          className={
                            selectedPath === ftp.fullPath
                              ? "bg-gray-200 cursor-pointer py-2 border-b whitespace-normal"
                              : "hover:bg-gray-300 cursor-pointer py-2 border-b whitespace-normal"
                          }
                          onClick={() => setSelectedPath(ftp.fullPath)}
                        >
                          {ftp.fileName}
                        </li>
                      );
                    })
                  )}
                </ul>
              </div>
            </div>
          </div>

          {submitLoading ? <LoadingBtn /> :
            <button
              type="submit"
              className="w-full bg-buttonPrimary hover:bg-blue-600 duration-300 text-white rounded-md py-2"
            >
              Add FTP
            </button>}

        </form>
      )}
    </div>
  );
};

export default UpdateUserDesktop;
