import React from 'react';

const SelectRow = ({
    label,
    name,
    id,
    loadingCSV,
    csvDataHeader,
    selectedValue,
    handleFormInput,
    formData,
}) => {
    const handleChange = (event) => {
        const { value } = event.target;
        handleFormInput({ target: { name, value } });
    };

    return (
        <div className='flex-1 flex items-center justify-between gap-5'>
            <div className='flex-1 flex items-center justify-between gap-5'>
                <div className='flex flex-col gap-2'>
                    <label htmlFor={id} className='text-[#1A1B25] text-xs font-medium'>{label}</label>
                    <select
                        name={name}
                        id={id}
                        className='select-custom p-[5px] px-2 border border-[#E2E4E5] rounded-sm w-[428px] 2xl:w-[690px] outline-none hover:border-[#10111a7e] duration-200 cursor-pointer text-[#687385]'
                        defaultValue={formData[name] || ""}
                        onChange={handleChange}
                    >
                        <option value="" disabled> {formData[name] !== "" ? formData[name] : "Select Account"} </option>
                        {loadingCSV ? <option disabled>Loading...</option> :
                            csvDataHeader.map((header, index) => (
                                <option key={index} value={header}>{header}</option>
                            ))}
                    </select>
                </div>
            </div>
        </div>
    )
}

export default SelectRow;
