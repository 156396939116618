import axios from "axios";
import { useNavigate } from "react-router-dom";

const axiosClient = axios.create({
  // baseURL: `http://127.0.0.1:8000/api`,
  baseURL: `https://uniqueleverage.com/ul-backend/public/api`,
});

axiosClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("ACCESS_TOKEN");
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const navigate = useNavigate();
    if (error.response.status === 401) {
      localStorage.removeItem("ACCESS_TOKEN");
      navigate("/login");
    }
    const { response } = error;
    if (response && response.status === 401) {
      localStorage.removeItem("ACCESS_TOKEN");
      // window.location.href = "/ul-admin/login";
      navigate("/login");
    }
    if (response === undefined) {
      localStorage.removeItem("ACCESS_TOKEN");
      // window.location.href = "/ul-admin/login";
      navigate("/login");
    }

    return Promise.reject(error);
  }
);

export default axiosClient;
