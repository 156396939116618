import React from "react";

export const DownArrowIcon = ({ width, height, color }) => {
  return (
    <svg
      className="mx-2"
      width={width != undefined ? width : "10"}
      height={height != undefined ? height : "6"}
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.71655 0.232035C8.85799 0.0833867 9.04899 0 9.24803 0C9.44708 0 9.63807 0.0833867 9.77951 0.232035C9.84935 0.305029 9.9048 0.392037 9.94265 0.487995C9.98051 0.583952 10 0.686942 10 0.790971C10 0.895001 9.98051 0.997991 9.94265 1.09395C9.9048 1.18991 9.84935 1.27691 9.77951 1.34991L5.53202 5.76833C5.39026 5.91677 5.19913 6 5 6C4.80087 6 4.60974 5.91677 4.46798 5.76833L0.220486 1.34991C0.150654 1.27691 0.0951987 1.18991 0.057346 1.09395C0.0194934 0.997991 0 0.895001 0 0.790971C0 0.686942 0.0194934 0.583952 0.057346 0.487995C0.0951987 0.392037 0.150654 0.305029 0.220486 0.232035C0.361925 0.0833867 0.552923 0 0.751966 0C0.951008 0 1.14201 0.0833867 1.28345 0.232035L5.00163 3.8556L8.71655 0.232035Z"
        fill={color != undefined ? color : "#333333"}
      />
    </svg>
  );
};
