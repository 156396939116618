import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosClient from "../../../axios.client";
import axios from "axios";
import Header from "../../../layout/Header/Header";
import SideMenu from "../../../components/sidemenu/SideMenu";
import ListedVehicles from "./ListedVehicles";
import ActiveVehicles from "./ActiveVehicles";
import PendingVehicles from "./PendingVehicles";
import NoData from "./NoData";
import ViewCSV from "./ViewCSV";
import { EditButton } from "./components/EditButton";
import NotificationMsg from "../../../components/Alerts/NotificationMsg";
import BulkDescriptionEditModel from "./components/BulkDescriptionEditModel";
import VehicleEdit from "./components/VehicleEdit";
import { useSearchContext } from "Context/SearchContext";

const ManageVehicles = () => {
  const {
    setSelectedPage,
    search,
  } = useSearchContext();

  const navigate = useNavigate();
  const { VehicleId } = useParams();

  const [option, setOption] = useState("Vehicles"); // Tab options
  const [openDescriptionModel, setOpenDescriptionModel] = useState({
    isOpen: false,
    data: [],
  }); // Bulk Description

  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState([]); // contains User information
  const [allCars, setAllCars] = useState({
    listed: [],
    advertised: [],
    pending: [],
  }); // listed, advertised, pending

  function removeDuplicates(arr) {
    for (let i = 0; i < arr.length; i++) {
      for (let j = i + 1; j < arr.length; j++) {
        let keysMatch = true;
        for (let key in arr[i]) {
          if (key === "vin") {
            if (
              arr[i][key] === arr[j][key] &&
              arr[i]["year"] === arr[j]["year"] &&
              arr[i]["model"] === arr[j]["model"] &&
              arr[i]["make"] === arr[j]["make"]
            ) {
              // console.log("Duplicate " + arr[i]["year"] + " " + arr[i]["make"]);
              keysMatch = true;
              break;
            } else {
              keysMatch = false;
            }
          }
        }
        if (keysMatch) {
          arr.splice(j, 1);
          j--;
        }
      }
    }
    return arr;
  }

  // Getting all car data
  const fetchCars = async () => {
    try {
      setLoading(true);

      const [carsResponse, userResponse] = await Promise.all([
        axiosClient.get(`https://uniqueleverage.com/ul-backend/public/api/get-cars/${VehicleId}`),
        axiosClient.get(`/getSingleUser/${VehicleId}`)
      ]);
      setUserInfo(userResponse.data.response);

      const parsedCarsData = JSON.parse(carsResponse.data);

      const vehiclesListed = parsedCarsData.listed.filter(
        (x) =>
          x.price > 0 &&
          x.images !== "" &&
          x.images.split(",").length > 2 &&
          x.model !== "" &&
          x.make !== "" &&
          x.year !== "" &&
          ((x.trim !== "" &&
            x.bodystyle !== "" &&
            x.fueltype !== "" &&
            x.mileage >= 1000) ||
            x.make.toLowerCase().includes("trailer"))
      );

      let uniqueVehiclesListed = removeDuplicates(vehiclesListed);
      let uniqueVehiclesAdvertised = removeDuplicates(parsedCarsData.advertised);
      let uniqueVehiclesPending = removeDuplicates(parsedCarsData.pending);

      if (userResponse && userResponse.data.response.cruise_active === 1) {
        const advertisedVins = uniqueVehiclesAdvertised.map((vehicle) => vehicle.vin);
        const filteredListed = uniqueVehiclesListed.filter((x) => !advertisedVins.includes(x.vin))

        const updatedfilteredListed = filteredListed.map(vehicle => ({ ...vehicle, "status": "pending" }));
        uniqueVehiclesPending = [...uniqueVehiclesPending, ...updatedfilteredListed];
        uniqueVehiclesPending = removeDuplicates(uniqueVehiclesPending)

      }

      setAllCars({
        listed: uniqueVehiclesListed,
        advertised: uniqueVehiclesAdvertised,
        pending: uniqueVehiclesPending,
      });
    } catch (error) {
      // console.error(error);
      SetAlert(true, 'failed', "Unable to fetch cars");
    } finally {
      setLoading(false);
    }
  };


  const [listedCheckedValues, setListedCheckedValues] = useState([]);
  const [advertisedCheckedValues, setAdvertisedCheckedValues] = useState([]);
  const [pendingCheckedValues, setPendingCheckedValues] = useState([]);

  const [vehicleEditToggle, setVehicleEditToggle] = useState({
    isOpen: false,
    data: [],
  });

  const handleEditVehicle = (e) => {
    const { name, value } = e.target;

    setVehicleEditToggle((prev) => ({
      ...prev,
      data: { ...prev.data, [name]: value },
    }));
  };

  const EditVehicle = (e) => {
    e.preventDefault();

    const payload = {
      year: vehicleEditToggle.data.year,
      make: vehicleEditToggle.data.make,
      model: vehicleEditToggle.data.model,
      trim: vehicleEditToggle.data.trim,
      mileage: vehicleEditToggle.data.mileage,
      price: vehicleEditToggle.data.price,
      bodystyle: vehicleEditToggle.data.bodystyle,
      interiorcolor: vehicleEditToggle.data.interiorcolor,
      exteriorcolor: vehicleEditToggle.data.exteriorcolor,
      transmission: vehicleEditToggle.data.transmission,
      fueltype: vehicleEditToggle.data.fueltype,
      condition: vehicleEditToggle.data.condition,
      description: vehicleEditToggle.data.description,
      title: vehicleEditToggle.data.title,
      vin: vehicleEditToggle.data.vin,
      url: vehicleEditToggle.data.url,
    };

    axiosClient.post("update-manage-vehicle", {
      id: vehicleEditToggle.data.id,
      ...payload,
    })
      .then((res) => {
        if (res.status === 200) {
          SetAlert(true, "success", "Descriptions Updated");
          setVehicleEditToggle({ isOpen: false, data: [] });
        }
      })
      .catch((e) => {
        SetAlert(true, "failed", "Descriptions Failed to Update");
        setVehicleEditToggle({ isOpen: false, data: [] });
        return;
      });

    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData);

    if (data.option === "Vehicles") {
      const updatedList = allCars.listed.map((item) =>
        item.ID === vehicleEditToggle.data.id
          ? {
            ...item,
            ...payload,
          }
          : item
      );

      setAllCars((prev) => ({ ...prev, listed: updatedList }));
    }

    if (data.option === "Active") {
      const updatedList = allCars.advertised.map((item) =>
        item.ID === vehicleEditToggle.data.id
          ? {
            ...item,
            ...payload,
          }
          : item
      );

      setAllCars((prev) => ({ ...prev, advertised: updatedList }));
    }

    if (data.option === "Pending") {
      const updatedList = allCars.pending.map((item) =>
        item.ID === vehicleEditToggle.data.id
          ? {
            ...item,
            ...payload,
          }
          : item
      );

      setAllCars((prev) => ({ ...prev, pending: updatedList }));
    }
  };

  const handleBulkDescription = (e) => {
    const { name, value } = e.target;

    setOpenDescriptionModel((prev) => ({
      isOpen: true,
      data: {
        ...prev.data,
        [name]: value,
      },
    }));
  };

  // Edit Bulk Description
  const BulkEditDescription = (name) => {
    if (name === "vehicle") {
      let data = [];

      data = allCars.listed.filter((item) =>
        listedCheckedValues.includes(item.ID.toString())
      );

      if (data.length > 0) {
        setOpenDescriptionModel({ isOpen: true, data: data[0] });
      }
    }

    if (name === "advertised") {
      let data = [];

      data = allCars.advertised.filter((item) =>
        advertisedCheckedValues.includes(item.ID.toString())
      );

      if (data.length > 0) {
        setOpenDescriptionModel({ isOpen: true, data: data[0] });
      }
    }

    if (name === "pending") {
      let data = [];

      data = allCars.pending.filter((item) =>
        pendingCheckedValues.includes(item.ID.toString())
      );

      if (data.length > 0) {
        setOpenDescriptionModel({ isOpen: true, data: data[0] });
      }
    }
  };

  const handleBulkDescriptionUpdate = async (e) => {
    e.preventDefault();
    let Idlist = [];

    if (option === "Vehicles") {
      Idlist = listedCheckedValues;

      let data = allCars.listed.map((item) =>
        listedCheckedValues.includes(item.ID.toString())
          ? {
            ...item,
            description: openDescriptionModel.data.description,
          }
          : item
      );

      setAllCars((prevAllCars) => ({
        ...prevAllCars,
        listed: data,
      }));

      setListedCheckedValues([]);
    }

    if (option === "Active") {
      Idlist = advertisedCheckedValues;

      let data = allCars.advertised.map((item) =>
        advertisedCheckedValues.includes(item.ID.toString())
          ? {
            ...item,
            description: openDescriptionModel.data.description,
          }
          : item
      );

      setAllCars((prevAllCars) => ({
        ...prevAllCars,
        advertised: data,
      }));

      setAdvertisedCheckedValues([]);
    }

    if (option === "Pending") {
      Idlist = pendingCheckedValues;

      let data = allCars.pending.map((item) =>
        pendingCheckedValues.includes(item.ID.toString())
          ? {
            ...item,
            description: openDescriptionModel.data.description,
          }
          : item
      );

      setAllCars((prevAllCars) => ({
        ...prevAllCars,
        pending: data,
      }));

      setPendingCheckedValues([]);
    }

    await axiosClient
      .post("bulk-update-description", {
        list: Idlist,
        description: openDescriptionModel.data.description,
      })
      .then((res) => {
        if (res.status === 200) {
          SetAlert(true, "success", "Descriptions Updated");
        }
      })
      .catch((e) => SetAlert(true, "failed", "Descriptions Failed to Update"));

    setOpenDescriptionModel({ isOpen: false, data: [] });
  };

  // from listed to active and pending
  const BulkMoveToVehicle = (name) => {
    if (name === "pending") {
      let data = allCars.pending.filter((item) =>
        pendingCheckedValues.includes(item.ID.toString())
      );

      const remaining = allCars.pending.filter(
        (item) => !pendingCheckedValues.includes(item.ID.toString())
      );

      data = data.map((item) => ({ ...item, status: "listed" }));

      setAllCars((prevAllCars) => ({
        ...prevAllCars,
        listed: [...data, ...prevAllCars.listed],
        pending: remaining,
      }));

      axiosClient
        .post("/pending-bulk-move-to-listed", {
          list: pendingCheckedValues,
        })
        .then((res) => console.log(res))
        .catch((e) => console.log(e));

      setPendingCheckedValues([]);
      SetAlert(true, "success", "Row Moved To Listed");
      return;
    }

    if (name === "advertised") {
      let data = allCars.advertised.filter((item) =>
        advertisedCheckedValues.includes(item.ID.toString())
      );

      const remaining = allCars.advertised.filter(
        (item) => !advertisedCheckedValues.includes(item.ID.toString())
      );

      data = data.map((item) => ({ ...item, status: "listed" }));

      setAllCars((prevAllCars) => ({
        ...prevAllCars,
        advertised: remaining,
        listed: [...data, ...prevAllCars.listed],
      }));

      axiosClient
        .post("/advertised-bulk-move-to-listed", {
          list: advertisedCheckedValues,
        })
        .then((res) => console.log(res))
        .catch((e) => console.log(e));

      setAdvertisedCheckedValues([]);
      SetAlert(true, "success", "Row Moved To Listed");
      return;
    }
  };

  // from listed to active and pending
  const BulkMoveToActive = (name) => {
    if (name === "vehicle") {
      let data = allCars.listed.filter((item) =>
        listedCheckedValues.includes(item.ID.toString())
      );

      let remaining = allCars.listed.filter(
        (item) => !listedCheckedValues.includes(item.ID.toString())
      );

      data = data.map((item) => ({ ...item, status: "advertised" }));

      setAllCars((prevAllCars) => ({
        ...prevAllCars,
        advertised: [...data, ...prevAllCars.advertised],
        listed: remaining,
      }));

      axiosClient
        .post("/vehicle-bulk-move-to-advertised", { list: listedCheckedValues })
        .then((res) => console.log(res))
        .catch((e) => console.log(e));

      setListedCheckedValues([]);
      SetAlert(true, "success", "Row Moved To Active");
      return;
    }

    if (name === "pending") {
      let data = allCars.pending.filter((item) =>
        pendingCheckedValues.includes(item.ID.toString())
      );

      const remaining = allCars.pending.filter(
        (item) => !pendingCheckedValues.includes(item.ID.toString())
      );

      data = data.map((item) => ({ ...item, status: "advertised" }));

      setAllCars((prevAllCars) => ({
        ...prevAllCars,
        advertised: [...data, ...prevAllCars.advertised],
        pending: remaining,
      }));

      axiosClient
        .post("/pending-bulk-move-to-advertised", {
          list: pendingCheckedValues,
        })
        .then((res) => console.log(res))
        .catch((e) => console.log(e));

      setPendingCheckedValues([]);
      SetAlert(true, "success", "Row Moved To advertised");
      return;
    }
  };

  // From active to listed and pending
  const BulkMoveToPending = (name) => {
    if (name === "vehicle") {
      let data = allCars.listed.filter((item) =>
        listedCheckedValues.includes(item.ID.toString())
      );

      const remaining = allCars.listed.filter(
        (item) => !listedCheckedValues.includes(item.ID.toString())
      );

      data = data.map((item) => ({ ...item, status: "pending" }));

      setAllCars((prevAllCars) => ({
        ...prevAllCars,
        pending: [...data, ...prevAllCars.pending],
        listed: remaining,
      }));

      axiosClient
        .post("/vehicle-bulk-move-to-pending", {
          list: listedCheckedValues,
        })
        .then((res) => console.log(res))
        .catch((e) => console.log(e));

      setListedCheckedValues([]);
      SetAlert(true, "success", "Row Moved To Pending");
      return;
    }

    if (name === "advertised") {
      let data = allCars.advertised.filter((item) =>
        advertisedCheckedValues.includes(item.ID.toString())
      );

      const remaining = allCars.advertised.filter(
        (item) => !advertisedCheckedValues.includes(item.ID.toString())
      );

      data = data.map((item) => ({ ...item, status: "pending" }));

      setAllCars((prevAllCars) => ({
        ...prevAllCars,
        advertised: remaining,
        pending: [...data, ...prevAllCars.pending],
      }));

      axiosClient
        .post("/advertised-bulk-move-to-pending", {
          list: advertisedCheckedValues,
        })
        .then((res) => console.log(res))
        .catch((e) => console.log(e));

      setAdvertisedCheckedValues([]);
      SetAlert(true, "success", "Row Moved To Pending");
      return;
    }
  };

  // from pending to listed and active
  const BulkDeleteRow = (name) => {
    if (name === "vehicle") {
      let data = allCars.listed.filter(
        (item) => !listedCheckedValues.includes(item.ID.toString())
      );

      setAllCars((prevAllCars) => ({
        ...prevAllCars,
        listed: data,
      }));

      axiosClient
        .post("/vehicle-bulk-delete", { list: listedCheckedValues })
        .then((res) => console.log(res))
        .catch((e) => console.log(e));

      setListedCheckedValues([]);
      SetAlert(true, "success", "Successfully deleted");
      return;
    }

    if (name === "advertised") {
      let data = allCars.advertised.filter(
        (item) => !advertisedCheckedValues.includes(item.ID.toString())
      );

      setAllCars((prevAllCars) => ({
        ...prevAllCars,
        advertised: data,
      }));

      axiosClient
        .post("/advertised-bulk-delete", { list: advertisedCheckedValues })
        .then((res) => console.log(res))
        .catch((e) => console.log(e));

      setAdvertisedCheckedValues([]);
      SetAlert(true, "success", "Successfully deleted");
      return;
    }

    if (name === "pending") {
      let data = allCars.pending.filter(
        (item) => !pendingCheckedValues.includes(item.ID.toString())
      );

      setAllCars((prevAllCars) => ({
        ...prevAllCars,
        pending: data,
      }));

      axiosClient
        .post("/pending-bulk-delete", { list: pendingCheckedValues })
        .then((res) => console.log(res))
        .catch((e) => console.log(e));

      setPendingCheckedValues([]);
      SetAlert(true, "success", "Successfully deleted");

      return;
    }
  };

  const handleSelectAll = () => {
    if (option === "Vehicles") {
      const data = allCars.listed.map((item) => item.ID.toString());

      listedCheckedValues.length > 0
        ? setListedCheckedValues([])
        : setListedCheckedValues(data);
    }

    if (option === "Active") {
      const data = allCars.advertised.map((item) => item.ID.toString());

      advertisedCheckedValues.length > 0
        ? setAdvertisedCheckedValues([])
        : setAdvertisedCheckedValues(data);
    }

    if (option === "Pending") {
      const data = allCars.pending.map((item) => item.ID.toString());

      pendingCheckedValues.length > 0
        ? setPendingCheckedValues([])
        : setPendingCheckedValues(data);
    }
  };

  const [notification, setNotification] = useState({
    isOpen: false,
    status: "",
    msg: "",
  });

  const SetAlert = (bool, status, msg) => {
    setNotification({ isOpen: bool, status: status, msg: msg });

    setTimeout(() => {
      setNotification({ isOpen: false, status: "", msg: "" });
    }, 5000);
  };

  const [loadingCSV, setLoadingCSV] = useState(false);
  const [csvDataHeader, setCsvDataHeader] = useState([]);
  const [csvData, setCsvData] = useState([]);

  const fetchCSV = async () => {
    setLoadingCSV(true);

    try {
      const response = await axiosClient.get(`/get-csv-data/${VehicleId}`);
      if (response.data.error !== "File not found") {
        setCsvDataHeader(response.data[0] || []);
        setCsvData(response.data || []);
      } else {
        setCsvDataHeader(response.data[0] || []);
        setCsvData(null);
      }
    } catch (error) {
      // console.error(error);
      SetAlert(true, 'failed', 'unable to fetch csv data');
    }

    setLoadingCSV(false);
  };

  const [comparisonloading, SetComparisonLoading] = useState(false);
  const [noCSVDataHeader, SetNoCSVDataHeader] = useState([]);
  const [noCSVData, SetNoCSVData] = useState([]);

  const fetchComparisonData = async () => {
    SetComparisonLoading(true);

    await axios
      .get(`https://uniqueleverage.com/ul-backend/public/api/get-csv-data-comparision/${VehicleId}`)
      .then((res) => {

        if (res.data.error !== "File not found") {
          SetNoCSVDataHeader(res.data[0] || []);
          SetNoCSVData(res.data || []);
        } else {
          SetNoCSVDataHeader(res.data[0]);
          SetNoCSVData(null);
        }
      })
      .catch((e) => console.log(e));

    SetComparisonLoading(false);
  };

  useEffect(() => {
    fetchCars(); // getting all cars & getting single user
    fetchCSV(); // get all CSV
    fetchComparisonData(); // get Comparison Data

    setSelectedPage("manage_vehicle")
  }, []);

  return (
    <>
      {/* Notification Msg Start */}
      <NotificationMsg
        toggle={notification}
        Toggler={setNotification}
        message={notification.msg}
      ></NotificationMsg>
      {/* Notification Msg End */}

      {/* Bulk Description Edit Start */}
      {openDescriptionModel.isOpen && (
        <BulkDescriptionEditModel
          description={openDescriptionModel.data.description}
          setOpenDescriptionModel={setOpenDescriptionModel}
          handleBulkDescription={handleBulkDescription}
          handleBulkDescriptionUpdate={handleBulkDescriptionUpdate}
        ></BulkDescriptionEditModel>
      )}
      {/* Bulk Description Edit End */}

      {/* Vehicle Edit Start */}
      {vehicleEditToggle.isOpen && (
        <VehicleEdit
          option={option}
          data={vehicleEditToggle.data}
          setVehicleEditToggle={setVehicleEditToggle}
          handleEditVehicle={handleEditVehicle}
          EditVehicle={EditVehicle}
        ></VehicleEdit>
      )}
      {/* Vehicle Edit Edit End */}

      <>
        <div className="hidden md:flex justify-between items-center my-[30px]">
          <div className="flex flex-col gap-5">
            <h2 className="text-3xl font-semibold">Manage Vehicles</h2>
            <h3 className="font-bold text-[#383838]">
              User Name:{" "}
              <span className="text-buttonPrimary">
                {userInfo.username}
              </span>
            </h3>
          </div>

          <button
            className="py-2 px-5 rounded-md border border-[#D4D4D4] bg-[#F9F9F9] ml-2 text-[16px]"
            onClick={() => navigate(`/view-logs/${VehicleId}`)}
          >
            View Logs
          </button>
        </div>

        <div id="tabWrapper">
          <div id="tabs" className="flex items-center justify-between">
            <div className="flex gap-5 border-borderPrimary pt-2 pb-6">
              <button
                className={
                  option === "Vehicles"
                    ? "bg-buttonPrimary text-white p-3 rounded-md"
                    : "bg-backgroudSecondary text-gray-800 p-3 rounded-md border border-[#D4D4D4]"
                }
                onClick={() => setOption("Vehicles")}
              >
                Vehicles{" "}
                <span className="text-[16px]">
                  {allCars && `(${allCars.listed.length})`}
                </span>
              </button>

              <button
                className={
                  option === "Active"
                    ? "bg-buttonPrimary text-white p-3 rounded-md"
                    : "bg-backgroudSecondary text-gray-800 p-3 rounded-md border border-[#D4D4D4]"
                }
                onClick={() => setOption("Active")}
              >
                Active{" "}
                <span className="text-[16px]">
                  {allCars && `(${allCars.advertised.length})`}
                </span>
              </button>

              <button
                className={
                  option === "Pending"
                    ? "bg-buttonPrimary text-white p-3 rounded-md"
                    : "bg-backgroudSecondary text-gray-800 p-3 rounded-md border border-[#D4D4D4]"
                }
                onClick={() => setOption("Pending")}
              >
                Pending{" "}
                <span className="text-[16px]">
                  {allCars && `(${allCars.pending.length})`}
                </span>
              </button>

              <button
                className={
                  option === "csv"
                    ? "bg-buttonPrimary text-white p-3 rounded-md"
                    : "bg-backgroudSecondary text-gray-800 p-3 rounded-md border border-[#D4D4D4]"
                }
                onClick={() => setOption("csv")}
              >
                View CSV{" "}
                <span className="text-[16px]">
                  (
                  {csvData
                    ? csvData.length
                      ? `${csvData.filter(item => typeof item === 'object').length - 1}`
                      : 0
                    : 0}
                  )
                </span>
              </button>

              <button
                className={
                  option === "no_data"
                    ? "bg-buttonPrimary text-white p-3 rounded-md"
                    : "bg-backgroudSecondary text-gray-800 p-3 rounded-md border border-[#D4D4D4]"
                }
                onClick={() => setOption("no_data")}
              >
                Not in App{" "}
                <span className="text-[16px]">
                  (
                  {noCSVData
                    ? noCSVData.length
                      ? `${noCSVData.filter(item => typeof item === 'object').length - 1}`
                      : 0
                    : 0}
                  )
                </span>
              </button>

              <button className="bg-backgroudSecondary text-gray-800 p-3 rounded-md border border-[#D4D4D4]">
                Deleted
              </button>

              <button className="bg-backgroudSecondary text-gray-800 p-3 rounded-md border border-[#D4D4D4]">
                Updated
              </button>

              <EditButton
                option={option}
                listedCheckedValues={listedCheckedValues}
                advertisedCheckedValues={advertisedCheckedValues}
                pendingCheckedValues={pendingCheckedValues}
                BulkMoveToVehicle={BulkMoveToVehicle}
                BulkMoveToActive={BulkMoveToActive}
                BulkMoveToPending={BulkMoveToPending}
                BulkDeleteRow={BulkDeleteRow}
                BulkEditDescription={BulkEditDescription}
              />
            </div>
          </div>
          <div
            className={`border-b flex items-center gap-5 pb-3 ${option !== "Active" && "hidden"
              }`}
          >
            <div className="flex items-center gap-3">
              <div className="h-4 w-4 bg-orange-200 rounded-full"></div>-
              {"  "}
              url empty
            </div>
            <div className="flex items-center gap-3">
              <div className="h-4 w-4 bg-red-200 rounded-full"></div>-
              {"  "}
              Duplicate
            </div>
          </div>

          {loading ? (
            <div className="flex justify-center items-center h-screen">
              <div className="w-12 h-12 border-t-4 border-buttonPrimary border-solid border-opacity-80 rounded-full animate-spin"></div>
            </div>
          ) : (
            <div id="tabContent">
              <div
                id="UsersTab"
                className="flex flex-col justify-between h-[calc(100vh-220px)]"
              >
                <div className="overflow-x-auto hidden md:block">
                  <div className="p-1.5 min-w-full align-middle pt-7">
                    {option === "Vehicles" && (
                      <ListedVehicles
                        search={search}
                        listedCars={allCars.listed}
                        MainList={allCars}
                        updateList={setAllCars}
                        setListedCheckedValues={setListedCheckedValues}
                        listedCheckedValues={listedCheckedValues}
                        setVehicleEditToggle={setVehicleEditToggle}
                        option={option}
                        handleSelectAll={handleSelectAll}
                        advertisedCheckedValues={advertisedCheckedValues}
                        pendingCheckedValues={pendingCheckedValues}
                      />
                    )}

                    {option === "Active" && (
                      <ActiveVehicles
                        search={search}
                        advertisedCar={allCars.advertised}
                        MainList={allCars}
                        updateList={setAllCars}
                        setAdvertisedCheckedValues={
                          setAdvertisedCheckedValues
                        }
                        advertisedCheckedValues={advertisedCheckedValues}
                        setVehicleEditToggle={setVehicleEditToggle}
                        option={option}
                        handleSelectAll={handleSelectAll}
                        listedCheckedValues={listedCheckedValues}
                        pendingCheckedValues={pendingCheckedValues}
                      />
                    )}

                    {option === "Pending" && (
                      <PendingVehicles
                        search={search}
                        pending={allCars.pending}
                        MainList={allCars}
                        updateList={setAllCars}
                        setPendingCheckedValues={setPendingCheckedValues}
                        pendingCheckedValues={pendingCheckedValues}
                        setVehicleEditToggle={setVehicleEditToggle}
                        option={option}
                        handleSelectAll={handleSelectAll}
                        listedCheckedValues={listedCheckedValues}
                        advertisedCheckedValues={advertisedCheckedValues}
                      />
                    )}

                    {option === "csv" && loadingCSV ? (
                      <div className="flex justify-center items-center h-screen">
                        <div className="w-12 h-12 border-t-4 border-buttonPrimary border-solid border-opacity-80 rounded-full animate-spin"></div>
                      </div>
                    ) : (
                      option === "csv" && (
                        <ViewCSV
                          loadingCSV={loadingCSV}
                          csvDataHeader={csvDataHeader}
                          csvData={csvData}
                        />
                      )
                    )}

                    {option === "no_data" && comparisonloading ? (
                      <div className="flex justify-center items-center h-screen">
                        <div className="w-12 h-12 border-t-4 border-buttonPrimary border-solid border-opacity-80 rounded-full animate-spin"></div>
                      </div>
                    ) : (
                      option === "no_data" && (
                        <NoData
                          comparisonloading={comparisonloading}
                          noCSVDataHeader={noCSVDataHeader}
                          noCSVData={noCSVData}
                        />
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    </>
  );
};

export default ManageVehicles;
