
const InfoIcon = () => {
    return (
        <svg width="15" height="14" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12.0801 6C12.0801 2.68629 9.39381 0 6.08008 0C2.76637 0 0.0800781 2.68629 0.0800781 6C0.0800781 9.31373 2.76637 12 6.08008 12C9.39381 12 12.0801 9.31373 12.0801 6ZM6.91341 3.81869C6.91341 4.27892 6.54035 4.65202 6.08008 4.65202C5.61981 4.65202 5.24675 4.27892 5.24675 3.81869C5.24675 3.35845 5.61981 2.98535 6.08008 2.98535C6.54035 2.98535 6.91341 3.35845 6.91341 3.81869ZM6.74675 5.66667V8.66667H5.41341V5.66667H6.74675Z" fill="#373737" />
        </svg>

    )
}

export default InfoIcon