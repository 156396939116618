import { CalenderIcon } from 'components/icons/CalenderIcon'
import { DownArrowIcon } from 'components/icons/DownArrowIcon'
import { EditIcon } from 'components/icons/EditIcon'
import { MagnifyingGlassIcon } from 'components/icons/MagnifyingGlassIcon'
import PlusIcon from 'components/icons/PlusIcon'
import React, { useEffect, useRef, useState } from 'react'

import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import coy from 'react-syntax-highlighter/dist/esm/styles/prism/coy';
import { CircleDown } from 'components/icons/CircleDown';
import { useCustomerDetailContext } from 'Context/customerDetailContext/CustomerDetailContext'
import { SettingIcon } from 'components/icons/SettingIcon'
import { useAlertContext } from 'Context/AlertContext'
import axiosClient from 'axios.client'
import SmallLoading from 'components/Loadings/SmallLoading'

const EventsAndLogs = () => {
    const {
        setSelectedPage,
        customerDetail,
        usersList,
    } = useCustomerDetailContext();

    const {
        SetAlert,
    } = useAlertContext();

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedUser, setSelectedUser] = useState([]);
    const [activity, setActivity] = useState([]);
    const [loadingActivity, setLoadingActivity] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10); // Change as per your requirement

    const handleDateChange = (date) => {
        setSelectedDate(date.target.value);

        // console.log('date', selectedUser.id);
        fetchUsersActivity(selectedUser.id);
    };

    const handleSelectedUser = (user) => {
        setSelectedUser(user);

        fetchUsersActivity(user.id);
    }

    const fetchUsersActivity = async (id) => {
        // console.log('fetch', id, selectedUser, selectedDate);    
        setLoadingActivity(true);
        try {
            const [activityRes] = await Promise.all([
                // axiosClient.get(`/users/${id}/activity`)
                axiosClient.post(`https://uniqueleverage.com/ul-backend/public/api/users/${id}/activity`, { selectedDate }),
            ]);

            setActivity(activityRes.data || []);
        } catch (error) {
            SetAlert(true, 'failed', 'Failed to fetch user activity');
        }
        setLoadingActivity(false);
    }

    useEffect(() => {

        setSelectedUser(customerDetail);
        fetchUsersActivity(customerDetail.id);

        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const totalPages = Math.ceil(activity.length / pageSize);
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const displayedActivity = activity.slice(startIndex, endIndex);

    const formatDate = (inputDate) => {
        const date = new Date(inputDate);

        const year = date.getFullYear().toString().slice(-2);
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hour = date.getHours() > 12 ? (date.getHours() - 12).toString().padStart(2, '0') : date.getHours().toString().padStart(2, '0');
        const minute = date.getMinutes().toString().padStart(2, '0');
        const second = date.getSeconds().toString().padStart(2, '0');
        const period = date.getHours() >= 12 ? 'PM' : 'AM';

        const formattedDate = `${month}/${day}/${year}, ${hour}:${minute}:${second} ${period}`;

        return formattedDate;
    }


    const [toggleCodeFade, setToggleCodeFade] = useState(false);

    const obj = `{
        "id": "in_1O9FovBYSqaXijdvZsAmZmG4",
        "object": "invoice",
        "payment_intent": "pi_1O9FovBYSqaXijdvZsAmZmG4",
        "status": "paid",
        "amount_due": 19900,
        "charge": "ch_1O9FovBYSqaXijdvZsAmZmG4",
        "currency": "usd",
        "customer": "cus_JDk2ZQXQ8QZQXQ",
        "default_payment_method": null,
        "uri": "",
        "data": [
            {
            "id": "57dafe43317467e7b0ef5276",
            "projectId": "54c17e92e4b0bc49e27d9398",
            "number": 5,
            "startingUserId": "57a28b28961401f240812caf",
            "queued": 1473969731915,
            "started": 1473969754283,
            "completed": 1473969762970,
            "status": "Succeeded",
            "commitId": "1c6e8aa47951e39f9a905f0077af9355c35b712b",
            "executor": "us-west-2:i-007942020ce7baeb5",
            "outputCommitId": "73fe9481fe635bada713246c3c739beefa424e8c",
            "title": "Run with great R^2 of 1!",
            "isArchived": false,
            "postProcessedTimestamp": 1473969767822,
            "diagnosticStatistics": {
                "isError": false,
                "data": [
                {
                    "key": "R^2",
                    "value": "1.000"
                }
                ]
            },
            "isCompleted": true,
            "hardwareTierId": "gpu"
            }
        ]
    }`;

    return (
        <div className=''>

            <div className='pb-1'>
                <div className="flex items-center justify-between border-b border-borderPrimary pt-2 text-[14px]">
                    <div className='flex items-center gap-8 '>
                        <button className='font-semibold text-[#596171] pb-[11px]' onClick={() => { setSelectedPage('overview') }}>
                            Overview
                        </button>

                        <button className='font-semibold text-[#0C71EB] border-b-2 border-[#1B74E4] pb-[11px]' onClick={() => { setSelectedPage('eventsAndLogs') }}>
                            Events and logs
                        </button>
                    </div>
                </div>

                <div className='flex items-center justify-between'>
                    <h1 className='py-5 font-bold text-base'>Events and Logs</h1>

                    <div className='relative flex items-center gap-3 cursor-pointer'>
                        <div className='flex items-center gap-2 border border-[#10111A29] hover:border-[#10111a7e] duration-300 rounded-lg px-3 py-[4px]'
                            onClick={() => setDropdownOpen(!dropdownOpen)}>
                            <h1 className='text-[#353A44] font-semibold text-sm'>{selectedUser.username}</h1>
                            <DownArrowIcon color="#7C8689" />
                            {dropdownOpen && (
                                <div ref={dropdownRef} className='absolute bg-white z-20 top-10 border border-gray-200 rounded-lg shadow-md p-2 max-w-[200px] max-h-[200px] overflow-y-auto left-0'>
                                    <div className='py-1'>
                                        <ul>
                                            {usersList?.map((user, index) => (
                                                <li key={index}
                                                    className='hover:text-buttonPrimary py-[2px] text-[16px] text-gray-600'
                                                    onClick={() => handleSelectedUser(user)}
                                                >{user.username}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="flex items-center justify-between gap-3">
                            <input type="date" className={`border border-[#10111A29] hover:border-[#10111a7e] duration-300 text-[#353A44] font-semibold text-sm px-3 py-[3px] rounded-lg items-center gap-2 flex outline-none`}
                                onChange={handleDateChange} />
                        </div>

                        <button className="border border-[#10111A29] hover:border-[#10111a7e] duration-300 text-[#353A44] font-semibold text-sm px-3 py-[3px] rounded-lg flex items-center gap-2"
                        > <SettingIcon /> Status </button>
                    </div>
                </div>

                <div className="flex items-center justify-between border-b border-borderPrimary pt-2 text-[14px]">
                    <div className='flex items-center gap-8 '>
                        <button className='font-semibold text-[#0C71EB] text-sm border-b-2 border-[#1B74E4] pb-[11px]' onClick={() => { }}>
                            All Activity
                        </button>

                        <button className='font-semibold text-[#596171] text-sm pb-[11px]' onClick={() => { }}>
                            Error Reports
                        </button>

                        <button className='font-semibold text-[#596171] text-sm pb-[11px]' onClick={() => { }}>
                            Error Logs
                        </button>
                    </div>
                </div>

                <div className='py-5 flex flex-col'>

                    {loadingActivity ?
                        <div className="flex justify-center items-center">
                            <SmallLoading />
                        </div>
                        :
                        activity.length > 0 ? displayedActivity?.map((item, index) => (
                            <div key={index} style={{ position: 'relative' }}>
                                <div className={`${index !== 0 && "hidden"}`} style={{ position: 'absolute', left: -7, top: -7, zIndex: 10 }}>
                                    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#A3ACB9' className='bi bi-dot' viewBox='0 0 16 16'>
                                        <circle cx='8' cy='8' r='4' />
                                    </svg>
                                </div>

                                <div style={{ position: 'absolute', left: -7, bottom: -7, zIndex: 10 }}>
                                    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#A3ACB9' className='bi bi-dot' viewBox='0 0 16 16'>
                                        <circle cx='8' cy='8' r='4' />
                                    </svg>
                                </div>

                                <div className='border-l-2 py-3'>
                                    <div className='ml-3 flex flex-col gap-2 text-[#414552] text-sm border border-[#EBEEF1] p-3 rounded-lg w-[inherit] lines'>
                                        <div className='flex items-start gap-2'>
                                            <div className='w-[65px]'>
                                                <h1 className='font-[500]'>Last Ope:</h1>
                                            </div>
                                            <p className='font-[400] text-justify'>{item.last_ope}</p>
                                        </div>
                                        <p className='text-xs text-[#A3ACBA]'>{formatDate(item.date_reg)}</p>
                                    </div>
                                </div>
                            </div>
                        )) :
                            <div className='flex items-center justify-center h-[200px]'>
                                <h1 className='text-[#A3ACBA] text-[16px]'>No activity found</h1>
                            </div>
                    }


                    {/* <div className='w-8/12'>

                        <table className='w-full'>
                            <tbody>
                                <tr className='border-b'>
                                    <td className='p-2'>
                                        <h4 className='uppercase font-semibold text-[16px] text-gray-600 py-1'>Yesterday</h4>
                                    </td>
                                </tr>
                                <tr className='border-b'>
                                    <td className='p-2'>
                                        <span className='uppercase text-gray-500 bg-gray-100 rounded-lg py-[2px] px-3 font-semibold'>200 OK</span>
                                    </td>
                                    <td className='p-2'>
                                        <h1 className='uppercase text-gray-500'>POST</h1>
                                    </td>
                                    <td className='p-2'>
                                        <h1 className='text-gray-500'>/V1/terminal/connection_tokens</h1>
                                    </td>
                                    <td className='p-2'>
                                        <h1 className='text-gray-500'>10:15:49 PM</h1>
                                    </td>
                                </tr>
                                <tr className='border-b'>
                                    <td className='p-2'>
                                        <span className='uppercase text-gray-500 bg-gray-100 rounded-lg py-[2px] px-3 font-semibold'>200 OK</span>
                                    </td>
                                    <td className='p-2'>
                                        <h1 className='uppercase text-gray-500'>POST</h1>
                                    </td>
                                    <td className='p-2'>
                                        <h1 className='text-gray-500'>/V1/terminal/connection_tokens</h1>
                                    </td>
                                    <td className='p-2'>
                                        <h1 className='text-gray-500'>10:15:49 PM</h1>
                                    </td>
                                </tr>
                                <tr className='border-b'>
                                    <td className='p-2'>
                                        <span className='uppercase text-gray-500 bg-gray-100 rounded-lg py-[2px] px-3 font-semibold'>200 OK</span>
                                    </td>
                                    <td className='p-2'>
                                        <h1 className='uppercase text-gray-500'>POST</h1>
                                    </td>
                                    <td className='p-2'>
                                        <h1 className='text-gray-500'>/V1/terminal/connection_tokens</h1>
                                    </td>
                                    <td className='p-2'>
                                        <h1 className='text-gray-500'>10:15:49 PM</h1>
                                    </td>
                                </tr>

                                <tr className='border-b'>
                                    <td className='p-2'>
                                        <h4 className='uppercase font-semibold text-[16px] text-gray-600 py-1'>Dec 20, 2023</h4>
                                    </td>
                                </tr>
                                <tr className='border-b'>
                                    <td className='p-2'>
                                        <span className='uppercase text-gray-500 bg-gray-100 rounded-lg py-[2px] px-3 font-semibold'>200 OK</span>
                                    </td>
                                    <td className='p-2'>
                                        <h1 className='uppercase text-gray-500'>POST</h1>
                                    </td>
                                    <td className='p-2'>
                                        <h1 className='text-gray-500'>/V1/terminal/connection_tokens</h1>
                                    </td>
                                    <td className='p-2'>
                                        <h1 className='text-gray-500'>10:15:49 PM</h1>
                                    </td>
                                </tr>
                                <tr className='border-b border-l-blue-700 border-l-4 bg-gray-50'>
                                    <td className='p-2'>
                                        <span className='uppercase text-gray-500 bg-gray-100 rounded-lg py-[2px] px-3 font-semibold'>200 OK</span>
                                    </td>
                                    <td className='p-2'>
                                        <h1 className='uppercase text-gray-500'>POST</h1>
                                    </td>
                                    <td className='p-2'>
                                        <h1 className='text-gray-500 font-bold'>/V1/payouts</h1>
                                    </td>
                                    <td className='p-2'>
                                        <h1 className='text-gray-500'>10:15:49 PM</h1>
                                    </td>
                                </tr>
                                <tr className='border-b'>
                                    <td className='p-2'>
                                        <span className='uppercase text-gray-500 bg-gray-100 rounded-lg py-[2px] px-3 font-semibold'>200 OK</span>
                                    </td>
                                    <td className='p-2'>
                                        <h1 className='uppercase text-gray-500'>POST</h1>
                                    </td>
                                    <td className='p-2'>
                                        <h1 className='text-gray-500'>/V1/terminal/connection_tokens</h1>
                                    </td>
                                    <td className='p-2'>
                                        <h1 className='text-gray-500'>10:15:49 PM</h1>
                                    </td>
                                </tr>

                                <tr className='border-b'>
                                    <td className='p-2'>
                                        <h4 className='uppercase font-semibold text-[16px] text-gray-600 py-1'>Dec 19, 2023</h4>
                                    </td>
                                </tr>
                                <tr className='border-b'>
                                    <td className='p-2'>
                                        <span className='uppercase text-gray-500 bg-gray-100 rounded-lg py-[2px] px-3 font-semibold'>200 OK</span>
                                    </td>
                                    <td className='p-2'>
                                        <h1 className='uppercase text-gray-500'>POST</h1>
                                    </td>
                                    <td className='p-2'>
                                        <h1 className='text-gray-500'>/V1/terminal/connection_tokens</h1>
                                    </td>
                                    <td className='p-2'>
                                        <h1 className='text-gray-500'>10:15:49 PM</h1>
                                    </td>
                                </tr>
                                <tr className='border-b'>
                                    <td className='p-2'>
                                        <span className='uppercase text-gray-500 bg-gray-100 rounded-lg py-[2px] px-3 font-semibold'>200 OK</span>
                                    </td>
                                    <td className='p-2'>
                                        <h1 className='uppercase text-gray-500'>POST</h1>
                                    </td>
                                    <td className='p-2'>
                                        <h1 className='text-gray-500'>/V1/terminal/connection_tokens</h1>
                                    </td>
                                    <td className='p-2'>
                                        <h1 className='text-gray-500'>10:15:49 PM</h1>
                                    </td>
                                </tr>
                                <tr className='border-b border-l-blue-700 border-l-4 bg-gray-50'>
                                    <td className='p-2'>
                                        <span className='uppercase text-gray-500 bg-gray-100 rounded-lg py-[2px] px-3 font-semibold'>200 OK</span>
                                    </td>
                                    <td className='p-2'>
                                        <h1 className='uppercase text-gray-500'>POST</h1>
                                    </td>
                                    <td className='p-2'>
                                        <h1 className='text-gray-500 font-bold'>/V1/payouts</h1>
                                    </td>
                                    <td className='p-2'>
                                        <h1 className='text-gray-500'>10:15:49 PM</h1>
                                    </td>
                                </tr>
                                <tr className='border-b'>
                                    <td className='p-2'>
                                        <span className='uppercase text-gray-500 bg-gray-100 rounded-lg py-[2px] px-3 font-semibold'>200 OK</span>
                                    </td>
                                    <td className='p-2'>
                                        <h1 className='uppercase text-gray-500'>POST</h1>
                                    </td>
                                    <td className='p-2'>
                                        <h1 className='text-gray-500'>/V1/terminal/connection_tokens</h1>
                                    </td>
                                    <td className='p-2'>
                                        <h1 className='text-gray-500'>10:15:49 PM</h1>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div className='border-b p-3 flex items-center justify-center'>
                            <button className='text-[#625AFA] text-[16px] font-semibold flex items-center justify-center gap-2'>
                                <DownArrowIcon color="#625AFA" /> Load more</button>
                        </div>
                    </div>

                    <div className='w-4/12'>
                        <div className='flex items-center justify-between pb-5'>
                            <h1 className='font-bold text-base'>POST/V1/payouts</h1>
                            <EditIcon color="#1B74E4" />
                        </div>

                        <table className='w-full'>
                            <tr className='text-left'>
                                <th className='text-gray-500 font-normal pb-1'>Status</th>
                                <td className='text-gray-600 font-semibold text-[16px] pb-1'>200 OK</td>
                            </tr>
                            <tr className='text-left'>
                                <th className='text-gray-500 font-normal pb-1'>ID</th>
                                <td className='text-gray-500 text-[16px] pb-1'>req_2AbF5aXslUTDQ8</td>
                            </tr>
                            <tr className='text-left'>
                                <th className='text-gray-500 font-normal pb-1'>Time</th>
                                <td className='text-gray-500 text-[16px] pb-1'>12/19/23, 3:47:13 PM</td>
                            </tr>
                            <tr className='text-left'>
                                <th className='text-gray-500 font-normal pb-1'>IP Address</th>
                                <td className='text-gray-500 text-[16px] pb-1'>75.40.211.155</td>
                            </tr>
                            <tr className='text-left'>
                                <th className='text-gray-500 font-normal pb-1'>API Version</th>
                                <td className='text-buttonPrimary flex items-center gap-2 text-[16px] pb-1'>2017-02-14
                                    <button><EditIcon color="#1B74E4" height={13} width={13} /></button></td>
                            </tr>
                            <tr className='text-left'>
                                <th className='text-gray-500 font-normal pb-1'>Source</th>
                                <td className='text-gray-500 text-[16px] pb-1'>Dashboard - <span className='text-buttonPrimary'>admin@uniqueleverage.com</span></td>
                            </tr>
                            <tr className='text-left'>
                                <th className='text-gray-500 font-normal pb-1'>Idempotency</th>
                                <td className='text-gray-500 text-[16px] pb-1'>key - eef7611f-c5ce-4c10-9bd2-da2be7</td>
                            </tr>
                        </table>

                        <div className='overflow-hidden'>
                            <h1 className='text-gray-600 text-base font-semibold pt-5'>Response body</h1>

                            <div className={`relative`}>
                                <div className={`${!toggleCodeFade ? "codeFade h-64" : "overflow-x-auto"}`}
                                    style={{
                                        "display": "flex",
                                        "textAlignLast": "justify"
                                    }}>
                                    <SyntaxHighlighter language="javascript" style={coy} wrapLines={true} wrapLongLines={true} showLineNumbers={true}>
                                        {obj}
                                    </SyntaxHighlighter>
                                </div>

                                <div className='flex justify-center'>
                                    <button className='absolute bottom-10 px-3 z-10 border border-gray-300 rounded-lg bg-white shadow-md text-[16px] flex items-center gap-2' onClick={() => setToggleCodeFade(!toggleCodeFade)}>
                                        <CircleDown />{toggleCodeFade ? "Hide lines" : `See all ${obj.split('\n').length} lines`}</button>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>

            </div>

            <div className="flex items-center justify-between my-4">
                <div className="">
                    <span className="font-semibold text-[14px]">{activity.length}</span> {activity.length > 1 ? "results" : "result"}
                </div>

                <div>
                    {totalPages > 1 && (
                        <div className="flex items-center gap-4">
                            <button
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                                className={`h-[26px] w-[70px] text-sm border border-gray-300 rounded-lg font-semibold ${currentPage === 1 && "text-[#9a9ca1]"}`}>
                                Previous
                            </button>

                            <button
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                                className={`h-[26px] w-[70px] text-sm border border-gray-300 rounded-lg font-semibold ${currentPage === totalPages && "text-[#9a9ca1]"}`}>
                                Next
                            </button>
                        </div>
                    )}
                </div>
            </div>

        </div>
    )
}

export default EventsAndLogs