import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NotificationMsg from "../../../components/Alerts/NotificationMsg";
import Header from "../../../layout/Header/Header";
import SideMenu from "../../../components/sidemenu/SideMenu";
import axiosClient from "../../../axios.client";
import ErrorLogs from "./ErrorLogs";
import InfoLogs from "./InfoLogs";

const ViewLogs = () => {
  const { userId } = useParams();

  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState([]); // contains User information
  const [option, setOption] = useState("error"); // Tab options

  const [notification, setNotification] = useState({
    isOpen: false,
    status: "",
    msg: "",
  });

  const SetAlert = (bool, status, msg) => {
    setNotification({ isOpen: bool, status: status, msg: msg });

    setTimeout(() => {
      setNotification({ isOpen: false, status: "", msg: "" });
    }, 5000);
  };

  const [allLogs, setAllLogs] = useState({
    error: [],
    info: [],
  });

  const fetchLogs = async () => {
    setLoading(true);

    try {
      const [logResponse, userResponse] = await Promise.all([
        axiosClient.get(`https://uniqueleverage.com/ul-backend/public/api/get-user-logs/${userId}`),
        axiosClient.get(`/getSingleUser/${userId}`)
      ]);

      setAllLogs(logResponse.data || []);
      setUserInfo(userResponse.data.response);
    } catch (error) {
      // console.error(error);
      SetAlert(true, "failed", "Failed to get the Logs");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLogs();
  }, []);

  return (
    <>
      {/* Notification Msg Start */}
      <NotificationMsg
        toggle={notification}
        Toggler={setNotification}
        message={notification.msg}
      ></NotificationMsg>
      {/* Notification Msg End */}
      <div className="wrapper bg-backgroudWrapper">
        <div className="md:flex w-full h-screen">
          <SideMenu />

          <div className="w-full bg-backgroudPrimary overflow-x-auto" id="main-content">
            <Header pageName={"manage_vehicle"} />

            <div className="px-[18px] md:px-[24px] md:mx-[14px]  md:h-[calc(100vh-120px)] bg-white">
              <div className="hidden md:flex justify-between items-center my-[30px]">
                <div className="flex flex-col gap-5">
                  <h2 className="text-3xl font-semibold">Manage Vehicles</h2>
                  <h3 className="font-bold text-[#383838]">
                    User Name:{" "}
                    <span className="text-buttonPrimary">
                      {userInfo.username}
                    </span>
                  </h3>
                </div>
                <div></div>
              </div>

              <div id="tabWrapper">
                <div
                  id="tabs"
                  className="flex items-center justify-between border-b"
                >
                  <div className="flex gap-5 border-borderPrimary pt-2 pb-6">
                    <button
                      className={
                        option === "error"
                          ? "bg-buttonPrimary text-white p-3 rounded-md"
                          : "bg-backgroudSecondary text-gray-800 p-3 rounded-md border border-[#D4D4D4]"
                      }
                      onClick={() => setOption("error")}
                    >
                      Errors Logs
                    </button>

                    <button
                      className={
                        option === "info"
                          ? "bg-buttonPrimary text-white p-3 rounded-md"
                          : "bg-backgroudSecondary text-gray-800 p-3 rounded-md border border-[#D4D4D4]"
                      }
                      onClick={() => setOption("info")}
                    >
                      Info Logs
                    </button>
                  </div>
                </div>

                {loading ? (
                  <div className="flex justify-center items-center h-screen">
                    <div className="w-12 h-12 border-t-4 border-buttonPrimary border-solid border-opacity-80 rounded-full animate-spin"></div>
                  </div>
                ) : (
                  <div id="tabContent">
                    <div
                      id="UsersTab"
                      className="flex flex-col justify-between h-[calc(100vh-220px)]"
                    >
                      <div className="overflow-x-auto hidden md:block">
                        <div className="p-1.5 min-w-full align-middle pt-7">
                          <table className="border-b min-w-full divide-y divide-gray-200">
                            <thead>
                              <tr className="sticky top-0 bg-white">
                                <th
                                  scope="col"
                                  className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
                                >
                                  Log Type
                                </th>
                                <th
                                  scope="col"
                                  className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
                                >
                                  Username
                                </th>
                                <th
                                  scope="col"
                                  className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
                                >
                                  Error Description
                                </th>
                                <th
                                  scope="col"
                                  className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
                                >
                                  Day Reg
                                </th>
                                <th
                                  scope="col"
                                  className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
                                >
                                  Date Mod
                                </th>
                                <th
                                  scope="col"
                                  className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
                                >
                                  Date Reg
                                </th>
                              </tr>
                            </thead>
                            <tbody className="border divide-y divide-gray-200">
                              {/* start */}
                              {option === "error" && (
                                <ErrorLogs ErrorLog={allLogs} userInfo={userInfo} />
                              )}

                              {option === "info" && (
                                <InfoLogs InfoLog={allLogs} userInfo={userInfo} />
                              )}
                              {/* end */}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewLogs;
