import React, { useEffect, useRef, useState } from 'react'
import PlusIcon from 'components/icons/PlusIcon'
import { useLocation, useNavigate } from 'react-router-dom'
import AllUserList from './AllUserList'
import { useSearchContext } from 'Context/SearchContext'
import NIcon from 'components/icons/NIcon'
import { useCustomerContext } from 'Context/CustomerContext'
import MainFilters from './Features/MainFilters'
import SecondaryFilters from './Features/SecondaryFilters'
import { useUserContext } from 'Context/UserContext'

export default function AllUsers({
    loading,
    vehicleCountLoading,
    userVehicleCount,
}) {
    const navigate = useNavigate();
    const location = useLocation();

    const {
        search,
    } = useSearchContext();

    const {
        userList,
    } = useCustomerContext();

    const {
        setToggleAddUser,
    } = useUserContext();

    const [moreFilterIsOpen, setMoreFilterIsOpen] = useState(false);
    const [editColumnIsOpen, setEditColumnIsOpen] = useState(false);
    const moreFilterRef = useRef(null);
    const editColumnRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (moreFilterRef.current && !moreFilterRef.current.contains(event.target)) {
                setMoreFilterIsOpen(false);
            }
            if (editColumnRef.current && !editColumnRef.current.contains(event.target)) {
                setEditColumnIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const [userMode, setUserMode] = useState("all");

    const modifiedUser = (mode) => {
        if (mode === "all") {
            navigate("/dealership/?tab=allUsers&mode=all");
            setUserMode("all");
        } else if (mode === "frozen") {
            navigate("/dealership/?tab=allUsers&mode=frozen");
            setUserMode("frozen");
        } else if (mode === "support") {
            navigate("/dealership/?tab=allUsers&mode=support");
            setUserMode("support");
        } else if (mode === "canceled") {
            navigate("/dealership/?tab=allUsers&mode=canceled");
            setUserMode("canceled");
        }
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const redirectStatus = queryParams.get("mode");

        if (redirectStatus === "all") {
            setUserMode("all");
        } else if (redirectStatus === "frozen") {
            setUserMode("frozen");
        } else if (redirectStatus === "support") {
            setUserMode("support");
        } else if (redirectStatus === "canceled") {
            setUserMode("canceled");
        } else {
            setUserMode("all");
        }

    }, [location.search]);

    const [columns, setColumns] = useState(localStorage.getItem('userColumns') ? JSON.parse(localStorage.getItem('userColumns')) : [
        { id: 1, name: 'LAST POST', active: true, dbKey: 'lastprocessedCSV' },
        { id: 2, name: 'LAST ACTIVE', active: true, dbKey: 'updated_at' },
        { id: 3, name: 'VEHICLES', active: true, dbKey: 'listed' },
        { id: 4, name: 'ACTIVE', active: true, dbKey: 'advertised' },
        { id: 5, name: 'PENDING', active: true, dbKey: 'pending' },
        { id: 6, name: 'STATUS', active: true, dbKey: 'status' },
        { id: 7, name: 'USER NAME', active: true, dbKey: 'username' },
    ]);


    return (
        <div className=''>
            {/* Heads */}

            <div className='flex items-center justify-between'>
                <div className='flex flex-col gap-2 pb-[34px]'>
                    <h1 className='text-[28px] font-bold leading-9 font-roboto'>Users
                    </h1>
                </div>
                <div className='flex items-center gap-5'>
                    <button
                        className="bg-buttonPrimary w-[157px] pt-[5px] pr-[8px] pb-[5px] pl-[7px] rounded-lg shadow-md items-center text-white text-[14px] flex gap-[8px]" onClick={() => { setToggleAddUser(true) }}
                    > <span className='flex items-center gap-[2px]'><PlusIcon /> Add New User</span> <NIcon /> </button>
                </div>
            </div>

            <div className=''>
                <div className="flex gap-1 border-b border-borderPrimary">
                    <button className='font-semibold text-[#596171] pb-1' onClick={() => {
                        navigate("/dealership/?tab=allCustomers")
                    }}>
                        Customers
                    </button>

                    <span className='text-[#D7D7D7] px-3'>|</span>

                    <button className='font-semibold text-[#1B74E4] border-b-2 border-[#1B74E4] pb-1' onClick={() => {
                        navigate("/dealership/?tab=allUsers")
                    }}>
                        Users
                    </button>
                </div>
            </div>

            {/* Filter Section */}

            <MainFilters userMode={userMode} modifiedUser={modifiedUser} />

            <SecondaryFilters
                moreFilterIsOpen={moreFilterIsOpen}
                setMoreFilterIsOpen={setMoreFilterIsOpen}
                editColumnIsOpen={editColumnIsOpen}
                setEditColumnIsOpen={setEditColumnIsOpen}
                moreFilterRef={moreFilterRef}
                editColumnRef={editColumnRef}
                columns={columns}
                setColumns={setColumns}
            />

            {/* Sortable Table */}

            {loading ? (

                <div className="flex justify-center items-center mt-60">
                    <div className="w-12 h-12 border-t-4 border-buttonPrimary border-solid border-opacity-80 rounded-full animate-spin"></div>
                </div>

            ) : (
                <AllUserList
                    allUsers={userList[userMode]}
                    search={search}
                    vehicleCountLoading={vehicleCountLoading}
                    userVehicleCount={userVehicleCount}
                    columns={columns}
                />
            )}

        </div>
    );
}