import React from 'react'

export const UniqueLeverageLogo = () => {
    return (
        <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.2304 10.6851V8.56602L18.7814 7.98266C18.7814 7.98266 18.5534 6.71824 18.1424 6.34108L19.2965 5.10923C18.3213 5.64248 17.3812 6.2333 16.4816 6.87832C17.3112 8.28506 17.5652 9.93993 17.1937 11.5179C16.8223 13.096 15.8522 14.4831 14.474 15.407C13.0958 16.3309 11.409 16.7247 9.74497 16.5112C8.08092 16.2977 6.55981 15.4922 5.48034 14.253C4.40087 13.0137 3.84104 11.4303 3.91079 9.81356C3.98054 8.19683 4.67483 6.66364 5.85731 5.51508C7.03979 4.36652 8.62503 3.68557 10.3017 3.60596C11.9784 3.52635 13.6254 4.05382 14.9193 5.08481C15.9425 4.35595 17.0103 3.68726 18.1171 3.08236L18.0157 2.92227L16.0115 3.76069C16.0115 3.76069 14.9474 2.88971 14.5562 2.7866V0.461268L12.4506 0L11.1388 1.82879C10.5237 1.76543 9.90313 1.77089 9.28937 1.84507L8.05078 0L5.97616 0.838423V3.12848C5.97616 3.12848 4.75165 3.99947 4.62779 4.25724L2.41242 3.47037L1.18791 5.34257L2.62354 7.00857C2.62354 7.00857 2.21819 8.27298 2.23508 8.71797L0 9.28235V11.5209L2.37583 12.1558C2.37583 12.1558 2.82059 13.7458 2.96134 13.7973L1.55386 15.4878L3.02608 17.1619L5.24427 16.3479C5.67957 16.7345 6.18414 17.0415 6.73338 17.2542L6.66301 19.631L8.70385 20.1737L10.1113 18.2418L11.8848 18.1577L13.1459 19.9865L15.2008 19.2485L15.2346 16.8227C15.7451 16.5277 16.208 16.1621 16.6083 15.7374L18.7561 16.6084L19.9074 14.728L18.4098 13.0078C18.7211 12.48 18.8976 11.8885 18.925 11.2821L21.2304 10.6851Z" fill="#1B74E4" />
            <path d="M22.2496 3.44248C19.5767 4.51939 17.1098 6.00198 14.9474 7.83109C13.3136 9.29782 11.9886 11.0416 11.0421 12.9707C11.0006 13.0624 10.9397 13.1451 10.8632 13.2139C10.7077 13.3605 10.4981 13.4448 10.2781 13.4491C9.98706 13.4264 9.71651 13.2991 9.52258 13.0937L6.40686 9.22892C6.29133 9.08916 6.21927 8.92197 6.19871 8.74597C6.17816 8.56996 6.2099 8.39203 6.29042 8.23199C6.34968 8.10335 6.44863 7.99441 6.57444 7.91929C6.71651 7.86333 6.86919 7.83514 7.02319 7.83643C7.16038 7.83548 7.29598 7.86413 7.41956 7.9202C7.54315 7.97626 7.65143 8.05824 7.73609 8.15983L9.85773 10.6989C9.9259 10.8112 9.9827 10.8513 10.0338 10.8513C10.1332 10.8513 10.1929 10.6989 10.1929 10.6989C10.3748 10.383 10.5738 10.0761 10.7893 9.7795C12.1725 7.9086 12.9394 7.15221 13.2717 6.86623L13.9136 6.30763C16.8674 3.71509 21.4174 1.79341 21.4174 1.79341C21.6413 1.72421 21.8748 1.68639 22.1104 1.68115C22.7892 1.68115 22.9227 2.0981 22.9227 2.0981C23.2863 3.08166 22.2496 3.44248 22.2496 3.44248Z" fill="#1B74E4" />
        </svg>
    )
}
