import React from 'react'

export const PlusDashIcon = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_425_13801)">
                <path d="M6.5625 3.1875C6.5625 3.03832 6.50324 2.89524 6.39775 2.78975C6.29226 2.68426 6.14918 2.625 6 2.625C5.85082 2.625 5.70774 2.68426 5.60225 2.78975C5.49676 2.89524 5.4375 3.03832 5.4375 3.1875V5.4375H3.1875C3.03832 5.4375 2.89524 5.49676 2.78975 5.60225C2.68426 5.70774 2.625 5.85082 2.625 6C2.625 6.14918 2.68426 6.29226 2.78975 6.39775C2.89524 6.50324 3.03832 6.5625 3.1875 6.5625H5.4375V8.8125C5.4375 8.96168 5.49676 9.10476 5.60225 9.21025C5.70774 9.31574 5.85082 9.375 6 9.375C6.14918 9.375 6.29226 9.31574 6.39775 9.21025C6.50324 9.10476 6.5625 8.96168 6.5625 8.8125V6.5625H8.8125C8.96168 6.5625 9.10476 6.50324 9.21025 6.39775C9.31574 6.29226 9.375 6.14918 9.375 6C9.375 5.85082 9.31574 5.70774 9.21025 5.60225C9.10476 5.49676 8.96168 5.4375 8.8125 5.4375H6.5625V3.1875Z" fill="#87909F" />
                <path fillRule="evenodd" clipRule="evenodd" d="M12 6C12 7.5913 11.3679 9.11742 10.2426 10.2426C9.11742 11.3679 7.5913 12 6 12C4.4087 12 2.88258 11.3679 1.75736 10.2426C0.632141 9.11742 0 7.5913 0 6C0 4.4087 0.632141 2.88258 1.75736 1.75736C2.88258 0.632141 4.4087 0 6 0C9.3225 0 12 2.68575 12 6ZM10.875 6C10.875 7.29293 10.3614 8.53291 9.44715 9.44715C8.53291 10.3614 7.29293 10.875 6 10.875C4.70707 10.875 3.46709 10.3614 2.55285 9.44715C1.63861 8.53291 1.125 7.29293 1.125 6C1.125 4.70707 1.63861 3.46709 2.55285 2.55285C3.46709 1.63861 4.70707 1.125 6 1.125C8.7 1.125 10.875 3.306 10.875 6Z" fill="#87909F" />
            </g>
            <defs>
                <clipPath id="clip0_425_13801">
                    <rect width="12" height="12" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}
