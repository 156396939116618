import { useEffect, useState } from "react";
import SmallLoading from "../../../components/Loadings/SmallLoading";
import SelectUserBtn from "./SelectUserBtn";

export function GlobalReport({ globalReport, globalLoading }) {
    const [currentDate, setCurrentDate] = useState(new Date());

    useEffect(() => {

        const timer = setInterval(() => {
            setCurrentDate(new Date());
        }, 60000); // updates every minute

        return () => {
            clearInterval(timer);
        };
    }, []);
    return (
        <>
            <div className="flex items-center justify-between pt-[18px]">
                <h1 className="font-bold text-[#353A44] pl-[18px]">Status</h1>
                <div className="flex gap-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M3.64204 2.95533C4.85175 1.90711 6.39936 1.3311 8.00004 1.33333C11.682 1.33333 14.6667 4.318 14.6667 8C14.6667 9.424 14.22 10.744 13.46 11.8267L11.3334 8H13.3334C13.3335 6.95442 13.0262 5.93187 12.4498 5.05951C11.8734 4.18714 11.0533 3.50344 10.0915 3.09342C9.12966 2.68339 8.06852 2.56514 7.04001 2.75335C6.01151 2.94156 5.06101 3.42793 4.30671 4.152L3.64204 2.95533ZM12.358 13.0447C11.1483 14.0929 9.60072 14.6689 8.00004 14.6667C4.31804 14.6667 1.33337 11.682 1.33337 8C1.33337 6.576 1.78004 5.256 2.54004 4.17333L4.66671 8H2.66671C2.66662 9.04559 2.97388 10.0681 3.55026 10.9405C4.12665 11.8129 4.94675 12.4966 5.90859 12.9066C6.87042 13.3166 7.93156 13.4349 8.96007 13.2467C9.98857 13.0584 10.9391 12.5721 11.6934 11.848L12.358 13.0447Z" fill="#818DA0"/>
                    </svg>
                    <h1 className="text-xs text-[#818DA0]" >Updated 7:02 PM</h1>
                </div>
                
                {/* <SelectUserBtn /> */}
            </div>

            <div className="flex items-center justify-between border-b border-[#E3E8EE] pb-[25px] pt-[20px]">
                <div className="flex items-center gap-[14px] pl-[18px]">
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="10" height="10" rx="5" fill="#FFCE20" />
                    </svg>
                    <h1 className="font-semibold">Pending</h1>
                </div>

                <h1 className="font-semibold">{globalLoading ? <SmallLoading /> : globalReport.pending}</h1>
            </div>

            <div className="flex items-center justify-between border-b border-[#E3E8EE] pb-[28px] pt-[28px]">
                <div className="flex items-center gap-[14px] pl-[18px]">
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="10" height="10" rx="5" fill="#4096FF" />
                    </svg>
                    <h1 className="font-semibold">Active</h1>
                </div>

                <h1 className="font-semibold">{globalLoading ? <SmallLoading /> : globalReport.posted}</h1>
            </div>

            <div className="flex items-center justify-between pt-[27px] pb-[24px]">
                <div className="flex items-center gap-[14px] pl-[18px]">
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="10" height="10" rx="5" fill="#EE5D50" />
                    </svg>
                    <h1 className="font-semibold">Error</h1>
                </div>

                <h1 className="font-semibold">85</h1>
            </div>

            {/* <div className="flex items-center justify-between">
                <div className="text-[#687385] text-sm font-semibold hover:cursor-pointer">
                    2 Users
                </div>

                <div className="flex flex-col items-end gap-1">
                    <p className="font-semibold text-[#687385]">Today</p>
                    <span className="text-[16px] text-[#818DA0]">Updated {currentDate.toLocaleTimeString()}</span>
                </div>
            </div> */}
        </>
    );
}