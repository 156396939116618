import { useAlertContext } from "Context/AlertContext";
import { useCustomerDetailContext } from "Context/customerDetailContext/CustomerDetailContext";
import { DeleteIconThin } from "components/icons/DeleteIconThin";
import PlusIcon from "components/icons/PlusIcon";

export const MetaDataEditModal = () => {
    const { SetAlert } = useAlertContext();

    const { setOpenEditMetaModal, openEditMetaModal } = useCustomerDetailContext();

    return (
        <div className="block fixed w-full h-full bg-[#D4DAE0] bg-opacity-50 z-[110]">
            <div className="flex items-center justify-center h-screen">
                <div className="bg-white rounded-lg">
                    <div className="border-b pb-2 p-5">
                        <h1 className="font-bold text-[16px]">Edit Meta Data</h1>
                    </div>
                    <div className="p-5 bg-white rounded-md">
                        <form>
                            <div className="">
                                <table>
                                    <tbody>
                                        <tr className="flex gap-3 items-center w-[450px] pb-2">
                                            <th>
                                                <div className="font-semibold text-[16px] p-1 px-2 border border-gray-200 rounded-lg text-left w-[150px]">
                                                    Dealership Name
                                                </div>
                                            </th>
                                            <td className="w-full flex items-center gap-3">
                                                <input type="text" className="w-full border border-gray-200 rounded-lg outline-none p-1" placeholder="Hudsons Autos" />
                                                <button><DeleteIconThin /></button>
                                            </td>
                                        </tr>

                                        <tr className="flex gap-3 items-center w-[450px] pb-2">
                                            <th>
                                                <div className="font-semibold text-[16px] p-1 px-2 border border-gray-200 rounded-lg text-left w-[150px]">
                                                    Inventory Source
                                                </div>
                                            </th>
                                            <td className="w-full flex items-center gap-3">
                                                <input type="text" className="w-full border border-gray-200 rounded-lg outline-none p-1" placeholder="CarsForSales" />
                                                <button><DeleteIconThin /></button>
                                            </td>
                                        </tr>

                                        <tr className="flex gap-3 items-center w-[450px] pb-2">
                                            <th>
                                                <div className="font-semibold text-[16px] p-1 px-2 border border-gray-200 rounded-lg text-left w-[150px]">
                                                    Website
                                                </div>
                                            </th>
                                            <td className="w-full flex items-center gap-3 pb-2">
                                                <input type="text" className="w-full border border-gray-200 rounded-lg outline-none p-1" placeholder="Dealership Name" />
                                                <button><DeleteIconThin /></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </form>
                    </div>
                    <div className="border-t flex items-center justify-between px-5">
                        <button className="text-buttonPrimary flex items-center gap-2 py-4 text-[16px]"> <PlusIcon color="#1B74E4" /> Add another item</button>
                        <div className="flex items-center gap-5">
                            <button className="border border-gray-200 rounded-lg text-[16px] p-1 px-2 shadow-md"
                                onClick={() => setOpenEditMetaModal(false)}>Cancel</button>
                            <button className="rounded-lg bg-buttonPrimary text-[16px] text-white p-1 px-2 shadow-md">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
