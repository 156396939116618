import React from 'react'

export const CalenderIcon = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3_11589)">
                <path fillRule="evenodd" clipRule="evenodd" d="M5.5475 0.65625C5.5475 0.482202 5.47836 0.315282 5.35529 0.192211C5.23222 0.0691404 5.0653 0 4.89125 0C4.7172 0 4.55028 0.0691404 4.42721 0.192211C4.30414 0.315282 4.235 0.482202 4.235 0.65625V1.3125H3.0625C2.36631 1.3125 1.69863 1.58906 1.20634 2.08134C0.714062 2.57363 0.4375 3.24131 0.4375 3.9375V11.375C0.4375 12.0712 0.714062 12.7389 1.20634 13.2312C1.69863 13.7234 2.36631 14 3.0625 14H10.9375C11.6337 14 12.3014 13.7234 12.7937 13.2312C13.2859 12.7389 13.5625 12.0712 13.5625 11.375V3.9375C13.5625 3.24131 13.2859 2.57363 12.7937 2.08134C12.3014 1.58906 11.6337 1.3125 10.9375 1.3125H9.75625V0.65625C9.75625 0.482202 9.68711 0.315282 9.56404 0.192211C9.44097 0.0691404 9.27405 0 9.1 0C8.92595 0 8.75903 0.0691404 8.63596 0.192211C8.51289 0.315282 8.44375 0.482202 8.44375 0.65625V1.3125H5.5475V0.65625ZM8.44375 3.28125V2.625H5.5475V3.28125C5.5475 3.4553 5.47836 3.62222 5.35529 3.74529C5.23222 3.86836 5.0653 3.9375 4.89125 3.9375C4.7172 3.9375 4.55028 3.86836 4.42721 3.74529C4.30414 3.62222 4.235 3.4553 4.235 3.28125V2.625H3.0625C2.7144 2.625 2.38056 2.76328 2.13442 3.00942C1.88828 3.25556 1.75 3.5894 1.75 3.9375V4.8125H12.25V3.9375C12.25 3.5894 12.1117 3.25556 11.8656 3.00942C11.6194 2.76328 11.2856 2.625 10.9375 2.625H9.75625V3.28125C9.75625 3.4553 9.68711 3.62222 9.56404 3.74529C9.44097 3.86836 9.27405 3.9375 9.1 3.9375C8.92595 3.9375 8.75903 3.86836 8.63596 3.74529C8.51289 3.62222 8.44375 3.4553 8.44375 3.28125ZM12.25 6.125H1.75V11.375C1.75 11.7231 1.88828 12.0569 2.13442 12.3031C2.38056 12.5492 2.7144 12.6875 3.0625 12.6875H10.9375C11.2856 12.6875 11.6194 12.5492 11.8656 12.3031C12.1117 12.0569 12.25 11.7231 12.25 11.375V6.125Z" fill="#474E5A" />
                <path d="M5.25 7H3.5V8.75H5.25V7ZM5.25 9.625H3.5V11.375H5.25V9.625ZM7.875 9.625H6.125V11.375H7.875V9.625ZM8.75 9.625H10.5V11.375H8.75V9.625ZM6.125 7H7.875V8.75H6.125V7ZM10.5 7H8.75V8.75H10.5V7Z" fill="#474E5A" />
            </g>
            <defs>
                <clipPath id="clip0_3_11589">
                    <rect width="14" height="14" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}
