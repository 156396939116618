import React, { useState } from "react";
import { HorizontalMenuIcon } from "../../../components/icons/HorizontalMenuIcon";
import { DragIcon } from "../../../components/icons/DragIcon";

export const ErrorLog = () => {
    const data = [
        {
            id: 1,
            title: 'Ocean State Auto Sales',
            dateTime: 'Luis Florencio',
        },
        {
            id: 2,
            title: 'Ocean State Auto Sales',
            dateTime: 'Luis Florencio',
        },
        {
            id: 3,
            title: 'Ocean State Auto Sales',
            dateTime: 'Luis Florencio',
        },
        {
            id: 4,
            title: 'Ocean State Auto Sales',
            dateTime: 'Luis Florencio',
        }
        // Add more items as needed
    ];

    return (
        <div className="flex-1 pt-[20px] w1/3">
            <div className="flex items-center justify-between pb-[24px]">
                <div className="flex items-center gap-5">
                    <h1 className="font-bold text-[16px]">Error Logs</h1>
                </div>
                <div className="hover:cursor-pointer">
                    {/* <HorizontalMenuIcon /> */}
                    <h1 className="font-normal text-[14px]">All Time</h1>
                </div>
            </div>

            <div className="flex flex-col gap-[11px]">
                {data.map((item, index) => (
                    <div className="flex items-center justify-between border-b border-[#E3E8EE] pb-[7px]" key={index}>
                        <div className="">
                            <h1 className="text-sm">{item.title}</h1>
                            <h1 className="text-[14px] text-[#596171]">{item.dateTime}</h1>
                        </div>
                        {/* <DragIcon /> */}
                        <label className="text-[14px] text-[#1B74E4]">See Error</label>
                    </div>
                ))}
            </div>
            <div className="flex items-center justify-between pt-[20px]">
                <div className="flex items-center gap-5">
                <label className="text-[14px] text-[#1B74E4]">4 of 24 results</label>
                </div>
                <div className="flex gap-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M3.64204 2.95533C4.85175 1.90711 6.39936 1.3311 8.00004 1.33333C11.682 1.33333 14.6667 4.318 14.6667 8C14.6667 9.424 14.22 10.744 13.46 11.8267L11.3334 8H13.3334C13.3335 6.95442 13.0262 5.93187 12.4498 5.05951C11.8734 4.18714 11.0533 3.50344 10.0915 3.09342C9.12966 2.68339 8.06852 2.56514 7.04001 2.75335C6.01151 2.94156 5.06101 3.42793 4.30671 4.152L3.64204 2.95533ZM12.358 13.0447C11.1483 14.0929 9.60072 14.6689 8.00004 14.6667C4.31804 14.6667 1.33337 11.682 1.33337 8C1.33337 6.576 1.78004 5.256 2.54004 4.17333L4.66671 8H2.66671C2.66662 9.04559 2.97388 10.0681 3.55026 10.9405C4.12665 11.8129 4.94675 12.4966 5.90859 12.9066C6.87042 13.3166 7.93156 13.4349 8.96007 13.2467C9.98857 13.0584 10.9391 12.5721 11.6934 11.848L12.358 13.0447Z" fill="#818DA0"/>
                    </svg>
                    <h1 className="text-xs text-[#818DA0]" >Updated now</h1>
                </div>
            </div>
        </div>
    );
};
