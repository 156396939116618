import React from 'react'

export const ExportIcon = () => {
    return (
        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_425_13854)">
                <path d="M8.34856 5.79749C8.29675 5.84935 8.25567 5.9109 8.22765 5.97864C8.19963 6.04637 8.18523 6.11896 8.18526 6.19226C8.1853 6.26556 8.19977 6.33814 8.22785 6.40584C8.25593 6.47355 8.29708 6.53506 8.34893 6.58687C8.40079 6.63868 8.46234 6.67976 8.53008 6.70778C8.59781 6.7358 8.6704 6.7502 8.7437 6.75017C8.817 6.75013 8.88957 6.73566 8.95728 6.70758C9.02499 6.67949 9.0865 6.63835 9.13831 6.58649L11.7708 3.95699C11.8227 3.90523 11.8639 3.84374 11.8919 3.77605C11.92 3.70835 11.9345 3.63578 11.9345 3.56249C11.9345 3.4892 11.92 3.41663 11.8919 3.34894C11.8639 3.28125 11.8227 3.21976 11.7708 3.16799L9.13906 0.538495C9.03291 0.441494 8.89345 0.38915 8.7497 0.39235C8.60594 0.395551 8.46895 0.45405 8.36723 0.555679C8.2655 0.657307 8.20687 0.794241 8.20353 0.937995C8.2002 1.08175 8.25241 1.22126 8.34931 1.32749L10.0233 2.99999H6.31006C5.76305 2.99999 5.23844 3.21729 4.85165 3.60409C4.46486 3.99088 4.24756 4.51549 4.24756 5.06249V8.43749C4.24756 8.58668 4.30682 8.72975 4.41231 8.83524C4.5178 8.94073 4.66087 8.99999 4.81006 8.99999C4.95924 8.99999 5.10232 8.94073 5.20781 8.83524C5.3133 8.72975 5.37256 8.58668 5.37256 8.43749V5.06249C5.37256 4.54499 5.79256 4.12499 6.31006 4.12499H10.0233L8.34856 5.79749Z" fill="#474E5A" />
                <path d="M1.24756 8.25C1.39674 8.25 1.53982 8.30926 1.64531 8.41475C1.7508 8.52024 1.81006 8.66332 1.81006 8.8125V10.5H10.8101V8.8125C10.8101 8.66332 10.8693 8.52024 10.9748 8.41475C11.0803 8.30926 11.2234 8.25 11.3726 8.25C11.5217 8.25 11.6648 8.30926 11.7703 8.41475C11.8758 8.52024 11.9351 8.66332 11.9351 8.8125V11.0625C11.9351 11.2117 11.8758 11.3548 11.7703 11.4602C11.6648 11.5657 11.5217 11.625 11.3726 11.625H1.24756C1.09837 11.625 0.9553 11.5657 0.849811 11.4602C0.744322 11.3548 0.685059 11.2117 0.685059 11.0625V8.8125C0.685059 8.66332 0.744322 8.52024 0.849811 8.41475C0.9553 8.30926 1.09837 8.25 1.24756 8.25Z" fill="#474E5A" />
            </g>
            <defs>
                <clipPath id="clip0_425_13854">
                    <rect width="12" height="12" fill="white" transform="translate(0.310059)" />
                </clipPath>
            </defs>
        </svg>

    )
}
