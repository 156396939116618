import SmallLoading from "components/Loadings/SmallLoading";
import React, { useEffect, useRef } from "react";

const SelectFTPAccount = ({
    selectedFTP,
    setSelectedFTP,
    ftpData,
    handleSelectedUser,
    ftpSearch,
    setFtpSearch,
    openSearch,
    setOpenSearch,
    ftpListLoading
}) => {
    const toggleList = useRef(null);

    useEffect(() => {
        const handleClick = (e) => {
            if (toggleList.current && !toggleList.current.contains(e.target)) {
                setOpenSearch(false);
            }
        };

        document.addEventListener("mousedown", handleClick);

        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, [toggleList, setOpenSearch]);

    const handleSelectFTP = (ftp) => {
        setSelectedFTP(ftp.user);
        handleSelectedUser(ftp.user);
        setFtpSearch({ isOpen: false, phrase: ftpSearch.phrase });
        setOpenSearch(false);
    };

    const renderFtpList = () => {
        if (ftpListLoading) {
            return <div>Loading...</div>;
        }

        return (
            <div className="fixed bg-white rounded-lg shadow-lg w-[213px] 2xl:w-[250px] 5xl:w-[343px] p-2 max-h-[200px] overflow-y-auto z-[51] text-[16px]" ref={toggleList}>
                <ul>
                    {!ftpListLoading ? ftpData.length > 0 ? ftpData.filter((ftp) =>
                        (ftpSearch.phrase ? ftpSearch.phrase.toLowerCase() : "") === ""
                            ? ftp
                            : ftp.user.toLowerCase().includes(ftpSearch.phrase)
                    ).map((ftp, index) => (
                        <li
                            key={index}
                            className="hover:bg-[#1b75e413] cursor-pointer p-1 text-sm"
                            onClick={() => handleSelectFTP(ftp)}
                        >
                            {ftp.user.split("@")[0]}
                        </li>
                    ))
                        :
                        <li className="text-center"><SmallLoading /></li>
                        :
                        <li className="text-center"><SmallLoading /></li>
                    }
                </ul>
            </div>
        );
    };

    return (
        <>
            <input
                type="text"
                autoComplete="off"
                name="ftpSearch"
                value={selectedFTP}
                placeholder="FTP Account"
                className="select-custom p-2 px-2 border border-[#E2E4E5] rounded-sm w-[213px] 2xl:w-[250px] 5xl:w-[343px] outline-none hover:border-[#10111a7e] font-medium text-sm duration-200 cursor-pointer text-[#687385]"
                onClick={() => setOpenSearch(true)}
                onChange={(e) => {
                    setFtpSearch({ isOpen: true, phrase: e.target.value });
                    setSelectedFTP(e.target.value);
                }}
            />

            {openSearch && renderFtpList()}
        </>
    );
};

export default SelectFTPAccount;
