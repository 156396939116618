import React, { useEffect, useState } from "react";
import Header from "../../../layout/Header/Header";
import SideMenu from "../../../components/sidemenu/SideMenu";
import { LoadingBtn } from "../../../components/Button/LoadingBtn";
import NotificationMsg from "../../../components/Alerts/NotificationMsg";
import { Input } from './components/Input';
import axiosClient from "../../../axios.client";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const TableMapping = () => {
  const navigate = useNavigate();
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [listToggle, setListToggle] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [csvDataHeader, setCsvDataHeader] = useState([]);
  const [csvData, setCsvData] = useState([]);

  const descriptionThreshold = 50;

  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(
    Array(csvDataHeader && csvDataHeader.length).fill(false)
  );

  const toggleDescription = (index) => {
    const newIsDescriptionExpanded = [...isDescriptionExpanded];
    newIsDescriptionExpanded[index] = !newIsDescriptionExpanded[index];
    setIsDescriptionExpanded(newIsDescriptionExpanded);
  };

  const fetchList = async (path) => {
    setLoadingCSV(true);

    try {
      const response = await axios.get(`https://uniqueleverage.com/ul-backend/public/api/get-csv-list`, {
        params: { path: path },
      });
      console.log(response);
      setCsvDataHeader(response.data[0] || []);
      setCsvData(response.data || []);
    } catch (error) {
      SetAlert(true, 'failed', 'something went wrong');
      // console.error(error);
    }

    setLoadingCSV(false);
    setListToggle(true);
  };

  const [formData, setFormData] = useState({
    vin: '',
    year: '',
    make: '',
    model: '',
    trim: '',
    engine: '',
    mileage: '',
    price: '',
    exteriorcolor: '',
    interiorcolor: '',
    transmission: '',
    images: '',
    description: '',
    fueltype: '',
    bodystyle: '',
    condition: '',
    additional_column: ''
  });

  const [errors, setErrors] = useState({
    vin: '',
    year: '',
    make: '',
    model: '',
    trim: '',
    engine: '',
    mileage: '',
    price: '',
    exteriorcolor: '',
    interiorcolor: '',
    transmission: '',
    images: '',
    description: '',
    fueltype: '',
    bodystyle: '',
    condition: '',
    additional_column: '',
  });

  const [pathError, setPathError] = useState("");

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      if (!formData[key]) {
        newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required.`;
      } else {
        newErrors[key] = '';
      }
    });

    setErrors(newErrors);

    if (Object.values(newErrors).every((error) => !error)) {
      if (selectedPath === "") {
        setPathError("Select file path");
        return;
      }
      setPathError("");

      setSaveLoading(true);

      axiosClient
        .post("/save-heading", { ...formData, selectedFTP, selectedPath })
        .then((res) => {
          if (res.data.message === "successful") {
            SetAlert(true, "success", "successful saved");
            setFormData({
              vin: '',
              year: '',
              make: '',
              model: '',
              trim: '',
              engine: '',
              mileage: '',
              price: '',
              exteriorcolor: '',
              interiorcolor: '',
              transmission: '',
              images: '',
              description: '',
              fueltype: '',
              bodystyle: '',
              condition: '',
              additional_column: ''
            });
            setSelectedFTP("");
            setSelectedPath("");
            navigate('/ftp');
          }
        })
        .catch((e) => {
          SetAlert(true, "failed", "Something went wrong");
        })
        .finally(() => setSaveLoading(false));
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSelectedUser = (user) => {
    const path = ftpData.find((data) => data.user === user).homedir;

    axios
      .get(`https://uniqueleverage.com/ul-backend/public/api/get-file-list`, {
        params: { ftpPath: path },
      })
      .then((res) => {
        setFtpList(res.data);
      })
      .catch((e) => SetAlert(true, "failed", "Failed to fetch ftp path"));
  };

  // FTP Section

  const [ftpData, setFtpData] = useState([]); // ftp user name list
  const [ftpList, setFtpList] = useState(""); // ftp select user name paths
  const [ftpSearch, setFtpSearch] = useState({ isOpen: false, phrase: "" }); // ftp search
  const [selectedFTP, setSelectedFTP] = useState(""); // selected ftp user name
  const [selectedPath, setSelectedPath] = useState("");
  const [openSearch, setOpenSearch] = useState(false);

  const fetchFTP = () => {

    axiosClient
      .get("https://uniqueleverage.com/ul-backend/public/api/getFtp")
      .then((res) => setFtpData(res.data.data))
      .catch((e) => console.log(e));
  };

  const [notification, setNotification] = useState({
    isOpen: false,
    status: "",
    msg: "",
  });

  const SetAlert = (bool, status, msg) => {
    setNotification({ isOpen: bool, status: status, msg: msg });

    setTimeout(() => {
      setNotification({ isOpen: false, status: "", msg: "" });
    }, 5000);
  };

  useEffect(() => {
    fetchFTP();
  }, []);

  return (
    <>
      {/* Notification Msg Start */}
      <NotificationMsg
        toggle={notification}
        Toggler={setNotification}
        message={notification.msg}
      ></NotificationMsg>
      {/* Notification Msg End */}

      <div className="wrapper bg-backgroudWrapper">
        <div className="md:flex w-full h-screen">
          <SideMenu />

          <div
            className="w-full bg-backgroudPrimary overflow-x-auto"
            id="main-content"
          >
            <Header />

            <div className="px-[18px] md:px-[24px] md:mx-[14px]  md:h-[calc(100vh-120px)] bg-white">
              <div className="hidden md:flex justify-between items-center my-[30px]">
                <h1 className="text-xl font-semibold">Create Facebook Mapping</h1>
              </div>

              <div id="tabWrapper" onClick={() => { openSearch === true && setOpenSearch(false) }}>
                <div className="px-[18px] md:px-[24px] md:mx-[14px]  md:h-[calc(100vh-260px)] bg-white flex justify-center">
                  <form className="flex justify-center w-[1300px] pt-0" onSubmit={handleFormSubmit}>
                    <div>
                      <div className="min-w[300px] w-[600px] px-5">
                        <div
                          className="py-8 flex flex-col gap-4"
                        >
                          {pathError !== "" && <h1 className="text-red-500 font-semibold">Select Ftp Path</h1>}

                          <div className="flex flex-col gap-5 max-w-[500px] p-5 py-10 items-center shadow-lg rounded-lg border border-gray-100">
                            <div className="w-[350px] relative">
                              <label className="font-bold text-[#404040] block">FTP</label>
                              <input
                                type="search"
                                required
                                autoComplete="off"
                                name="ftpSearch"
                                value={selectedFTP}
                                placeholder="search dealership"
                                className="border border-[#EAEAEA] rounded-md p-2 w-full"
                                onClick={() => setOpenSearch(true)}
                                onChange={(e) => {
                                  setFtpSearch({ isOpen: true, phrase: e.target.value });
                                  setSelectedFTP(e.target.value);
                                }}
                              />

                              {ftpSearch.phrase !== "" && ftpSearch.isOpen && (
                                <div className="fixed bg-white rounded-lg shadow-lg w-[350px] p-2 max-h-[200px] overflow-y-auto z-[51] text-[16px]">
                                  <ul>
                                    {ftpData &&
                                      ftpData
                                        .filter((ftp) => {
                                          return ftpSearch.phrase.toLowerCase() === ""
                                            ? ftp
                                            : ftp.user.toLowerCase().includes(ftpSearch.phrase);
                                        })
                                        .map((ftp, index) => {
                                          return (
                                            <li
                                              key={index}
                                              className="hover:bg-gray-100 cursor-pointer p-2"
                                              onClick={() => {
                                                setSelectedFTP(ftp.user);
                                                handleSelectedUser(ftp.user);
                                                setFtpSearch({
                                                  isOpen: false,
                                                  phrase: ftpSearch.phrase,
                                                });
                                                setOpenSearch(false);
                                              }}
                                            >
                                              {ftp.user}
                                            </li>
                                          );
                                        })}
                                  </ul>
                                </div>
                              )}

                              {openSearch && ftpSearch.phrase === "" && (
                                <div className="fixed bg-white rounded-lg shadow-lg w-[350px] p-2 max-h-[200px] overflow-y-auto z-[51] text-[16px]">
                                  <ul>
                                    {ftpData &&
                                      ftpData
                                        .filter((ftp) => {
                                          return ftpSearch.phrase.toLowerCase() === ""
                                            ? ftp
                                            : ftp.user.toLowerCase().includes(ftpSearch.phrase);
                                        })
                                        .map((ftp, index) => {
                                          return (
                                            <li
                                              key={index}
                                              className="hover:bg-gray-100 cursor-pointer p-2"
                                              onClick={() => {
                                                setSelectedFTP(ftp.user);
                                                handleSelectedUser(ftp.user);
                                                setFtpSearch({
                                                  isOpen: false,
                                                  phrase: ftpSearch.phrase,
                                                });
                                                setOpenSearch(false);
                                              }}
                                            >
                                              {ftp.user}
                                            </li>
                                          );
                                        })}
                                  </ul>
                                </div>
                              )}
                            </div>
                            <div className="w-[350px] block">
                              <label htmlFor="folder" className="font-bold text-[#404040]">
                                File
                              </label>
                              <ul className="border border-[#EAEAEA] p-2 max-w-[350px] max-h-[180px] rounded-lg overflow-auto text-[16px]">
                                {ftpList === "" ? (
                                  <li className="text-gray-400 w-full whitespace-normal">
                                    ./root/
                                  </li>
                                ) : (
                                  ftpList.map((ftp, index) => {
                                    return (
                                      <li
                                        key={index}
                                        className={
                                          selectedPath === ftp.fullPath
                                            ? "bg-gray-200 cursor-pointer py-2 border-b whitespace-normal"
                                            : "hover:bg-gray-300 cursor-pointer py-2 border-b whitespace-normal"
                                        }
                                        onClick={() => setSelectedPath(ftp.fullPath)}
                                      >
                                        {ftp.fileName}
                                      </li>
                                    );
                                  })
                                )}
                              </ul>
                            </div>
                            <div>
                              {loadingCSV ?
                                <LoadingBtn />
                                :
                                <input type="button" className="bg-buttonPrimary p-2 px-5 rounded-md text-white mt-2 hover:bg-blue-700 duration-200 cursor-pointer" value="load file" onClick={() => { fetchList(selectedPath) }} />}

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="min-w[300px] w-[1000px] px-5 shadow-lg rounded-lg border border-gray-100 pt-5">
                      <div className="flex items-center gap-5 mb-5">
                        <Input value={"VIN"} inputName={"vin"} errors={errors} handleInputChange={handleInputChange} formData={formData} />

                        <Input value={"Year"} inputName={"year"} errors={errors} handleInputChange={handleInputChange} formData={formData} />
                      </div>

                      <div className="flex items-center gap-5 mb-5">
                        <Input value={"Make"} inputName={"make"} errors={errors} handleInputChange={handleInputChange} formData={formData} />

                        <Input value={"Model"} inputName={"model"} errors={errors} handleInputChange={handleInputChange} formData={formData} />
                      </div>

                      <div className="flex items-center gap-5 mb-5">
                        <Input value={"Trim"} inputName={"trim"} errors={errors} handleInputChange={handleInputChange} formData={formData} />

                        <Input value={"Engine"} inputName={"engine"} errors={errors} handleInputChange={handleInputChange} formData={formData} />
                      </div>

                      <div className="flex items-center gap-5 mb-5">
                        <Input value={"Mileage"} inputName={"mileage"} errors={errors} handleInputChange={handleInputChange} formData={formData} />

                        <Input value={"Price"} inputName={"price"} errors={errors} handleInputChange={handleInputChange} formData={formData} />
                      </div>

                      <div className="flex items-center gap-5 mb-5">
                        <Input value={"Exterior Color"} inputName={"exteriorcolor"} errors={errors} handleInputChange={handleInputChange} formData={formData} />

                        <Input value={"Interior Color"} inputName={"interiorcolor"} errors={errors} handleInputChange={handleInputChange} formData={formData} />
                      </div>

                      <div className="flex items-center gap-5 mb-5">
                        <Input value={"Transmission"} inputName={"transmission"} errors={errors} handleInputChange={handleInputChange} formData={formData} />

                        <Input value={"Images"} inputName={"images"} errors={errors} handleInputChange={handleInputChange} formData={formData} />
                      </div>

                      <div className="flex items-center gap-5 mb-5">
                        <Input value={"Description"} inputName={"description"} errors={errors} handleInputChange={handleInputChange} formData={formData} />

                        <Input value={"Fuel Type"} inputName={"fueltype"} errors={errors} handleInputChange={handleInputChange} formData={formData} />
                      </div>

                      <div className="flex items-center gap-5 mb-5">
                        <Input value={"Body Style"} inputName={"bodystyle"} errors={errors} handleInputChange={handleInputChange} formData={formData} />

                        <Input value={"Condition"} inputName={"condition"} errors={errors} handleInputChange={handleInputChange} formData={formData} />
                      </div>

                      <Input value={"Add Additional Columns"} inputName={"additional_column"} errors={errors} handleInputChange={handleInputChange} formData={formData} />

                      <div className="flex justify-end">
                        {saveLoading ? <div className="mt-5"><LoadingBtn /></div> :
                          <button className="bg-buttonPrimary p-3 px-8 text-md text-white rounded-lg mt-5">Save</button>
                        }
                      </div>
                    </div>

                  </form>
                </div>

                <div id="tabContent border-t">
                  <div
                    id="UsersTab"
                    className="flex flex-col justify-between pb-5"
                  >
                    <div className="overflow-x-auto hidden md:block">
                      <div className="p-1.5 min-w-full align-middle pt-7">
                        {listToggle && (<table className="border-b min-w-full divide-y divide-gray-200">
                          <thead>
                            <tr className="sticky top-0 bg-white">
                              {csvDataHeader &&
                                csvDataHeader.map((item, index) => (
                                  <th
                                    key={index}
                                    scope="col"
                                    className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
                                  >
                                    {item}
                                  </th>
                                ))}
                            </tr>
                          </thead>
                          <tbody className="border divide-y divide-gray-200">
                            {csvData ?
                              csvData.map(
                                (row, rowIndex) =>
                                  rowIndex !== 0 && (
                                    <tr
                                      key={rowIndex}
                                      className={
                                        rowIndex % 2 === 0
                                          ? "divide-x h-6 bg-backgroudSecondary"
                                          : "divide-x h-6 bg-backgroudPrimary"
                                      }
                                    >
                                      {[...Array(csvDataHeader.length)].map((_, i) => (
                                        <td
                                          key={i}
                                          className="px-3 whitespace-wrap text-textTableHeader dark:text-gray-200"
                                        >
                                          <p
                                            className={`text-[#606060] max-w-xs ${isDescriptionExpanded[rowIndex] ? "" : "line-clamp-1"
                                              }`}
                                            dangerouslySetInnerHTML={{
                                              __html: row[i],
                                            }}
                                          ></p>

                                          {row[i] && row[i].length > descriptionThreshold && (
                                            <button
                                              className="text-blue-500 pt-2 cursor-pointer"
                                              onClick={() => toggleDescription(rowIndex)}
                                            >
                                              {isDescriptionExpanded[rowIndex] ? "Show Less" : "Read More"}
                                            </button>
                                          )}
                                        </td>
                                      ))}
                                    </tr>
                                  )
                              ) : <tr><td><h1 className="text-center">File not found</h1></td></tr>}
                          </tbody>
                        </table>)}
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TableMapping;
