import React from 'react'

export const SwapIcon = () => {
    return (
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_581_3293)">
                <path d="M8.77498 6.92007C8.72081 6.86984 8.65728 6.83077 8.58801 6.80509C8.51874 6.77942 8.44509 6.76763 8.37127 6.77042C8.22218 6.77605 8.08143 6.84067 7.97998 6.95007C7.87854 7.05947 7.82471 7.20469 7.83033 7.35378C7.83596 7.50287 7.90058 7.64362 8.00998 7.74507L9.58498 9.20757H1.64248C1.4933 9.20757 1.35023 9.26683 1.24474 9.37232C1.13925 9.47781 1.07998 9.62088 1.07998 9.77007C1.07998 9.91925 1.13925 10.0623 1.24474 10.1678C1.35023 10.2733 1.4933 10.3326 1.64248 10.3326H9.58498L8.00998 11.7951C7.95581 11.8453 7.91207 11.9057 7.88124 11.9728C7.85042 12.04 7.83312 12.1125 7.83033 12.1864C7.82755 12.2602 7.83933 12.3338 7.86501 12.4031C7.89068 12.4724 7.92975 12.5359 7.97998 12.5901C8.03021 12.6442 8.09062 12.688 8.15776 12.7188C8.2249 12.7496 8.29745 12.7669 8.37127 12.7697C8.44509 12.7725 8.51874 12.7607 8.58801 12.735C8.65728 12.7094 8.72081 12.6703 8.77498 12.6201L11.4 10.1826C11.4568 10.1299 11.5021 10.0661 11.5331 9.99512C11.5641 9.92413 11.5801 9.84752 11.5801 9.77007C11.5801 9.69262 11.5641 9.616 11.5331 9.54502C11.5021 9.47404 11.4568 9.41022 11.4 9.35757L8.77498 6.92007ZM4.64998 1.74507C4.75939 1.64362 4.82401 1.50287 4.82963 1.35378C4.83526 1.20469 4.78143 1.05947 4.67998 0.95007C4.57854 0.840668 4.43779 0.776046 4.2887 0.77042C4.13961 0.764794 3.99439 0.818625 3.88498 0.92007L1.26148 3.35607C1.20408 3.40857 1.15822 3.47245 1.12682 3.54362C1.09542 3.6148 1.07917 3.69173 1.0791 3.76953C1.07903 3.84733 1.09514 3.92429 1.12641 3.99552C1.15768 4.06676 1.20342 4.13071 1.26073 4.18332L3.88498 6.62007C3.99439 6.72152 4.13961 6.77535 4.2887 6.76972C4.43779 6.76409 4.57854 6.69947 4.67998 6.59007C4.78143 6.48067 4.83526 6.33545 4.82963 6.18636C4.82401 6.03727 4.75939 5.89651 4.64998 5.79507L3.07498 4.33257H11.0175C11.1667 4.33257 11.3097 4.27331 11.4152 4.16782C11.5207 4.06233 11.58 3.91925 11.58 3.77007C11.58 3.62089 11.5207 3.47781 11.4152 3.37232C11.3097 3.26683 11.1667 3.20757 11.0175 3.20757H3.07498L4.64998 1.74507Z" fill="#87909F" />
            </g>
            <defs>
                <clipPath id="clip0_581_3293">
                    <rect width="12" height="12" fill="white" transform="translate(0.330078 0.77002)" />
                </clipPath>
            </defs>
        </svg>

    )
}
