import React from 'react'

const NegativeArrow = () => {
    return (
        <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.72675 0.333313L1.27342 0.333313C1.14157 0.333866 1.01285 0.373502 0.903525 0.447211C0.794203 0.520919 0.709191 0.62539 0.659242 0.747411C0.609291 0.869432 0.596644 1.00352 0.622901 1.13273C0.649158 1.26194 0.713139 1.38046 0.806753 1.47331L4.52675 5.1933C4.58873 5.25579 4.66246 5.30539 4.7437 5.33923C4.82494 5.37308 4.91208 5.3905 5.00009 5.3905C5.08809 5.3905 5.17523 5.37308 5.25647 5.33923C5.33771 5.30539 5.41144 5.25579 5.47342 5.1933L9.19342 1.47331C9.28703 1.38046 9.35102 1.26194 9.37727 1.13273C9.40353 1.00352 9.39088 0.869432 9.34093 0.747411C9.29098 0.62539 9.20597 0.520919 9.09665 0.447211C8.98733 0.373502 8.8586 0.333866 8.72675 0.333313Z" fill="#E53E3E" />
        </svg>
    )
}

export default NegativeArrow