
import ManageVehicleMenu from './manageVehicles/ManageVehicleMenu';
import PlusIcon from 'components/icons/PlusIcon';
import { EditIcon } from 'components/icons/EditIcon';
import ManageVehicleDetail from './manageVehicles/ManageVehicleDetail';
import EventsAndLogs from './EventsAndLogs';
import { useCustomerDetailContext } from 'Context/customerDetailContext/CustomerDetailContext';
import { useEffect, useState } from 'react';
import SmallLoading from 'components/Loadings/SmallLoading';
import { useParams } from 'react-router-dom';

export default function UserDetail({ item }) {
    const { userID, customerID } = useParams();

    const {
        handleSelectedUser,
        ftpList,
        fetchManageVehicleData,
        manageVehicleLoading,
        fetchUserDetail,
        userDetailLoading,
        userDetail,
    } = useCustomerDetailContext();

    const [renderCondition, setRenderCondition] = useState(true);

    useEffect(() => {
        setRenderCondition(true);
        setTimeout(() => {
            manageVehicleLoading === false &&
                setRenderCondition(false)
        }, 1000);

    }, [userDetailLoading, manageVehicleLoading])

    useEffect(() => {
        fetchUserDetail(userID);
        // handleSelectedUser(item.assignedFtpAccounts)
        // fetchManageVehicleData(item.id)
    }, []);

    if (userDetailLoading) {
        return (
            <div className="flex justify-center w-full items-center mt-60">
                <div className="w-12 h-12 border-t-4 border-buttonPrimary border-solid border-opacity-80 rounded-full animate-spin"></div>
            </div>
        )
    }

    return (
        <table className='w-full'>
            <tbody>
                <tr className='bg-white'>
                    <td colSpan={11} className='text-sm'>
                        <div className='flex flex-col gap-2 py-5 font-medium'>
                            <div className='flex items-center justify-between'>
                                <h1 className='font-semibold'>ID</h1>
                                <h2 className='font-normal'>{userDetail.id}</h2>
                            </div>
                            <div className='flex items-center justify-between'>
                                <h1 className='font-semibold'>Name</h1>
                                <h2 className='font-normal'>{userDetail.username || "-"}</h2>
                            </div>
                            <div className='flex items-center justify-between'>
                                <h1 className='font-semibold'>Number</h1>
                                <h2 className='font-normal'>{userDetail.mobile || "-"}</h2>
                            </div>
                            <div className='flex items-center justify-between'>
                                <h1 className='font-semibold'>Phone Number</h1>
                                <h2 className='font-normal'>{userDetail.phone || "-"}</h2>
                            </div>
                            <div className='flex items-center justify-between'>
                                <h1 className='font-semibold'>Email</h1>
                                <h2 className='font-normal'>{userDetail.email || "-"}</h2>
                            </div>
                            <div className='flex items-center justify-between'>
                                <h1 className='font-semibold'>Billing Address</h1>
                                <h2 className='font-normal'>{userDetail.address || "-"}</h2>
                            </div>
                            <div className='flex items-center justify-between'>
                                <h1 className='font-semibold'>Inventory Source</h1>
                                <h2 className='text-buttonPrimary font-normal'>{userDetail.inventory_source || "-"}</h2>
                            </div>
                        </div>

                        <table className='w-full'>
                            <thead>
                                <tr className='border-b font-bold'>
                                    <td className='font-semibold pb-3'>csv</td>
                                    <td className='font-semibold pb-3'>Source</td>
                                    <td className='font-semibold pb-3'>Last Processed</td>
                                    <td className='font-semibold pb-3'>Last Action</td>
                                    <td className='font-semibold pb-3'>Timestamp</td>
                                    <td className='font-semibold pb-3'>Location</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='border-b text-sm font-normal'>
                                    <td className='text-buttonPrimary py-4'>{ftpList?.fileName !== undefined ? ftpList.fileName : "-"}</td>
                                    <td className=' py-4'>{userDetail.inventory_source || "-"}</td>
                                    <td className=' py-4'>{userDetail.lastprocessed || '-'}</td>
                                    <td className=' py-4'>Post Vehicle</td>
                                    <td className=' py-4'>Dec, 11th, 2024</td>
                                    <td className=' py-4'>{userDetail.address || '-'}</td>
                                    <td className=' py-4'><button><EditIcon width="12" height="12" color="#1B74E4" /></button></td>
                                </tr>
                            </tbody>
                        </table>

                        <div className='flex items-center justify-between py-[13px] border-b'>
                            <h1 className='font-bold text-[16px]'>Custom Rules</h1>
                            <button>
                                <PlusIcon color="#1B74E4" height={32} width={32} />
                            </button>
                        </div>

                        <div className='py-4'>
                            <h1 className='font-bold text-[16px] pb-[15px]'>Manage Vehicles</h1>

                            {renderCondition ?
                                manageVehicleLoading && (
                                    <div className='flex justify-center py-20'>
                                        <SmallLoading />
                                    </div>)
                                :

                                <div className='pb-5 flex border border-[#E4E8ED] rounded-md'>

                                    <ManageVehicleMenu />

                                    <ManageVehicleDetail manageVehicleLoading={manageVehicleLoading} />

                                </div>}

                            <div className='py-5 hidden'>
                                <h1 className='font-semibold text-lg pb-5'>Events and logs</h1>

                                <div className='flex flex-col gap-2'>
                                    <h1 className='text-gray-600 font-semibold'>LATEST ACTIVITY</h1>
                                    <h4 className='text-gray-500 text-[16px]'>Paymentintent status: <span className='font-semibold'>succeeded</span></h4>
                                </div>

                                <EventsAndLogs />
                            </div>

                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

    );
}