import { SwapIcon } from 'components/icons/SwapIcon';
import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import coy from 'react-syntax-highlighter/dist/esm/styles/prism/coy';
import { CircleDown } from 'components/icons/CircleDown';
import { useState } from 'react';


export default function EventsAndLogs() {

    const [toggleCodeFade, setToggleCodeFade] = useState(false);

    const obj = `{
        "id": "in_1O9FovBYSqaXijdvZsAmZmG4",
        "object": "invoice",
        "payment_intent": "pi_1O9FovBYSqaXijdvZsAmZmG4",
        "status": "paid",
        "amount_due": 19900,
        "charge": "ch_1O9FovBYSqaXijdvZsAmZmG4",
        "currency": "usd",
        "customer": "cus_JDk2ZQXQ8QZQXQ",
        "default_payment_method": null,
        "uri": "",
        "data": [
            {
            "id": "57dafe43317467e7b0ef5276",
            "projectId": "54c17e92e4b0bc49e27d9398",
            "number": 5,
            "startingUserId": "57a28b28961401f240812caf",
            "queued": 1473969731915,
            "started": 1473969754283,
            "completed": 1473969762970,
            "status": "Succeeded",
            "commitId": "1c6e8aa47951e39f9a905f0077af9355c35b712b",
            "executor": "us-west-2:i-007942020ce7baeb5",
            "outputCommitId": "73fe9481fe635bada713246c3c739beefa424e8c",
            "title": "Run with great R^2 of 1!",
            "isArchived": false,
            "postProcessedTimestamp": 1473969767822,
            "diagnosticStatistics": {
                "isError": false,
                "data": [
                {
                    "key": "R^2",
                    "value": "1.000"
                }
                ]
            },
            "isCompleted": true,
            "hardwareTierId": "gpu"
            }
        ]
    }`;

    return (
        <div className='flex gap-1 pt-8'>
            <div className='w-6/12 border-r border-gray-200'>
                <h1 className='font-semibold text-gray-600'>L Activity</h1>
                <div className='p-5 overflow-y-auto max-h-[600px] flex flex-col gap-3'>
                    <div className='border border-[#625AFA] p-4 rounded-md'>
                        <h1>A draft invoice for $199.00 USD to
                            in_1O9FovBYSqaXijdvZsAmZmG4 was finalized</h1>
                        <h4 className='text-gray-400 text-[16px] pt-2'>11/5/23, 7:33:54 PM</h4>
                    </div>
                    <div className='border border-gray-300 p-4 rounded-md'>
                        <h1>in_1O9FovBYSqaXijdvZsAmZmG4's payment for an invoice
                            for $199.00 USD succeeded</h1>
                        <h4 className='text-gray-400 text-[16px] pt-2'>11/5/23, 7:33:54 PM</h4>
                    </div>
                    <div className='border border-gray-300 p-4 rounded-md'>
                        <h1>in_1O9FovBYSqaXijdvZsAmZmG4's invoice for $199.00
                            USD was paid</h1>
                        <h4 className='text-gray-400 text-[16px] pt-2'>11/5/23, 7:33:54 PM</h4>
                    </div>
                </div>
            </div>
            <div className='w-6/12 border-l border-gray-200 px-5'>
                <h2 className='text-gray-600 flex items-center gap-2'><SwapIcon />From Stripe</h2>
                <h1 className='font-semibold text-lg pb-3'>invoice.finalized</h1>
                <button className='text-buttonPrimary font-semibold pb-5'>View event detail</button>

                <div>
                    <h1 className='pb-3 font-semibold'>Event Data</h1>

                    <div className={`relative`}>
                        <div className={`${!toggleCodeFade && "codeFade h-64"}`}>
                            <SyntaxHighlighter language="javascript" style={coy} wrapLines={true} wrapLongLines={true} showLineNumbers={true}>
                                {obj}
                            </SyntaxHighlighter>
                        </div>

                        <div className='flex justify-center'>
                            <button className='absolute bottom-2 z-10 border border-gray-300 px-3 rounded-lg bg-white shadow-md text-[16px] flex items-center gap-2' onClick={() => setToggleCodeFade(!toggleCodeFade)}>
                                <CircleDown />{toggleCodeFade ? "Hide lines" : `See all ${obj.split('\n').length} lines`}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}