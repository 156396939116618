import ProfileImage from 'assets/images/CarImage.jpg';
import ImageSlider from './ImageSlider';
import { ExteriorIcon } from 'components/icons/ExteriorIcon';
import { MPGIcon } from 'components/icons/MPGIcon';
import { Transmition } from 'components/icons/Transmition';
import { useCustomerDetailContext } from 'Context/customerDetailContext/CustomerDetailContext';
import { useEffect, useState } from 'react';
import { NumberToPrice } from 'Utils/NumberToPrice';
import { checkImageURL } from 'Utils/CheckImageURL';
import DieselIcon from 'components/icons/DieselIcon';

export default function ManageVehicleDetail() {
    const {
        manageVehicleLoading, // for separate page
        manageVehicleData,
        setVehicleDetailData,
        vehicleDetailData,
        setVehicleImages,
        vehicleImages,
    } = useCustomerDetailContext();

    useEffect(() => {
        if (!manageVehicleLoading) {
            setVehicleDetailData(manageVehicleData?.listed[0]);
            setVehicleImages(manageVehicleData?.listed[0]?.images.split(',')[0]);
        }
    }, [manageVehicleLoading]);

    return (
        <div className='w-full'>
            <div className='flex items-center justify-end gap-8 border-b font-normal text-xs pr-[18px] py-[10px]'>
                <button className=''>Edit Vehicle</button>
                <button className='text-buttonPrimary '>Mark As Pending</button>
                <button className='text-red-500 '>Delete</button>
            </div>
            <div className='pl-5'>
                <h1 className='font-bold text-base'>{vehicleDetailData.year} {vehicleDetailData.model} - {NumberToPrice(vehicleDetailData.price)}</h1>

                <div className='py-5 w-[570px]'>
                    <img src={checkImageURL(vehicleImages) ? vehicleImages : ""} alt="demo" className='w-full fit-cover h-96 rounded-lg' />
                </div>

                <div className='flex items-center gap-3 overflow-x-hidden w-[570px]'>
                    <ImageSlider setVehicleImages={setVehicleImages} images={vehicleDetailData?.images?.split(',')} />
                </div>

                <div className='py-5 flex items-center gap-10'>

                    <div className='flex items-center gap-1'>
                        <DieselIcon color="#1B74E4" />
                        <div>
                            <h3 className='text-[12px] text-gray-400'>Fuel Type</h3>
                            <h1 className='font-medium'>{vehicleDetailData.fueltype}</h1>
                        </div>
                    </div>

                    <div className='flex items-center gap-1'>
                        <ExteriorIcon color="#1B74E4" />
                        <div>
                            <h3 className='text-[12px] text-gray-400'>Exterior Color</h3>
                            <h1 className='font-medium'>{vehicleDetailData.exteriorcolor}</h1>
                        </div>
                    </div>

                    <div className='flex items-center gap-1'>
                        <MPGIcon color="#1B74E4" />
                        <div>
                            <h3 className='text-[12px] text-gray-400'>MPG</h3>
                            <h1 className='font-medium'>{vehicleDetailData.engine}</h1>
                        </div>
                    </div>

                    <div className='flex items-center gap-1'>
                        <Transmition color="#1B74E4" />
                        <div>
                            <h3 className='text-[12px] text-gray-400'>Transmition</h3>
                            <h1 className='font-medium'>{vehicleDetailData.transmission}</h1>
                        </div>
                    </div>
                </div>

                <div className='flex items-center gap-10'>
                    <div>
                        <label htmlFor="price" className='text-gray-500 text-[12px]'>Price</label>
                        <div className='px-3 py-[5px] w-[154px] border border-gray-400 rounded-lg font-medium' id='price'>
                            <span className='text-gray-400'>$</span>{vehicleDetailData?.price?.toLocaleString() || "-"}
                        </div>
                    </div>
                    <div>
                        <label htmlFor="mileage" className='text-gray-500 text-[12px]'>Mileage</label>
                        <div className='px-3 py-[5px] w-[154px] border border-gray-400 rounded-lg font-medium' id='mileage'>
                            {vehicleDetailData?.mileage?.toLocaleString() || "-"} <span className='text-gray-400'>miles</span>
                        </div>
                    </div>
                </div>

                <div className='pt-5'>
                    <label htmlFor="description" className='font-semibold'>Description</label>
                    <p className='text-gray-600'>
                        {vehicleDetailData.description || "No description"}
                    </p>
                </div>
            </div>
        </div>
    );
}