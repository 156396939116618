import { useTeamsContext } from 'Context/TeamsContext/TeamsContext';
import SmallLoading from 'components/Loadings/SmallLoading';

const AddTeam = () => {
    const { setAddTeamToggle, addTeam, handleInputChange, TeamForm, addTeamLoading } = useTeamsContext();

    return (
        <div className="fixed inset-0 z-[110] bg-[#D3D9DF] bg-opacity-50 flex justify-center items-center font-roboto">
            <div className="bg-white w-[500px] h-[fit-content] max-h-[100vh]  rounded-lg shadow-md overflow-y-auto">
                <div className="flex justify-between items-center p-5">
                    <h1 className="font-[500]">Add Team</h1>
                </div>
                <hr />
                {/* <h1 className="font-[600] mt-5 px-5">User Information</h1> */}

                <form onSubmit={addTeam}>
                    <div className="flex flex-col gap-3 px-5 py-5">
                        <div className="flex flex-col gap-2">
                            <label htmlFor="username" className="font-[500] text-sm">Username</label>
                            <input type="name"
                                name="username"
                                onChange={handleInputChange}
                                value={TeamForm.username}
                                required
                                id="username"
                                className="border border-gray-200 rounded-md placeholder:text-sm placeholder:font-[400] px-2 py-[3px] focus:shadow-blue-100 focus:shadow-md outline-none duration-300"
                                placeholder="Jenny"
                                autoComplete="off"
                                autoFocus
                            />

                        </div>

                        <div className="flex flex-col gap-2">
                            <label htmlFor="email" className="font-[500] text-sm">Email</label>
                            <input type="text"
                                name="email"
                                id="email"
                                className="border border-gray-200 rounded-md placeholder:text-sm placeholder:font-[400] px-2 py-[3px] focus:shadow-blue-100 focus:shadow-md outline-none duration-300"
                                placeholder="jenny.rosen@gmail.com"
                                autoComplete="off"
                                required
                                value={TeamForm.email}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="flex flex-col gap-2">
                            <label htmlFor="password" className="font-[500] text-sm">Password <span className='text-xs'></span></label>
                            <input type="text"
                                name="password"
                                id="password"
                                className="border border-gray-200 rounded-md placeholder:text-sm placeholder:font-[400] px-2 py-[3px] focus:shadow-blue-100 focus:shadow-md outline-none duration-300"
                                placeholder="Admin@1122"
                                autoComplete="off"
                                required
                                value={TeamForm.password}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="flex flex-col gap-2">
                            <label htmlFor="roles" className="font-[500] text-sm">Roles</label>
                            <select
                                name="role"
                                onChange={handleInputChange}
                                required
                                id='role'
                                className="border border-gray-200 rounded-md placeholder:text-sm placeholder:font-[400] px-2 py-[3px] focus:shadow-blue-100 focus:shadow-md outline-none font-[500] text-sm duration-300"
                                defaultValue={""}
                            >
                                <option value="" className="text-gray-200 font-[500] text-sm" disabled>
                                    select roles
                                </option>
                                <option value="admin" className="text-gray-700 font-[500] text-sm">
                                    Admin
                                </option>
                                <option value="editor" className="text-gray-700 font-[500] text-sm">
                                    Editor
                                </option>
                            </select>
                        </div>
                    </div>

                    <hr />

                    <div className="flex items-center justify-end gap-2 p-5">
                        <span className="bg-white border border-gray-300 rounded-md py-[2px] px-2 font-[500] text-sm hover:shadow-md duration-300 cursor-default" onClick={() => { setAddTeamToggle(false) }}>Cancel</span>
                        {addTeamLoading ?
                            <span className="bg-buttonPrimary rounded-md py-[4px] px-2 text-white font-[500] text-sm hover:shadow-md duration-300 flex items-center gap-2 cursor-default"><SmallLoading color={"white"} /> Add Team</span>
                            :
                            <button className="bg-buttonPrimary rounded-md py-[4px] px-2 text-white font-[500] text-sm hover:shadow-md duration-300">Add Team</button>}

                    </div>
                </form>
            </div>
        </div>
    )
}

export default AddTeam