import { useState, useEffect, useRef } from "react";
import { SettingSolidIcon } from "../../../components/icons/SettingSolidIcon";
import PlusIcon from "../../../components/icons/PlusIcon";

export default function AddBtn({ dropdownOpen }) {
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);

    const handleClickOutside = (event) => {
        if (buttonRef.current && buttonRef.current.contains(event.target)) {
            return;
        }
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setToggleDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="relative">
            <button ref={buttonRef} className={`p-1 px-2 border border-gray-300 rounded-md flex items-center font-semibold cursor-pointer text-[#414552] hover:border-[#10111a7e] duration-300 text-sm ${toggleDropdown && "border-[#10111a7e]"}`} onClick={() => { setToggleDropdown(!toggleDropdown) }}>
                <PlusIcon color='black' />
                Add
            </button>
            <div ref={dropdownRef}>
                {toggleDropdown &&
                    <div className='absolute top-9 right-0 w-max bg-white border border-gray-100 rounded-md shadow-xl p-3 flex flex-col text-start gap-2 z-10'>
                        <div className="bg-white rounded-[6px] overflow-hidden shadow-login-card-drop">
                            <div className="inline-flex flex-col items-start gap-[8px] py-2">

                                <div className="flex items-center gap-[3px] cursor-pointer">
                                    <PlusIcon color={"#1B74E4"} height={30} width={30} />
                                    <div className="text-[#1b74e4]">
                                        Duplicate
                                    </div>
                                </div>

                                <div className="flex items-center gap-[3px] cursor-pointer">
                                    <PlusIcon color={"#1B74E4"} height={30} width={30} />
                                    <div className="text-[#1b74e4]">
                                        Erros
                                    </div>
                                </div>

                                <div className="flex items-center gap-[3px] cursor-pointer">
                                    <PlusIcon color={"#1B74E4"} height={30} width={30} />
                                    <div className="text-[#1b74e4]">
                                        Freeze
                                    </div>
                                </div>

                                <div className="flex items-center gap-[3px] cursor-pointer">
                                    <PlusIcon color={"#1B74E4"} height={30} width={30} />
                                    <div className="text-[#1b74e4]">
                                        Deleted
                                    </div>
                                </div>

                                <div className="flex items-center gap-[3px] cursor-pointer">
                                    <PlusIcon color={"#1B74E4"} height={30} width={30} />
                                    <div className="text-[#1b74e4]">
                                        Updated
                                    </div>
                                </div>

                                <div className="flex items-center gap-[3px] cursor-pointer">
                                    <PlusIcon color={"#1B74E4"} height={30} width={30} />
                                    <div className="text-[#1b74e4]">
                                        Get Vehicles
                                    </div>
                                </div>

                                <div className="flex items-center gap-[3px] cursor-pointer">
                                    <PlusIcon color={"#1B74E4"} height={30} width={30} />
                                    <div className="text-[#1b74e4]">
                                        Pending
                                    </div>
                                </div>

                                <div className="flex items-center gap-[3px] cursor-pointer">
                                    <PlusIcon color={"#1B74E4"} height={30} width={30} />
                                    <div className="text-[#1b74e4]">
                                        Active
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}