import React from 'react'

export const BluePlusIcon = ({ color, height, width }) => {
    return (
      <svg width={width === undefined ? "28" : width}
      height={height === undefined ? "28" : height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" rx="12" fill="#1B74E4"/>
        <g clipPath="url(#clip0_1683_2258)">
            <path d="M12.8504 7.00002C12.8504 6.77459 12.7608 6.55839 12.6014 6.39898C12.442 6.23958 12.2258 6.15002 12.0004 6.15002C11.775 6.15002 11.5588 6.23958 11.3993 6.39898C11.2399 6.55839 11.1504 6.77459 11.1504 7.00002V11.15H7.00039C6.77496 11.15 6.55876 11.2396 6.39935 11.399C6.23994 11.5584 6.15039 11.7746 6.15039 12C6.15039 12.2255 6.23994 12.4417 6.39935 12.6011C6.55876 12.7605 6.77496 12.85 7.00039 12.85H11.1504V17C11.1504 17.2255 11.2399 17.4417 11.3993 17.6011C11.5588 17.7605 11.775 17.85 12.0004 17.85C12.2258 17.85 12.442 17.7605 12.6014 17.6011C12.7608 17.4417 12.8504 17.2255 12.8504 17V12.85H17.0004C17.2258 12.85 17.442 12.7605 17.6014 12.6011C17.7608 12.4417 17.8504 12.2255 17.8504 12C17.8504 11.7746 17.7608 11.5584 17.6014 11.399C17.442 11.2396 17.2258 11.15 17.0004 11.15H12.8504V7.00002Z" fill="white"/>
        </g>
        <defs>
            <clipPath id="clip0_1683_2258">
                <rect width="12" height="12" fill="white" transform="translate(6 6)"/>
            </clipPath>
        </defs>
      </svg>
      
    )
}
