import React from 'react'

export const CruiseIcon = ({ color, height, width }) => {
    return (
        <svg
            width={`${width === undefined ? "24" : width}`}
            height={`${height === undefined ? "24" : height}`}
            viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_642_875)">
                <path d="M12 1.99512C17.523 1.99512 22 6.47212 22 11.9951C22 17.5181 17.523 21.9951 12 21.9951C6.477 21.9951 2 17.5181 2 11.9951C2 6.47212 6.477 1.99512 12 1.99512ZM12 3.99512C7.582 3.99512 4 7.57712 4 11.9951C4 16.4131 7.582 19.9951 12 19.9951C16.418 19.9951 20 16.4131 20 11.9951C20 7.57712 16.418 3.99512 12 3.99512ZM15.833 7.33212C16.07 7.16612 16.392 7.19412 16.596 7.39912C16.8 7.60312 16.826 7.92512 16.659 8.15912C14.479 11.2051 13.279 12.8371 13.061 13.0561C12.475 13.6411 11.525 13.6411 10.939 13.0561C10.354 12.4701 10.354 11.5201 10.939 10.9341C11.313 10.5611 12.944 9.36012 15.833 7.33212ZM17.5 10.9951C18.052 10.9951 18.5 11.4431 18.5 11.9951C18.5 12.5471 18.052 12.9951 17.5 12.9951C16.948 12.9951 16.5 12.5471 16.5 11.9951C16.5 11.4431 16.948 10.9951 17.5 10.9951ZM6.5 10.9951C7.052 10.9951 7.5 11.4431 7.5 11.9951C7.5 12.5471 7.052 12.9951 6.5 12.9951C5.948 12.9951 5.5 12.5471 5.5 11.9951C5.5 11.4431 5.948 10.9951 6.5 10.9951ZM8.818 7.39912C9.208 7.78912 9.208 8.42212 8.818 8.81312C8.428 9.20312 7.794 9.20312 7.404 8.81312C7.014 8.42312 7.014 7.78912 7.404 7.39912C7.794 7.00912 8.427 7.00912 8.818 7.39912ZM12 5.49512C12.552 5.49512 13 5.94312 13 6.49512C13 7.04712 12.552 7.49512 12 7.49512C11.448 7.49512 11 7.04712 11 6.49512C11 5.94312 11.448 5.49512 12 5.49512Z" fill={`${color === undefined ? "#8E93A6" : color}`} />
            </g>
            <defs>
                <clipPath id="clip0_642_875">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}
