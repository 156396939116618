import React, { useState } from "react";

const ErrorLogs = ({ ErrorLog, userInfo }) => {
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const descriptionThreshold = 300;

  const toggleDescription = () => {
    setIsDescriptionExpanded(!isDescriptionExpanded);
  };

  return (
    <>
      {ErrorLog && ErrorLog.error.length > 0 ? (
        ErrorLog.error.map((error, index) => (
          <tr
            key={index}
            className={
              index % 2 === 0
                ? "divide-x h-16 bg-backgroudSecondary"
                : "divide-x h-16 bg-backgroudPrimary"
            }
          >
            <td className="px-6 py-4 whitespace-nowrap text-textTableHeader dark:text-gray-200">
              Error
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-textTableHeader dark:text-gray-200">
              {userInfo.username}
            </td>
            <td className="px-6 py-4 whitespace-wrap text-textTableHeader dark:text-gray-200">
              <p
                className={`text-[#606060] ${isDescriptionExpanded ? "" : "line-clamp-6"
                  }`}
                dangerouslySetInnerHTML={{
                  __html: error.last_ope,
                }}
              ></p>

              {error.last_ope.length > descriptionThreshold && (
                <button
                  className="text-blue-500 mt-2 cursor-pointer"
                  onClick={toggleDescription}
                >
                  {isDescriptionExpanded ? "Show Less" : "Read More"}
                </button>
              )}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-textTableHeader dark:text-gray-200">
              {error.day_reg}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-textTableHeader dark:text-gray-200">
              {error.date_mod}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-textTableHeader dark:text-gray-200">
              {error.date_reg}
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="10" className="text-center">
            Nothing to show here
          </td>
        </tr>
      )}
    </>
  );
};

export default ErrorLogs;
