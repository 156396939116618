import React, { useEffect, useState } from "react";
import axiosClient from "../../../axios.client";
import { ExitIcon } from "../../../components/icons/ExitIcon";

const AddUserDesktop = ({ setAddUserModel, fetchUsersList }) => {
  const [dealershipName, setDealerShipName] = useState([]);
  const [selectedDealershipName, setSelectedDealershipName] = useState(
    dealershipName && dealershipName.length > 0 ? dealershipName[0].name : ""
  );
  const [loading, setLoading] = useState(false);

  const [formDate, setFormDate] = useState({
    name: selectedDealershipName,
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    password: "",
    user_id: "",
    phone: "",
    address: "",
    status: "",
    inventory_source: "",
  });

  const fetchDealerShip = async () => {
    setLoading(true);

    try {
      await axiosClient
        .get("/getUsers")
        .then((res) => setDealerShipName(res.data.data))
        .catch((e) => console.log(e));
    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  };

  const handleInput = (e) => {
    const { name, value } = e.target;

    setFormDate({ ...formDate, [name]: value });
  };

  const submitUser = async (e) => {
    e.preventDefault();
    // console.log(formDate);

    try {
      await axiosClient
        .post("/addUser", formDate)
        // .then((res) => console.log(res))
        .catch((e) => console.log(e));
    } catch (e) {
      console.log(e);
    }

    fetchUsersList !== undefined && fetchUsersList();
    setAddUserModel(false);
  };

  useEffect(() => {
    fetchDealerShip();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-12 h-12 border-t-4 border-buttonPrimary border-solid border-opacity-80 rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div className="p-5 bg-white rounded-md">
      <div className="flex items-center justify-between">
        <h1 className="font-bold text-2xl">Add User</h1>
        <button className="font-bold" onClick={() => setAddUserModel(false)}>
          <ExitIcon />
        </button>
      </div>

      <form onSubmit={submitUser}>
        <div className="py-8 flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <label className="font-bold">Dealership Name</label>
            <select
              className="border border-[#EAEAEA] p-2 rounded-sm"
              name="name"
              onChange={(e) => {
                setSelectedDealershipName(e.target.value);
                handleInput(e);
              }}
              defaultValue={selectedDealershipName}
            >
              {dealershipName &&
                dealershipName.map((dealerName) => {
                  return (
                    <option
                      value={dealerName.name}
                      key={dealerName.id}
                      className="text-gray-700"
                    >
                      {dealerName.name}
                    </option>
                  );
                })}
            </select>
          </div>

          <div className="flex flex-col gap-2">
            <label className="font-bold">Name</label>
            <div className="flex">
              <input
                type="name"
                name="first_name"
                onChange={(e) => handleInput(e)}
                value={formDate.first_name}
                required
                placeholder="First Name"
                className="p-2 border border-[#EAEAEA] rounded-sm flex-1 border-r-0 w-full"
              />
              <input
                type="name"
                name="last_name"
                onChange={(e) => handleInput(e)}
                value={formDate.last_name}
                required
                placeholder="Last Name"
                className="p-2 border border-[#EAEAEA] rounded-sm flex-1 border-l-0 w-full"
              />
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <label className="font-bold">Username</label>
            <input
              type="name"
              name="username"
              required
              onChange={(e) => handleInput(e)}
              value={formDate.username}
              placeholder="username..."
              className="p-2 border border-[#EAEAEA] rounded-sm"
            />
          </div>

          <div className="flex flex-col gap-2">
            <label className="font-bold">Password</label>
            <input
              type="text"
              name="password"
              required
              onChange={(e) => handleInput(e)}
              value={formDate.password}
              placeholder="Admin@1122"
              className="p-2 border border-[#EAEAEA] rounded-sm"
            />
          </div>

          {/* <div className="hidden flex flex-col gap-2">
            <label className="font-bold">User ID</label>
            <input
              type="number"
              name="user_id"
              required
              onChange={(e) => handleInput(e)}
              value={formDate.user_id}
              placeholder="100"
              className="p-2 border border-[#EAEAEA] rounded-sm"
            />
          </div> */}

          <div className="flex flex-col gap-2">
            <label className="font-bold">Phone Number</label>
            <input
              type="number"
              name="phone"
              onChange={(e) => handleInput(e)}
              value={formDate.phone}
              required
              placeholder="+1 313-530-6666"
              className="p-2 border border-[#EAEAEA] rounded-sm"
            />
          </div>

          <div className="flex flex-col gap-2">
            <label className="font-bold">Email ID</label>
            <input
              type="email"
              name="email"
              onChange={(e) => handleInput(e)}
              value={formDate.email}
              required
              placeholder="admin@uniqueleverage.com"
              className="p-2 border border-[#EAEAEA] rounded-sm"
            />
          </div>

          <div className="flex flex-col gap-2">
            <label className="font-bold">Address</label>
            <input
              type="text"
              name="address"
              onChange={(e) => handleInput(e)}
              value={formDate.address}
              required
              placeholder="address..."
              className="p-2 border border-[#EAEAEA] rounded-sm"
            />
          </div>

          <div className="flex flex-col gap-2">
            <label className="font-bold">Source</label>
            <input
              type="text"
              name="inventory_source"
              onChange={(e) => handleInput(e)}
              value={formDate.inventory_source}
              required
              placeholder="inventory source..."
              className="p-2 border border-[#EAEAEA] rounded-sm"
            />
          </div>

          <div className="flex flex-col gap-2">
            <label className="font-bold">Status</label>
            <select
              name="status"
              onChange={(e) => handleInput(e)}
              required
              className="border border-[#EAEAEA] p-2 rounded-sm"
              defaultValue={""}
            >
              <option value="" className="text-gray-200" disabled>
                select option
              </option>
              <option value="active" className="text-gray-700">
                Active
              </option>
              <option value="inactive" className="text-gray-700">
                Inactive
              </option>
            </select>
          </div>

          <div className="hidden flex gap-2">
            <span className="bg-buttonPrimary rounded-full w-5 h-5 text-white text-lg text-center flex align-middle items-center  cursor-pointer">
              <span className="ml-[3.7px] mb-[2px]">+</span>
            </span>

            <h3 className="font-bold text-[16px]  cursor-pointer">
              Assign Feed Data
            </h3>
          </div>
        </div>

        <button
          type="submit"
          className="w-full bg-buttonPrimary hover:bg-blue-600 duration-300 text-white rounded-sm py-2"
        >
          Add User
        </button>
      </form>
    </div>
  );
};

export default AddUserDesktop;
