import { useCustomerContext } from 'Context/CustomerContext';
import { useUserContext } from 'Context/UserContext';
import { useCustomerDetailContext } from 'Context/customerDetailContext/CustomerDetailContext';
import axiosClient from 'axios.client';
import React from 'react'
import { useNavigate } from 'react-router-dom'

export const MenuDropDown = ({ openMenu, index, item }) => {
    const navigate = useNavigate();
    const {
        usersList,
        setUsersList,
        setEditCustomerDetail,
    } = useCustomerDetailContext();

    const { SetAlert } = useCustomerContext();

    const {
        setToggleFtpModel,
    } = useUserContext();

    const handleFreeze = () => {
        const updatedListUser = usersList.map((user) => {
            if (user.id === item.id) {
                return { ...user, freeze: item.freeze === 1 ? 0 : 1 };
            }
            return user;
        });

        setUsersList(updatedListUser);

        updateFreeze(item.freeze === 1 ? 0 : 1, item.id);
    };

    const updateFreeze = async (val, id) => {
        const selectedUser = usersList.find((user) => user.id === id);

        if (selectedUser) {
            const addressParts = (selectedUser.address || "").split(",");

            const payload = {
                ...selectedUser,
                freeze: val,
                city: addressParts[0] || "",
                state: addressParts[1] || "",
                formPackage: selectedUser.package,
            };

            try {
                const response = await axiosClient.post("/update-freeze", payload)

                if (response.data.status === 200) {
                    SetAlert(true, "success", response.data.message);
                } else if (response.data.status === 400) {
                    SetAlert(true, "failed", response.data.message);
                }
            } catch (error) {
                // console.error("Error while updating:", error);
                SetAlert(true, "failed", "Error while updating");
            }
        }
    }

    return (
        <>
            <div className={
                openMenu === index
                    ? "absolute top-8 w-40 right-0 bg-white border border-gray-100 rounded-md shadow-xl p-3 z-20"
                    : "hidden"
            } >
                {/* <h1 className='text-[16px] font-bold pb-2'>More</h1> */}
                <div className='flex flex-col gap-2 items-start'>

                    <button className='text-gray-500 hover:text-[#1B74E4] hover:font-semibold'
                        onClick={() => { setEditCustomerDetail({ customer: item, isOpen: true }) }}>
                        Edit User
                    </button>

                    <button className='text-gray-500 hover:text-[#1B74E4] hover:font-semibold'
                        onClick={handleFreeze}>
                        {item.freeze === 1 ? "Unfreeze" : "Freeze"} User
                    </button>

                    <button className='text-gray-500 hover:text-[#1B74E4] hover:font-semibold'
                        onClick={() => { navigate(`/dealerShip/manage-vehicles/${item.id}`) }}>
                        Manage Vehicles
                    </button>

                    <button className='text-gray-500 hover:text-[#1B74E4] hover:font-semibold'
                        onClick={() => { navigate(`/connect/${item.id}`) }}>
                        Connect +
                    </button>

                    <button className='text-gray-500 hover:text-[#1B74E4] hover:font-semibold'
                        onClick={() =>
                            setToggleFtpModel({
                                id: item.id,
                                isBool: true,
                                ftpPath: item.ftp_file_path,
                                FTPaccount: item.FTPaccount,
                                assignedFtpAccounts: item.assignedFtpAccounts
                            })
                        }>
                        FTP
                    </button>

                </div>
            </div>
        </>
    )
}
