import { useAlertContext } from "Context/AlertContext";
import axiosClient from "axios.client";
import { createContext, useContext, useEffect, useState } from "react";

export const FtpContext = createContext();

export const FtpContextProvider = ({ children }) => {
    const { SetAlert } = useAlertContext();

    const [mappedDataLoading, setMappedDataLoading] = useState(false);
    const [mappedData, setMappedData] = useState([]);

    const MappedDate = async () => {
        setMappedDataLoading(true);

        try {
            const res = await axiosClient.get('/get-mapped-csv-data');

            if (res.status === 200) {
                setMappedData(res.data);
            } else {
                SetAlert(true, 'failed', 'Something went wrong. Please Refresh Page.');
            }
        } catch (error) {
            SetAlert(true, 'failed', 'Something went wrong. Please Refresh Page.');
        }

        setMappedDataLoading(false);
    }

    const [ftpData, setFtpData] = useState([]);
    const [ftpList, setFtpList] = useState([]);
    const [selectedFTP, setSelectedFTP] = useState("");
    const [openSearch, setOpenSearch] = useState(false);
    const [ftpSearch, setFtpSearch] = useState({ isOpen: false, phrase: "" });
    const [selectedPath, setSelectedPath] = useState("");
    const [ftpListLoading, setFtpListLoading] = useState(false);
    const [csvLoading, setCsvLoading] = useState(false);

    const handleSelectedUser = (user) => {
        setCsvLoading(true);

        const path = ftpData.find((data) => data.user === user).homedir;

        axiosClient.get(`https://uniqueleverage.com/ul-backend/public/api/get-file-list`, {
            params: { ftpPath: path },
        })
            .then((res) => {
                setFtpList(res.data);
                setSelectedPath(res.data[0].fullPath)
            })
            .catch((e) => {
                // SetAlert(true, "failed", "Please try again")
                console.log(e);
            });

        setCsvLoading(false);
    };

    const fetchFTP = () => {
        setFtpListLoading(true)

        axiosClient
            .get("https://uniqueleverage.com/ul-backend/public/api/getFtp")
            .then((res) => setFtpData(res.data.data))
            .catch((e) => SetAlert(true, "failed", "Please refresh the page"));

        setFtpListLoading(false)
    };

    // const [csvData, setCsvData] = useState([]);
    const [csvDataHeader, setCsvDataHeader] = useState([]);
    const [loadingCSV, setLoadingCSV] = useState(false);

    const fetchList = async (path) => {
        setLoadingCSV(true);

        try {
            const response = await axiosClient.get(`https://uniqueleverage.com/ul-backend/public/api/get-csv-list`, {
                params: { path: path },
            });

            setCsvDataHeader(response.data[0] || []);
            // setCsvData(response.data || []);
        } catch (error) {
            SetAlert(true, 'failed', 'Something went wrong. Please try again.');
            // console.error(error);
        }

        setLoadingCSV(false);
    };

    useEffect(() => {
        MappedDate();
        fetchFTP();
    }, [])

    const [selectedDataMapping, setSelectedDataMapping] = useState('');
    const handleSelectedDataMapping = async (e) => {
        const targetPath = e.target.value;
        setSelectedDataMapping(targetPath);
        setSelectedFTP("")
        setSelectedPath("")
        setFtpList("")
        setCsvDataHeader([]);
        setFormFields([]);
        setFormData(initialFormData);

        if (targetPath === "") return;

        setLoadingCSV(true);

        try {
            const ftpAccount = await mappedData.find((data) => data.ftp_file_path === targetPath).ftp_account;

            const response = await axiosClient.get(`https://uniqueleverage.com/ul-backend/public/api/get-csv-list`, {
                params: { path: targetPath },
            });

            await axiosClient.get(`get-csv-row/${ftpAccount}`)
                .then((res) => {
                    setFtpSearch(res.data.ftp_account);
                    setSelectedFTP(res.data.ftp_account);
                    setSelectedPath(res.data.ftp_file_path);
                    setFormData({
                        vin: res.data.vin || "",
                        msrp: res.data.msrp || "",
                        year: res.data.year || "",
                        make: res.data.make || "",
                        model: res.data.model || "",
                        trim: res.data.trim || "",
                        engine: res.data.engine || "",
                        mileage: res.data.mileage || "",
                        price: res.data.price || "",
                        exteriorcolor: res.data.exteriorcolor || "",
                        interiorcolor: res.data.interiorcolor || "",
                        transmission: res.data.transmission || "",
                        images: res.data.images || "",
                        description: res.data.description || "",
                        fueltype: res.data.fueltype || "",
                        bodystyle: res.data.bodystyle || "",
                        condition: res.data.condition || "",
                        additional_column: res.data.additional_column || "",
                    })

                    try {
                        const additionalColumnJSON = JSON.parse(res.data.additional_column);
                        setFormFields(additionalColumnJSON);
                    } catch (error) {
                        setFormFields([]);
                    }
                })
                .catch((e) => { SetAlert(true, "failed", "Something went wrong. Please try again") });

            setCsvDataHeader(response.data[0] || []);
        } catch (error) {
            setCsvDataHeader([]);
            SetAlert(true, 'failed', 'File not found. Please try again.');
            // console.error(error);
        }

        setLoadingCSV(false);
    };

    const initialFormData = {
        vin: '',
        msrp: '',
        year: '',
        make: '',
        model: '',
        trim: '',
        engine: '',
        mileage: '',
        price: '',
        exteriorcolor: '',
        interiorcolor: '',
        transmission: '',
        images: '',
        description: '',
        fueltype: '',
        bodystyle: '',
        condition: '',
    };

    const [formData, setFormData] = useState(initialFormData);
    const [formFields, setFormFields] = useState([]);

    const handleFormInput = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }

    const handleInputChange = (id, e) => {
        const { name, value } = e.target;
        const updatedFields = formFields.map((field) =>
            field.id === id ? { ...field, [name]: value } : field
        );
        setFormFields(updatedFields);
    };

    const handleSelectChange = (id, e) => {
        const { value } = e.target;
        const updatedFields = formFields.map((field) =>
            field.id === id ? { ...field, selectedOption: value } : field
        );
        setFormFields(updatedFields);
    };

    const handleAddField = () => {
        const lastField = formFields[formFields.length - 1];
        const newId = lastField ? lastField.id + 1 : 1;
        const newFieldName = `name-${newId}`;
        setFormFields([...formFields, { id: newId, [newFieldName]: '', selectedOption: '' }]);
    };

    const handleRemoveField = (id) => {
        const filteredFields = formFields.filter((field) => field.id !== id);
        setFormFields(filteredFields);
    };

    const [mappingLoading, setMappingLoading] = useState(false);

    const submitMapping = async (e) => {
        setMappingLoading(true);

        if (Object.values(formData).includes('') === 0) {
            SetAlert(true, 'failed', 'Please fill all fields.');
            setMappingLoading(false);
            return;
        }

        // at least one field is required
        // if (formFields.length === 0) {
        //     SetAlert(true, 'failed', 'Please fill some field.');
        //     setMappingLoading(false);
        //     return;
        // }

        const payload = {
            ftp: selectedFTP,
            selectedPath: selectedPath,
            ...formData,
            additional_column: JSON.stringify(formFields)
        };

        try {
            const res = await axiosClient.post('/v2/save-heading', payload);

            // console.log(res);

            if (res.status === 200) {
                SetAlert(true, 'success', 'Successfully Submitted.');
                setFormData(initialFormData);
                setFormFields([]);
                setCsvDataHeader([]);

                MappedDate();
            }
            else {
                SetAlert(true, 'failed', 'Something went wrong. Please Try Again.');
            }
        } catch (error) {
            SetAlert(true, 'failed', 'Something went wrong. Please Try Again.');
        }

        setMappingLoading(false);
    }

    const updateMapping = async (e) => {
        setMappingLoading(true);

        if (Object.values(formData).includes('') === 0) {
            SetAlert(true, 'failed', 'Please fill all fields.');
            setMappingLoading(false);
            return;
        }

        const payload = {
            ftp: selectedFTP,
            selectedPath: selectedPath,
            ...formData,
            additional_column: JSON.stringify(formFields)
        };

        try {
            const res = await axiosClient.post('/v2/update-heading', payload);

            // console.log(res);

            if (res.status === 200) {
                SetAlert(true, 'success', 'Successfully Updated.');
                setFormData(initialFormData);
                setFormFields([]);
                setCsvDataHeader([]);
            }
            else {
                SetAlert(true, 'failed', 'Something went wrong. Please Try Again.');
            }
        } catch (error) {
            SetAlert(true, 'failed', 'Something went wrong. Please Try Again.');
        }

        setMappingLoading(false);
    }

    return (
        <FtpContext.Provider value={{
            mappedDataLoading,
            setMappedDataLoading,
            mappedData,

            setFormData,
            formData,
            handleFormInput,
            submitMapping,

            ftpListLoading,
            ftpData,
            ftpList,
            selectedFTP,
            openSearch,
            ftpSearch,
            setOpenSearch,
            setFtpSearch,
            setFtpList,
            setSelectedFTP,
            handleSelectedUser,
            selectedPath,
            setSelectedPath,
            csvLoading,

            fetchList,
            loadingCSV,
            csvDataHeader,

            handleSelectedDataMapping,
            selectedDataMapping,

            formFields,
            setFormFields,
            handleInputChange,
            handleSelectChange,
            handleRemoveField,
            handleAddField,

            mappingLoading,
            updateMapping,
        }}>
            {children}
        </FtpContext.Provider>
    );
};

export const useFtpContext = () => {
    return useContext(FtpContext);
};