import React, { useState, useEffect } from 'react'
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid';
import axiosClient from "../../axios.client";
import { useStateContext } from "../../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import NotificationMsg from "../../components/Alerts/NotificationMsg";
import { UniqueLeverageLogo } from '../../components/icons/UniqueLeverageLogo';
import BgImage from '../../assets/images/login-bg-screen.jpeg';

const Login = () => {
  const navigate = useNavigate();
  const { token, setUser, setToken } = useStateContext();

  const [notification, setNotification] = useState({
    isOpen: false,
    status: "",
    msg: "",
  });

  const [loading, setLoading] = useState(false);

  const SetAlert = (bool, status, msg) => {
    setNotification({ isOpen: bool, status: status, msg: msg });

    setTimeout(() => {
      setNotification({ isOpen: false, status: "", msg: "" });
    }, 5000);
  };

  const [form, setForm] = useState({
    username: "",
    password: "",
  });
  const [errMessage, setErrMessage] = useState("");


  const handleInput = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    setErrMessage("");
    try {
      axiosClient
        .post("/login", form)
        .then((res) => {
          setUser(res.data.user);
          setToken(res.data.token);
          setErrMessage(res.data.message);
        })
        .catch((err) => {
          const response = err.response;
          if (response && response.status === 422) {
            setErrMessage(response.data.message);
          }
        })
        .finally(() => setLoading(false));
    } catch (e) {
      SetAlert(true, "failed", "Something went wrong");
    }

    setForm({
      username: "",
      password: "",
    });
  };

  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (token) {
      return navigate("/");
    }
  }, [token, navigate]);

  return (
    <>
      {/* Notification Msg Start */}
      <NotificationMsg
        toggle={notification}
        Toggler={setNotification}
        message={notification.msg}
      ></NotificationMsg>
      {/* Notification Msg End */}

      <div className='relative'>
        <div className='bg-[#1d77e4] absolute h-[50%] w-full top-0 left-0 -z-50'>
          <img src={BgImage} alt="Bg-Image" />
        </div>
        <div className='flex justify-center items-center h-screen flex-col gap-5'>
          <h3 className='text-[#1B74E4] p-2 px-3 font-semibold bg-[#FFFFFFB2] rounded-md flex items-center gap-2'>
            <UniqueLeverageLogo />

            Unique Leverage</h3>
          <h1 className='font-semibold text-4xl text-[#FFFFFF]'>Sign in to your account!</h1>
          <div className='p-10 rounded-md border border-gray-100 shadow-lg min-w-[550px] bg-white'>
            <form className='flex flex-col gap-8' onSubmit={handleSubmit}>
              <div className='flex flex-col gap-2'>
                <label htmlFor="username">Username</label>
                <input type="name"
                  onChange={(e) => handleInput(e)}
                  value={form.username}
                  autoComplete="name"
                  required
                  name="username" id="username" placeholder='username' className='border border-[#D9DCE1] p-3 px-4 rounded-md w-full outline-none focus:border-blue-200 focus:shadow-sm shadow-blue-200 duration-300' />
                {errMessage && (
                  <div className="mb-3">
                    <p className="text-red-600">{errMessage}</p>
                  </div>
                )}
              </div>

              <div className='flex flex-col gap-2'>
                <div className='flex justify-between items-center'>
                  <label htmlFor="password">Password</label>
                  <span className='text-[#1B74E4] hover:cursor-pointer'>Forgot your password?</span>
                </div>
                <div className="relative flex items-center">
                  <input type={showPassword ? 'text' : 'password'}
                    onChange={(e) => handleInput(e)}
                    value={form.password}
                    required
                    name="password" id="password" placeholder='************' className='border border-[#D9DCE1] p-3 px-4 rounded-md w-full outline-none focus:border-blue-200 focus:shadow-sm shadow-blue-200 duration-300' />
                  <span onClick={handlePasswordVisibility} className="absolute right-0 top-1 mt-3 mr-4">
                    {showPassword ? <EyeOffIcon className="h-5 w-5 text-[#A3ACB9] cursor-pointer" /> : <EyeIcon className="h-5 w-5 text-[#A3ACB9] cursor-pointer" />}
                  </span>
                </div>
              </div>

              <div className='flex items-center gap-2'>
                <input type="checkbox" name="remember" id="remember" className="scale-125" />
                <label htmlFor="remember">Remember me</label>
              </div>

              <div>
                {loading ? (
                  <button
                    type="button"
                    className="flex w-full justify-center rounded-md bg-buttonPrimary px-4 py-2 text-[16px] font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  >
                    <div className="flex justify-center items-center gap-2 p-4">
                      <div className="w-2 h-2 rounded-full animate-pulse bg-white"></div>
                      <div className="w-2 h-2 rounded-full animate-pulse bg-white"></div>
                      <div className="w-2 h-2 rounded-full animate-pulse bg-white"></div>
                    </div>
                  </button>
                ) : (
                  <button className='bg-buttonPrimary w-full text-white p-4 rounded-md '>Continue</button>
                )}
              </div>

              <div className='text-center'>
                <p className='text-[#4F566B]'>Don’t have an account?
                  <span className='text-[#1B74E4] hover:cursor-pointer' onClick={() => { navigate('/signup') }}> Sign up</span>
                </p>
              </div>

              <div className='text-[#4F566B] flex items-center justify-center'>
                &copy; Unique Leverage&nbsp;&nbsp;.&nbsp;&nbsp;
                <button onClick={() => {/* handle contact click */ }}>Contact</button> &nbsp;&nbsp;.&nbsp;&nbsp;
                <button onClick={() => {/* handle privacy click */ }}>Privacy & Terms</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login