import { useTeamsContext } from 'Context/TeamsContext/TeamsContext'
import { DownArrowToggleIcon } from 'components/icons/DownArrowToggleIcon'
import InfoIcon from 'components/icons/InfoIcon'
import NIcon from 'components/icons/NIcon'
import PlusIcon from 'components/icons/PlusIcon'
import TeamRow from './component/TeamRow'

const Teams = () => {
    const { loading, team, setAddTeamToggle } = useTeamsContext();

    return (
        <div>
            <div className='flex items-center justify-between border-b pb-5'>
                <div className='flex gap-4'>
                    <div className='uppercase text-white font-bold text-2xl text-center bg-[#1C7ED6] rounded-lg p-5 px-7'>U</div>

                    <div className=''>
                        <h1 className='text-[28px] font-bold leading-9 font-roboto'>Teams
                        </h1>
                        <p className='flex items-center gap-2 text-[#555555]'>
                            Unique Leverage
                            <span className='p-[1.5px] bg-[#999999] rounded-full'></span>
                            <span>{team.length} member</span>
                            <span className='p-[1.5px] bg-[#999999] rounded-full'></span>
                            <span>You are an <span className='font-bold'> Editor</span></span>
                            <span className='font-bold'>(Owner)</span>
                            <InfoIcon />
                        </p>
                    </div>
                </div>
                <div className='flex items-center gap-5'>
                    <button className="bg-buttonPrimary pt-[5px] pr-[8px] pb-[5px] pl-[7px] rounded-lg shadow-md items-center text-white text-[14px] flex item-center gap-[8px]"
                        onClick={() => { setAddTeamToggle(true) }}>
                        <span className='flex items-center gap-[2px]'> <PlusIcon /> Add Member</span>
                        <NIcon />
                    </button>
                </div>
            </div>

            <div className='border-t pt-4 min-h-[150px] overflow-hidden pb-24'>

                {loading ?
                    <div className="flex justify-center items-center py-56">
                        <div className="w-12 h-12 border-t-4 border-buttonPrimary border-solid border-opacity-80 rounded-full animate-spin"></div>
                    </div>
                    :
                    <table className='w-[100%]'>
                        <thead>
                            <tr className='border-b'>
                                <th className='font-semibold text-sm text-left pb-3'> Name</th>

                                <th className='font-semibold text-sm text-left pb-3'>Username</th>

                                <th className='font-semibold text-sm text-left pb-3'> <span className='flex items-center gap-2'> Status <DownArrowToggleIcon /> </span></th>

                                <th className='font-semibold text-sm text-left pb-3'>Date</th>

                                <th className='font-semibold text-sm text-left pb-3'> <span className='flex items-center gap-2'> Roles <DownArrowToggleIcon /> </span></th>

                                <th className='font-semibold text-sm text-left pb-3'></th>

                                <th className='font-semibold text-sm text-left pb-3'></th>
                            </tr>
                        </thead>

                        <tbody className='max-w-[100%]'>
                            {team.map((item, index) => {
                                return (
                                    <TeamRow key={index} item={item} />
                                )
                            })}
                        </tbody>
                    </table>
                }
            </div>
        </div>
    )
}

export default Teams