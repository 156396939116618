import React, { useState, useEffect, useRef } from 'react';
import SmallLoading from 'components/Loadings/SmallLoading';
import FilePinIcon from 'components/icons/FilePinIcon';

const SelectFilePath = ({ csvLoading, ftpList, selectedPath, setSelectedPath }) => {
    const [isOpen, setIsOpen] = useState(false);
    const containerRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const handleSelectPath = (path) => {
        setSelectedPath(path);
        setIsOpen(false);
    };

    useEffect(() => {
        if (ftpList.length > 0 && !selectedPath) {
            handleSelectPath(ftpList[0].fullPath);
        }
    }, [ftpList]);

    return (
        <div ref={containerRef} className='relative'>
            <div
                className='p-2 px-2 border border-[#E2E4E5] rounded-sm w-[294px] 2xl:w-[344px] 5xl:w-[474px] 6xl:w-[474px] outline-none hover:border-[#10111a7e] duration-200 cursor-pointer text-[#687385] text-sm flex items-center justify-between gap-5'
                onClick={() => setIsOpen(!isOpen)}
            >
                {csvLoading ? (
                    <SmallLoading />
                ) : (
                    <>
                        {selectedPath ? (
                            <span className='w-[294px] 2xl:w-[344px] 5xl:w-[474px] 6xl:w-[474px] overflow-auto 2xl:pb-1 text-sm'>{selectedPath}</span>
                        ) : (
                            <>
                                <span className='font-medium'>Choose File</span>
                                <span className='flex items-center gap-2'>
                                    <FilePinIcon />
                                    <span>./root/</span>
                                </span>
                            </>
                        )}
                    </>
                )}
            </div>
            {isOpen && (
                <ul className='absolute z-10 mt-1 w-[294px] 2xl:w-[344px] 5xl:w-[474px] 6xl:w-[474px] bg-white border border-gray-300 shadow-lg rounded-md text-sm'>
                    {ftpList.length > 0 && ftpList.map((ftp, index) => (
                        <li
                            key={index}
                            className={
                                selectedPath === ftp.fullPath
                                    ? "bg-[#1b75e413] cursor-pointer p-1 border-b whitespace-normal text-sm"
                                    : "hover:bg-[#1b75e413] cursor-pointer p-1 border-b whitespace-normal text-sm"
                            }
                            onClick={() => handleSelectPath(ftp.fullPath)}
                        >
                            {ftp.fileName}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default SelectFilePath;
