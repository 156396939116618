import axios from "axios";
import axiosClient from "axios.client";
import { createContext, useContext, useState } from "react";
import { fetchCars, BulkMoveToListed, BulkMoveToActive, BulkMoveToPending } from './index'

export const CustomerDetailContext = createContext();

export const CustomerDetailContextProvider = ({ children }) => {
    const [selectedPage, setSelectedPage] = useState("overview");

    const [usersList, setUsersList] = useState([]);
    const [customerDetail, setCustomerDetail] = useState({
        id: "",
        name: "",
        website: "",
        state: "",
        city: "",
        phone: "",
        first_name: "",
        last_name: "",
        email: "",
        mobile: "",
        formPackage: "",
        user_count: "",
        amount: "",
        inventory_source: "",
        vehicles: "",
        fb_ads: "",
        fb_ads_budget: "",
        FTPaccount: "",
    });
    const [loading, setLoading] = useState(false);

    const fetchUsersList = async (customerID) => {
        try {
            setLoading(true);

            const [usersListResponse, ftpData] = await Promise.all([
                axiosClient.post("/getUsersList", { id: customerID }),
                axiosClient.get("https://uniqueleverage.com/ul-backend/public/api/getFtp"),
            ]);

            setFtpData(ftpData.data.data);
            const usersListData = usersListResponse.data.data;

            setUsersList(usersListData);
            setCustomerDetail({
                ...customerDetail,
                ...usersListData[0],
                id: usersListData[0].id,
                name: usersListData[0].name,
                website: usersListData[0].website,
                state: usersListData[0].address.split(",")[1] || "",
                city: usersListData[0].address.split(",")[0] || "",
                phone: usersListData[0].phone,
                first_name: usersListData[0].first_name || "",
                last_name: usersListData[0].last_name || "",
                email: usersListData[0].email,
                mobile: usersListData[0].mobile,
                formPackage: usersListData[0].package || "",
                user_count: usersListData[0].user_count || "",
                amount: usersListData[0].amount || "",
                fb_ads: usersListData[0].fb_ads,
                fb_ads_budget: usersListData[0].fb_ads_budget || "",
                inventory_source: usersListData[0].inventory_source || "",
                vehicles: 0,
                FTPaccount: usersListData[0].FTPaccount || "",
            });

        } catch (error) {
            const errorMessage = error.message === "timeout exceeded" ? "Timeout exceeded. Please refresh." : "Failed to get data. Please refresh.";
            SetAlert(true, "failed", errorMessage);

        } finally {
            setLoading(false);
        }

        fetchVehicleCount();
    };

    const [userDetailLoading, setUserDetailLoading] = useState(false);
    const [userDetail, setUserDetail] = useState([]);
    const fetchUserDetail = async (id) => {
        setUserDetailLoading(true)
        try {
            const [usersDetailResponse] = await Promise.all([
                axiosClient.post(`/getUsersDetail/${id}`),
            ]);

            setUserDetail(usersDetailResponse.data.data);
            fetchManageVehicleData(usersDetailResponse.data.data.id);
            handleSelectedUser(usersDetailResponse.data.data.assignedFtpAccounts);
        } catch (error) {
            const errorMessage = error.message === "timeout exceeded" ? "Timeout exceeded. Please refresh." : "Failed to get data. Please refresh.";
            SetAlert(true, "failed", errorMessage);
        }
        setUserDetailLoading(false)
    }

    const [isLoading, setIsLoading] = useState(false);
    const [vehicleData, setVehicleData] = useState([]);
    const fetchVehicleCount = async () => {
        setIsLoading(true);

        try {
            axios.get("https://uniqueleverage.com/ul-backend/public/api/get-car-count")
                .then((res) => {

                    setVehicleData({
                        listed: res.data.listed,
                        advertised: res.data.active,
                        pending: res.data.pending,
                    });

                    setIsLoading(false)
                });

        } catch (e) {
            console.error(e);
            SetAlert(true, "failed", "Failed to Fetch Vehicle Count");
        }

    }

    const [notification, setNotification] = useState({
        isOpen: false,
        status: "",
        msg: "",
    });

    const SetAlert = (bool, status, msg) => {
        setNotification({ isOpen: bool, status: status, msg: msg });

        setTimeout(() => {
            setNotification({ isOpen: false, status: "", msg: "" });
        }, 5000);
    };

    const [addUserModel, setAddUserModel] = useState(false); // All User Page Add Btn Toggler

    // FTP section
    const [ftpData, setFtpData] = useState([]); // ftp data list
    const [ftpList, setFtpList] = useState(""); // ftp select user name paths

    const handleSelectedUser = (user) => {
        if (user !== null) {
            const path = ftpData?.find((data) => data.user === user || "").homedir || "";

            axios.get(`https://uniqueleverage.com/ul-backend/public/api/get-file-list`, {
                params: { ftpPath: path },
            })
                .then((res) => {
                    setFtpList(res.data[0]);
                })
                .catch((e) => SetAlert(true, "failed", "Failed to fetch ftp path"));
        } else {
            setFtpList("");
        }
    };

    // Manage Vehicle Section
    const [manageVehicleData, setManageVehicleData] = useState([]);
    const [manageVehicleLoading, setManageVehicleLoading] = useState(false);

    const fetchManageVehicleData = async (vehicleId) => {
        try {
            setManageVehicleLoading(true);

            const [manageVehicleResponse] = await Promise.all([
                fetchCars(vehicleId),
            ]);

            setManageVehicleData(manageVehicleResponse);
        } catch (error) {
            console.log(error);
        } finally {
            setManageVehicleLoading(false);
        }
    }

    const [type, setType] = useState('listed');

    const [listedCheckedValues, setListedCheckedValues] = useState([]); // Listed Page Checkbox Toggler
    const [activeCheckedValues, setActiveCheckedValues] = useState([]); // Active Page Checkbox Toggler
    const [pendingCheckedValues, setPendingCheckedValues] = useState([]); // Pending Page Checkbox Toggler
    const [cruiseCheckedValues, setCruiseCheckedValues] = useState([]); // Cruise Page Checkbox Toggler

    const [vehicleDetailData, setVehicleDetailData] = useState([]);
    const [vehicleImages, setVehicleImages] = useState([]);
    const handleSelectedCar = (ID, status) => {

        let selectedCarItem = "";
        if (status === "listed")
            selectedCarItem = manageVehicleData.listed.find((item) => item.ID === ID);

        if (status === "pending")
            selectedCarItem = manageVehicleData.pending.find((item) => item.ID === ID);

        if (status === "active")
            selectedCarItem = manageVehicleData.advertised.find((item) => item.ID === ID);

        if (status === "cruise")
            selectedCarItem = manageVehicleData.cruise.find((item) => item.ID === ID);

        if (selectedCarItem) {
            setVehicleDetailData(selectedCarItem);
            setVehicleImages(selectedCarItem.images.split(',')[0]);
        }
    }

    // search vehicle
    const [vehicleSearch, setVehicleSearch] = useState("");

    // edit customer detail toggle
    const [editCustomerDetail, setEditCustomerDetail] = useState({ customer: [], isOpen: false });

    // customer detail meta data
    const [openEditMetaModal, setOpenEditMetaModal] = useState(false);

    // handle move to listed
    const handleMoveToListed = () => {
        if (type === "pending") {
            let data = manageVehicleData.pending.filter((item) =>
                pendingCheckedValues.includes(item.ID.toString())
            );

            const remaining = manageVehicleData.pending.filter(
                (item) => !pendingCheckedValues.includes(item.ID.toString())
            );

            data = data.map((item) => ({ ...item, status: "listed" }));

            setManageVehicleData((prevmanageVehicleData) => ({
                ...prevmanageVehicleData,
                listed: [...data, ...prevmanageVehicleData.listed],
                pending: remaining,
            }));

            axiosClient
                .post("/pending-bulk-move-to-listed", {
                    list: pendingCheckedValues,
                })
                .then((res) => console.log(res))
                .catch((e) => console.log(e));

            setPendingCheckedValues([]);
            SetAlert(true, "success", "Row Moved To Listed");
            return;
        }

        if (type === "advertised") {
            let data = manageVehicleData.advertised.filter((item) =>
                activeCheckedValues.includes(item.ID.toString())
            );

            const remaining = manageVehicleData.advertised.filter(
                (item) => !activeCheckedValues.includes(item.ID.toString())
            );

            data = data.map((item) => ({ ...item, status: "listed" }));

            setManageVehicleData((prevmanageVehicleData) => ({
                ...prevmanageVehicleData,
                advertised: remaining,
                listed: [...data, ...prevmanageVehicleData.listed],
            }));

            axiosClient
                .post("/advertised-bulk-move-to-listed", {
                    list: activeCheckedValues,
                })
                .then((res) => console.log(res))
                .catch((e) => console.log(e));

            setActiveCheckedValues([]);
            SetAlert(true, "success", "Row Moved To Listed");
            return;
        }
    }

    // handle move to active
    const handleMoveToActive = () => {

        if (type === "listed") {
            let data = manageVehicleData.listed.filter((item) =>
                listedCheckedValues.includes(item.ID.toString())
            );

            let remaining = manageVehicleData.listed.filter(
                (item) => !listedCheckedValues.includes(item.ID.toString())
            );

            data = data.map((item) => ({ ...item, status: "advertised" }));

            setManageVehicleData((prevmanageVehicleData) => ({
                ...prevmanageVehicleData,
                advertised: [...data, ...prevmanageVehicleData.advertised],
                listed: remaining,
            }));

            axiosClient
                .post("/vehicle-bulk-move-to-advertised", { list: listedCheckedValues })
                .then((res) => console.log(res))
                .catch((e) => console.log(e));

            setListedCheckedValues([]);
            SetAlert(true, "success", "Row Moved To Active");
            return;
        }

        if (type === "pending") {
            let data = manageVehicleData.pending.filter((item) =>
                pendingCheckedValues.includes(item.ID.toString())
            );

            const remaining = manageVehicleData.pending.filter(
                (item) => !pendingCheckedValues.includes(item.ID.toString())
            );

            data = data.map((item) => ({ ...item, status: "advertised" }));

            setManageVehicleData((prevmanageVehicleData) => ({
                ...prevmanageVehicleData,
                advertised: [...data, ...prevmanageVehicleData.advertised],
                pending: remaining,
            }));

            axiosClient
                .post("/pending-bulk-move-to-advertised", {
                    list: pendingCheckedValues,
                })
                .then((res) => console.log(res))
                .catch((e) => console.log(e));

            setPendingCheckedValues([]);
            SetAlert(true, "success", "Row Moved To advertised");
            return;
        }
    }

    // handle move to pending
    const handleMoveToPending = () => {
        if (type === "listed") {
            let data = manageVehicleData.listed.filter((item) =>
                listedCheckedValues.includes(item.ID.toString())
            );

            const remaining = manageVehicleData.listed.filter(
                (item) => !listedCheckedValues.includes(item.ID.toString())
            );

            data = data.map((item) => ({ ...item, status: "pending" }));

            setManageVehicleData((prevmanageVehicleData) => ({
                ...prevmanageVehicleData,
                pending: [...data, ...prevmanageVehicleData.pending],
                listed: remaining,
            }));

            axiosClient
                .post("/vehicle-bulk-move-to-pending", {
                    list: listedCheckedValues,
                })
                .then((res) => console.log(res))
                .catch((e) => console.log(e));

            setListedCheckedValues([]);
            SetAlert(true, "success", "Row Moved To Pending");
            return;
        }

        if (type === "advertised") {
            let data = manageVehicleData.advertised.filter((item) =>
                activeCheckedValues.includes(item.ID.toString())
            );

            const remaining = manageVehicleData.advertised.filter(
                (item) => !activeCheckedValues.includes(item.ID.toString())
            );

            data = data.map((item) => ({ ...item, status: "pending" }));

            setManageVehicleData((prevmanageVehicleData) => ({
                ...prevmanageVehicleData,
                advertised: remaining,
                pending: [...data, ...prevmanageVehicleData.pending],
            }));

            axiosClient
                .post("/advertised-bulk-move-to-pending", {
                    list: activeCheckedValues,
                })
                .then((res) => console.log(res))
                .catch((e) => console.log(e));

            setActiveCheckedValues([]);
            SetAlert(true, "success", "Row Moved To Pending");
            return;
        }
    }

    return (
        <CustomerDetailContext.Provider value={{
            selectedPage,
            setSelectedPage,
            loading,
            usersList,
            isLoading,
            vehicleData,
            notification,
            setNotification,
            SetAlert,
            fetchUsersList,
            customerDetail,
            setUsersList,
            addUserModel,
            setAddUserModel,
            handleSelectedUser,
            ftpData,
            ftpList,
            manageVehicleLoading,
            fetchManageVehicleData,

            manageVehicleData,

            type,
            setType,

            listedCheckedValues,
            setListedCheckedValues,

            activeCheckedValues,
            setActiveCheckedValues,

            pendingCheckedValues,
            setPendingCheckedValues,

            cruiseCheckedValues,
            setCruiseCheckedValues,

            handleSelectedCar,
            setVehicleDetailData,
            vehicleDetailData,
            setVehicleImages,
            vehicleImages,

            setVehicleSearch,
            vehicleSearch,

            editCustomerDetail,
            setEditCustomerDetail,

            userDetailLoading,
            fetchUserDetail,
            userDetail,

            openEditMetaModal,
            setOpenEditMetaModal,

            handleMoveToListed,
            handleMoveToActive,
            handleMoveToPending,
        }}>
            {children}
        </CustomerDetailContext.Provider>
    );
};

export const useCustomerDetailContext = () => {
    return useContext(CustomerDetailContext);
};