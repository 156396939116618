import { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

export const PaymentContext = createContext();

export const PaymentContextProvider = ({ children }) => {
  const [paymentStatus, setPaymentStatus] = useState(false);

  return (
    <PaymentContext.Provider value={{ paymentStatus, setPaymentStatus }}>
      {children}
    </PaymentContext.Provider>
  );
};

export const usePaymentContext = () => {
  return useContext(PaymentContext);
};

PaymentContextProvider.propTypes = {
  children: PropTypes.node,
};
