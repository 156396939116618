import SmallLoading from "components/Loadings/SmallLoading";
import CursorDragIcon from "components/icons/CursorDragIcon";
import { DragIcon } from "components/icons/DragIcon";
import { PlusDashIcon } from "components/icons/PlusDashIcon"
import PlusIcon from "components/icons/PlusIcon";
import { RightMarkToggleIcon } from "components/icons/RightMarkToggleIcon";
import { SettingIcon } from "components/icons/SettingIcon";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";


const SecondaryFilters = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [moreFilterIsOpen, setMoreFilterIsOpen] = useState(false);
    const [editColumnIsOpen, setEditColumnIsOpen] = useState(false);
    const moreFilterRef = useRef(null);
    const editColumnRef = useRef(null);
    const moveUserRef = useRef(null)


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (moreFilterRef.current && !moreFilterRef.current.contains(event.target)) {
                setMoreFilterIsOpen(false);
            }

            if (editColumnRef.current && !editColumnRef.current.contains(event.target)) {
                setEditColumnIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const redirectStatus = queryParams.get("mode");
        // modes :
        // all, support, canceled, onboarding, package all_in-basic-pro

    }, [location.search]);

    return (
        <div className='flex items-center justify-between pt-[24px] pb-[20px]'>
            <div className='flex items-center gap-3 flex-wrap'>
                <button className='flex items-center gap-2 py-[1px] px-2 text-[14px] text-[#687385] font-semibold border border-dashed hover:border-[#10111a7e] border-[#C0C8D2] rounded-full hover:bg-slate-50 duration-300'> <PlusDashIcon /> Email</button>

                <button className='flex items-center gap-2 py-[1px] px-2 text-[14px] text-[#687385] font-semibold border border-dashed hover:border-[#10111a7e] border-[#C0C8D2] rounded-full hover:bg-slate-50 duration-300'> <PlusDashIcon /> Card</button>

                <div className='relative' ref={moreFilterRef}>
                    <button onClick={() => { }} className={`flex items-center gap-2 py-[1px] px-2 text-[14px] text-[#687385] font-semibold border border-dashed hover:border-[#10111a7e] rounded-full hover:bg-slate-50 duration-300 ${moreFilterIsOpen ? "border-[#10111a7e] bg-slate-50" : "border-[#C0C8D2]"}`}> <PlusDashIcon />Created date</button>
                    {moreFilterIsOpen && <>
                        {/* <div className='triangle absolute left-5 top-[27px] border border-gray-100 rounded-md shadow-xl z-20'></div> */}
                        <div className='rotate-[270deg] absolute left-5 top-[20px] z-30'>
                            <RightMarkToggleIcon color="#e7e7e761" width={32} height={32} />
                        </div>
                        <div className='absolute top-10 left-0 w-72 bg-white border border-gray-100 rounded-lg shadow-xl p-3 z-20'>
                            <div className='flex flex-col gap-2'>
                                <button className='text-[#1B74E4] text-[14px] font-semibold flex items-center hover:bg-gray-100 rounded-md'><PlusIcon color={'#1B74E4'} /> Discount</button>
                                <button className='text-[#1B74E4] text-[14px] font-semibold flex items-center hover:bg-gray-100 rounded-md'><PlusIcon color={'#1B74E4'} /> Delinquent</button>
                                <button className='text-[#1B74E4] text-[14px] font-semibold flex items-center hover:bg-gray-100 rounded-md'><PlusIcon color={'#1B74E4'} /> Total Spend</button>
                                <button className='text-[#1B74E4] text-[14px] font-semibold flex items-center hover:bg-gray-100 rounded-md'><PlusIcon color={'#1B74E4'} /> Payments</button>
                                <button className='text-[#1B74E4] text-[14px] font-semibold flex items-center hover:bg-gray-100 rounded-md'><PlusIcon color={'#1B74E4'} /> Refund</button>
                                <button className='text-[#1B74E4] text-[14px] font-semibold flex items-center hover:bg-gray-100 rounded-md'><PlusIcon color={'#1B74E4'} /> Dispute Losses</button>
                                <button className='text-[#1B74E4] text-[14px] font-semibold flex items-center hover:bg-gray-100 rounded-md'><PlusIcon color={'#1B74E4'} /> First Payment</button>
                                <button className='text-[#1B74E4] text-[14px] font-semibold flex items-center hover:bg-gray-100 rounded-md'><PlusIcon color={'#1B74E4'} /> Last Payment</button>
                                <button className='text-[#1B74E4] text-[14px] font-semibold flex items-center hover:bg-gray-100 rounded-md'><PlusIcon color={'#1B74E4'} /> In recovery</button>
                            </div>
                        </div>
                    </>}
                </div>

                <button className='flex items-center gap-2 py-[1px] px-2 text-[14px] text-[#687385] font-semibold border border-dashed hover:border-[#10111a7e] border-[#C0C8D2] rounded-full hover:bg-slate-50 duration-300'> <PlusDashIcon /> Type</button>

                <button className='flex items-center gap-2 py-[1px] px-2 text-[14px] text-[#687385] font-semibold border border-dashed hover:border-[#10111a7e] border-[#C0C8D2] rounded-full hover:bg-slate-50 duration-300'> <PlusDashIcon /> More filters </button>

            </div>

            <div className='flex items-center gap-2 text-[14px]'>

                <div className='relative' ref={editColumnRef}>
                    <button className={`border border-[#10111A29] hover:border-[#10111a7e] px-2 py-1 duration-300 rounded-md flex items-center font-medium gap-1`}
                        onClick={() => { }}><SettingIcon /> Edit columns</button>

                    {editColumnIsOpen && <>
                        <div className='rotate-[270deg] absolute right-5 top-[20px] z-30'>
                            <RightMarkToggleIcon color="#e7e7e761" width={32} height={32} />
                        </div>
                        <div className='absolute top-10 right-0 w-72 bg-white border border-gray-100 rounded-lg shadow-xl p-3 z-20'>
                            <div className='flex flex-col gap-2'>
                                <div className='border-b pb-2'>
                                    <h1 className='text-[14px] font-semibold mb-2'>Fixed columns</h1>
                                    <input className='text-[14px] py-1 outline-none' type='name' placeholder='Dealership Name' disabled />
                                </div>
                                <div>
                                    <h1 className='font-semibold text-[16px] mb-2'>Active columns</h1>
                                    <div className='flex flex-col gap-2'>

                                        {/* {
                                            columns.map((column, index) => (
                                                <div key={column.name}>
                                                    <div draggable
                                                        onDragStart={() => (dragColumn.current = index)}
                                                        onDragEnter={() => (draggedOverColumn.current = index)}
                                                        onDragEnd={handleSort}
                                                        onDragOver={(e) => e.preventDefault()} className='flex items-center justify-between'>
                                                        <div className='flex items-center gap-2'>
                                                            <input
                                                                type="checkbox"
                                                                checked={column.active}
                                                                onChange={(e) => { handleCheckboxChange(e, index) }}
                                                                name={column.name}
                                                                id={column.name}
                                                            />

                                                            <label htmlFor={column.name} className='text-gray-400 cursor-pointer'>{column.name}</label>
                                                        </div>
                                                        <div

                                                        >
                                                            <DragIcon />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        } */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </>}

                </div>

                {/* <button className='border border-[#10111A29] hover:border-[#10111a7e] duration-300 px-2 py-1 rounded-md flex items-center font-medium gap-1'><CopyIcon /> Copy</button> */}
            </div>
        </div>
    )
}

export default SecondaryFilters