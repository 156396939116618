import React from "react";

export const AddUserBtn = ({ setAddUserModel }) => {
  return (
    <button
      onClick={() => setAddUserModel(true)}
      type="button"
      className={
        "text-white bg-buttonPrimary hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-[16px] px-5 py-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      }
    >
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 5.5C11.1823 5.5 11.3572 5.57243 11.4861 5.70136C11.6151 5.8303 11.6875 6.00516 11.6875 6.1875V10.3125H15.8125C15.9948 10.3125 16.1697 10.3849 16.2986 10.5139C16.4276 10.6428 16.5 10.8177 16.5 11C16.5 11.1823 16.4276 11.3572 16.2986 11.4861C16.1697 11.6151 15.9948 11.6875 15.8125 11.6875H11.6875V15.8125C11.6875 15.9948 11.6151 16.1697 11.4861 16.2986C11.3572 16.4276 11.1823 16.5 11 16.5C10.8177 16.5 10.6428 16.4276 10.5139 16.2986C10.3849 16.1697 10.3125 15.9948 10.3125 15.8125V11.6875H6.1875C6.00516 11.6875 5.8303 11.6151 5.70136 11.4861C5.57243 11.3572 5.5 11.1823 5.5 11C5.5 10.8177 5.57243 10.6428 5.70136 10.5139C5.8303 10.3849 6.00516 10.3125 6.1875 10.3125H10.3125V6.1875C10.3125 6.00516 10.3849 5.8303 10.5139 5.70136C10.6428 5.57243 10.8177 5.5 11 5.5Z"
          fill="white"
        />
      </svg>
      Add User
    </button>
  );
};

export const SubmitAddUserBtn = ({ setAddUserBtn, userFormSubmit }) => {
  return (
    <button
      type="button"
      onClick={() => {
        setAddUserBtn(false);
        userFormSubmit();
      }}
      className={
        "text-white bg-buttonPrimary hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-[16px] px-5 py-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      }
    >
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 5.5C11.1823 5.5 11.3572 5.57243 11.4861 5.70136C11.6151 5.8303 11.6875 6.00516 11.6875 6.1875V10.3125H15.8125C15.9948 10.3125 16.1697 10.3849 16.2986 10.5139C16.4276 10.6428 16.5 10.8177 16.5 11C16.5 11.1823 16.4276 11.3572 16.2986 11.4861C16.1697 11.6151 15.9948 11.6875 15.8125 11.6875H11.6875V15.8125C11.6875 15.9948 11.6151 16.1697 11.4861 16.2986C11.3572 16.4276 11.1823 16.5 11 16.5C10.8177 16.5 10.6428 16.4276 10.5139 16.2986C10.3849 16.1697 10.3125 15.9948 10.3125 15.8125V11.6875H6.1875C6.00516 11.6875 5.8303 11.6151 5.70136 11.4861C5.57243 11.3572 5.5 11.1823 5.5 11C5.5 10.8177 5.57243 10.6428 5.70136 10.5139C5.8303 10.3849 6.00516 10.3125 6.1875 10.3125H10.3125V6.1875C10.3125 6.00516 10.3849 5.8303 10.5139 5.70136C10.6428 5.57243 10.8177 5.5 11 5.5Z"
          fill="white"
        />
      </svg>
      Submit User
    </button>
  );
};

export const CancelAddUserBtn = ({ setAddUserBtn }) => {
  return (
    <button
      type="button"
      onClick={() => {
        setAddUserBtn(false);
      }}
      className={
        "text-white bg-red-500 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded text-[16px] px-5 py-2.5 text-center inline-flex items-center mr-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
      }
    >
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="#fff"
        xmlns="http://www.w3.org/2000/svg"
        className="mr-2"
      >
        <path
          d="M1.28534 10.7133L10.7147 1.28662M1.28534 1.28662L10.7147 10.7133"
          stroke="#fff"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
      Cancel
    </button>
  );
};
