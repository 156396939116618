import { DownArrowIcon } from 'components/icons/DownArrowIcon'
// import ManageVehicleDetail from 'pages/dealership/customerDetail/overview/customerRendering/manageVehicles/ManageVehicleDetail';
// import ManageVehicleMenu from 'pages/dealership/customerDetail/overview/customerRendering/manageVehicles/ManageVehicleMenu';

import { useEffect, useRef, useState } from 'react'
import ManageVehicleMenu from './components/ManageVehicles/ManageVehicleMenu';
import ManageVehicleDetail from './components/ManageVehicles/ManageVehicleDetail';
import { useNavigate, useParams } from "react-router-dom";
import { useCustomerDetailContext } from 'Context/customerDetailContext/CustomerDetailContext';
import { useCustomerContext } from 'Context/CustomerContext';

const Connect = () => {
    const {
        manageVehicleLoading,
        manageVehicleData
    } = useCustomerDetailContext();
    const navigate = useNavigate();

    const { userList, loading } = useCustomerContext();

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const { dealerShipID } = useParams();

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    }

    const fetchUsersList = async () => { }

    const [user, setUser] = useState([]);

    useEffect(() => {
        fetchUsersList();

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    useEffect(() => {
        const allUser = userList.all.filter((user) => user.name == manageVehicleData?.user?.name);
        setUser(allUser);

    }, [userList, manageVehicleData, dealerShipID]);

    return (

        <div>
            <div className='flex items-start justify-between pb-5'>
                <h1 className='font-bold text-[28px] leading-[32px] text-[#30313D]'>Connect + {manageVehicleData?.user?.username}</h1>

                <div className='flex items-center gap-3'>
                    <h1 className='font-normal text-sm text-[#414552]'>{loading ? "" : user.length > 1 ? user.length + " Users" : user.length + " User"}</h1>
                    <div className='relative cursor-pointer'>
                        <div className={`flex items-center gap-2 justify-between border hover:border-[#10111a7e] duration-300 rounded-lg min-w-[160px] px-3 py-[4px] ${dropdownOpen ? "border-[#10111a7e]" : "border-[#10111A29]"}`}
                            onClick={() => setDropdownOpen(!dropdownOpen)}>
                            <h1 className='text-[#353A44] font-semibold text-sm'>{user.filter(item => item.id == dealerShipID)[0]?.username || "loading..."}</h1>
                            <DownArrowIcon color="#7C8689" />
                            {dropdownOpen && (
                                <div ref={dropdownRef} className='absolute bg-white z-20 top-10 border border-gray-200 rounded-lg shadow-md p-2 w-full max-w-[200px] max-h-[200px] overflow-y-auto left-0'>
                                    <div className='py-1'>
                                        <ul>
                                            {
                                                user.map((item, index) => (
                                                    <li
                                                        key={index}
                                                        className={`py-[2px] text-[16px] ${item.id == dealerShipID ? 'text-buttonPrimary' : 'text-gray-600'} hover:text-buttonPrimary`}
                                                        onClick={() => { navigate(`/connect/${item.id}`) }}
                                                    >
                                                        {item.username}
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex items-center justify-between border-b border-borderPrimary pt-2 text-[14px]">
                <div className='flex items-center gap-8 '>
                    <button className='font-semibold text-[#0C71EB] border-b-2 border-[#1B74E4] pb-[11px]' onClick={() => { }}>
                        Remote
                    </button>

                    <button className='font-semibold text-[#596171] pb-[11px]' onClick={() => { }}>
                        Source
                    </button>

                    <button className='font-semibold text-[#596171] pb-[11px]' onClick={() => { }}>
                        Mapped
                    </button>
                </div>
            </div>

            <div className='py-5 flex rounded-md'>

                <ManageVehicleMenu />

                <ManageVehicleDetail />

            </div>
        </div>
    )
}

export default Connect