import React from "react";
import { EmailIcon } from "../../../../components/icons/EmailIcon";
import { PhoneIcon } from "../../../../components/icons/PhoneIcon";
import { BoxIcon } from "../../../../components/icons/BoxIcon";
import { UsersIcon } from "../../../../components/icons/UsersIcon";
import { DollarSignIcon } from "../../../../components/icons/DollarSignIcon";
import { DealerCenterIcon } from "../../../../components/icons/DealerCenterIcon";
import { CarIcon } from "../../../../components/icons/CarIcon";
import { TwoCircleCursorIcon } from "../../../../components/icons/TwoCircleCursorIcon";
import { FTPAccountIcon } from "../../../../components/icons/FTPAccountIcon";
import { EditIcon } from "../../../../components/icons/EditIcon";
import PlusIcon from "../../../../components/icons/PlusIcon";

const DealerShipHeader = ({
  editBtn,
  setEditBtn,
  setAddUserModel,
  paymentBtnLoading,
  totalUser,
  setDeleteModelToggle,
  dealerShipForm,
  handleInputChange,
  formSubmit,
  paymentFunction,
  isLoading,
  vehicleData,
}) => {
  return (
    <div>
      <form
        className="hidden md:flex justify-between items-center my-[15px]"
        onSubmit={formSubmit}
      >
        <div className="flex flex-col gap-5">
          <h2 className={editBtn ? "hidden" : "text-3xl font-bold"}>
            {dealerShipForm.name}
          </h2>
          <input
            type="text"
            name="name"
            required
            value={dealerShipForm.name}
            onChange={handleInputChange}
            className={
              editBtn
                ? "text-textSecondary font-semibold w-[200px] px-2 py-1 border border-gray-100 rounded-md"
                : "hidden"
            }
          />

          <div
            className={
              editBtn
                ? "flex flex-row gap-[35px] text-[16px]"
                : "flex flex-row gap-[85px] text-[16px]"
            }
          >
            {/* Start */}
            <div className="flex flex-row gap-3">
              <div
                className={
                  editBtn ? "flex flex-col gap-6" : "flex flex-col gap-3"
                }
              >
                <label className="text-[#545454]">Website:</label>
                <label className="text-[#545454]">City:</label>
                <label className="text-[#545454]">State:</label>
                <label className="text-[#545454]">Mobile:</label>
              </div>

              <div className="flex flex-col gap-3">
                <a
                  href={
                    dealerShipForm.website === "" ||
                      dealerShipForm.website === null
                      ? "#"
                      : dealerShipForm.website
                  }
                  className={
                    editBtn ? "hidden" : "text-textSecondary font-semibold"
                  }
                >
                  {dealerShipForm.website === "" ||
                    dealerShipForm.website === null
                    ? "--"
                    : dealerShipForm.website}
                </a>
                <input
                  type="text"
                  name="website"
                  required
                  value={dealerShipForm.website}
                  onChange={handleInputChange}
                  className={
                    editBtn
                      ? "text-textSecondary font-semibold w-[200px] px-2 py-1 border border-gray-100 rounded-md"
                      : "hidden"
                  }
                />
                <h1
                  className={
                    editBtn ? "hidden" : "text-[#262626] font-semibold"
                  }
                >
                  {dealerShipForm.city ? dealerShipForm.city : "--"}
                </h1>
                <input
                  type="text"
                  name="city"
                  required
                  onChange={handleInputChange}
                  value={dealerShipForm.city}
                  className={
                    editBtn
                      ? "text-[#262626] font-semibold w-[200px] px-2 py-1 border border-gray-100 rounded-md"
                      : "hidden"
                  }
                />
                <h1
                  className={
                    editBtn ? "hidden" : "text-[#262626] font-semibold"
                  }
                >
                  {dealerShipForm.state ? dealerShipForm.state : '--'}
                </h1>
                <input
                  type="text"
                  name="state"
                  required
                  onChange={handleInputChange}
                  value={dealerShipForm.state}
                  className={
                    editBtn
                      ? "text-[#262626] font-semibold w-[200px] px-2 py-1 border border-gray-100 rounded-md"
                      : "hidden"
                  }
                />
                <a
                  className={
                    editBtn ? "hidden" : "text-textSecondary font-semibold"
                  }
                  href={`tel:${dealerShipForm.phone === "" ? "--" : dealerShipForm.phone
                    }`}
                >
                  {dealerShipForm.phone ? dealerShipForm.phone : "--"}
                </a>
                <input
                  type="number"
                  name="phone"
                  value={dealerShipForm.phone}
                  onChange={handleInputChange}
                  className={
                    editBtn
                      ? "text-textSecondary font-semibold w-[200px] px-2 py-1 border border-gray-100 rounded-md"
                      : "hidden"
                  }
                />
              </div>
            </div>

            <div className="flex flex-row gap-3">
              <div
                className={
                  editBtn ? "flex flex-col gap-6" : "flex flex-col gap-3"
                }
              >
                <label className="text-[#545454]">First Name:</label>
                <label className="text-[#545454]">Last Name:</label>
                <label className="text-[#545454] flex item-center gap-3">
                  <EmailIcon />
                  Email:
                </label>
                <label className="text-[#545454] flex item-center gap-2">
                  <PhoneIcon />
                  Phone:
                </label>
              </div>

              <div className="flex flex-col gap-3">
                <div>
                  <h3
                    className={
                      editBtn ? "hidden" : "text-[#383838] font-semibold"
                    }
                  >
                    {dealerShipForm.first_name ? dealerShipForm.first_name : "--"}
                  </h3>
                  <input
                    type="name"
                    name="first_name"
                    required
                    value={dealerShipForm.first_name}
                    onChange={handleInputChange}
                    className={
                      editBtn
                        ? "text-[#383838] font-semibold w-[200px] px-2 py-1 border border-gray-100 rounded-md"
                        : "hidden"
                    }
                  />
                </div>
                <div>
                  <h3
                    className={
                      editBtn ? "hidden" : "text-[#383838] font-semibold"
                    }
                  >
                    {dealerShipForm.last_name ? dealerShipForm.last_name : "--"}
                  </h3>
                  <input
                    type="name"
                    name="last_name"
                    required
                    value={dealerShipForm.last_name}
                    onChange={handleInputChange}
                    className={
                      editBtn
                        ? "text-[#383838] font-semibold w-[200px] px-2 py-1 border border-gray-100 rounded-md"
                        : "hidden"
                    }
                  />
                </div>
                <div>
                  <a
                    className={
                      editBtn ? "hidden" : "text-buttonPrimary font-semibold"
                    }
                    href={`mailto:${dealerShipForm.email === ""
                      ? "--"
                      : dealerShipForm.email
                      }`}
                  >
                    {dealerShipForm.email === "" || dealerShipForm.email === null ? "--" : dealerShipForm.email}
                  </a>
                  <input
                    type="email"
                    name="email"
                    required
                    value={dealerShipForm.email}
                    onChange={handleInputChange}
                    className={
                      editBtn
                        ? "text-buttonPrimary font-semibold w-[200px] px-2 py-1 border border-gray-100 rounded-md"
                        : "hidden"
                    }
                  />
                </div>
                <div>
                  <a
                    className={
                      editBtn ? "hidden" : "text-buttonPrimary font-semibold"
                    }
                    href={`tel:${dealerShipForm.mobile === "" || dealerShipForm.mobile === null
                      ? "--"
                      : dealerShipForm.mobile
                      }`}
                  >
                    {dealerShipForm.mobile ? dealerShipForm.mobile : "--"}
                  </a>
                  <input
                    type="number"
                    name="mobile"
                    required
                    value={dealerShipForm.mobile}
                    onChange={handleInputChange}
                    className={
                      editBtn
                        ? "text-buttonPrimary font-semibold w-[200px] px-2 py-1 border border-gray-100 rounded-md"
                        : "hidden"
                    }
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-row gap-3">
              <div
                className={
                  editBtn ? "flex flex-col gap-6" : "flex flex-col gap-3"
                }
              >
                <label className="flex item-center gap-2 text-[#545454]">
                  <BoxIcon />
                  Package:
                </label>
                <label className="flex items-center gap-3 text-[#545454]">
                  <UsersIcon />
                  User:
                </label>
                <label className="flex items-center gap-2 text-[#545454]">
                  <DollarSignIcon />
                  Amount:
                </label>
                <label className="flex items-center gap-2 text-[#545454] hidden">
                  <DealerCenterIcon />
                  Source:
                </label>
              </div>

              <div className="flex flex-col gap-3">
                <div>
                  <h3
                    className={
                      editBtn ? "hidden" : "text-buttonPrimary font-semibold"
                    }
                  >
                    {dealerShipForm.formPackage === ""
                      ? "--"
                      : dealerShipForm.formPackage}
                  </h3>
                  <select
                    name="formPackage"
                    value={dealerShipForm.formPackage}
                    onChange={handleInputChange}
                    className={
                      editBtn
                        ? "text-[#383838] font-semibold w-[200px] px-2 py-1 border border-gray-100 rounded-md"
                        : "hidden"
                    }
                  >
                    <option value="">select package</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Yearly">Yearly</option>
                  </select>
                </div>
                <div>
                  <h3
                    className="text-[#383838] font-semibold"
                  // className={
                  //   editBtn ? "hidden" : "text-[#383838] font-semibold"
                  // }
                  >
                    {totalUser.length}
                  </h3>
                  {/* <input
                    type="number"
                    name="user_count"
                    required
                    value={dealerShipForm.user_count}
                    onChange={handleInputChange}
                    className={
                      editBtn
                        ? "text-[#383838] font-semibold w-[200px] px-2 py-1 border border-gray-100 rounded-md"
                        : "hidden"
                    }
                  /> */}
                </div>
                <div>
                  <h3
                    className={
                      editBtn ? "hidden" : "text-buttonPrimary font-semibold"
                    }
                  >
                    {dealerShipForm.amount === ""
                      ? "--"
                      : `$ ${dealerShipForm.amount}`}
                  </h3>
                  <input
                    type="number"
                    name="amount"
                    value={dealerShipForm.amount}
                    onChange={handleInputChange}
                    className={
                      editBtn
                        ? "text-buttonPrimary font-semibold w-[200px] px-2 py-1 border border-gray-100 rounded-md"
                        : "hidden"
                    }
                  />
                </div>
                {/* <div className="hidden">
                  <h3
                    className={
                      editBtn ? "hidden" : "text-[#383838] font-semibold"
                    }
                  >
                    {dealerShipForm.inventory_source === ""
                      ? "--"
                      : dealerShipForm.inventory_source}
                  </h3>
                  <input
                    type="text"
                    name="inventory_source"
                    required
                    value={dealerShipForm.inventory_source}
                    onChange={handleInputChange}
                    className={
                      editBtn
                        ? "text-[#383838] font-semibold w-[200px] px-2 py-1 border border-gray-100 rounded-md"
                        : "hidden"
                    }
                  />
                </div> */}
              </div>
            </div>

            <div className="flex flex-row gap-3">
              <div
                className={
                  editBtn ? "flex flex-col gap-6" : "flex flex-col gap-3"
                }
              >
                <label className="flex items-center gap-2 text-[#545454]">
                  <CarIcon />
                  Vehicle:
                </label>
                <label className="flex items-center gap-2 font-semibold text-[#545454]">
                  <TwoCircleCursorIcon />
                  FB Ads:
                </label>
                <label
                  className={`flex items-center gap-2 font-semibold text-[#545454] 
                  ${dealerShipForm.fb_ads === 0 ||
                    dealerShipForm.fb_ads === "" ||
                    (dealerShipForm.fb_ads === null && "hidden")
                    }`}
                >
                  {/* <TwoCircleCursorIcon /> */}
                  FB Ads Budget:
                </label>
                <label className="flex items-center gap-2 ont-semibold text-[#545454] hidden">
                  <FTPAccountIcon />
                  FTP:
                </label>
              </div>

              <div className="flex flex-col gap-3">
                <div>
                  <h3 className="text-[#383838] font-semibold">
                    {isLoading ? "--" : vehicleData.listed !== undefined ? vehicleData.listed[dealerShipForm.id] : 0}
                  </h3>
                  {/* <input
                     type="text"
                     name="vehicles"
                     required
                     value={dealerShipForm.vehicles}
                     onChange={handleInputChange}
                     className={
                       editBtn
                         ? "text-[#383838] font-semibold w-[200px] px-2 py-1 border border-gray-100 rounded-md"
                         : "hidden"
                     }
                    /> */}
                </div>
                <div>
                  <h3
                    className={
                      editBtn ? "hidden" : "text-buttonPrimary font-semibold"
                    }
                  >
                    {dealerShipForm.fb_ads === 0 ||
                      dealerShipForm.fb_ads === "" ||
                      dealerShipForm.fb_ads === null
                      ? "False"
                      : "True"}
                  </h3>

                  <select
                    name="fb_ads"
                    defaultValue={
                      dealerShipForm.fb_ads === 0 ||
                        dealerShipForm.fb_ads === "" ||
                        dealerShipForm.fb_ads === null
                        ? 0
                        : 1
                    }
                    onChange={handleInputChange}
                    className={
                      editBtn
                        ? "text-[#383838] font-semibold w-[100px] px-2 py-1 border border-gray-100 rounded-md"
                        : "hidden"
                    }
                  >
                    <option value={dealerShipForm.fb_ads} disabled>
                      {dealerShipForm.fb_ads === 0 ||
                        dealerShipForm.fb_ads === "" ||
                        dealerShipForm.fb_ads === null
                        ? "False"
                        : "True"}
                    </option>
                    <option value="1">True</option>
                    <option value="0">False</option>
                  </select>
                </div>

                <div
                  className={`
                  ${dealerShipForm.fb_ads === 0 ||
                      dealerShipForm.fb_ads === "" ||
                      dealerShipForm.fb_ads === null
                      ? "hidden"
                      : "block"
                    }`}
                >
                  <h3
                    className={
                      editBtn ? "hidden" : "text-buttonPrimary font-semibold"
                    }
                  >
                    {" "}
                    {dealerShipForm.fb_ads_budget === ""
                      ? "$ 00"
                      : `$ ${dealerShipForm.fb_ads_budget}`}
                  </h3>
                  <input
                    type="text"
                    name="fb_ads_budget"
                    value={dealerShipForm.fb_ads_budget}
                    onChange={handleInputChange}
                    className={
                      editBtn
                        ? "text-buttonPrimary font-semibold w-[200px] px-2 py-1 border border-gray-100 rounded-md"
                        : "hidden"
                    }
                  />
                </div>

                <div className="hidden">
                  <h3
                    className={
                      editBtn ? "hidden" : "text-buttonPrimary font-semibold"
                    }
                  >
                    {" "}
                    {dealerShipForm.FTPaccount === ""
                      ? "--"
                      : dealerShipForm.FTPaccount}
                  </h3>
                  {/* <input
                    type="text"
                    name="FTPaccount"
                    required
                    value={dealerShipForm.FTPaccount}
                    onChange={handleInputChange}
                    className={
                      editBtn
                        ? "text-buttonPrimary font-semibold w-[200px] px-2 py-1 border border-gray-100 rounded-md"
                        : "hidden"
                    }
                  /> */}
                </div>
              </div>
            </div>

            {/* end */}
          </div>
        </div>

        <div className="flex items-center">
          <div className="flex items-center gap-4">
            <div className="flex flex-col gap-4">
              <button
                type="submit"
                className={
                  editBtn
                    ? "font-semibold flex items-center gap-2 px-4 py-2 rounded-md text-white bg-buttonPrimary text-[16px]"
                    : "hidden"
                }
              >
                <EditIcon color={"#fff"} />
                Update
              </button>
              <span
                className={
                  editBtn
                    ? "hidden"
                    : "font-semibold flex items-center gap-2 border border-[#EAEAEA] px-4 py-2 rounded-md text-[#484848] cursor-pointer text-[16px]"
                }
                onClick={() => setEditBtn(!editBtn)}
              >
                <EditIcon />
                Edit
              </span>
              <span
                className="font-normal flex items-center gap-1 bg-red-500 text-white px-4 py-2 rounded-md text-[16px] cursor-pointer"
                onClick={() =>
                  setDeleteModelToggle({
                    isOpen: true,
                    id: dealerShipForm.id,
                  })
                }
              >
                Delete
              </span>
            </div>
            <div className="flex flex-col gap-4">
              <button
                className="font-normal flex items-center gap-1 bg-buttonPrimary text-white px-4 py-2 rounded-md text-[16px]"
                onClick={() => setAddUserModel(true)}
              >
                <PlusIcon />
                Add User
              </button>{" "}
              {paymentBtnLoading ? (
                <span className="font-normal flex items-center gap-1 bg-buttonPrimary text-white px-4 py-2 rounded-md text-[16px] cursor-wait">
                  <span className="flex gap-2">
                    <span className="w-5 h-5 border-t-4 border-white border-solid rounded-full animate-spin"></span>

                    <span>Payment Method</span>
                  </span>
                </span>
              ) : (
                <span
                  className="font-normal flex items-center gap-1 bg-buttonPrimary text-white px-4 py-2 rounded-md text-[16px] cursor-pointer"
                  onClick={() => paymentFunction()}
                >
                  Payment Method
                </span>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DealerShipHeader;
