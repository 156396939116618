import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { AuthContextProvider } from "./Context/AuthContext";
import { PaymentContextProvider } from "./Context/PaymentContext";
import { CustomerContextProvider } from "Context/CustomerContext";
import { CustomerDetailContextProvider } from "Context/customerDetailContext/CustomerDetailContext";
import { SearchContextProvider } from 'Context/SearchContext';
import { UserContextProvider } from 'Context/UserContext';
import { AlertContextProvider } from "Context/AlertContext";
import { TeamsContextProvider } from "Context/TeamsContext/TeamsContext";
import { FtpContextProvider } from "Context/FTPContext/FtpContext";
import { DataContextProvider } from "Context/DataContext/DataContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <AlertContextProvider>
        <PaymentContextProvider>
          <CustomerContextProvider>
            <CustomerDetailContextProvider>
              <UserContextProvider>
                <SearchContextProvider>
                  <TeamsContextProvider>
                    <FtpContextProvider>
                      <DataContextProvider>
                        <BrowserRouter basename="/ul-admin">
                          <App />
                        </BrowserRouter>
                      </DataContextProvider>
                    </FtpContextProvider>
                  </TeamsContextProvider>
                </SearchContextProvider>
              </UserContextProvider>
            </CustomerDetailContextProvider>
          </CustomerContextProvider>
        </PaymentContextProvider>
      </AlertContextProvider>
    </AuthContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
