import React from 'react'

export const DownArrowToggleIcon = () => {
    return (
        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.38963 8.62437L3.09763 5.41587C3.0174 5.30377 2.96962 5.17173 2.95953 5.03425C2.94944 4.89677 2.97744 4.75917 3.04045 4.63656C3.10346 4.51395 3.19904 4.41108 3.3167 4.33925C3.43435 4.26741 3.56953 4.2294 3.70738 4.22937H8.29288C8.43067 4.22936 8.5658 4.2673 8.68344 4.33904C8.80108 4.41078 8.89669 4.51355 8.95976 4.63605C9.02283 4.75856 9.05094 4.89608 9.04099 5.03351C9.03105 5.17094 8.98344 5.30297 8.90338 5.41512L6.61063 8.62512C6.54125 8.72234 6.44966 8.80159 6.34347 8.85627C6.23728 8.91095 6.11957 8.93947 6.00013 8.93947C5.88069 8.93947 5.76298 8.91095 5.65679 8.85627C5.5506 8.80159 5.45901 8.72159 5.38963 8.62437Z" fill="#87909F" />
        </svg>

    )
}
