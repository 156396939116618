import { useCustomerContext } from 'Context/CustomerContext';
import { useUserContext } from 'Context/UserContext'
import axios from 'axios';
import axiosClient from 'axios.client';
import { LoadingBtn } from 'components/Button/LoadingBtn';
import SmallLoading from 'components/Loadings/SmallLoading';
import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();

const FTPModel = () => {
    const { setToggleFtpModel, toggleFtpModel } = useUserContext();
    const { SetAlert } = useCustomerContext();

    const [isLoading, setIsLoading] = useState(false); // loading
    const [ftpData, setFtpData] = useState([]); // ftp user name list
    const [ftpList, setFtpList] = useState(""); // ftp select user name paths
    const [ftpSearch, setFtpSearch] = useState({ isOpen: false, phrase: "" }); // ftp search
    const [selectedFTP, setSelectedFTP] = useState(""); // selected ftp user name
    const [selectedPath, setSelectedPath] = useState("");
    const [openSearch, setOpenSearch] = useState(false);
    const [defaultAssignedFTPs, setDefaultAssignedFTPs] = useState(4);
    const [multiSelectData, setMultiSelectData] = useState([]);
    const [selectedAssignedFTP, setSelectedAssignedFTP] = useState([]);

    const handleSelectedUser = (user) => {
        const path = ftpData.find((data) => data.user === user).homedir;

        axios.get(`https://uniqueleverage.com/ul-backend/public/api/get-file-list`, {
            params: { ftpPath: path },
        })
            .then((res) => {
                setFtpList(res.data);
            })
            .catch((e) => SetAlert(true, "failed", "Failed to fetch ftp path"));
    };

    const [submitLoading, setSubmitLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitLoading(true);

        const payload = { ftp_file_path: selectedPath, user: selectedFTP, selectedAssignedFTP: selectedAssignedFTP.join(", ") };

        axiosClient.post(`/ftp-account-update/${toggleFtpModel.id}`, payload)
            .then((res) => {
                // console.log(res);

                if (res.status === 200) {
                    setToggleFtpModel({ id: toggleFtpModel.id, isBool: false });
                    SetAlert(true, "success", "FTP Account Successfully Updated");
                }
            })
            .catch((e) => SetAlert(true, "failed", "FTP Account Failed to Update"))
            .finally(() => {
                setSubmitLoading(false);
                setToggleFtpModel({ id: toggleFtpModel.id, isBool: false });
            });
    };

    const getMultiSelectData = (ftpData) => {
        const data = ftpData.map((data) => ({
            value: data.user,
            label: data.user,
        }));
        return data;
    }
    const selectDefaultAssignedFtpAccount = (ftpData) => {
        let ftp = toggleFtpModel.assignedFtpAccounts;

        ftpData.map((data, i) => {
            if (ftp.includes(data.user)) {

                setDefaultAssignedFTPs(i);
            }
        })
    }

    const fetchFTP = () => {
        setIsLoading(true);

        axiosClient
            .get("https://uniqueleverage.com/ul-backend/public/api/getFtp")
            .then((res) => {
                setFtpData(res.data.data)
                setMultiSelectData(getMultiSelectData(res.data.data))
                selectDefaultAssignedFtpAccount(res.data.data);
            })
            .catch((e) => console.log(e));

        setIsLoading(false);
    };

    useEffect(() => {
        fetchFTP();
    }, []);


    return (
        <div className="fixed inset-0 z-[110] bg-[#D3D9DF] bg-opacity-50 flex justify-center items-center font-roboto">
            <div className="bg-white h-[fit-content] max-h-[95vh] rounded-lg shadow-md">
                <div className="flex justify-between items-center p-5">
                    <h1 className="font-[500]">Assign FTP</h1>
                </div>
                <hr />
                <form onSubmit={handleSubmit}>
                    <div
                        className="p-5 px-10 flex flex-col gap-4"
                        onClick={() => {
                            openSearch === true && setOpenSearch(false);
                        }}
                    >
                        <div className="flex flex-col items-center gap-5">
                            <div className="w-[350px] relative">
                                <label className="font-[500] text-sm">FTP</label>
                                <input
                                    type="search"
                                    required
                                    autoComplete="off"
                                    name="ftpSearch"
                                    value={selectedFTP || toggleFtpModel.FTPaccount}
                                    placeholder="search dealership"
                                    className="border border-[#EAEAEA] rounded-md p-2 w-full"
                                    onClick={() => setOpenSearch(true)}
                                    onChange={(e) => {
                                        setFtpSearch({ isOpen: true, phrase: e.target.value });
                                        setSelectedFTP(e.target.value);
                                    }}
                                />

                                {ftpSearch.phrase !== "" && ftpSearch.isOpen && (
                                    <div className="fixed bg-white rounded-lg shadow-lg w-[350px] p-2 max-h-[200px] overflow-y-auto z-[51] text-[16px]">
                                        <ul>
                                            {ftpData &&
                                                ftpData
                                                    .filter((ftp) => {
                                                        return ftpSearch.phrase.toLowerCase() === ""
                                                            ? ftp
                                                            : ftp.user.toLowerCase().includes(ftpSearch.phrase);
                                                    })
                                                    .map((ftp, index) => {
                                                        return (
                                                            <li
                                                                key={index}
                                                                className="hover:bg-gray-100 cursor-pointer p-2"
                                                                onClick={() => {
                                                                    setSelectedFTP(ftp.user);
                                                                    handleSelectedUser(ftp.user);
                                                                    setFtpSearch({
                                                                        isOpen: false,
                                                                        phrase: ftpSearch.phrase,
                                                                    });
                                                                    setOpenSearch(false);
                                                                }}
                                                            >
                                                                {ftp.user}
                                                            </li>
                                                        );
                                                    })}
                                        </ul>
                                    </div>
                                )}

                                {openSearch && ftpSearch.phrase === "" && (
                                    <div className="fixed bg-white rounded-lg shadow-lg w-[350px] p-2 max-h-[200px] overflow-y-auto z-[51] text-[16px]">
                                        <ul>
                                            {ftpData &&
                                                ftpData
                                                    .filter((ftp) => {
                                                        return ftpSearch.phrase.toLowerCase() === ""
                                                            ? ftp
                                                            : ftp.user.toLowerCase().includes(ftpSearch.phrase);
                                                    })
                                                    .map((ftp, index) => {
                                                        return (
                                                            <li
                                                                key={index}
                                                                className="hover:bg-gray-100 cursor-pointer p-2"
                                                                onClick={() => {
                                                                    setSelectedFTP(ftp.user);
                                                                    handleSelectedUser(ftp.user);
                                                                    setFtpSearch({
                                                                        isOpen: false,
                                                                        phrase: ftpSearch.phrase,
                                                                    });
                                                                    setOpenSearch(false);
                                                                }}
                                                            >
                                                                {ftp.user}
                                                            </li>
                                                        );
                                                    })}
                                        </ul>
                                    </div>
                                )}
                            </div>
                            <div className="w-[350px] block">
                                <label htmlFor="folder" className="font-[500] text-sm">
                                    File
                                </label>
                                <ul className="border border-[#EAEAEA] p-2 max-w-[350px] max-h-[180px] rounded-lg overflow-auto text-[16px]">
                                    {ftpList === "" ? (
                                        <li className="text-gray-400 w-full whitespace-normal">
                                            {toggleFtpModel.ftpPath}
                                        </li>
                                    ) : (
                                        ftpList.map((ftp, index) => {
                                            return (
                                                <li
                                                    key={index}
                                                    className={
                                                        selectedPath === ftp.fullPath
                                                            ? "bg-gray-200 cursor-pointer py-2 border-b whitespace-normal"
                                                            : "hover:bg-gray-300 cursor-pointer py-2 border-b whitespace-normal"
                                                    }
                                                    onClick={() => setSelectedPath(ftp.fullPath)}
                                                >
                                                    {ftp.fileName}
                                                </li>
                                            );
                                        })
                                    )}
                                </ul>
                            </div>
                            <div className="w-[350px] block">
                                <label htmlFor="folder" className="font-[500] text-sm">
                                    Assign FTP(s)
                                </label>
                                <Select
                                    closeMenuOnSelect={false}
                                    components={animatedComponents}
                                    defaultValue={[multiSelectData[defaultAssignedFTPs], multiSelectData[1]]}
                                    isMulti
                                    options={multiSelectData}
                                    name="selectedAssignedFTP"
                                    onChange={(e) => { setSelectedAssignedFTP(e.map((data) => data.value)) }}
                                />
                            </div>
                        </div>
                    </div>

                    <hr />
                    {submitLoading ?
                        <div className="flex items-center justify-end gap-2 p-5 px-10">
                            <button className="bg-white border border-gray-300 rounded-md py-[2px] px-2 font-[500] text-sm hover:shadow-md duration-300" onClick={() => { setToggleFtpModel({ id: toggleFtpModel.id, isBool: false }) }}>Cancel</button>

                            <span className="bg-buttonPrimary rounded-md py-[4px] px-2 text-white font-[500] text-sm hover:shadow-md duration-300 flex items-center gap-2">Assign FTP <SmallLoading color="white" /></span>
                        </div>
                        :
                        <div className="flex items-center justify-end gap-2 p-5 px-10">
                            <button className="bg-white border border-gray-300 rounded-md py-[2px] px-2 font-[500] text-sm hover:shadow-md duration-300" onClick={() => { setToggleFtpModel({ id: toggleFtpModel.id, isBool: false }) }}>Cancel</button>

                            <button className="bg-buttonPrimary rounded-md py-[4px] px-2 text-white font-[500] text-sm hover:shadow-md duration-300">Assign FTP</button>
                        </div>}

                </form>
            </div>
        </div>
    )
}

export default FTPModel