import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosClient from "../../../../axios.client";
import { DeleteIcon } from "../../../../components/icons/DeleteIcon";
import { MenuIcon } from "../../../../components/icons/MenuIcon";
import PlusIcon from "../../../../components/icons/PlusIcon";
import { EditIcon } from "../../../../components/icons/EditIcon";
import UpdateUserDesktop from "../../AddUser/UpdateUserDesktop";
import AddUser from "../../AddUser/AddUser";

const UsedCars = ({
  setOpenEdit,
  userDealer,
  setUserDealer,
  // fetchUsersList,
  SetAlert,
  DealerShipName,
}) => {
  const navigate = useNavigate();

  const [cruiseToggle, setCruiseToggle] = useState({
    id: "",
    name: "",
    website: "",
    state: "",
    city: "",
    phone: "",
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    formPackage: "",
    user_count: "",
    amount: "",
    dealerCenter: "",
    vehicles: "",
    perMonthAmt: "",
    FTPaccount: "",
    cruise_active: "",
  });
  const [deleteModal, setDeleteModal] = useState(true); // Delete Model
  const [menuOpen, SetMenuOpen] = useState({ isOpen: false, data: [] }); // Edit Model
  const [addUserOpen, SetAddUserOpen] = useState(false); // Add User Model

  const [usersList, setUsersList] = useState([]);
  const [selectedUserName, setSelectedUserName] = useState(
    usersList && usersList.length > 0 ? usersList[0].id : ""
  );
  const [loading, setLoading] = useState(false);

  const fetchUsersList = async () => {
    setLoading(true);

    const payload = {
      dealership: DealerShipName,
    };

    try {
      await axiosClient.post("/getUsersList", payload).then((res) => {
        setUsersList(res.data.data);
        setSelectedUserName(res.data.data[0].id);
      });
    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  };

  const DateFormat = (data) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const date = new Date(data);
    const year = date.getFullYear();
    const monthIndex = date.getMonth();
    const day = date.getDate();

    const formattedDate = `${months[monthIndex]}, ${day}, ${year}`;

    return formattedDate;
  };

  const updateCruise = (val, id) => {
    const updatedListUser = usersList.map((user) => {
      if (user.id === id) {
        return { ...user, cruise_active: val };
      }
      return user;
    });

    usersList.map((user) => {
      if (user.id === id) {
        return setCruiseToggle({ ...user, cruise_active: val });
      }

      return user;
    });

    setUsersList(updatedListUser);

    updateRowCruise(val, id);
  };

  const updateRowCruise = async (val, id) => {
    const selectedUser = usersList.find((user) => user.id === id);

    if (selectedUser) {
      const payload = {
        ...selectedUser,
        cruise_active: val,
        city: selectedUser.address ? selectedUser.address.split(",")[0] : "",
        state: selectedUser.address ? selectedUser.address.split(",")[1] : "",
        formPackage: selectedUser.package,
      };

      try {
        const response = await axiosClient
          .post("/updateDealerShip", payload)
          // .then((res) => console.log(res))
          .catch((e) => console.log(e));

        if (response.data.message === "Cruise was updated successfully") {
          return { success: true, message: response.data.message };
        } else {
          return { success: false, message: response.data.message };
        }
      } catch (error) {
        console.error("Error updating Cruise:", error);
        return {
          success: false,
          message: "An error occurred while updating Cruise",
        };
      }
    }
  };

  const deleteUser = async (id) => {
    const userResponse = window.confirm("Do you want to proceed?");

    if (userResponse) {
      try {
        await axiosClient.post(`/deleteDealerShipUser/${id}`).then((res) => {
          if (res.status === 200) {
            SetAlert(true, "success", "User was deleted");

            const updatedList = usersList.filter((user) => user.id !== id);

            updatedList.length === 0 && navigate("/");

            setSelectedUserName(updatedList[0].id);
            setUsersList(updatedList);
          }
        });
      } catch (e) {
        SetAlert(true, "failed", "Unable to delete user");
      }
    } else {
      setDeleteModal(false);
    }
  };

  const [vehicleLengths, setVehicleLengths] = useState([]);

  const fetchVehiclesCount = async (data) => {
    axiosClient("/get-car-count")
      .then((res) => setVehicleLengths(res.data))
      .catch((e) => console.log(e));
  };

  const updatedAllUsersList = usersList.map((user) => {
    const matchingVehicle = vehicleLengths.find(
      (vehicle) => vehicle.userId === user.id
    );

    if (matchingVehicle) {
      return {
        ...user,
        advertised: matchingVehicle.advertised,
        listed: matchingVehicle.listed,
        pending: matchingVehicle.pending,
      };
    } else {
      return {
        ...user,
        advertised: 0,
        listed: 0,
        pending: 0,
      };
    }
  });

  const updateUserList = (data) => {
    setUsersList(data);
  };

  useEffect(() => {
    fetchVehiclesCount();
    fetchUsersList();
  }, []);

  return (
    <>
      {menuOpen.isOpen && (
        <div className="block fixed top-0 left-0 z-[52] w-full h-full bg-black bg-opacity-30">
          <div className="flex items-center justify-center h-screen">
            <UpdateUserDesktop
              selectedUserName={selectedUserName}
              setAddUserModel={SetMenuOpen}
              editToggle={menuOpen}
              updatedUserList={updateUserList}
              list={updatedAllUsersList}
              SetAlert={SetAlert}
            />
          </div>
        </div>
      )}

      {addUserOpen.isOpen && (
        <div className="block fixed top-0 left-0 z-[52] w-full h-full bg-black bg-opacity-30">
          <div className="flex items-center justify-center h-screen">
            <AddUser
              setAddUserModel={SetAddUserOpen}
              editToggle={addUserOpen}
              fetchUsersList={fetchUsersList}
            />
          </div>
        </div>
      )}

      <div className="block fixed top-0 z-[51] w-full h-full bg-black bg-opacity-30">
        <div className="flex items-center justify-center h-screen">
          <div className="absolute top-0 left-0 h-full w-full bg-white z-50">
            <div className="flex items-center justify-between border-b py-4">
              <button
                className="pl-5"
                onClick={() => setOpenEdit({ isOpen: false, section: "" })}
              >
                <svg
                  width="7"
                  height="12"
                  viewBox="0 0 7 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.24179 11.2369C5.11045 11.237 4.98038 11.2112 4.85905 11.1609C4.73772 11.1107 4.62752 11.0369 4.53479 10.9439L0.292787 6.7009C0.105316 6.51337 0 6.25906 0 5.9939C0 5.72873 0.105316 5.47442 0.292787 5.2869L4.53479 1.0439C4.72339 0.861738 4.97599 0.760943 5.23819 0.763221C5.50038 0.7655 5.7512 0.870669 5.93661 1.05608C6.12201 1.24149 6.22718 1.4923 6.22946 1.75449C6.23174 2.01669 6.13094 2.26929 5.94879 2.4579L2.41379 5.9939L5.94879 9.5299C6.0886 9.66975 6.1838 9.84791 6.22237 10.0419C6.26094 10.2358 6.24113 10.4368 6.16546 10.6195C6.08979 10.8022 5.96166 10.9584 5.79725 11.0683C5.63283 11.1782 5.43954 11.2369 5.24179 11.2369Z"
                    fill="black"
                  />
                </svg>
              </button>
              <h1 className="font-bold">Users</h1>

              <div className="pr-5">
                <button
                  className="text-textButton rounded-2xl bg-buttonPrimary w-[32px] h-[32px]"
                  onClick={() => navigate("/add-user")}
                >
                  <PlusIcon />
                </button>
              </div>
            </div>

            {loading ? (
              <div className="flex justify-center items-center h-screen">
                <div className="w-12 h-12 border-t-4 border-buttonPrimary border-solid border-opacity-80 rounded-full animate-spin"></div>
              </div>
            ) : (
              <div className="md:hidden pt-5 p-5">
                <label className="font-bold block mb-2">Select User</label>
                <select
                  name="user"
                  id="user"
                  className="border border-[#EDEDED] rounded-sm p-2 w-full font-semibold"
                  defaultValue={selectedUserName}
                  onChange={(e) => setSelectedUserName(e.target.value)}
                >
                  {usersList &&
                    usersList.map((user) => {
                      return (
                        <option value={user.id} key={user.id}>
                          {user.username}
                        </option>
                      );
                    })}
                </select>

                {updatedAllUsersList &&
                  updatedAllUsersList
                    .filter((user) => user.id == selectedUserName)
                    .map((user) => {
                      return (
                        <div className="pt-10" key={user.id}>
                          <div className="flex justify-between border-b pb-4">
                            <h4 className="font-semibold text-md">{user.id}</h4>
                            <h4 className="font-semibold text-md">
                              {user.username}
                            </h4>
                          </div>
                          <div className="flex justify-between mt-5 bg-[#FBFBFB]">
                            <h3 className="font-semibold text-md text-[#3D3D3D]">
                              Status
                            </h3>
                            <h3 className="font-semibold text-md text-[#3F3F3F]">
                              {user.status ? user.status : "Offline"}
                            </h3>
                          </div>
                          <div className="flex justify-between mt-5">
                            <h3 className="font-semibold text-md text-[#3D3D3D]">
                              Password
                            </h3>
                            <h3 className="font-semibold text-md text-[#3F3F3F]">
                              {user.pass}
                            </h3>
                          </div>
                          <div className="flex justify-between mt-5 bg-[#FBFBFB]">
                            <h3 className="font-semibold text-md text-[#3D3D3D]">
                              Phone Number
                            </h3>
                            <a
                              href={`tel:${user.phone}`}
                              className="font-semibold text-md text-buttonPrimary"
                            >
                              {user.phone}
                            </a>
                          </div>
                          <div className="flex justify-between mt-5 bg-[#FBFBFB]">
                            <h3 className="font-semibold text-md text-[#3D3D3D]">
                              Vehicles
                            </h3>
                            <h3 className="font-semibold text-md text-[#3F3F3F]">
                              {user.listed}
                            </h3>
                          </div>
                          <div className="flex justify-between mt-5">
                            <h3 className="font-semibold text-md text-[#3D3D3D]">
                              Pending
                            </h3>
                            <h3 className="font-semibold text-md text-[#3F3F3F]">
                              {user.pending}
                            </h3>
                          </div>
                          <div className="flex justify-between mt-5 bg-[#FBFBFB]">
                            <h3 className="font-semibold text-md text-[#3D3D3D]">
                              Active
                            </h3>
                            <h3 className="font-semibold text-md text-[#3F3F3F]">
                              {user.advertised}
                            </h3>
                          </div>
                          <div className="flex justify-between mt-5">
                            <h3 className="font-semibold text-md text-[#3D3D3D]">
                              Cruise
                            </h3>
                            <h3
                              className={
                                user.cruise_active === 1
                                  ? "bg-[#06C753] rounded-xl w-10 h-5 p-[1.5px] flex items-center duration-300"
                                  : "bg-[#b1b1b1] rounded-xl w-10 h-5 p-[1.5px] flex items-center duration-300"
                              }
                              onClick={() => {
                                updateCruise(
                                  user.cruise_active === 1
                                    ? (cruiseToggle.cruise_active = 0)
                                    : (cruiseToggle.cruise_active = 1),
                                  (cruiseToggle.id = user.id)
                                );
                              }}
                            >
                              <span
                                className={
                                  user.cruise_active === 1
                                    ? "h-4 w-4 bg-white block rounded-xl duration-300  translate-x-5"
                                    : "h-4 w-4 bg-white block rounded-xl duration-300  translate-x-0"
                                }
                              ></span>
                            </h3>
                          </div>
                          <div className="flex justify-between mt-5 bg-[#FBFBFB]">
                            <h3 className="font-semibold text-md text-[#3D3D3D]">
                              Last Active
                            </h3>
                            <h3 className="font-semibold text-md text-[#3F3F3F]">
                              {DateFormat(user.created_at)}
                            </h3>
                          </div>
                          <div className="flex justify-between mt-5">
                            <h3 className="font-semibold text-md text-[#3D3D3D]">
                              Last Post
                            </h3>
                            <h3 className="font-semibold text-md text-[#3F3F3F]">
                              {DateFormat(user.created_at)}
                            </h3>
                          </div>
                          <div className="flex justify-between mt-5 bg-[#FBFBFB]">
                            <h3 className="font-semibold text-md text-[#3D3D3D]">
                              Action
                            </h3>
                            <h3 className="font-semibold text-md text-[#3F3F3F] text-center flex gap-3">
                              <button
                                className="pr-1"
                                onClick={() =>
                                  SetMenuOpen({
                                    isOpen: true,
                                    data: {
                                      id: user.id || "",
                                      name: user.name || "",
                                      password: user.pass || "",
                                      phone: user.phone || "",
                                      first_name: user.first_name || "",
                                      last_name: user.last_name || "",
                                      email: user.email || "",
                                      status: user.status || "",
                                    },
                                  })
                                }
                              >
                                <EditIcon />
                              </button>
                              <button
                                className="pl-1 relative"
                                onClick={() => {
                                  setDeleteModal(!deleteModal);
                                }}
                              >
                                <MenuIcon />
                                <div
                                  className={
                                    deleteModal
                                      ? "hidden"
                                      : "absolute right-0 top-9 bg-white z-10 shadow-lg rounded-lg"
                                  }
                                >
                                  <span
                                    className="text-red-500 p-3 px-4 font-semibold flex items-center gap-2 border border-gray-100"
                                    onClick={() => deleteUser(user.id)}
                                  >
                                    <DeleteIcon />
                                    Delete
                                  </span>
                                </div>
                              </button>
                            </h3>
                          </div>
                        </div>
                      );
                    })}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UsedCars;
