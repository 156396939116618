import React from 'react'

export const DeleteIconThin = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.5 15V4.49999H3.75V3.74999H6.75V3.17249H11.25V3.74999H14.25V4.49999H13.5V15H4.5ZM5.25 14.25H12.75V4.49999H5.25V14.25ZM7.356 12.75H8.106V5.99999H7.356V12.75ZM9.894 12.75H10.644V5.99999H9.894V12.75Z" fill="#FF1C31" />
        </svg>

    )
}
