import { useCustomerContext } from 'Context/CustomerContext';
import React from 'react'

const AddCustomerModel = () => {
    const {
        setToggleAddCustomer,
        formData,
        handleInputChange,
        formSubmit,
    } = useCustomerContext();

    return (
        <div className="fixed inset-0 z-[110] bg-[#D3D9DF] bg-opacity-50 flex justify-center items-center font-roboto">
            <div className="bg-white h-[fit-content] max-h-[100vh] w-[500px] rounded-lg shadow-md overflow-y-auto">
                <div className="flex justify-between items-center p-5">
                    <h1 className="font-[500]">Create customer</h1>
                </div>
                <hr />
                {/* <h1 className="font-[600] mt-5 px-5">Customer Information</h1> */}

                <form onSubmit={formSubmit}>
                    <div className="flex flex-col gap-3 px-5 py-5">
                        <div className="flex flex-col gap-2">
                            <label htmlFor="dealerShip" className="font-[500] text-sm">Dealership</label>
                            <input type="text"
                                name="dealerShip"
                                id="dealerShip"
                                className="border border-gray-200 rounded-md placeholder:text-sm placeholder:font-[400] px-2 py-[3px] focus:shadow-blue-100 focus:shadow-md outline-none duration-300"
                                placeholder="Jenny Rosen Automobiles"
                                autoComplete="off"
                                autoFocus
                                required
                                value={formData.dealerShip}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="flex flex-col gap-2">
                            <label htmlFor="email" className="font-[500] text-sm">Email</label>
                            <input type="email"
                                name="email"
                                id="email"
                                className="border border-gray-200 rounded-md placeholder:text-sm placeholder:font-[400] px-2 py-[3px] focus:shadow-blue-100 focus:shadow-md outline-none duration-300"
                                placeholder="jenny.rosen@example.com"
                                autoComplete="off"
                                required
                                value={formData.email}
                                onChange={handleInputChange}
                            />

                        </div>

                        <div className="flex flex-col gap-2">
                            <label htmlFor="website" className="font-[500] text-sm">Website</label>
                            <input type="text"
                                name="website"
                                id="website"
                                className="border border-gray-200 rounded-md placeholder:text-sm placeholder:font-[400] px-2 py-[3px] focus:shadow-blue-100 focus:shadow-md outline-none duration-300"
                                placeholder="https://www.website.com/"
                                autoComplete="off"
                                required
                                value={formData.website}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="flex flex-col gap-2">
                            <label htmlFor="state" className="font-[500] text-sm">State</label>
                            <input type="text"
                                name="state"
                                id="state"
                                className="border border-gray-200 rounded-md placeholder:text-sm placeholder:font-[400] px-2 py-[3px] focus:shadow-blue-100 focus:shadow-md outline-none duration-300"
                                placeholder="Michigan"
                                autoComplete="off"
                                required
                                value={formData.state}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="flex flex-col gap-2">
                            <label htmlFor="city" className="font-[500] text-sm">City</label>
                            <input type="text"
                                name="city"
                                id="city"
                                className="border border-gray-200 rounded-md placeholder:text-sm placeholder:font-[400] px-2 py-[3px] focus:shadow-blue-100 focus:shadow-md outline-none duration-300"
                                placeholder="Detroit"
                                autoComplete="off"
                                required
                                value={formData.city}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="flex flex-col gap-2">
                            <label htmlFor="phoneNo" className="font-[500] text-sm">Phone Number</label>
                            <input type="number"
                                name="phoneNo"
                                id="phoneNo"
                                className="border border-gray-200 rounded-md placeholder:text-sm placeholder:font-[400] px-2 py-[3px] focus:shadow-blue-100 focus:shadow-md outline-none duration-300"
                                placeholder="555 555-1234"
                                autoComplete="off"
                                required
                                value={formData.phoneNo}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="flex flex-col gap-2">
                            <label htmlFor="formPackage" className="font-[500] text-sm">Package</label>
                            <input type="text"
                                name="formPackage"
                                id="formPackage"
                                className="border border-gray-200 rounded-md placeholder:text-sm placeholder:font-[400] px-2 py-[3px] focus:shadow-blue-100 focus:shadow-md outline-none duration-300"
                                placeholder="Basic"
                                autoComplete="off"
                                required
                                value={formData.formPackage}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="flex flex-col gap-2">
                            <label htmlFor="formUser" className="font-[500] text-sm">User</label>
                            <input type="number"
                                name="formUser"
                                id="formUser"
                                className="border border-gray-200 rounded-md placeholder:text-sm placeholder:font-[400] px-2 py-[3px] focus:shadow-blue-100 focus:shadow-md outline-none duration-300"
                                placeholder="2"
                                autoComplete="off"
                                required
                                value={formData.formUser}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="flex flex-col gap-2">
                            <label htmlFor="firstName" className="font-[500] text-sm">First Name</label>
                            <input type="text"
                                name="firstName"
                                id="firstName"
                                className="border border-gray-200 rounded-md placeholder:text-sm placeholder:font-[400] px-2 py-[3px] focus:shadow-blue-100 focus:shadow-md outline-none duration-300"
                                placeholder="Jenny"
                                autoComplete="off"
                                required
                                value={formData.firstName}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="flex flex-col gap-2">
                            <label htmlFor="lastName" className="font-[500] text-sm">Last Name</label>
                            <input type="text"
                                name="lastName"
                                id="lastName"
                                className="border border-gray-200 rounded-md placeholder:text-sm placeholder:font-[400] px-2 py-[3px] focus:shadow-blue-100 focus:shadow-md outline-none duration-300"
                                placeholder="Rosen"
                                autoComplete="off"
                                required
                                value={formData.lastName}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <hr />

                    <div className="flex items-center justify-end gap-2 p-5">
                        <button className="bg-white border border-gray-300 rounded-md py-[2px] px-2 font-[500] text-sm hover:shadow-md duration-300" onClick={() => { setToggleAddCustomer(false) }}>Cancel</button>
                        <button className="bg-buttonPrimary rounded-md py-[4px] px-2 text-white font-[500] text-sm hover:shadow-md duration-300">Add customer</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AddCustomerModel