import removeDuplicates from "Utils/RemoveDuplicateRows";
import axiosClient from "axios.client";

export const fetchCars = async (VehicleId) => {

    try {
        const [carsResponse, userResponse] = await Promise.all([
            axiosClient.get(`https://uniqueleverage.com/ul-backend/public/api/get-cars/${VehicleId}`),
            axiosClient.get(`/getSingleUser/${VehicleId}`),
        ]);

        const parsedCarsData = JSON.parse(carsResponse.data);

        const vehiclesListed = parsedCarsData.listed.filter(
            (x) =>
                x.price > 0 &&
                x.images !== "" &&
                x.images.split(",").length > 2 &&
                x.model !== "" &&
                x.make !== "" &&
                x.year !== "" &&
                ((x.trim !== "" &&
                    x.bodystyle !== "" &&
                    x.fueltype !== "" &&
                    x.mileage >= 1000) ||
                    x.make.toLowerCase().includes("trailer"))
        );

        let uniqueVehiclesListed = removeDuplicates(vehiclesListed);
        let uniqueVehiclesAdvertised = removeDuplicates(parsedCarsData.advertised);
        let uniqueVehiclesPending = removeDuplicates(parsedCarsData.pending);
        let uniqueVehiclesCruise;

        if (userResponse && userResponse.data.response.cruise_active === 1) {
            const advertisedVins = uniqueVehiclesAdvertised.map((vehicle) => vehicle.vin);
            const filteredListed = uniqueVehiclesListed.filter(
                (x) => !advertisedVins.includes(x.vin)
            )
            const updatedfilteredListed = filteredListed.map(vehicle => ({ ...vehicle, "status": "pending" }));
            uniqueVehiclesPending = [...uniqueVehiclesPending, ...updatedfilteredListed];
            uniqueVehiclesPending = removeDuplicates(uniqueVehiclesPending)

        }


        uniqueVehiclesCruise = [...uniqueVehiclesListed, ...uniqueVehiclesAdvertised, ...uniqueVehiclesPending].filter((car) => car.cruise === 1);

        return {
            user: userResponse.data.response,
            listed: uniqueVehiclesListed,
            advertised: uniqueVehiclesAdvertised,
            pending: uniqueVehiclesPending,
            cruise: uniqueVehiclesCruise
        };
    } catch (error) {
        return {
            error: "Unable to fetch cars"
        }
    }
};