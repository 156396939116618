import React, { useEffect, useState } from 'react';
import '../css/bubbleChart.css'

const BubbleChar = ({ userStatus, userStatusLoading }) => {
    const [percentages, setPercentages] = useState({
        top: 55.33,
        right: 25,
        left: 15,
        bottom: 8,
    });

    const calculatePercentageForBubbleChart = () => {
        let sum = userStatus.userPosting + userStatus.freeze + userStatus.error + 10;

        setPercentages({
            top: Math.round(userStatus.userPosting / sum * 100),
            right: Math.round(userStatus.freeze / sum * 100),
            left: Math.round(10 / sum * 100), // Duplicated Active
            bottom: Math.round(userStatus.error / sum * 100),
        })
    }

    useEffect(() => {
        calculatePercentageForBubbleChart();
    }, [userStatus]);

    if (userStatusLoading) {
        return (
            
                <div className="bubbleBody relative text-center text-[16px] font-semibold flex justify-center animate-pulse">
                    <div className="venncirctop flex items-center justify-center" style={{ width: `${58.33 + 10}%`, height: `${58.33 + 10}%` }}>
                        <a className="venntxttop hover:text-[#353A44]">
                            58.33%
                        </a>
                    </div>

                    <div className="venncirclft flex items-center justify-center" style={{ width: `${15 + 10}%`, height: `${15 + 10}%` }}>
                        <a className="venntxtlft hover:text-[#353A44]">
                            15%
                        </a>
                    </div>

                    <div className="venncircrt flex items-center justify-center" style={{ width: `${25 + 10}%`, height: `${25 + 10}%` }}>
                        <a className="venntxtrt hover:text-[#353A44]">
                            25%
                        </a>
                    </div>

                    <div className="venncircbtm flex items-center justify-center" style={{ width: `${8 + 10}%`, height: `${8 + 10}%` }}>
                        <a className="venntxtbtm hover:text-[#353A44]">
                            8%
                        </a>
                    </div>
                </div>
            
        );
    }

    return (


            <div className="bubbleBody relative text-center text-[16px] font-semibold flex justify-center">
                <div className="venncirctop flex items-center justify-center" style={{ width: `${percentages.top + 10}%`, height: `${percentages.top + 10}%` }}>
                    <a className="venntxttop hover:text-[#353A44]" href="#">
                        {percentages.top}%
                    </a>
                </div>

                <div className="venncirclft flex items-center justify-center" style={{ width: `${percentages.left + 10}%`, height: `${percentages.left + 10}%` }}>
                    <a className="venntxtlft hover:text-[#353A44]" href="#">
                        {percentages.left}%
                    </a>
                </div>

                <div className="venncircrt flex items-center justify-center" style={{ width: `${percentages.right + 10}%`, height: `${percentages.right + 10}%` }}>
                    <a className="venntxtrt hover:text-[#353A44]" href="#">
                        {percentages.right}%
                    </a>
                </div>

                <div className="venncircbtm flex items-center justify-center" style={{ width: `${percentages.bottom + 10}%`, height: `${percentages.bottom + 10}%` }}>
                    <a className="venntxtbtm hover:text-[#353A44]" href="#">
                        {percentages.bottom}%
                    </a>
                </div>
            </div>
        
    );
};

export default BubbleChar;