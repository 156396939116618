import React from "react";

export const DollarSignIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 4.50024V15.5002M12.75 7.70858C12.75 6.44358 11.5189 5.41691 10 5.41691C8.48108 5.41691 7.25 6.44358 7.25 7.70858C7.25 8.97358 8.48108 10.0002 10 10.0002C11.5189 10.0002 12.75 11.0269 12.75 12.2919C12.75 13.5569 11.5189 14.5836 10 14.5836C8.48108 14.5836 7.25 13.5569 7.25 12.2919"
        stroke="#484848"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M5.41659 2.06001C6.80948 1.25417 8.39072 0.831027 9.99992 0.833507C15.0627 0.833507 19.1666 4.93742 19.1666 10.0002C19.1666 15.0629 15.0627 19.1668 9.99992 19.1668C4.93717 19.1668 0.833252 15.0629 0.833252 10.0002C0.833252 8.33092 1.27967 6.76434 2.05975 5.41684"
        stroke="#484848"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
