import React from 'react'

export const ListedVehicleIcon = ({ color, height, width }) => {
    return (
        <svg
            width={`${width === undefined ? "24" : width}`}
            height={`${height === undefined ? "24" : height}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_642_858)">
                <path d="M18.9998 18.9932H4.99976V19.9932C4.99976 20.2584 4.8944 20.5127 4.70686 20.7003C4.51933 20.8878 4.26497 20.9932 3.99976 20.9932H2.99976C2.73454 20.9932 2.48019 20.8878 2.29265 20.7003C2.10511 20.5127 1.99976 20.2584 1.99976 19.9932V10.9932L4.51276 4.29116C4.65569 3.90986 4.91161 3.58127 5.24631 3.34932C5.58101 3.11737 5.97854 2.99311 6.38576 2.99316H17.6138C18.021 2.99311 18.4185 3.11737 18.7532 3.34932C19.0879 3.58127 19.3438 3.90986 19.4868 4.29116L21.9998 10.9932V19.9932C21.9998 20.2584 21.8944 20.5127 21.7069 20.7003C21.5193 20.8878 21.265 20.9932 20.9998 20.9932H19.9998C19.7345 20.9932 19.4802 20.8878 19.2927 20.7003C19.1051 20.5127 18.9998 20.2584 18.9998 19.9932V18.9932ZM4.13576 10.9932H19.8638L17.6138 4.99316H6.38576L4.13576 10.9932ZM6.49976 15.9932C6.89758 15.9932 7.27911 15.8351 7.56042 15.5538C7.84172 15.2725 7.99976 14.891 7.99976 14.4932C7.99976 14.0953 7.84172 13.7138 7.56042 13.4325C7.27911 13.1512 6.89758 12.9932 6.49976 12.9932C6.10193 12.9932 5.7204 13.1512 5.4391 13.4325C5.15779 13.7138 4.99976 14.0953 4.99976 14.4932C4.99976 14.891 5.15779 15.2725 5.4391 15.5538C5.7204 15.8351 6.10193 15.9932 6.49976 15.9932ZM17.4998 15.9932C17.8976 15.9932 18.2791 15.8351 18.5604 15.5538C18.8417 15.2725 18.9998 14.891 18.9998 14.4932C18.9998 14.0953 18.8417 13.7138 18.5604 13.4325C18.2791 13.1512 17.8976 12.9932 17.4998 12.9932C17.1019 12.9932 16.7204 13.1512 16.4391 13.4325C16.1578 13.7138 15.9998 14.0953 15.9998 14.4932C15.9998 14.891 16.1578 15.2725 16.4391 15.5538C16.7204 15.8351 17.1019 15.9932 17.4998 15.9932Z" fill={`${color === undefined ? "#1B74E4" : color}`} />
            </g>
            <defs>
                <clipPath id="clip0_642_858">
                    <rect width={`${width === undefined ? "24" : width}`}
                        height={`${height === undefined ? "24" : height}`}
                        fill={`${color === undefined ? "#1B74E4" : color}`} />
                </clipPath>
            </defs>
        </svg>

    )
}
