import React from "react";

export const FTPAccountIcon = () => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.1666 16.4166H11.8333M0.833252 16.4166H8.16659M9.99992 14.5833V11.8333"
        stroke="#484848"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M9.99984 18.2499C11.0124 18.2499 11.8332 17.4291 11.8332 16.4166C11.8332 15.4041 11.0124 14.5833 9.99984 14.5833C8.98732 14.5833 8.1665 15.4041 8.1665 16.4166C8.1665 17.4291 8.98732 18.2499 9.99984 18.2499Z"
        stroke="#484848"
        strokeWidth="1.5"
      />
      <path
        d="M11.8333 11.8333H3.58325C3.22212 11.8333 2.86452 11.7621 2.53087 11.6239C2.19723 11.4857 1.89407 11.2832 1.63871 11.0278C1.38335 10.7724 1.18078 10.4693 1.04258 10.1356C0.904383 9.80199 0.833252 9.44439 0.833252 9.08325C0.833252 8.72212 0.904383 8.36452 1.04258 8.03087C1.18078 7.69723 1.38335 7.39407 1.63871 7.13871C1.89407 6.88335 2.19723 6.68078 2.53087 6.54258C2.86452 6.40438 3.22212 6.33325 3.58325 6.33325M3.58325 6.33325H16.4166M3.58325 6.33325C2.85391 6.33325 2.15443 6.04352 1.63871 5.5278C1.12298 5.01207 0.833252 4.3126 0.833252 3.58325C0.833252 2.85391 1.12298 2.15443 1.63871 1.63871C2.15443 1.12298 2.85391 0.833252 3.58325 0.833252H6.33325M16.4166 6.33325C16.7777 6.33325 17.1353 6.40438 17.469 6.54258C17.8026 6.68078 18.1058 6.88335 18.3611 7.13871C18.6165 7.39407 18.8191 7.69723 18.9573 8.03087C19.0955 8.36452 19.1666 8.72212 19.1666 9.08325C19.1666 9.44439 19.0955 9.80199 18.9573 10.1356C18.8191 10.4693 18.6165 10.7724 18.3611 11.0278C18.1058 11.2832 17.8026 11.4857 17.469 11.6239C17.1353 11.7621 16.7777 11.8333 16.4166 11.8333H15.4999M16.4166 6.33325C17.1459 6.33325 17.8454 6.04352 18.3611 5.5278C18.8769 5.01207 19.1666 4.3126 19.1666 3.58325C19.1666 2.85391 18.8769 2.15443 18.3611 1.63871C17.8454 1.12298 17.1459 0.833252 16.4166 0.833252H9.99992M10.9166 3.58325H16.4166M10.9166 9.08325H16.4166"
        stroke="#484848"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.49992 4.49984C5.00618 4.49984 5.41659 4.08943 5.41659 3.58317C5.41659 3.07691 5.00618 2.6665 4.49992 2.6665C3.99366 2.6665 3.58325 3.07691 3.58325 3.58317C3.58325 4.08943 3.99366 4.49984 4.49992 4.49984Z"
        fill="#484848"
      />
      <path
        d="M4.49992 9.99984C5.00618 9.99984 5.41659 9.58943 5.41659 9.08317C5.41659 8.57691 5.00618 8.1665 4.49992 8.1665C3.99366 8.1665 3.58325 8.57691 3.58325 9.08317C3.58325 9.58943 3.99366 9.99984 4.49992 9.99984Z"
        fill="#484848"
      />
    </svg>
  );
};
