import React, { useEffect, useState } from "react";
import ReportChart from "./Components/ReportChart";
import { useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import PlusIcon from "../../components/icons/PlusIcon";
import BubbleChar from "./Components/BubbleChar";
import axiosClient from "../../axios.client";
import DashboardStatus from "./Components/DashboardStatus";
import { DashboardStatusLoading } from "./Components/DashboardStatusLoading";
import { GlobalReport } from "./Components/GlobalReport";
import { UserStatus } from "./Components/UserStatus";
import { DownArrowIcon } from "../../components/icons/DownArrowIcon";
import { CalenderIcon } from "../../components/icons/CalenderIcon";
import { Reminder } from "./Components/Reminder";
import EditBtn from "./Components/EditBtn";
import AddBtn from "./Components/AddBtn";
import { ErrorLog } from "./Components/ErrorLog";
import NIcon from "components/icons/NIcon";
import { useCustomerContext } from "Context/CustomerContext";

const Dashboard = () => {
  const {
    setToggleAddCustomer,
  } = useCustomerContext();

  const ref = useRef();
  const { events } = useDraggable(ref);

  const [status, setStatus] = useState([]);
  const [globalReport, setGlobalReport] = useState([]);
  const [userStatus, setUserStatus] = useState([]);
  const [newUser, setNewUser] = useState({
    dealership: [],
    dealershipCount: []
  });

  const [statusLoading, setStatusLoading] = useState(false);
  const fetchStatus = async () => {
    setStatusLoading(true);
    try {
      const [statusResponse] = await Promise.all([
        axiosClient.get("/dashboard-status"),
      ]);

      setStatus(statusResponse.data);
    } catch (error) {
      console.log(error);
    }
    finally {
      setStatusLoading(false);
    }
  };

  const [globalLoading, setGlobalLoading] = useState(false);
  const fetchGlobalReport = async () => {
    setGlobalLoading(true);
    try {
      const [_globalReport] = await Promise.all([
        axiosClient.get("/dashboard-global-reports"),
      ]);

      setGlobalReport(_globalReport.data || []);
    } catch (error) {
      console.log(error);
    }
    finally {
      setGlobalLoading(false);
    }
  }

  const [userStatusLoading, setUserStatusLoading] = useState(false);
  const fetchUserStatus = async () => {
    setUserStatusLoading(true);
    try {
      const [_userStatus] = await Promise.all([
        axiosClient.get("/dashboard-user-status"),
      ]);

      setUserStatus(_userStatus.data || []);
    } catch (error) {
      console.log(error);
    }
    finally {
      setUserStatusLoading(false);
    }
  }

  const [newUserLoading, setNewUserLoading] = useState(false);
  const fetchNewUser = async () => {
    setNewUserLoading(true);
    try {
      const [_newUser] = await Promise.all([
        axiosClient.get("/dashboard-dealership"),
      ]);

      setNewUser(_newUser.data || []);
    } catch (error) {
      console.log(error);
    }
    finally {
      setNewUserLoading(false);
    }
  }

  useEffect(() => {
    fetchStatus();
    fetchGlobalReport();
    fetchUserStatus();
    // fetchNewUser();
  }, []);



  return (
    <div className="pb-10">
      {/* Body Header */}
      <div className="flex justify-between border-b pb-[14px]">
        <div>
          <h1 className="text-[28px] font-bold leading-9 font-roboto">Today</h1>
        </div>



        <div className="flex items-end gap-5">

          <button className="bg-buttonPrimary w-[157px] pt-[5px] pr-[8px] pb-[5px] pl-[7px] rounded-lg shadow-md items-center text-white text-[14px] flex gap-[8px]" onClick={() => { setToggleAddCustomer(true) }}
          > <span className='flex items-center gap-[2px]'><PlusIcon /> Add customer</span> <NIcon /> </button>
        </div>

      </div>

      {/* Top Status */}
      <div className="flex items-center gap-[17px] overflow-y-auto overflow-x-hidden scrollbar-hide py-[20px] 5xl:!w-[1556px]" style={{width: 'calc(100vw - 390px)'}} {...events} ref={ref}>
        {
          statusLoading ? <DashboardStatusLoading /> : <DashboardStatus status={status} />
        }
      </div>

      {/* Overview */}
      <div className="w-full">
        <div>
          <h1 className="font-bold text-[28px] font-roboto text-[#30313D] leading-9">Reports Overview</h1>
        </div>

        <div className={`pt-[20px] pb-[16px] flex justify-between item-center border-b relative text-[16px]`}>
          <div className={`flex items-center h-[34px]`}>
            <button className="flex gap-1 items-center font-semibold border border-[#D5DBE1] p-1 pl-2 pr-0 rounded-l-md text-[#414552]">
              Nathan Alison
              <DownArrowIcon width={12} height={12} />
            </button>

            <button className="flex items-center gap-2 p-1 px-2 border-r border-t border-b border-[#D5DBE1] rounded-r-md font-semibold text-[#414552]">
              <CalenderIcon />
              Nov 29-Dec 5
            </button>
          </div>

          <div className={`flex items-center gap-4 text-[14px]`}>
            <AddBtn />
            <EditBtn />
          </div>
        </div>
      </div>
      <div className="flex items-center gap-[19px] pb-[15px] pt-[20px] border-b border-[#D5DBE1]">
        <div className="flex items-center gap-2">
          <h1 className="font-bold text-[#414552] text-[16px]">Ocean State Auto Sales</h1>
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <g clipPath="url(#clip0_1683_2365)">
              <path fillRule="evenodd" clipRule="evenodd" d="M8.75 1.75H3.25C2.85218 1.75 2.47064 1.90804 2.18934 2.18934C1.90804 2.47064 1.75 2.85218 1.75 3.25V8.75C1.75 9.14782 1.90804 9.52936 2.18934 9.81066C2.47064 10.092 2.85218 10.25 3.25 10.25H8.75C9.14782 10.25 9.52936 10.092 9.81066 9.81066C10.092 9.52936 10.25 9.14782 10.25 8.75V3.25C10.25 2.85218 10.092 2.47064 9.81066 2.18934C9.52936 1.90804 9.14782 1.75 8.75 1.75ZM3.25 0.25C2.45435 0.25 1.69129 0.56607 1.12868 1.12868C0.56607 1.69129 0.25 2.45435 0.25 3.25V8.75C0.25 9.54565 0.56607 10.3087 1.12868 10.8713C1.69129 11.4339 2.45435 11.75 3.25 11.75H8.75C9.54565 11.75 10.3087 11.4339 10.8713 10.8713C11.4339 10.3087 11.75 9.54565 11.75 8.75V3.25C11.75 2.45435 11.4339 1.69129 10.8713 1.12868C10.3087 0.56607 9.54565 0.25 8.75 0.25H3.25Z" fill="#6C7688" />
              <path fillRule="evenodd" clipRule="evenodd" d="M4.48242 6.50005C4.48242 6.3144 4.55617 6.13635 4.68745 6.00507C4.81872 5.8738 4.99677 5.80005 5.18242 5.80005H6.27242C6.45807 5.80005 6.63612 5.8738 6.7674 6.00507C6.89867 6.13635 6.97242 6.3144 6.97242 6.50005V8.50005C6.97242 8.59197 6.95432 8.683 6.91914 8.76793C6.88396 8.85286 6.8324 8.93002 6.7674 8.99502C6.7024 9.06002 6.62523 9.11159 6.5403 9.14676C6.45537 9.18194 6.36435 9.20005 6.27242 9.20005C6.1805 9.20005 6.08947 9.18194 6.00454 9.14676C5.91962 9.11159 5.84245 9.06002 5.77745 8.99502C5.71245 8.93002 5.66088 8.85286 5.62571 8.76793C5.59053 8.683 5.57242 8.59197 5.57242 8.50005V7.20005H5.18242C4.99677 7.20005 4.81872 7.1263 4.68745 6.99502C4.55617 6.86375 4.48242 6.6857 4.48242 6.50005Z" fill="#6C7688" />
              <path d="M5 4C5.01152 3.74252 5.12191 3.4994 5.30818 3.32128C5.49446 3.14315 5.74226 3.04373 6 3.04373C6.25774 3.04373 6.50554 3.14315 6.69182 3.32128C6.87809 3.4994 6.98848 3.74252 7 4C6.98848 4.25748 6.87809 4.50059 6.69182 4.67872C6.50554 4.85685 6.25774 4.95627 6 4.95627C5.74226 4.95627 5.49446 4.85685 5.30818 4.67872C5.12191 4.50059 5.01152 4.25748 5 4Z" fill="#6C7688" />
            </g>
            <defs>
              <clipPath id="clip0_1683_2365">
                <rect width="12" height="12" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <h1 className="text-[#414552] text-sm font-semibold">2 Users</h1>
      </div>
      {/* </div> */}

      {/* Graph and Global Reports */}
      <div className="flex items-center">
        <div className="w-2/3 mr-[-22px]">
          <ReportChart />
        </div>

        <div className=" border-l border-1 border-gray-100 flex flex-col w-1/3">

          <GlobalReport globalReport={globalReport} globalLoading={globalLoading} />

        </div>
      </div>

      {/* User Status, Sales Log, and Team Members */}
      <div className="flex items-center justify-between gap-[4%] border-t" >
        {/* User Status */}
        <div className="flex w-[61%] gap-[4%]" >
          <div className="flex-1 pt-[20px]" >
            <div className="flex items-center justify-between">
              <div>
                <h1 className="font-bold text-[16px]">User Status</h1>
              </div>
              <div className="hover:cursor-pointer">
                {/* <MenuIcon color="#858D9D" /> */}
                <h1 className="font-normal text-[14px]">All Time</h1>
              </div>
            </div>

            <BubbleChar userStatus={userStatus} userStatusLoading={userStatusLoading} />


            <div className="flex flex-row justify-between">
              <UserStatus userStatus={userStatus} userStatusLoading={userStatusLoading} />
            </div>
          </div>

          {/* Reminder */}
          <Reminder />
        </div>

        {/* Error Logs */}
        <ErrorLog />


      </div>
    </div>
  );
};

export default Dashboard;