import { useFtpContext } from 'Context/FTPContext/FtpContext';
import LineJoinArrow from 'components/icons/LineJoinArrow';
import PlusIcon from 'components/icons/PlusIcon';
import SelectDataMapping from './components/SelectDataMapping';
import InputRow from './components/InputRow';
import SelectRow from './components/SelectRow';
import SelectFTPAccount from './components/SelectFTPAccount';
import SelectFilePath from './components/SelectFilePath';
import SmallLoading from 'components/Loadings/SmallLoading';
import NIcon from 'components/icons/NIcon';
import NewField from './components/NewField';

const FTP = () => {
    const {
        mappedData,
        mappedDataLoading,
        ftpData,
        ftpList,
        selectedFTP,
        openSearch,
        ftpSearch,
        setOpenSearch,
        setFtpSearch,
        setFtpList,
        setSelectedFTP,
        handleSelectedUser,
        ftpListLoading,
        selectedPath,
        setSelectedPath,
        csvLoading,
        fetchList,
        loadingCSV,
        csvDataHeader,
        formData,
        handleFormInput,
        handleSelectedDataMapping,
        selectedDataMapping,
        submitMapping,
        formFields,
        handleInputChange,
        handleSelectChange,
        handleRemoveField,
        handleAddField,
        mappingLoading,
        updateMapping,
    } = useFtpContext();

    const inputRowsData = [
        { label: 'VIN', name: 'vin', id: 'vin', selectLabel: 'VIN Select', selectName: 'vin' },
        { label: 'MSRP', name: 'msrp', id: 'msrp', selectLabel: 'MSRP Select', selectName: 'msrp' },
        { label: 'Trim', name: 'trim', id: 'trim', selectLabel: 'Trim Select', selectName: 'trim' },
        { label: 'Mileage', name: 'mileage', id: 'mileage', selectLabel: 'Mileage Select', selectName: 'mileage' },
        { label: 'Exterior Color', name: 'exteriorcolor', id: 'exteriorcolor', selectLabel: 'Exterior Color Select', selectName: 'exteriorcolor' },
        { label: 'Interior Color', name: 'interiorcolor', id: 'interiorcolor', selectLabel: 'Interior Color Select', selectName: 'interiorcolor' },
        { label: 'Transmission', name: 'transmission', id: 'transmission', selectLabel: 'Transmission Select', selectName: 'transmission' },
        { label: 'Description', name: 'description', id: 'description', selectLabel: 'Description Select', selectName: 'description' },
        { label: 'Body Style', name: 'bodystyle', id: 'bodystyle', selectLabel: 'Body Style Select', selectName: 'bodystyle' },
        { label: 'Year', name: 'year', id: 'year', selectLabel: 'Year Select', selectName: 'year' },
        { label: 'Make', name: 'make', id: 'make', selectLabel: 'Make Select', selectName: 'make' },
        { label: 'Model', name: 'model', id: 'model', selectLabel: 'Model Select', selectName: 'model' },
        { label: 'Engine', name: 'engine', id: 'engine', selectLabel: 'Engine Select', selectName: 'engine' },
        { label: 'Price', name: 'price', id: 'price', selectLabel: 'Price Select', selectName: 'price' },
        { label: 'Images', name: 'images', id: 'images', selectLabel: 'Images Select', selectName: 'images' },
        { label: 'Fuel Type', name: 'fueltype', id: 'fueltype', selectLabel: 'Fuel Type Select', selectName: 'fueltype' },
        { label: 'Condition', name: 'condition', id: 'condition', selectLabel: 'Condition Select', selectName: 'condition' },
    ];

    return (
        <div className='pb-7 pt-[0px] bg-white w-[inherit]'>
            <div className='mb-8 flex items-center gap-5 justify-between'>
                <h1 className='text-3xl font-bold leading-9 text-[##30313D] font-roboto'>File Transfer Protocol</h1>

                {selectedDataMapping === "" ?
                    <button className="bg-buttonPrimary hover:bg-[#1b75e4f1] hover:shadow-md duration-200 pt-[5px] pr-[8px] pb-[5px] pl-[7px] rounded-lg shadow-md items-center text-white text-[14px] flex item-center gap-[8px]"
                        onClick={() => { submitMapping() }}>
                        {mappingLoading ?
                            <span className='flex items-center justify-center gap-[2px] w-[120px]'>  <SmallLoading /> </span>
                            :
                            <>
                                <span className='flex items-center gap-[2px]'> <PlusIcon /> Add Mapping </span> <NIcon />
                            </>
                        }

                    </button>
                    :
                    <button className="bg-buttonPrimary hover:bg-[#1b75e4f1] hover:shadow-md duration-200 pt-[5px] pr-[8px] pb-[5px] pl-[7px] rounded-lg shadow-md items-center text-white text-[14px] flex item-center gap-[8px]"
                        onClick={() => { updateMapping() }}>
                        {mappingLoading ?
                            <span className='flex items-center justify-center gap-[2px] w-[120px]'>  <SmallLoading /> </span>
                            :
                            <>
                                <span className='flex items-center gap-[2px]'> <PlusIcon /> Update Mapping </span> <NIcon />
                            </>
                        }
                    </button>
                }
            </div>

            <div className="select-container flex items-start gap-10">
                <div>
                    <SelectDataMapping
                        mappedDataLoading={mappedDataLoading}
                        mappedData={mappedData}
                        handleSelectedDataMapping={handleSelectedDataMapping}
                        selectedDataMapping={selectedDataMapping}
                    />
                    <p className='text-[#687385] font-normal text-xs my-2 max-w-[213px] 2xl:max-w-[250px]'>Upload the CSV Data or Modify an Existing Table in your doc.</p>
                </div>

                <div>
                    <SelectFTPAccount
                        ftpData={ftpData}
                        ftpList={ftpList}
                        selectedFTP={selectedFTP}
                        openSearch={openSearch}
                        ftpSearch={ftpSearch}
                        setOpenSearch={setOpenSearch}
                        setFtpSearch={setFtpSearch}
                        setFtpList={setFtpList}
                        setSelectedFTP={setSelectedFTP}
                        handleSelectedUser={handleSelectedUser}
                        ftpListLoading={ftpListLoading}
                    />
                </div>

                <div className='flex items-center gap-10'>
                    <SelectFilePath
                        csvLoading={csvLoading}
                        ftpList={ftpList}
                        selectedPath={selectedPath}
                        setSelectedPath={setSelectedPath}
                    />
                    {loadingCSV ?
                        <button className='bg-[#1B74E4] rounded-sm p-2 px-2 w-[160px] 2xl:w-[188px] 5xl:w-[257px] text-white hover:bg-[#1b75e4eb] duration-200 text-sm flex items-center gap-2 justify-center'><SmallLoading /> Load File</button>
                        :
                        <button className='bg-[#1B74E4] rounded-sm p-2 px-2 w-[160px] 2xl:w-[188px] 5xl:w-[257px] text-white hover:bg-[#1b75e4eb] duration-200 text-sm' onClick={() => fetchList(selectedPath)}>Load File</button>
                    }
                </div>
            </div>

            <div className='max-w-[inherit]'>
                <div className='flex items-start justify-between gap-20 mt-5'>
                    <div className='flex-1 flex items-center justify-between gap-5 w-[360px] 2xl:w-[423px] 5xl:w-[690px]'>
                        <h1 className='text-[#30313D] font-bold text-lg'>Columns</h1>
                        <button className='text-[#1B74E4] text-xs font-medium flex items-center gap-1 mr-[60px] 2xl:mr-0' onClick={handleAddField}><PlusIcon color="#1B74E4" width={16} height={16} /> New Field</button>
                    </div>

                    <div>{/* GAP */}</div>

                    <div className='flex-1 flex items-center justify-between gap-5 w-[428px] 2xl:w-[500px] 5xl:w-[690px]'>
                        <h1 className='text-[#30313D] font-bold text-lg'>Map Data</h1>
                        <div className="flex justify-center">
                            <div className={"bg-[#06C753] rounded-xl w-[26px] h-[13px] p-[2.3px] flex items-center duration-300 cursor-pointer"}>
                                <span className={"h-[11px] w-[11px] bg-white block rounded-xl duration-300 translate-x-[11.5px]"}></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='flex flex-col gap-5'>

                    <NewField
                        formFields={formFields}
                        handleInputChange={handleInputChange}
                        handleSelectChange={handleSelectChange}
                        handleRemoveField={handleRemoveField}
                        csvDataHeader={csvDataHeader}
                        loadingCSV={loadingCSV}
                    />

                    {inputRowsData.map((row, index) => (
                        <div className='flex items-center justify-between gap-20' key={index}>
                            <InputRow label={row.label} name={row.name} id={row.id} type='text' readOnly={true} />

                            <div className='mt-5'><LineJoinArrow /></div>

                            <SelectRow label={row.selectLabel} name={row.selectName} id={row.selectName}
                                csvDataHeader={csvDataHeader}
                                loadingCSV={loadingCSV}
                                formData={formData}
                                handleFormInput={handleFormInput}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FTP;
