import React from "react";
import { useNavigate } from "react-router-dom";

const AccountTable = ({
  users,
  formToggler,
  handleInputChange,
  formData,
  search,
}) => {
  const navigate = useNavigate();

  return (
    <div id="tabContent">
      <div
        id="AccountsTab"
        className="flex flex-col justify-between h-[calc(100vh-220px)]"
      >
        <div id="accountCards" className="md:hidden">
          {users.data
            ? users.data.map((user, index) => (
              <div
                className="account-card flex justify-between py-[16px] border-b border-borderPrimary"
                key={index}
                onClick={() => navigate(`dealerShip-detail/${user.name}`)}
              >
                <div className="account-card-left">
                  <h3 className="pb-2"> {user.username} </h3>
                  <p className="text-textPrimary font-normal text-[14px]">
                    {user.address}
                  </p>
                  <p className="text-textPrimary font-normal text-[14px]">
                    {user.phone}
                  </p>
                </div>
                <button>
                  <svg
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.999912 0.763104C1.13125 0.762969 1.26132 0.788784 1.38265 0.839067C1.50397 0.889349 1.61417 0.963105 1.70691 1.0561L5.94891 5.2991C6.13638 5.48663 6.2417 5.74094 6.2417 6.0061C6.2417 6.27127 6.13638 6.52558 5.94891 6.7131L1.70691 10.9561C1.51831 11.1383 1.26571 11.2391 1.00351 11.2368C0.741315 11.2345 0.490502 11.1293 0.305094 10.9439C0.119686 10.7585 0.0145168 10.5077 0.0122385 10.2455C0.00996017 9.98331 0.110754 9.73071 0.292912 9.5421L3.82791 6.0061L0.292912 2.4701C0.153103 2.33025 0.0578966 2.15209 0.0193291 1.95814C-0.0192385 1.76418 0.000564575 1.56315 0.0762348 1.38045C0.151905 1.19775 0.280044 1.04159 0.444454 0.931703C0.608864 0.821818 0.802162 0.763146 0.999912 0.763104Z"
                      fill="black"
                    />
                  </svg>
                </button>
              </div>
            ))
            : ""}
        </div>

        <div className="overflow-x-auto hidden md:block">
          <div className="p-1.5 min-w-full align-middle pt-7">
            <table className="border-b min-w-full divide-y divide-gray-200">
              <thead>
                <tr className="sticky top-0 bg-white">
                  <th
                    scope="col"
                    className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
                  >
                    Dealership
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
                  >
                    Website
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
                  >
                    State
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
                  >
                    City
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
                  >
                    Phone Number
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
                  >
                    Cell Number
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
                  >
                    Package
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
                  >
                    Users
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
                  >
                    First Name
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
                  >
                    Last Name
                  </th>
                </tr>
                {/* </thead> */}
                {/* <tbody> */}
                <tr
                  className={
                    formToggler
                      ? "divide-x h-16 bg-backgroudPrimary sticky top-10 bg-white"
                      : "hidden"
                  }
                >
                  <td className="px-2 py-4 whitespace-nowrap text-[16px] font-medium text-gray-800 dark:text-gray-200">
                    <input
                      type="text"
                      placeholder="dealership"
                      name="dealerShip"
                      required
                      value={formData.dealerShip}
                      onChange={handleInputChange}
                      className="border-1 border-black outline-none text-l rounded-sm w-[125px]"
                    />
                  </td>
                  <td className="px-2 py-4 whitespace-nowrap text-[16px] text-gray-800 dark:text-gray-200">
                    <input
                      type="text"
                      placeholder="website"
                      name="website"
                      required
                      value={formData.website}
                      onChange={handleInputChange}
                      className="border-1 border-black outline-none text-l rounded-sm w-[125px]"
                    />
                  </td>
                  <td className="px-2 py-4 whitespace-nowrap text-[16px] text-gray-800 dark:text-gray-200">
                    <input
                      type="text"
                      placeholder="state"
                      name="state"
                      required
                      value={formData.state}
                      onChange={handleInputChange}
                      className="border-1 border-black outline-none text-l rounded-sm w-[125px]"
                    />
                  </td>
                  <td className="px-2 py-4 whitespace-nowrap text-[16px] text-gray-800 dark:text-gray-200">
                    <input
                      type="text"
                      placeholder="city"
                      name="city"
                      required
                      value={formData.city}
                      onChange={handleInputChange}
                      className="border-1 border-black outline-none text-l rounded-sm w-[125px]"
                    />
                  </td>
                  <td className="px-2 py-4 whitespace-nowrap text-[16px] text-gray-800 dark:text-gray-200">
                    <input
                      type="number"
                      placeholder="phone number"
                      name="phoneNo"
                      required
                      value={formData.phoneNo}
                      onChange={handleInputChange}
                      className="border-1 border-black outline-none text-l rounded-sm w-[125px]"
                    />
                  </td>
                  <td className="px-2 py-4 whitespace-nowrap text-[16px] text-gray-800 dark:text-gray-200">
                    <input
                      type="number"
                      placeholder="cell number"
                      name="cellNo"
                      required
                      value={formData.cellNo}
                      onChange={handleInputChange}
                      className="border-1 border-black outline-none text-l rounded-sm w-[125px]"
                    />
                  </td>
                  <td className="px-2 py-4 whitespace-nowrap text-[16px] text-gray-800 dark:text-gray-200">
                    <input
                      type="text"
                      placeholder="package"
                      name="formPackage"
                      required
                      value={formData.formPackage}
                      onChange={handleInputChange}
                      className="border-1 border-black outline-none text-l rounded-sm w-[100px]"
                    />
                  </td>
                  <td className="px-2 py-4 whitespace-nowrap text-[16px] text-gray-800 dark:text-gray-200">
                    <input
                      type="number"
                      placeholder="user"
                      name="formUser"
                      min="0"
                      required
                      value={formData.formUser}
                      onChange={handleInputChange}
                      className="border-1 border-black outline-none text-l rounded-sm w-[90px]"
                    />
                  </td>
                  <td className="px-2 py-4 whitespace-nowrap text-[16px] text-gray-800 dark:text-gray-200">
                    <input
                      type="name"
                      placeholder="first name"
                      name="firstName"
                      required
                      value={formData.firstName}
                      onChange={handleInputChange}
                      className="border-1 border-black outline-none text-l rounded-sm w-[125px]"
                    />
                  </td>
                  <td className="px-2 py-4 whitespace-nowrap text-[16px] text-gray-800 dark:text-gray-200">
                    <input
                      type="name"
                      placeholder="last name"
                      name="lastName"
                      required
                      value={formData.lastName}
                      onChange={handleInputChange}
                      className="border-1 border-black outline-none text-l rounded-sm w-[125px]"
                    />
                  </td>
                </tr>
              </thead>
              <tbody className="border divide-y divide-gray-200">
                {users.data ? (
                  users.data
                    .filter((user) => {
                      if (search.pageName === "account") {
                        if (search.value.toLowerCase() === "") {
                          return user;
                        }
                        return (
                          user.name.toLowerCase().includes(search.value) ||
                          user.phone.toLowerCase().includes(search.value) ||
                          (user.first_name
                            ? user.first_name
                              .toLowerCase()
                              .includes(search.value)
                            : "") ||
                          (user.last_name
                            ? user.last_name
                              .toLowerCase()
                              .includes(search.value)
                            : "")
                        );
                      }
                      return user;
                    })
                    .map((user, index) => (
                      <tr
                        className={
                          index % 2 === 0
                            ? "divide-x h-16 bg-backgroudSecondary"
                            : "divide-x h-16 bg-backgroudPrimary"
                        }
                        key={index}
                        onClick={() => navigate(`dealerShip-detail/${user.id}`)}
                      >
                        <td className="px-6 py-4 whitespace-nowrap text-[16px] text-gray-800 dark:text-gray-200">
                          {user.name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-[16px] text-textSecondary dark:text-gray-200">
                          {user.website !== null ? user.website : "--"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-[16px] text-gray-800 dark:text-gray-200">
                          {user.address.split(",")[1]
                            ? user.address.split(",")[1]
                            : "--"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-[16px] text-gray-800 dark:text-gray-200">
                          {user.address.split(",")[0]
                            ? user.address.split(",")[0]
                            : "--"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-[16px] text-textSecondary dark:text-gray-200">
                          {user.phone}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-[16px] text-textSecondary dark:text-gray-200">
                          {user.phone}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-[16px] text-gray-800 dark:text-gray-200">
                          {user.package !== null ? user.package : "--"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-[16px] text-gray-800 dark:text-gray-200">
                          {user.length !== null ? user.user_count : "--"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-[16px] text-gray-800 dark:text-gray-200">
                          {user.first_name !== null ? user.first_name : "--"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-[16px] text-gray-800 dark:text-gray-200">
                          {user.last_name !== null ? user.last_name : "--"}
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan="10" className="text-center">
                      Nothing to show here
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {/* </form> */}
          </div>
          {/* </div> */}
        </div>

        {/* <div id="accountPagination hidden">
          <div className="flex justify-center items-center md:justify-end">
            <p className="mx-2 font-medium text-textPrimary text-[14px]">
              {" "}
              Displaying Page{" "}
            </p>
            <div className="mx-2 font-medium text-textPrimary text-[14px]">
              <select
                name=""
                id=""
                className="bg-white w-[70px] h-[40px] rounded-lg border"
              >
                <option value="1">1</option>
              </select>
            </div>
            <p className="mx-2 font-medium text-textPrimary text-[14px]">
              {" "}
              of 5
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default AccountTable;
