export default function DateFormat(data) {
    const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];

    const date = new Date(data);
    const year = date.getFullYear();
    const monthIndex = date.getMonth();
    const day = date.getDate();

    const formattedDate = `${months[monthIndex]}, ${day}, ${year}`;

    return formattedDate;
};