import React from 'react'

const PositiveArrow = () => {
    return (
        <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.27325 5.66669H8.72658C8.85843 5.66613 8.98715 5.6265 9.09647 5.55279C9.2058 5.47908 9.29081 5.37461 9.34076 5.25259C9.39071 5.13057 9.40336 4.99648 9.3771 4.86727C9.35084 4.73806 9.28686 4.61954 9.19325 4.52669L5.47325 0.806696C5.41127 0.74421 5.33754 0.694614 5.2563 0.660768C5.17506 0.626923 5.08792 0.609497 4.99991 0.609497C4.91191 0.609497 4.82477 0.626923 4.74353 0.660768C4.66229 0.694614 4.58856 0.74421 4.52658 0.806696L0.80658 4.52669C0.712966 4.61954 0.648985 4.73806 0.622728 4.86727C0.596471 4.99648 0.609118 5.13057 0.659068 5.25259C0.709018 5.37461 0.79403 5.47908 0.903352 5.55279C1.01267 5.6265 1.1414 5.66613 1.27325 5.66669Z" fill="#1A9882" />
        </svg>
    )
}

export default PositiveArrow