import React from 'react'

export const VisaLogo = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 0H24V24H0V0Z" fill="#00579F" />
            <path d="M10.3674 14.907H8.85018L9.79893 9.10503H11.3162L10.3674 14.907ZM15.8679 9.24753C15.4335 9.08201 14.9723 8.9981 14.5074 9.00003C13.0089 9.00003 11.9537 9.78978 11.9469 10.92C11.9349 11.7525 12.7022 12.216 13.2767 12.4935C13.8639 12.7778 14.0642 12.963 14.0642 13.2158C14.0574 13.6043 13.5894 13.7835 13.1522 13.7835C12.5462 13.7835 12.2222 13.6913 11.7287 13.4753L11.5284 13.3823L11.3162 14.685C11.6724 14.8448 12.3279 14.9873 13.0089 14.9933C14.6012 14.9933 15.6377 14.2155 15.6497 13.0125C15.6557 12.3518 15.2507 11.8455 14.3762 11.4323C13.8452 11.1668 13.5204 10.9875 13.5204 10.716C13.5264 10.4685 13.7949 10.2158 14.3942 10.2158C14.7828 10.2047 15.169 10.2808 15.5244 10.4385L15.6617 10.5L15.8679 9.24753ZM17.8854 12.852L18.4907 11.2283C18.4847 11.241 18.6159 10.8893 18.6902 10.6733L18.7967 11.1728C18.7967 11.1728 19.0839 12.5618 19.1462 12.852H17.8854ZM19.7582 9.10503H18.5844C18.2222 9.10503 17.9469 9.21003 17.7909 9.58653L15.5372 14.907H17.1294L17.4482 14.037H19.3959C19.4402 14.2403 19.5774 14.907 19.5774 14.907H20.9822L19.7582 9.10503ZM7.58268 9.10503L6.09618 13.0613L5.93418 12.2588C5.65968 11.3333 4.79793 10.3275 3.83643 9.82728L5.19768 14.901H6.80268L9.18768 9.10503H7.58268Z" fill="white" />
            <path d="M4.71675 9.10498H2.27475L2.25 9.22273C4.155 9.70348 5.41575 10.8645 5.934 12.2595L5.403 9.59248C5.316 9.22198 5.0475 9.11773 4.71675 9.10498Z" fill="white" />
        </svg>

    )
}
