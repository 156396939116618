import React, { useEffect, useState } from "react";
import Header from "../../layout/Header/Header";
import SideMenu from "../../components/sidemenu/SideMenu";
import { useNavigate } from "react-router-dom";
import RenderCsvList from "./RenderCsvList";
import NotificationMsg from '../../components/Alerts/NotificationMsg';
import axiosClient from "../../axios.client";

const FTP_facebook = () => {
  const navigate = useNavigate();

  const [loadingCSV, setLoadingCSV] = useState(false);
  const [csvData, setCsvData] = useState([]);


  const fetchCSV = async () => {
    setLoadingCSV(true);

    try {
      const response = await axiosClient.get(`/get-mapped-csv-data`);
      if (response.status === 200) {

        setCsvData(response.data);
      } else {
        setCsvData(null);
      }
    } catch (error) {
      console.error(error);
    }

    setLoadingCSV(false);
  };

  const [notification, setNotification] = useState({
    isOpen: false,
    status: "",
    msg: "",
  });

  const SetAlert = (bool, status, msg) => {
    setNotification({ isOpen: bool, status: status, msg: msg });

    setTimeout(() => {
      setNotification({ isOpen: false, status: "", msg: "" });
    }, 5000);
  };

  useEffect(() => { fetchCSV() }, []);

  return (
    <>
      {/* Notification Msg Start */}
      <NotificationMsg
        toggle={notification}
        Toggler={setNotification}
        message={notification.msg}
      ></NotificationMsg>
      {/* Notification Msg End */}

      <div className="wrapper bg-backgroudWrapper">
        <div className="md:flex w-full h-screen">
          <SideMenu />

          <div
            className="w-full bg-backgroudPrimary overflow-x-auto"
            id="main-content"
          >
            <Header />

            <div className="px-[18px] md:px-[24px] md:mx-[14px]  md:h-[calc(100vh-120px)] bg-white">
              <div className="hidden md:flex justify-between items-center my-[30px]">

                <h1 className="text-xl font-semibold">Facebook Mapping List</h1>

                <button className="text-white bg-buttonPrimary hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-[16px] px-5 py-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  onClick={() => navigate('/ftp-facebook/create-ftp')}>Create</button>
              </div>

              <div id="tabWrapper">
                <div id="tabs">
                </div>
                <RenderCsvList loadingCSV={loadingCSV} csvData={csvData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FTP_facebook;
