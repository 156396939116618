import React from 'react'

export const CountPending = () => {
    return (
        <div className="flex justify-center items-center">
            <div className="relative inline-flex">
                <div className="w-[10px] h-[10px] bg-blue-400 rounded-full"></div>
                <div className="w-[10px] h-[10px] bg-blue-400 rounded-full absolute top-0 left-0 animate-ping"></div>
                <div className="w-[10px] h-[10px] bg-blue-400 rounded-full absolute top-0 left-0 animate-pulse"></div>
            </div>
        </div>
    )
}