import { UniqueLeverageIcon } from "components/icons/UniqueLeverageIcon";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

const SideMenu = () => {
    const navigate = useNavigate();

    const [toggleNavbar, setToggleNavbar] = useState(false);

    return (
        <div className="pt-10 bg-[#FBFCFD]">
            <ul className="flex flex-col gap-3 text-[16px]">
                <NavLink to={"/"}
                    className={({ isActive }) =>
                        isActive
                            ? "text-buttonPrimary font-semibold cursor-pointer px-5 border-l-2 border-buttonPrimary"
                            : "text-[#353A44] font-semibold cursor-pointer px-5"
                    }>
                    Home</NavLink>

                <NavLink to={"/dealerShip"}
                    className={({ isActive }) =>
                        isActive
                            ? "text-buttonPrimary font-semibold cursor-pointer px-5 border-l-2 border-buttonPrimary"
                            : "text-[#353A44] font-semibold cursor-pointer px-5"
                    }>
                    Dealership</NavLink>


                <li className="text-[#353A44] font-semibold cursor-pointer px-5"
                    onClick={() => navigate("/")}>
                    Team</li>


                <li className="text-[#353A44] font-semibold cursor-pointer px-5"
                    onClick={() => navigate("/")}>
                    More +</li>
            </ul>
        </div>
    )
}

export default SideMenu