import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SettingIcon } from "../../components/icons/SettingIcon";
import { EditIcon } from "../../components/icons/EditIcon";
import UserTableMobileView from "./UserTableMobileView";
import axiosClient from "../../axios.client";
import { CountPending } from "../../components/Button/CountPending";
import { LoadingBtn } from "../../components/Button/LoadingBtn";

const UserTable = ({
  allUsersList,
  userFormData,
  handleUserInputChange,
  setToggleFtpModel,
  toggleFtpModel,
  setEditToggle,
  setAllUsersList,
  userVehicleCount,
  search,
  setProcessDataAlert,
  processLoader,
  isLoading,
}) => {
  const navigate = useNavigate();

  const [cruiseToggle, setCruiseToggle] = useState(true);
  const [selectedUser, setSelectedUser] = useState(
    allUsersList && allUsersList.length > 0 ? allUsersList[0].id : ""
  );

  const DateFormat = (data) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const date = new Date(data);
    const year = date.getFullYear();
    const monthIndex = date.getMonth();
    const day = date.getDate();

    const formattedDate = `${months[monthIndex]}, ${day}, ${year}`;

    return formattedDate;
  };


  return (
    <div id="tabContent">
      <div
        id="UsersTab"
        className="flex flex-col justify-between h-[calc(100vh-220px)]"
      >
        {/* Desktop View */}
        <div className="overflow-x-auto hidden md:block">
          <div className="p-1.5 min-w-full align-middle pt-7">
            {/* <div className="rounded-lg overflow-hidden"> */}
            <table className="border-b min-w-full divide-y divide-gray-200">
              <thead>
                <tr className="sticky top-0 bg-white">
                  <th
                    scope="col"
                    className="px-2 py-3 text-left text-[16px] font-semibold text-textTableHeader"
                  >
                    ID
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3 text-left text-[16px] font-semibold text-textTableHeader"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3 text-left text-[16px] font-semibold text-textTableHeader"
                  >
                    User
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3 text-left text-[16px] font-semibold text-textTableHeader"
                  >
                    Vehicles
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3 text-left text-[16px] font-semibold text-textTableHeader"
                  >
                    Pending
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3 text-left text-[16px] font-semibold text-textTableHeader"
                  >
                    Active
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3 text-left text-[16px] font-semibold text-textTableHeader"
                  >
                    FTP Account
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3 text-left text-[16px] font-semibold text-textTableHeader"
                  >
                    Last Active
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3 text-left text-[16px] font-semibold text-textTableHeader"
                  >
                    Last Process
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3 text-left text-[16px] font-semibold text-textTableHeader"
                  >
                    Manage
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-3 text-left text-[16px] font-semibold text-textTableHeader"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="border divide-y divide-gray-200">
                {allUsersList &&
                  allUsersList
                    .filter((user) => {
                      if (search.pageName === "users") {
                        return search.value.toLowerCase() === ""
                          ? user
                          : user.username
                            .toLowerCase()
                            .includes(search.value) ||
                          user.id
                            .toString()
                            .toLowerCase()
                            .includes(search.value);
                      }
                      return user;
                    })
                    .map((user, index) => {
                      return (
                        <tr
                          className={
                            user.freeze === 1 ? "divide-x h-16 bg-red-100" :
                              index % 2 === 0
                                ? "divide-x h-16 bg-backgroudSecondary"
                                : "divide-x h-16 bg-backgroudPrimary"
                          }
                          key={user.id}
                        >
                          <td className="px-6 py-4 whitespace-nowrap text-[16px] text-gray-800 dark:text-gray-200">
                            {user.id}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[16px] text-gray-800 dark:text-gray-200">
                            Online
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[16px] text-gray-800 dark:text-gray-200">
                            {user.username}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[16px] text-textSecondary dark:text-gray-200">

                            {!isLoading ? userVehicleCount.listed[user.id] ? userVehicleCount.listed[user.id] : "0" : <CountPending />}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[16px] text-textSecondary dark:text-gray-200">

                            {!isLoading ? userVehicleCount.pending[user.id] ? userVehicleCount.pending[user.id] : "0" : <CountPending />}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[16px] text-textSecondary dark:text-gray-200">

                            {!isLoading ? userVehicleCount.advertised[user.id] ? userVehicleCount.advertised[user.id] : "0" : <CountPending />}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[16px] dark:text-gray-200">
                            {user.FTPaccount}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[16px] text-gray-800 dark:text-gray-200">
                            {DateFormat(user.updated_at)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[16px] text-gray-800 dark:text-gray-200">
                            {DateFormat(user.lastprocessedCSV)}
                          </td>
                          <td className="py-4 whitespace-nowrap text-[16px] text-gray-800 dark:text-gray-200 text-center">
                            <button
                              className="py-2 px-3 text-white bg-textSecondary rounded-lg mr-2"
                              onClick={() =>
                                navigate(`/manage-vehicles/${user.id}`)
                              }
                            >
                              Manage Vehicles
                            </button>
                            {
                              processLoader.ftp === user.FTPaccount ? (
                                processLoader.isOpen ? (
                                  <button><LoadingBtn /></button>
                                ) : (
                                  <button
                                    className="py-2 px-3 text-white bg-textSecondary rounded-lg mr-2"
                                    onClick={() => {
                                      setProcessDataAlert({ isOpen: true, data: user.FTPaccount });
                                    }}
                                  >
                                    Process Data
                                  </button>
                                )
                              ) : (
                                <button
                                  className="py-2 px-3 text-white bg-textSecondary rounded-lg mr-2"
                                  onClick={() => {
                                    setProcessDataAlert({ isOpen: true, data: user.FTPaccount });
                                  }}
                                >
                                  Process Data
                                </button>
                              )
                            }


                            <button
                              className="py-2 px-5 rounded-lg border border-[#D4D4D4] bg-[#F9F9F9] ml-2"
                              onClick={() => navigate(`/view-logs/${user.id}`)}
                            >
                              View Logs
                            </button>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-[16px] text-gray-800 dark:text-gray-200 text-center">
                            <button
                              className="pr-1"
                              onClick={() =>
                                setToggleFtpModel({
                                  id: user.id,
                                  isBool: true,
                                })
                              }
                            >
                              <SettingIcon />
                            </button>
                            <button
                              className="pl-1"
                              onClick={() =>
                                setEditToggle({
                                  isOpen: true,
                                  data: {
                                    id: user.id || "",
                                    name: user.name || "",
                                    password: user.pass || "",
                                    phone: user.phone || "",
                                    first_name: user.first_name || "",
                                    last_name: user.last_name || "",
                                    username: user.username || "",
                                    email: user.email || "",
                                    address: user.address || "",
                                    status: user.status || "",
                                    inventory_source: user.inventory_source || "",
                                    FTPaccount: user.FTPaccount || "",
                                  },
                                })
                              }
                            >
                              <EditIcon width={20} height={20} />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>

          {/* Mobile View */}
          <UserTableMobileView
            cruiseToggle={cruiseToggle}
            setCruiseToggle={setCruiseToggle}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            DateFormat={DateFormat}
            allUsersList={allUsersList}
            setAllUsersList={setAllUsersList}
          ></UserTableMobileView>
        </div>
      </div>
    </div>
  );
};

export default UserTable;
