import { useCustomerContext } from 'Context/CustomerContext';
import { DownArrowIcon } from 'components/icons/DownArrowIcon'
import React from 'react'

const MainFilters = ({
    userMode,
    modifiedUser,
}) => {

    const { userList } = useCustomerContext();

    return (
        <div className='flex items-center justify-between gap-5 flex-wrap text-[14px] pt-[24px]'>
            <button className={`${userMode === 'all' ? "border-[#1B74E4] text-[#1B74E4] border-1" : "text-[#414552]"} p-[9px] flex-1 border rounded-[6px] font-medium text-start hover:border-[#1B74E4] duration-300`}
                onClick={() => { modifiedUser("all") }}>All ({userList?.all?.length || 0})</button>

            <button className={`${userMode === 'frozen' ? "border-[#1B74E4] text-[#1B74E4] border-1" : "text-[#414552]"} p-[9px] flex-1 border rounded-[6px] font-medium text-start hover:border-[#1B74E4] duration-300`}
                onClick={() => { modifiedUser("frozen") }}>Frozen ({userList?.frozen?.length || 0})</button>

            <button className={`${userMode === 'support' ? "border-[#1B74E4] text-[#1B74E4] border-1" : "text-[#414552]"} p-[9px] flex-1 border rounded-[6px] font-medium text-start hover:border-[#1B74E4] duration-300`}
                onClick={() => { modifiedUser("support") }}>Support ({userList?.support?.length || 0})</button>

            <button className={`${userMode === 'canceled' ? "border-[#1B74E4] text-[#1B74E4] border-1" : "text-[#414552]"} p-[9px] flex-1 border rounded-[6px] font-medium text-start hover:border-[#1B74E4] duration-300`}
                onClick={() => { modifiedUser("canceled") }}>Canceled ({userList?.canceled?.length || 0})</button>

            <div className='border border-[#10111A29] rounded-[6px] p-[9px] flex-1 flex font-medium hover:border-[#1B74E4] duration-300'>
                <button className='text-start text-[#414552] border-r flex-1'> Errors</button>
                <button className='text-end text-[#414552] px-1 flex items-center gap-2'>  <DownArrowIcon /></button>
            </div>
        </div>
    )
}

export default MainFilters