import { checkImageURL } from 'Utils/CheckImageURL';

export default function ImageSlider({ setVehicleImages, images }) {

    const slideLeft = () => {
        var slider = document.getElementById('slider');
        slider.scrollLeft = slider.scrollLeft - 500;
    };

    const slideRight = () => {
        var slider = document.getElementById('slider');
        slider.scrollLeft = slider.scrollLeft + 500;
    };

    return (
        <>
            <div className='relative flex items-center'>
                <div onClick={slideLeft} className='cursor-pointer bg-white rounded-full p-[4px] absolute -left-0 z-10'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-gray-500">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                    </svg>
                </div>

                <div id='slider' className='w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide rounded-md'>
                    {images?.map((image, index) =>
                        <img className='w-24 inline-block p-2 cursor-pointer hover:scale-105 ease-in-out duration-300 rounded-md h-[70px] object-fit' src={checkImageURL(image) ? image : ""} alt='/' key={index} onClick={() => { setVehicleImages(image) }} />
                    )}
                </div>

                <div onClick={slideRight} className='cursor-pointer bg-white rounded-full p-[4px] absolute -right-0 z-10'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-gray-500">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                    </svg>
                </div>
            </div>
        </>
    );
}