import React from "react";

export const BoxIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.3075 5.68481L11.745 1.54692C11.5429 1.43527 11.3158 1.37671 11.085 1.37671C10.8541 1.37671 10.627 1.43527 10.425 1.54692L2.86246 5.68653C2.64649 5.8047 2.46621 5.97868 2.34044 6.19032C2.21466 6.40195 2.14802 6.64347 2.14746 6.88966V15.1087C2.14802 15.3549 2.21466 15.5964 2.34044 15.8081C2.46621 16.0197 2.64649 16.1937 2.86246 16.3118L10.425 20.4515C10.627 20.5631 10.8541 20.6217 11.085 20.6217C11.3158 20.6217 11.5429 20.5631 11.745 20.4515L19.3075 16.3118C19.5234 16.1937 19.7037 16.0197 19.8295 15.8081C19.9553 15.5964 20.0219 15.3549 20.0225 15.1087V6.89051C20.0224 6.64389 19.9559 6.40184 19.8301 6.18971C19.7043 5.97758 19.5238 5.80319 19.3075 5.68481ZM11.085 2.75005L17.9892 6.5313L15.4308 7.93208L8.52574 4.15083L11.085 2.75005ZM11.085 10.3125L4.18074 6.5313L7.09402 4.9363L13.9982 8.71755L11.085 10.3125ZM3.52246 7.73442L10.3975 11.4968V18.8693L3.52246 15.1096V7.73442ZM18.6475 15.1061L11.7725 18.8693V11.5002L14.5225 9.99544V13.0625C14.5225 13.2449 14.5949 13.4198 14.7238 13.5487C14.8528 13.6776 15.0276 13.75 15.21 13.75C15.3923 13.75 15.5672 13.6776 15.6961 13.5487C15.825 13.4198 15.8975 13.2449 15.8975 13.0625V9.24262L18.6475 7.73442V15.1053V15.1061Z"
        fill="#484848"
      />
    </svg>
  );
};
