import React from 'react'

export const FacebookLogo = () => {
    return (
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="34" height="34" rx="3" fill="#1B74E4" />
            <path d="M18.0478 23.7478V17.6008H20.1215L20.4298 15.194H18.0478V13.661C18.0478 12.9665 18.2413 12.491 19.238 12.491H20.501V10.3453C19.8865 10.2794 19.2688 10.2476 18.6508 10.25C16.8178 10.25 15.5593 11.369 15.5593 13.4233V15.1895H13.499V17.5963H15.5638V23.7478H18.0478Z" fill="white" />
        </svg>
    )
}
