import React from 'react'
import { ExitIcon } from '../icons/ExitIcon'

export const ProcessDataModel = ({
    setProcessDataAlert,
    processDataModel,
    SetAlert,
    handleUserProcessData
}) => {
    return (
        <div className="p-5 bg-white rounded-md">
            <div className="flex items-center justify-between">
                <div></div>
                <button
                    className="font-bold"
                    onClick={() =>
                        setProcessDataAlert({ isOpen: false, data: [] })
                    }
                >
                    <ExitIcon />
                </button>
            </div>

            <div className='p-5 mt-5'>
                <h1>Are you sure you want to process data</h1>
            </div>

            <div className='flex justify-center my-5'>
                <button className='bg-buttonPrimary text-white rounded-md px-5 p-2' onClick={() => {
                    handleUserProcessData(processDataModel.data);
                    setProcessDataAlert({ isOpen: false, data: [] })
                    SetAlert(true, 'success', 'Process data started');
                }}>Confirm</button>
            </div>
        </div>
    )
}
