import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../../layout/Header/Header";
import SideMenu from "../../../components/sidemenu/SideMenu";
import axiosClient from "../../../axios.client";

const AddDealerShip = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    dealerShip: "",
    website: "",
    state: "",
    city: "",
    phoneNo: "",
    cellNo: "",
    formPackage: "",
    formUser: "",
    firstName: "",
    lastName: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const formSubmit = async (e) => {
    e.preventDefault();

    await axiosClient
      .post("/addDealerShip", formData)
      .then((res) => console.log(res))
      .catch((error) => {
        console.log(error);
      });

    navigate("/");
  };

  return (
    <div className="wrapper bg-backgroudWrapper">
      <div className="md:flex w-full h-screen">
        <SideMenu />

        <div className="w-full bg-backgroudPrimary overflow-x-auto" id="main-content">
          <Header />

          <div className="absolute top-0 w-full h-full bg-white z-50 md:hidden">
            <div className="flex justify-between p-4 py-5">
              <h1 className="text-2xl font-bold">Add Dealership</h1>
              <button className="font-bold" onClick={() => navigate("/")}>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 15L15 1M1 1L15 15"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
            </div>
            <div className="mt-3 p-4">
              <form onSubmit={formSubmit}>
                <div className="mb-4">
                  <label className="block font-bold">Dealership Name</label>
                  <input
                    name="dealerShip"
                    type="text"
                    placeholder="GeorgesUsedCars"
                    className="w-full border border-[#EAEAEA] p-2 mt-2"
                    value={formData.dealerShip}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="block font-bold">Website URL</label>
                  <input
                    type="text"
                    name="website"
                    placeholder="https://www.georgesapproval.com/"
                    className="w-full border border-[#EAEAEA] p-2 mt-2"
                    value={formData.website}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="block font-bold">State</label>
                  <input
                    type="text"
                    name="state"
                    placeholder="Michigan"
                    className="w-full border border-[#EAEAEA] p-2 mt-2"
                    value={formData.state}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="block font-bold">City</label>
                  <input
                    type="text"
                    name="city"
                    placeholder="Allen Park"
                    className="w-full border border-[#EAEAEA] p-2 mt-2"
                    value={formData.city}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="block font-bold">Phone Number</label>
                  <input
                    type="number"
                    name="phoneNo"
                    placeholder="+1 313-530-6666"
                    className="w-full border border-[#EAEAEA] p-2 mt-2"
                    value={formData.phoneNo}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="block font-bold">Cell Number</label>
                  <input
                    type="number"
                    name="cellNo"
                    placeholder="+1 313-530-6666"
                    className="w-full border border-[#EAEAEA] p-2 mt-2"
                    value={formData.cellNo}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="block font-bold">Package</label>
                  <select
                    name="formPackage"
                    className="w-full border border-[#EAEAEA] p-2 mt-2"
                    defaultChecked=""
                    value={formData.formPackage}
                    onChange={handleInputChange}
                  >
                    <option value="" disabled>
                      Select Package
                    </option>
                    <option value="Monthly">Monthly</option>
                    <option value="Yearly">Yearly</option>
                  </select>
                </div>
                <div className="mb-4">
                  <label className="block font-bold">User</label>
                  <input
                    type="number"
                    name="formUser"
                    placeholder="2"
                    className="w-full border border-[#EAEAEA] p-2 mt-2"
                    value={formData.formUser}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="block font-bold">First Name</label>
                  <input
                    type="name"
                    name="firstName"
                    placeholder="Nathan"
                    className="w-full border border-[#EAEAEA] p-2 mt-2"
                    value={formData.firstName}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="mb-4">
                  <label className="block font-bold">Last Name</label>
                  <input
                    type="name"
                    name="lastName"
                    placeholder="Allison"
                    className="w-full border border-[#EAEAEA] p-2 mt-2"
                    value={formData.lastName}
                    onChange={handleInputChange}
                  />
                </div>
                <button
                  type="submit"
                  className="p-2 bg-buttonPrimary text-white rounded-md w-full mt-6 mb-2"
                >
                  Add Dealership
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDealerShip;
