import React, { useEffect, useRef, useState } from "react";
import { MenuIcon } from "../../../../components/icons/MenuIcon";
import { CarIcon } from "../../../../components/icons/CarIcon";
import { ActiveIcon } from "../../../../components/icons/ActiveIcon";
import { PendingIcon } from "../../../../components/icons/PendingIcon";
import { DeleteIcon } from "../../../../components/icons/DeleteIcon";
import { EditIcon } from "../../../../components/icons/EditIcon";

export const EditButton = ({
  option,
  listedCheckedValues,
  advertisedCheckedValues,
  pendingCheckedValues,
  BulkMoveToVehicle,
  BulkMoveToActive,
  BulkMoveToPending,
  BulkDeleteRow,
  BulkEditDescription,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const editButtonRef = useRef();

  const handleRowClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickedOutside = (e) => {
    if (editButtonRef.current && !editButtonRef.current.contains(e.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickedOutside);
    return () => {
      document.removeEventListener("click", handleClickedOutside);
    };
  }, []);

  const renderVehiclesMenu = () => {
    return (
      <div className="block absolute right-5 top-[-15px] bg-white z-10 shadow-lg rounded-lg w-[180px]">
        <span
          className="text-[#6C6C6C] p-3 px-4 flex items-center gap-2 hover:bg-gray-100"
          onClick={() => BulkMoveToActive("vehicle")}
        >
          <ActiveIcon />
          Mark as Active
        </span>
        <span
          className="text-[#6C6C6C] p-3 px-4 flex items-center gap-2 hover:bg-gray-100"
          onClick={() => BulkMoveToPending("vehicle")}
        >
          <PendingIcon />
          Mark as Pending
        </span>
        <span
          className="text-[#6C6C6C] p-3 px-4 flex items-center gap-2 hover:bg-gray-100"
          onClick={() => BulkEditDescription("vehicle")}
        >
          <EditIcon />
          Edit Description
        </span>
        <span
          className="text-red-500 p-3 px-4 font-semibold flex items-center gap-2 hover:bg-gray-100"
          onClick={() => BulkDeleteRow("vehicle")}
        >
          <DeleteIcon />
          Delete
        </span>
      </div>
    );
  };

  const renderActiveMenu = () => {
    return (
      <div className="block absolute right-5 top-[-15px] bg-white z-10 shadow-lg rounded-lg w-[180px]">
        <span
          className="text-[#6C6C6C] p-3 px-4 flex items-center gap-2 hover:bg-gray-100"
          onClick={() => BulkMoveToVehicle("advertised")}
        >
          <CarIcon />
          Mark as Listed
        </span>
        <span
          className="text-[#6C6C6C] p-3 px-4 flex items-center gap-2 hover:bg-gray-100"
          onClick={() => BulkMoveToPending("advertised")}
        >
          <PendingIcon />
          Mark as Pending
        </span>
        <span
          className="text-[#6C6C6C] p-3 px-4 flex items-center gap-2 hover:bg-gray-100"
          onClick={() => BulkEditDescription("advertised")}
        >
          <EditIcon />
          Edit Description
        </span>
        <span
          className="text-red-500 p-3 px-4 font-semibold flex items-center gap-2 hover:bg-gray-100"
          onClick={() => BulkDeleteRow("advertised")}
        >
          <DeleteIcon />
          Delete
        </span>
      </div>
    );
  };

  const renderPendingMenu = () => {
    return (
      <div className="block absolute right-5 top-[-15px] bg-white z-10 shadow-lg rounded-lg w-[180px]">
        <span
          className="text-[#6C6C6C] p-3 px-4 flex items-center gap-2 hover:bg-gray-100"
          onClick={() => BulkMoveToVehicle("pending")}
        >
          <CarIcon />
          Mark as Listed
        </span>
        <span
          className="text-[#6C6C6C] p-3 px-4 flex items-center gap-2 hover:bg-gray-100"
          onClick={() => BulkMoveToActive("pending")}
        >
          <ActiveIcon />
          Mark as Active
        </span>
        <span
          className="text-[#6C6C6C] p-3 px-4 flex items-center gap-2 hover:bg-gray-100"
          onClick={() => BulkEditDescription("pending")}
        >
          <EditIcon />
          Edit Description
        </span>
        <span
          className="text-red-500 p-3 px-4 font-semibold flex items-center gap-2 hover:bg-gray-100"
          onClick={() => BulkDeleteRow("pending")}
        >
          <DeleteIcon />
          Delete
        </span>
      </div>
    );
  };

  return (
    <>
      {option === "Vehicles" && listedCheckedValues.length > 0 && (
        <button className="relative">
          <span
            className={"align-middle"}
            ref={editButtonRef}
            onClick={handleRowClick}
          >
            <MenuIcon />
          </span>
          {isMenuOpen && renderVehiclesMenu()}
        </button>
      )}

      {option === "Active" && advertisedCheckedValues.length > 0 && (
        <button className="relative">
          <span
            className={"align-middle"}
            ref={editButtonRef}
            onClick={handleRowClick}
          >
            <MenuIcon />
          </span>
          {isMenuOpen && renderActiveMenu()}
        </button>
      )}

      {option === "Pending" && pendingCheckedValues.length > 0 && (
        <button className="relative">
          <span
            className={"align-middle"}
            ref={editButtonRef}
            onClick={handleRowClick}
          >
            <MenuIcon />
          </span>
          {isMenuOpen && renderPendingMenu()}
        </button>
      )}
    </>
  );
};
