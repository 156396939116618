import React, { useEffect, useState } from 'react'
import NotificationMsg from 'components/Alerts/NotificationMsg';
import SideMenu from 'components/sidemenu/SideMenu';
import Header from 'layout/Header/Header';
import { RightMarkToggleIcon } from 'components/icons/RightMarkToggleIcon';
import CustomerInfo from './overview/customerInfo/CustomerInfo';
import CustomerRendering from './overview/customerRendering/CustomerRendering';
import { MetaDataEditModal } from './overview/customerInfo/modal/MetaDataEditModal';
import EventsAndLogs from './eventsAndLogs/EventsAndLogs';
import { useCustomerDetailContext } from 'Context/customerDetailContext/CustomerDetailContext';
import { useNavigate, useParams } from 'react-router-dom';
import AddUserModel from './components/AddUserModel'

const CustomerDetail = () => {
    const { customerID } = useParams();
    const navigate = useNavigate();

    const {
        selectedPage,
        notification,
        setNotification,
        SetAlert,
        fetchUsersList,
        loading,
        customerDetail,
        setAddUserModel,
        addUserModel,
    } = useCustomerDetailContext();


    useEffect(() => {
        fetchUsersList(customerID);
    }, [customerID]);

    return (
        <>
            {/* Notification Msg Start */}
            <NotificationMsg
                toggle={notification}
                Toggler={setNotification}
                message={notification.msg}
            ></NotificationMsg>
            {/* Notification Msg End */}



            <div className='pb-7 pt-[0px] flex bg-white w-[inherit]'>
                {loading ?
                    <div className="flex justify-center w-full items-center mt-60">
                        <div className="w-12 h-12 border-t-4 border-buttonPrimary border-solid border-opacity-80 rounded-full animate-spin"></div>
                    </div>
                    :

                    <div className='flex items-start gap-[40px] w-[inherit]'>
                        <div className='sticky top-14'>
                            <CustomerInfo />
                        </div>

                        <div className='w-[inherit]'>
                            {
                                selectedPage === "overview" ?
                                    <CustomerRendering customerID={customerID} />
                                    :
                                    <EventsAndLogs />
                            }
                        </div>
                    </div>}
            </div>
        </>
    )
}

export default CustomerDetail