import SplitAddress from 'Utils/SplitAddress';
import { RightMarkToggleIcon } from 'components/icons/RightMarkToggleIcon';
import { USFlag } from 'components/Flags/USFlag';
import { DownArrowToggleIcon } from 'components/icons/DownArrowToggleIcon';
import { useCustomerDetailContext } from 'Context/customerDetailContext/CustomerDetailContext';

export const Details = ({ setToggleDetail, toggleDetail }) => {
    const {
        customerDetail,
        setEditCustomerDetail,
    } = useCustomerDetailContext();

    return (
        <>
            <div className={`flex items-center justify-between pt-3 duration-300 ${!toggleDetail && "pb-3 border-b"}`}>
                <h1 className='font-bold text-[16px] flex items-center gap-2 cursor-pointer'
                    onClick={() => { setToggleDetail(!toggleDetail) }}>
                    {!toggleDetail ? <DownArrowToggleIcon /> :
                        <RightMarkToggleIcon
                            color="#687385"
                            height={13}
                            width={13}
                        />}
                    Details</h1>
                <button className={`${toggleDetail && "hidden"} text-[#0C71EB] hover:text-[#5399ef] duration-300 text-sm font-medium`}
                    onClick={() => { setEditCustomerDetail({ customer: customerDetail, isOpen: true }) }}>Edit</button>
            </div>

            {/* details dropdown */}

            <div className={`text-[14px] font-normal py-2 flex flex-col gap-3 ${toggleDetail && "hidden"}`}>
                <div className='hidden'>
                    <h1 className='text-[#687385]'>Customer ID</h1>
                    <div className='px-1 w-fit text-[12px]'>
                        {customerDetail.id ? customerDetail.id : "N/A"}
                    </div>
                </div>

                <div className='hidden'>
                    <h1 className='text-[#687385]'>Account Owner</h1>
                    <h2 className='text-[#414552]'>{customerDetail.name ? customerDetail.name : "-"}</h2>
                </div>

                <div>
                    <h1 className='text-[#687385]'>Name</h1>
                    <h2 className='text-[#414552]'>{customerDetail.first_name ? customerDetail.first_name + ' ' + customerDetail.last_name : "-"}</h2>
                </div>

                <div>
                    <h1 className='text-[#687385]'>Email</h1>
                    <h2 className='text-[#414552]'>{customerDetail.email ? customerDetail.email : "-"}</h2>
                </div>

                <div>
                    <h1 className='text-[#687385]'>Address</h1>
                    <h2 className='text-[#414552]'>{SplitAddress(customerDetail.address).state}</h2>
                    <h2 className='text-[#414552] flex items-center gap-2'>{SplitAddress(customerDetail.address).city} <USFlag /></h2>
                    <h2 className='text-[#414552]'>{customerDetail.mobile}</h2>
                </div>

                <div>
                    <h1 className='text-[#687385]'>Dealership Phone</h1>
                    <h2 className='text-[#0C71EB]'>{customerDetail.phone ? customerDetail.phone : "-"}</h2>
                </div>

                <button className='text-buttonPrimary text-left hidden'
                    onClick={() => { setToggleDetail(!toggleDetail) }}>Show less
                </button>
            </div>
        </>
    )
}
