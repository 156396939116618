import { useCustomerContext } from 'Context/CustomerContext';
import { useUserContext } from 'Context/UserContext';
import SearchInput from './components/SearchInput';

const AddUserModel = () => {
    const {
        setToggleAddUser,
        toggleAddUser,
        setSelectedDealershipName,
        selectedDealershipName,
        formData,
        handleInputChange,
        formSubmit,
    } = useUserContext();

    const {
        allCustomers,
    } = useCustomerContext();

    return (
        <div className="fixed inset-0 z-[110] bg-[#D3D9DF] bg-opacity-50 flex justify-center items-center font-roboto">
            <div className="bg-white w-[500px] h-[fit-content] max-h-[100vh]  rounded-lg shadow-md overflow-y-auto">
                <div className="flex justify-between items-center p-5">
                    <h1 className="font-[500]">Create customer</h1>
                </div>
                <hr />
                {/* <h1 className="font-[600] mt-5 px-5">User Information</h1> */}

                <form onSubmit={formSubmit}>
                    <div className="flex flex-col gap-3 px-5 py-5">
                        <div className="flex flex-col gap-2">
                            <label htmlFor="dealerShip" className="font-[500] text-sm">Dealership</label>
                            <SearchInput
                                userName={""}
                                list={allCustomers}
                                setSelectedDealershipName={setSelectedDealershipName}
                            />

                            {/* <select name="name"
                                className="border border-gray-200 rounded-md placeholder:text-sm placeholder:font-[400] px-2 py-[3px] focus:shadow-blue-100 focus:shadow-md outline-none cursor-pointer text-sm font-[500] duration-300"
                                onChange={(e) => {
                                    setSelectedDealershipName(e.target.value);
                                    handleInputChange(e);
                                }}
                                defaultValue={selectedDealershipName}
                            >
                                {allCustomers.length > 0 &&
                                    allCustomers.map((dealerName) => {
                                        return (
                                            <option
                                                value={dealerName.name}
                                                key={dealerName.id}
                                                className="text-gray-700 font-[500] text-sm cursor-pointer"
                                            >
                                                {dealerName.name}
                                            </option>
                                        );
                                    })}
                            </select> */}
                        </div>

                        <div className="flex flex-col gap-2">
                            <label htmlFor="first_name" className="font-[500] text-sm">First Name</label>
                            <input type="name"
                                name="first_name"
                                onChange={handleInputChange}
                                value={formData.first_name}
                                required
                                id="first_name"
                                className="border border-gray-200 rounded-md placeholder:text-sm placeholder:font-[400] px-2 py-[3px] focus:shadow-blue-100 focus:shadow-md outline-none duration-300"
                                placeholder="Jenny"
                                autoComplete="off"
                                autoFocus
                            />

                        </div>

                        <div className="flex flex-col gap-2">
                            <label htmlFor="last_name" className="font-[500] text-sm">Last Name</label>
                            <input type="text"
                                name="last_name"
                                id="last_name"
                                className="border border-gray-200 rounded-md placeholder:text-sm placeholder:font-[400] px-2 py-[3px] focus:shadow-blue-100 focus:shadow-md outline-none duration-300"
                                placeholder="Rosen"
                                autoComplete="off"
                                required
                                onChange={handleInputChange}
                                value={formData.last_name}
                            />
                        </div>

                        <div className="flex flex-col gap-2">
                            <label htmlFor="username" className="font-[500] text-sm">User Name</label>
                            <input type="text"
                                name="username"
                                id="username"
                                className="border border-gray-200 rounded-md placeholder:text-sm placeholder:font-[400] px-2 py-[3px] focus:shadow-blue-100 focus:shadow-md outline-none duration-300"
                                placeholder="JennyRosen"
                                autoComplete="off"
                                required
                                value={formData.username}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="flex flex-col gap-2">
                            <label htmlFor="password" className="font-[500] text-sm">Password</label>
                            <input type="text"
                                name="password"
                                id="password"
                                className="border border-gray-200 rounded-md placeholder:text-sm placeholder:font-[400] px-2 py-[3px] focus:shadow-blue-100 focus:shadow-md outline-none duration-300"
                                placeholder="Admin@1122"
                                autoComplete="off"
                                required
                                value={formData.password}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="flex flex-col gap-2">
                            <label htmlFor="phone" className="font-[500] text-sm">Phone Number</label>
                            <input type="number"
                                name="phone"
                                id="phone"
                                className="border border-gray-200 rounded-md placeholder:text-sm placeholder:font-[400] px-2 py-[3px] focus:shadow-blue-100 focus:shadow-md outline-none duration-300"
                                placeholder="555 555-1234"
                                autoComplete="off"
                                required
                                value={formData.phone}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="flex flex-col gap-2">
                            <label htmlFor="email" className="font-[500] text-sm">Email</label>
                            <input type="text"
                                name="email"
                                id="email"
                                className="border border-gray-200 rounded-md placeholder:text-sm placeholder:font-[400] px-2 py-[3px] focus:shadow-blue-100 focus:shadow-md outline-none duration-300"
                                placeholder="jenny.rosen@gmail.com"
                                autoComplete="off"
                                required
                                value={formData.email}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="flex flex-col gap-2">
                            <label htmlFor="address" className="font-[500] text-sm">Address</label>
                            <input type="text"
                                name="address"
                                id="address"
                                className="border border-gray-200 rounded-md placeholder:text-sm placeholder:font-[400] px-2 py-[3px] focus:shadow-blue-100 focus:shadow-md outline-none duration-300"
                                placeholder="Michigan Detroit"
                                autoComplete="off"
                                required
                                value={formData.address}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="flex flex-col gap-2">
                            <label htmlFor="inventory_source" className="font-[500] text-sm">Source</label>
                            <input type="text"
                                name="inventory_source"
                                id="inventory_source"
                                className="border border-gray-200 rounded-md placeholder:text-sm placeholder:font-[400] px-2 py-[3px] focus:shadow-blue-100 focus:shadow-md outline-none duration-300"
                                placeholder="Inventory source"
                                autoComplete="off"
                                required
                                value={formData.inventory_source}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="flex flex-col gap-2">
                            <label htmlFor="status" className="font-[500] text-sm">Status</label>
                            <select
                                name="status"
                                onChange={handleInputChange}
                                required
                                id='status'
                                className="border border-gray-200 rounded-md placeholder:text-sm placeholder:font-[400] px-2 py-[3px] focus:shadow-blue-100 focus:shadow-md outline-none font-[500] text-sm duration-300"
                                defaultValue={""}
                            >
                                <option value="" className="text-gray-200 font-[500] text-sm" disabled>
                                    select option
                                </option>
                                <option value="active" className="text-gray-700 font-[500] text-sm">
                                    Active
                                </option>
                                <option value="inactive" className="text-gray-700 font-[500] text-sm">
                                    Inactive
                                </option>
                            </select>
                        </div>
                    </div>

                    <hr />

                    <div className="flex items-center justify-end gap-2 p-5">
                        <button className="bg-white border border-gray-300 rounded-md py-[2px] px-2 font-[500] text-sm hover:shadow-md duration-300" onClick={() => { setToggleAddUser(false) }}>Cancel</button>
                        <button className="bg-buttonPrimary rounded-md py-[4px] px-2 text-white font-[500] text-sm hover:shadow-md duration-300">Add user</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AddUserModel