import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../layout/Header/Header";
import SideMenu from "../../../components/sidemenu/SideMenu";
import axiosClient from "../../../axios.client";
import RenderUserTableList from "./RenderUserTableList";
import DealerShipDetailMobileView from "./DealerShipDetailMobileView";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../../CheckoutForm";
import SuccessMsg from "../../../components/Alerts/SuccessMsg";
import { usePaymentContext } from "../../../Context/PaymentContext";
import UpdateUserDesktop from "../AddUser/UpdateUserDesktop";
import AddUserModel from "../AddUser/AddUserDesktop";
import ConfirmModel from "../../../components/Alerts/ConfirmModel";
import NotificationMsg from "../../../components/Alerts/NotificationMsg";
import axios from "axios";
import DealerShipHeader from "./components/DealerShipHeader";

const DealerShipDetail = () => {
  const { paymentStatus, setPaymentStatus } = usePaymentContext();

  const { dealerShipID } = useParams();
  const navigate = useNavigate();

  const [paymentMethodToggle, setPaymentMethodToggle] = useState(false);

  const [editBtn, setEditBtn] = useState(false); // dealerShip edit
  const [loading, setLoading] = useState(false);
  const [userDealer, setUserDealer] = useState([]); // Mobile DealerShip Data
  const [usersList, setUsersList] = useState([]);

  const [editToggle, setEditToggle] = useState({ isOpen: false, data: [] }); // User list edit Model Toggler
  const [addUserModel, setAddUserModel] = useState(false); // All User Page Add Btn Toggler

  const [paymentBtnLoading, setPaymentBtnLoading] = useState(false);
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");

  const [deleteModelToggle, setDeleteModelToggle] = useState({
    isOpen: false,
    id: "",
  });

  const [dealerShipForm, setDealerShipForm] = useState({
    id: "",
    name: "",
    website: "",
    state: "",
    city: "",
    phone: "",
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    formPackage: "",
    user_count: "",
    amount: "",
    inventory_source: "",
    vehicles: "",
    fb_ads: "",
    fb_ads_budget: "",
    FTPaccount: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setDealerShipForm({
      ...dealerShipForm,
      [name]: value,
    });
  };

  const formSubmit = async (e) => {
    e.preventDefault();
    try {
      setEditBtn(false);
      const response = await axiosClient.post(
        "/updateDealerShip",
        dealerShipForm
      );
      // .then((res) => console.log(res));

      if (response.data.message === "DealerShip was updated successfully") {
        return { success: true, message: response.data.message };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      console.error("Error updating DealerShip:", error);
      return {
        success: false,
        message: "An error occurred while updating DealerShip",
      };
    }
  };

  const [vehicleData, setVehicleData] = useState([]);

  const fetchUsersList = async () => {
    try {
      setLoading(true);

      const [usersListResponse] = await Promise.all([
        axiosClient.post("/getUsersList", { id: dealerShipID }),
      ]);

      const usersListData = usersListResponse.data.data;
      setUsersList(usersListData);
      setUserDealer(usersListData[0]);
      setDealerShipForm({
        ...dealerShipForm,
        id: usersListData[0].id,
        name: usersListData[0].name,
        website: usersListData[0].website,
        state: usersListData[0].address.split(",")[1] || "",
        city: usersListData[0].address.split(",")[0] || "",
        phone: usersListData[0].phone,
        first_name: usersListData[0].first_name || "",
        last_name: usersListData[0].last_name || "",
        email: usersListData[0].email,
        mobile: usersListData[0].mobile,
        formPackage: usersListData[0].package || "",
        user_count: usersListData[0].user_count || "",
        amount: usersListData[0].amount || "",
        fb_ads: usersListData[0].fb_ads,
        fb_ads_budget: usersListData[0].fb_ads_budget || "",
        inventory_source: usersListData[0].inventory_source || "",
        vehicles: 0,
        FTPaccount: usersListData[0].FTPaccount || "",
      });

    } catch (error) {
      const errorMessage = error.message === "timeout exceeded" ? "Timeout exceeded. Please refresh." : "Failed to get data. Please refresh.";
      SetAlert(true, "failed", errorMessage);
      console.error(error);
    } finally {
      setLoading(false);
    }

    fetchVehicleCount();
  };

  const [isLoading, setIsLoading] = useState(false);

  const fetchVehicleCount = async () => {
    setIsLoading(true);

    try {
      axios.get("https://uniqueleverage.com/ul-backend/public/api/get-car-count")
        .then((res) => {

          setVehicleData({
            listed: res.data.listed,
            advertised: res.data.active,
            pending: res.data.pending,
          });

          setIsLoading(false)
        });

    } catch (e) {
      console.error(e);
      SetAlert(true, "failed", "Failed to Fetch Vehicle Count");
    }

  }


  const DateFormat = (data) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const date = new Date(data);
    const year = date.getFullYear();
    const monthIndex = date.getMonth();
    const day = date.getDate();

    const formattedDate = `${months[monthIndex]}, ${day}, ${year}`;

    return formattedDate;
  };

  const paymentFunction = async () => {
    setPaymentBtnLoading(true);

    await axiosClient
      .post("/paymentMethod", userDealer)
      .then((res) => setClientSecret(res.data.response.client_secret))
      .catch((e) => console.log(e));

    setPaymentMethodToggle(true);

    setPaymentBtnLoading(false);
  };

  const deleteDealerShip = async (id) => {
    await axiosClient
      .post(`/deleteDealerShip/${id}`)
      .then((res) => {
        if (res.status === 200) {
          navigate("/");
        }
      })
      .catch((e) => {
        SetAlert(true, "failed", "Failed to delete dealership");
      });
  };

  const [notification, setNotification] = useState({
    isOpen: false,
    status: "",
    msg: "",
  });

  const SetAlert = (bool, status, msg) => {
    setNotification({ isOpen: bool, status: status, msg: msg });

    setTimeout(() => {
      setNotification({ isOpen: false, status: "", msg: "" });
    }, 5000);
  };

  useEffect(() => {
    fetchUsersList();

    // testing pk
    const publishableKey =
      "pk_test_51Np5B8CRKVVRZN31UjxfrKc8oECY1RCmnyIwADu7QP3w2Oo9rGbiMqSk5qit2Il8nWS8bSRtXHFq5erCWJRWB40c00I5jFwqod";

    // real pk
    // const publishableKey =
    //   "pk_test_51JOumzBYSqaXijdv87bBJShMqtmj8GnBsqaXeXxxfrrJeUScnAQsYT1e2FhpIV9v8CyAo6vROK1RgbGeIyXbrwfA007zopgR0q";

    setStripePromise(loadStripe(publishableKey));
  }, []);

  return (
    <>
      {/* Success Msg */}
      {paymentStatus && (
        <div className="block fixed z-[51] w-full h-full bg-black bg-opacity-30">
          <SuccessMsg setPaymentStatus={setPaymentStatus} />
        </div>
      )}

      {/* Notification Msg Start */}
      <NotificationMsg
        toggle={notification}
        Toggler={setNotification}
        message={notification.msg}
      ></NotificationMsg>
      {/* Notification Msg End */}

      {/* Delete Msg for dealership */}
      {deleteModelToggle.isOpen && (
        <div className="block fixed z-[51] w-full h-full bg-black bg-opacity-30">
          <ConfirmModel
            setModelToggle={setDeleteModelToggle}
            deleteFunction={deleteDealerShip}
            data={deleteModelToggle}
          />
        </div>
      )}

      {/* Payment Method Modal Start */}
      <div
        className={
          paymentMethodToggle
            ? "block fixed z-[51] w-full h-full bg-black bg-opacity-30"
            : "hidden"
        }
      >
        <div className="flex items-center justify-center h-screen">
          <div className="bg-white w-[50%] rounded-lg p-5">
            <div className="flex justify-between border-b pb-4">
              <h1>Payment Method</h1>
              <button
                className="font-bold"
                onClick={() => setPaymentMethodToggle(false)}
              >
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.28534 10.7133L10.7147 1.28662M1.28534 1.28662L10.7147 10.7133"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
            </div>
            <div className="pt-5">
              {clientSecret && stripePromise && (
                <Elements stripe={stripePromise} options={{ clientSecret }}>
                  <CheckoutForm dealerShipID={dealerShipID} />
                </Elements>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Payment Method Modal End */}

      {/* Add User Model Start */}
      {addUserModel && (
        <div className="block fixed z-[51] w-full h-full bg-black bg-opacity-30">
          <div className="flex items-center justify-center h-screen">
            <AddUserModel
              setAddUserModel={setAddUserModel}
              fetchUsersList={fetchUsersList}
            />
          </div>
        </div>
      )}
      {/* Add User Model End */}

      {/* Edit User Model Start */}
      {editToggle.isOpen && (
        <div className="block fixed z-[51] w-full h-full bg-black bg-opacity-30">
          <div className="flex items-center justify-center h-screen">
            <UpdateUserDesktop
              setAddUserModel={setEditToggle}
              editToggle={editToggle}
              SetAlert={SetAlert}
              setUsersList={setUsersList}
              usersList={usersList}
              setDealerShipForm={setDealerShipForm}
              dealerShipForm={dealerShipForm}
            />
          </div>
        </div>
      )}

      {/* Edit User Model End */}
      <div className="wrapper bg-backgroudWrapper">
        <div className="md:flex w-full h-screen">
          <SideMenu />

          <div
            className="w-full bg-backgroudPrimary overflow-x-auto"
            id="main-content"
          >
            <Header />

            {loading ? (
              <div className="flex justify-center items-center h-screen">
                <div className="w-12 h-12 border-t-4 border-buttonPrimary border-solid border-opacity-80 rounded-full animate-spin"></div>
              </div>
            ) : (
              <>
                {/* Mobile View */}
                <DealerShipDetailMobileView
                  navigate={navigate}
                  setUserDealer={setUserDealer}
                  userDealer={userDealer}
                  length={usersList.length}
                  fetchUsersList={fetchUsersList}
                ></DealerShipDetailMobileView>

                <div className="px-[18px] md:px-[24px] md:mx-[14px]  md:h-[calc(100vh-120px)] bg-white lg:block md:hidden hidden">
                  {/* Desktop Header Area Start*/}
                  <DealerShipHeader
                    editBtn={editBtn}
                    setEditBtn={setEditBtn}
                    setAddUserModel={setAddUserModel}
                    paymentBtnLoading={paymentBtnLoading}
                    totalUser={usersList}
                    setDeleteModelToggle={setDeleteModelToggle}
                    dealerShipForm={dealerShipForm}
                    handleInputChange={handleInputChange}
                    formSubmit={formSubmit}
                    paymentFunction={paymentFunction}
                    vehicleData={vehicleData}
                    isLoading={isLoading}
                  ></DealerShipHeader>
                  {/* Desktop Header Area End*/}

                  <div id="tabWrapper">
                    <div id="tabs">
                      <div className="flex gap-5 border-b border-borderPrimary"></div>
                    </div>

                    <div id="tabContent">
                      <div
                        id="UsersTab"
                        className="flex flex-col justify-between h-[calc(100vh-220px)]"
                      >
                        <div className="overflow-x-auto hidden md:block">
                          <div className="p-1.5 min-w-full align-middle pt-7">
                            <table className="border-b min-w-full divide-y divide-gray-200">
                              <thead>
                                <tr className="sticky top-0 bg-white">
                                  <th
                                    scope="col"
                                    className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
                                  >
                                    ID
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
                                  >
                                    User
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
                                  >
                                    Email
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
                                  >
                                    Password
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
                                  >
                                    Phone Number
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
                                  >
                                    FTP Account
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
                                  >
                                    Vehicles
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
                                  >
                                    Pending
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
                                  >
                                    Active
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
                                  >
                                    Cruise
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
                                  >
                                    Last Active
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
                                  >
                                    Last Post
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
                                  >
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <RenderUserTableList
                                SetAlert={SetAlert}
                                usersList={usersList}
                                DateFormat={DateFormat}
                                setEditToggle={setEditToggle}
                                dealerShipID={dealerShipID}
                                setDealerShipForm={setDealerShipForm}
                                dealerShipForm={dealerShipForm}
                                isLoading={isLoading}
                                vehicleData={vehicleData}
                              ></RenderUserTableList>
                            </table>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                {/* </div> */}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DealerShipDetail;
