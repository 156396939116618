export function DashboardStatusLoading({ }) {
    return (
        <>
            <div className="border border-1 border-[#ECF7FF] rounded-xl p-5 py-[22px] min-w-[264px]">
                <div className="flex-1 space-x-4">
                    <div className="flex-1 space-y-6">
                        <div className="flex justify-between items-center">
                            <span className="text-[#414552] font-semibold">Users</span>
                            <div className="bg-[#1A9882] rounded-full p-[6px] animate-pulse"></div>
                        </div>
                        <div className="flex justify-between pt-1">
                            <div className="h-3 bg-slate-200 rounded animate-pulse w-32"></div>
                            <div className="h-3 bg-slate-200 rounded animate-pulse w-8"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="border border-1 border-[#ECF7FF] rounded-xl p-5 py-[22px] min-w-[264px]">
                <div className="flex-1 space-x-4">
                    <div className="flex-1 space-y-6">
                        <div className="flex justify-between items-center">
                            <span className="text-[#414552] font-semibold">Offline</span>
                            <div className="bg-[#E53E3E] rounded-full p-[6px] animate-pulse"></div>
                        </div>
                        <div className="flex justify-between pt-1">
                            <div className="h-3 bg-slate-200 rounded animate-pulse w-32"></div>
                            <div className="h-3 bg-slate-200 rounded animate-pulse w-8"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="border border-1 border-[#ECF7FF] rounded-xl p-5 py-[22px] min-w-[264px]">
                <div className="flex-1 space-x-4">
                    <div className="flex-1 space-y-6">
                        <div className="flex justify-between items-center">
                            <span className="text-[#414552] font-semibold">Frozen</span>
                            <div className="bg-[#F98550] rounded-full p-[6px] animate-pulse"></div>
                        </div>
                        <div className="flex justify-between pt-1">
                            <div className="h-3 bg-slate-200 rounded animate-pulse w-32"></div>
                            <div className="h-3 bg-slate-200 rounded animate-pulse w-8"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="border border-1 border-[#ECF7FF] rounded-xl p-5 py-[22px] min-w-[264px]">
                <div className="flex-1 space-x-4">
                    <div className="flex-1 space-y-6">
                        <div className="flex justify-between items-center">
                            <span className="text-[#414552] font-semibold">Ticket</span>
                            <div className="bg-[#BE6AFF] rounded-full p-[6px] animate-pulse"></div>
                        </div>
                        <div className="flex justify-between pt-1">
                            <div className="h-3 bg-slate-200 rounded animate-pulse w-32"></div>
                            <div className="h-3 bg-slate-200 rounded animate-pulse w-8"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="border border-1 border-[#ECF7FF] rounded-xl p-5 py-[22px] min-w-[264px]">
                <div className="flex-1 space-x-4">
                    <div className="flex-1 space-y-6">
                        <div className="flex justify-between items-center">
                            <span className="text-[#414552] font-semibold">Onboarding</span>
                            <div className="bg-[#00ACF5] rounded-full p-[6px] animate-pulse"></div>
                        </div>
                        <div className="flex justify-between pt-1">
                            <div className="h-3 bg-slate-200 rounded animate-pulse w-32"></div>
                            <div className="h-3 bg-slate-200 rounded animate-pulse w-8"></div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="border border-1 border-[#ECF7FF] rounded-xl p-5 py-[22px] min-w-[264px]">
                <div className="flex-1 space-x-4">
                    <div className="flex-1 space-y-6">
                        <div className="flex justify-between items-center">
                            <span className="text-[#414552] font-semibold">Facebook</span>
                            <div className="bg-[#046AEA] rounded-full p-[6px] animate-pulse"></div>
                        </div>
                        <div className="flex justify-between pt-1">
                            <div className="h-3 bg-slate-200 rounded animate-pulse w-32"></div>
                            <div className="h-3 bg-slate-200 rounded animate-pulse w-8"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="border border-1 border-[#ECF7FF] rounded-xl p-5 py-[22px] min-w-[264px]">
                <div className="flex-1 space-x-4">
                    <div className="flex-1 space-y-6">
                        <div className="flex justify-between items-center">
                            <span className="text-[#414552] font-semibold">Google</span>
                            <div className="bg-[#1EB81B] rounded-full p-[6px] animate-pulse"></div>
                        </div>
                        <div className="flex justify-between pt-1">
                            <div className="h-3 bg-slate-200 rounded animate-pulse w-32"></div>
                            <div className="h-3 bg-slate-200 rounded animate-pulse w-8"></div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
}