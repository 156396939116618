import React from "react";
import PlusIcon from "../icons/PlusIcon";
import CloseIcon from "../icons/CloseIcon";

export const AddDealersShipBtn = ({ setFormToggler, formToggler }) => {
  return (
    <button
      type="button"
      onClick={() => setFormToggler(!formToggler)}
      className={
        formToggler
          ? "hidden text-white bg-buttonPrimary hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-[16px] px-5 py-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          : " text-white bg-buttonPrimary hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-[16px] px-5 py-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      }
    >
      <PlusIcon/>
      Add Dealership
    </button>
  );
};

export const SubmitDealerShipBtn = ({ formSubmit, formToggler }) => {
  return (
    <button
      type="button"
      onClick={() => formSubmit()}
      className={
        formToggler
          ? "text-white bg-buttonPrimary hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-[16px] px-5 py-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          : "hidden text-white bg-buttonPrimary hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-[16px] px-5 py-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      }
    >
      <PlusIcon />
      Submit Dealership
    </button>
  );
};

export const CancelDealerShipBtn = ({ setFormToggler, formToggler }) => {
  return (
    <button
      type="button"
      onClick={() => setFormToggler(!formToggler)}
      className={
        formToggler
          ? "text-white bg-red-500 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded text-[16px] px-5 py-2.5 text-center inline-flex items-center mr-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
          : "hidden text-white bg-red-500 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded text-[16px] px-5 py-2.5 text-center inline-flex items-center mr-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
      }
    >
      <CloseIcon/>
      Cancel
    </button>
  );
};
