import { useCustomerContext } from 'Context/CustomerContext';
import { DownArrowIcon } from 'components/icons/DownArrowIcon'
import React, { useEffect, useRef, useState } from 'react'

const MainFilters = ({
    modifiedCustomer,
    customerMode,
}) => {

    const { customerList } = useCustomerContext();

    const [packageToggle, setPackageToggle] = useState(false);
    const packageDropDownRef = useRef(null)

    const packageOptions = [
        { mode: 'all_in', label: 'All-In' },
        { mode: 'basic', label: 'Basic' },
        { mode: 'pro', label: 'Pro' }
    ];

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (packageDropDownRef.current && !packageDropDownRef.current.contains(event.target)) {
                setPackageToggle(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className='flex items-center justify-between gap-5 flex-wrap text-[14px] pt-[24px]'>
            <button className={`${customerMode === 'all' ? "border-[#1B74E4] text-[#1B74E4] border-1" : "text-[#414552]"} p-[9px] flex-1 border rounded-[6px] font-medium text-start hover:border-[#1B74E4] duration-300`}
                onClick={() => { modifiedCustomer("all") }}>All ({customerList?.all?.length || 0})</button>

            <button className={`${customerMode === 'onboarding' ? "border-[#1B74E4] text-[#1B74E4] border-1" : "text-[#414552]"} p-[9px] flex-1 border rounded-[6px] font-medium text-start hover:border-[#1B74E4] duration-300`}
                onClick={() => { modifiedCustomer("onboarding") }}>Onboarding ({customerList?.onboarding?.length || 0})</button>

            <button className={`${customerMode === 'support' ? "border-[#1B74E4] text-[#1B74E4] border-1" : "text-[#414552]"} p-[9px] flex-1 border rounded-[6px] font-medium text-start hover:border-[#1B74E4] duration-300`}
                onClick={() => { modifiedCustomer("support") }}>⁠Support ({customerList?.support?.length || 0})</button>

            <button className={`${customerMode === 'canceled' ? "border-[#1B74E4] text-[#1B74E4] border-1" : "text-[#414552]"} p-[9px] flex-1 border rounded-[6px] font-medium text-start hover:border-[#1B74E4] duration-300`}
                onClick={() => { modifiedCustomer("canceled") }}>Canceled ({customerList?.canceled?.length || 0})</button>

            <div className={`${customerMode === 'all_in' || customerMode === 'basic' || customerMode === 'pro' ? "border-[#1B74E4] text-[#1B74E4]" : "text-[#414552]"} border border-[#10111A29] rounded-[6px] p-[9px] flex-1 font-medium hover:border-[#1B74E4] duration-300 relative`}
                ref={packageDropDownRef}>
                <div className='flex' onClick={() => { setPackageToggle(!packageToggle) }}>
                    <button className='text-start text-[#414552] border-r flex-1'> Package</button>
                    <button className='text-end text-[#414552] px-1 flex items-center gap-2'>  <DownArrowIcon /></button>
                </div>

                {packageToggle &&
                    <div className='absolute top-[40px] left-0 bg-white shadow-md rounded-[6px] w-[100%] p-[10px] z-10'>
                        <div className='flex flex-col gap-2'>
                            {packageOptions.map(option => (
                                <button key={option.mode} className={`${customerMode === option.mode ? "text-[#1B74E4]" : "text-[#414552]"} text-left p-1 hover:text-[#1B74E4] hover:bg-gray-100`}
                                    onClick={() => { modifiedCustomer(option.mode) }}>{option.label} <span className='text-[11px]'>({customerList?.[option.mode]?.length || 0})</span></button>
                            ))}
                        </div>
                    </div>}
            </div>
        </div>
    )
}

export default MainFilters