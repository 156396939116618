import { DownArrowIcon } from "components/icons/DownArrowIcon"

const MainFilters = () => {

    return (
        <div className='flex items-center justify-between gap-5 flex-wrap text-[14px] pt-[24px]'>
            <button className={`border-[#1B74E4] text-[#1B74E4] border-1 p-[9px] flex-1 border rounded-[6px] font-medium text-start hover:border-[#1B74E4] duration-300`}
                onClick={() => { }}>Import</button>

            <button className={`text-[#414552] p-[9px] flex-1 border rounded-[6px] font-medium text-start hover:border-[#1B74E4] duration-300`}
                onClick={() => { }}>Mapped</button>

            <button className={`text-[#414552] p-[9px] flex-1 border rounded-[6px] font-medium text-start hover:border-[#1B74E4] duration-300`}
                onClick={() => { }}>Facebook Catalogs</button>

            <button className={`text-[#414552] p-[9px] flex-1 border rounded-[6px] font-medium text-start hover:border-[#1B74E4] duration-300`}
                onClick={() => { }}>Google Catalogs</button>

            <div className={`text-[#414552] border border-[#10111A29] rounded-[6px] p-[9px] flex-1 font-medium hover:border-[#1B74E4] duration-300 relative`}>
                <div className='flex' onClick={() => { }}>
                    <button className='text-start text-[#414552] border-r flex-1'> Rules</button>
                    <button className='text-end text-[#414552] px-1 flex items-center gap-2'>  <DownArrowIcon /></button>
                </div>
            </div>
        </div>
    )
}

export default MainFilters