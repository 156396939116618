import React, { useEffect, useRef, useState } from "react";
import axiosClient from "../../axios.client";
import { useStateContext } from "../../Context/AuthContext";
import { SearchIcon } from "../../components/icons/SearchIcon";
import { HelpIcon } from "components/icons/HelpIcon";
import { NotificationIcon } from "components/icons/NotificationIcon";
import { SettingSolidIcon } from "components/icons/SettingSolidIcon";
import { BluePlusIcon } from "components/icons/BluePlusIcon";
import { useSearchContext } from "Context/SearchContext";

const Header = ({ setToggleSearchModel, searchModel, toggleSearchModelFocus, setToggleSearchModelFocus }) => {
  const {
    setSearch,
    selectedPage,
    search,
  } = useSearchContext();

  const [authSwitch, setAuthSwitch] = useState(false);
  const authSwitchRef = useRef();

  const { setUser, setToken } = useStateContext();

  const logout = () => {
    axiosClient.post("/logout").then(() => {
      setUser({});
      setToken(null);
    });

    setAuthSwitch(false);
  };

  const handleClickedOutside = (e) => {
    if (!authSwitchRef.current.contains(e.target)) {
      setAuthSwitch(false);
    } else {
      setAuthSwitch(true);
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;

    setSearch({ pageName: selectedPage, value: value });
  };

  const handleSearch = (e) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData);

    const searchValue = data.search;

    setSearch({ pageName: selectedPage, value: searchValue });

    // e.currentTarget.reset();
  };

  useEffect(() => {
    document.addEventListener("click", handleClickedOutside);

    return () => {
      document.removeEventListener("click", handleClickedOutside);
    };
  }, []);

  return (
    <>
      <div className="flex justify-between">

        <form className="cursor-pointer" onSubmit={handleSearch} ref={searchModel}>
          <div className={`flex z-20 w-[572px] text-[#687385] outline-none rounded-lg cursor-pointer background-changable transition-all duration-300 ${toggleSearchModelFocus ? ' bg-[#eaf4ff3c]' : ''}`}>
            <button
              type="submit"
              className="inline pl-2 text-[16px] my-auto font-medium h-full ml-1"
            >
              <SearchIcon width={14} height={14} />
              <span className="sr-only">Search</span>
            </button>
            <input
              type="search"
              name="search"
              value={search.value}
              autoComplete="off"
              className={`inline p-2 w-[inherit] z-20 text-[16px] outline-none rounded-lg cursor-pointer background-changable transition-all duration-300 placeholder:font-roboto placeholder:text-[#242424a0] placeholder:text-sm placeholder:font-[600] ${toggleSearchModelFocus ? ' bg-[#eaf4ff3c]' : ''}`}
              placeholder="Search dealership, users, reports"
              onChange={handleChange}
              onClick={() => setToggleSearchModel(true)}
              onBlur={() => setToggleSearchModelFocus(false)}
            />
          </div>
        </form>

        <div className="relative justify-end text-sm flex items-center gap-6">
          <HelpIcon />
          <NotificationIcon />
          <p className="text-textSecondary text-[16px] cursor-pointer"
            ref={authSwitchRef}
          >
            <SettingSolidIcon height={16} width={16} />
          </p>
          <div
            className={
              authSwitch
                ? "absolute bg-white top-8 h-10 w-24 rounded-lg shadow-xl border border-gray-200"
                : "hidden"
            }
          >
            <span
              className="text-center block pt-2 hover:underline text-buttonPrimary hover:to-blue-600 cursor-pointer"
              onClick={() => logout()}
            >
              Logout
            </span>
          </div>
          <BluePlusIcon height={24} width={24} />
        </div>
      </div>
    </>
  );
};

export default Header;
