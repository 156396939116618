import React, { useEffect, useState } from 'react';
import SmallLoading from "../../../components/Loadings/SmallLoading";

export function UserStatus({ userStatus, userStatusLoading }) {
    const [percentages, setPercentages] = useState({
        top: 55.33,
        right: 25,
        left: 15,
        bottom: 8,
    });

    const calculatePercentageForBubbleChart = () => {
        let sum = userStatus.userPosting + userStatus.freeze + userStatus.error + 10;

        setPercentages({
            top: Math.round(userStatus.userPosting / sum * 100),
            right: Math.round(userStatus.freeze / sum * 100),
            left: Math.round(10 / sum * 100), // Duplicated Active
            bottom: Math.round(userStatus.error / sum * 100),
        })
    }

    useEffect(() => {
        calculatePercentageForBubbleChart();
    }, [userStatus]);
    return (
        <>
            <div className='flex flex-col justify-between gap-[12px]'>
                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-[8px] text-[#667085] text-sm">
                        <svg width="12" height="12" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="0.5" width="12" height="12" rx="6" fill="#05CD99" />
                        </svg>
                        <h1 className="text-sm">{userStatusLoading ? <SmallLoading /> : percentages.top+'%'}</h1>
                        Active Vehicles</div>
                    
                </div>

                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-[8px] text-[#667085] text-sm">
                        <svg width="12" height="12" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="0.5" width="12" height="12" rx="6" fill="#F98550" />
                        </svg>
                        <h1 className="text-sm">{userStatusLoading ? <SmallLoading /> : percentages.right+'%'}</h1>
                        Freeze Count</div>
                    
                </div>
            </div>
            <div className='flex flex-col justify-between gap-[12px]'>

                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-[8px] text-[#667085] text-sm">
                        <svg width="12" height="12" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="0.5" width="12" height="12" rx="6" fill="#EE5D50" />
                        </svg>
                        <h1 className="text-sm">{userStatusLoading ? <SmallLoading /> : percentages.bottom+'%'}</h1>
                        Pending Vehicles</div>
                    
                </div>

                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-[8px] text-[#667085] text-sm">
                        <svg width="12" height="12" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="0.5" width="12" height="12" rx="6" fill="#2BB2FE" />
                        </svg>
                        <h1 className="text-sm">{userStatusLoading ? <SmallLoading /> : percentages.left+'%'}</h1>
                        Onboard
                    </div>
                </div>
            </div>
        </>
    );
}