import SecondaryFilters from './components/SecondaryFilters'
import MainFilters from './components/MainFilters'
import { SortingIcon } from 'components/icons/SortingIcon'
import { MenuIcon } from 'components/icons/MenuIcon'
import { useDataContext } from 'Context/DataContext/DataContext'
import SplitAddress from 'Utils/SplitAddress'
import DateFormat from 'Utils/DateFormat'
import { useState } from 'react'

const Data = () => {
    const {
        dataLoading,
        csvData,
    } = useDataContext();

    const TimeFormatter = () => {
        const rawTime = "2023-10-24T16:25:32.000000Z";
        const date = new Date(rawTime);

        // Adjust the time to Eastern Standard Time (EST)
        date.setHours(date.getHours() - 4);

        // Format the time as "3:30 AM EST"
        const formattedTime = `${date.getHours()}:${('0' + date.getMinutes()).slice(-2)} AM EST`;

        return <div>{formattedTime}</div>;
    }

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = csvData.slice(indexOfFirstItem, indexOfLastItem);

    const isLastPage = indexOfLastItem >= csvData.length;

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    return (
        <div className=''>
            {/* Heads */}

            <div className='flex items-center justify-between'>
                <div className='flex flex-col gap-2'>
                    <h1 className='text-[28px] font-bold leading-9 font-roboto text-[#30313D]'>Data
                    </h1>
                </div>
            </div>

            {/* Filter Section */}

            <MainFilters />

            <SecondaryFilters />

            {/* Sortable Table */}

            <div className='min-h-[100%] w-full'>
                <div className='overflow-x-auto 5xl:!w-[1516px]' style={{ width: 'calc(100vw - 390px)' }}>
                    {dataLoading ?
                        <div className="flex justify-center items-center py-56">
                            <div className="w-12 h-12 border-t-4 border-buttonPrimary border-solid border-opacity-80 rounded-full animate-spin"></div>
                        </div>
                        :

                        <table className="5xl:w-full">
                            <thead className="sticky-top-4 bg-white z-10 text-[12px]">
                                <tr className='border-b pb-2'>
                                    <th className='font-semibold text-left flex items-center gap-[12px] w-[300px] pb-3 uppercase'>
                                        <input type="checkbox" />
                                        <span>Dealership name</span>
                                    </th>
                                    <th className='font-semibold text-left w-[250px] pb-3 uppercase'><span className={`flex items-center gap-3`}>City <SortingIcon /></span></th>
                                    <th className='font-semibold text-left w-[250px] pb-3 uppercase'><span className={`flex items-center gap-3`}>Source <SortingIcon /></span></th>
                                    <th className='font-semibold text-left w-[400px] pb-3 uppercase'>Original</th>
                                    <th className='font-semibold text-left w-[300px] pb-3 uppercase'>Modified</th>
                                    <th className='font-semibold text-left w-[300px] pb-3 uppercase'>Time</th>
                                    <th className='font-semibold text-left w-[300px] pb-3 uppercase'></th>
                                </tr>
                            </thead>

                            <tbody className='bg-white text-[14px]'>
                                {csvData.length > 0 ?
                                    currentItems.map((item, index) => {
                                        return (
                                            <tr key={index} className='border-b border-borderPrimary hover:bg-gray-100 duration-300'>
                                                <td className='flex items-center gap-[12px] py-4'>
                                                    <input type="checkbox" />
                                                    <span className='font-semibold text-[#353A44]'>{item?.users?.name || "-"}</span>
                                                </td>
                                                <td className='py-4'>
                                                    <span className='font-semibold text-[#687385]'>
                                                        {SplitAddress(item?.users?.address).city || "-"}
                                                    </span>
                                                </td>
                                                <td className='py-4'>
                                                    <span className='font-semibold text-[#687385]'>
                                                        {item?.users?.inventory_source || "-"}
                                                    </span>
                                                </td>
                                                <td className='py-4'>
                                                    <span className='font-semibold text-[#1B74E4]'>
                                                        {item?.users?.files[0]['fileName']}
                                                    </span>
                                                </td>
                                                <td className='py-4'>
                                                    <span className='font-semibold text-[#687385]'>
                                                        {DateFormat(item.updated_at) || "-"}
                                                    </span>
                                                </td>
                                                <td className='py-4'>
                                                    <span className='font-semibold text-[#687385]'>
                                                        {TimeFormatter(item.updated_at) || "-"}
                                                    </span>
                                                </td>
                                                <td className='py-4'>
                                                    <span className='font-semibold cursor-pointer'>
                                                        <MenuIcon color={"#353A44"} />
                                                    </span>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr>
                                        <td colSpan="7" className="text-center py-4">No data found</td>
                                    </tr>
                                }
                            </tbody>

                        </table>
                    }
                </div>

                <div>
                    {/* Pagination controls */}
                    <div className="flex items-center justify-between my-4">
                        <div>
                            <span className="font-semibold text-[14px]">{csvData.length}  {csvData.length > 1 ? "results" : "result"}</span>
                        </div>
                        <div>
                            <div className="flex items-center gap-4">
                                <button
                                    disabled={currentPage === 1}
                                    onClick={handlePrevPage}
                                    className={`h-[26px] w-[70px] text-sm border border-gray-300 rounded-lg font-semibold text-[#9a9ca1]`}
                                >
                                    Previous
                                </button>
                                <button
                                    disabled={indexOfLastItem >= csvData.length || isLastPage}
                                    onClick={handleNextPage}
                                    className={`h-[26px] w-[70px] text-sm border border-gray-300 rounded-lg font-semibold ${isLastPage && "text-[#9a9ca1]"}`}
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Data