import React from 'react'

const CursorDragIcon = () => {
    return (
        <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.13965 1.5L5.13965 0.5M5.13965 0.5L4.13965 1.5M5.13965 0.5V9.5M5.13965 9.5L6.13965 8.5M5.13965 9.5L4.13965 8.5M8.63965 6L9.63965 5M9.63965 5L8.63965 4M9.63965 5H0.639648M0.639648 5L1.63965 6M0.639648 5L1.63965 4" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default CursorDragIcon