import PlusIcon from 'components/icons/PlusIcon';
import { FacebookLogo } from 'components/SocialMedia/FacebookLogo';
import { ExitIcon } from 'components/icons/ExitIcon';
import { EditIcon } from 'components/icons/EditIcon';
import { SmallHorizontalMenuIcon } from 'components/icons/SmallHorizontalMenuIcon';
import { VisaLogo } from 'components/SocialMedia/VisaLogo';
import { RightArrowIcon } from 'components/icons/RightArrowIcon';
import { CustomerList } from './CustomerList';
import { useCustomerDetailContext } from 'Context/customerDetailContext/CustomerDetailContext';

export default function CustomerRendering({ customerID }) {
    const {
        setSelectedPage,
        setAddUserModel,
        customerDetail,
    } = useCustomerDetailContext();

    return (
        <div className=''>

            <div className='pb-1'>
                <div className="flex items-center justify-between border-b border-borderPrimary pt-2 text-[14px]">
                    <div className='flex items-center gap-8 '>
                        <button className='font-semibold text-[#0C71EB] border-b-2 border-[#0C71EB] pb-[11px]' onClick={() => { setSelectedPage('overview') }}>
                            Overview
                        </button>

                        <button className='font-semibold text-[#596171] pb-[11px]' onClick={() => { setSelectedPage('eventsAndLogs') }}>
                            Events and logs
                        </button>
                    </div>
                    {/* <div className='flex items-center gap-5'>
                        <button className="px-2 rounded-md flex items-center border border-gray-200 text-sm font-semibold py-1"><PlusIcon color="#545969" /> Filter
                        </button>

                        <button className="bg-buttonPrimary px-2 rounded flex items-center text-white text-sm font-semibold py-1"
                            onClick={() => { setAddUserModel({ customerName: customerDetail.name, isOpen: true }) }}><PlusIcon /> New User
                        </button>
                    </div> */}
                </div>

                <div className='border-b pb-4'>
                    <div className='pb-[26px] pt-[22px] flex items-center justify-between'>
                        <h1 className='font-bold text-[16px]'>Subscriptions</h1>
                        <button className='text-[#1B74E4] font-medium text-sm'>Create</button>
                    </div>

                    <div className='flex items-center justify-between'>
                        <div className='flex items-center gap-4 pl-[10px]'>
                            <FacebookLogo />
                            <div>
                                <div className='flex items-center gap-2'>
                                    <h1 className='font-medium text-[16px] leading-5'>Facebook Ads

                                    </h1>
                                    <h1 className='px-2 bg-[#EAF4FF] w-fit rounded-md text-[12px] font-medium text-[#1B74E4]'>
                                        Active
                                    </h1>
                                </div>
                                <h2 className='text-[#687385] text-sm font-normal'>Billing monthly • Next invoice on Dec 31 for $99.00</h2>
                            </div>
                        </div>
                        <div className='border border-gray-300 rounded-md px-2 py-1 shadow-sm flex items-center gap-8'>
                            <button><EditIcon height={12} width={12} /></button>
                            <button><ExitIcon height={12} width={12} /></button>
                            <button><SmallHorizontalMenuIcon /></button>
                        </div>
                    </div>
                </div>

                <div className='border-b pb-4'>
                    <div className='py-5 flex items-center justify-between'>
                        <h1 className='font-bold text-[16px]'>Payment Methods</h1>
                        <button className='text-[#1B74E4] font-medium text-sm'>Create</button>
                    </div>

                    <div className='flex items-center justify-between'>
                        <div className='flex items-center gap-4 pl-[10px]'>
                            <RightArrowIcon />
                            <VisaLogo />
                            <div className='flex items-center gap-3'>
                                <h1 className='font-semibold flex items-center gap-3'>Visa •••• 0399
                                </h1>
                                <div className='flex items-center gap-2 text-[#596171]'>
                                    <h2 className='px-2 bg-[#EAF4FF] w-fit rounded-md text-[14px] font-[600] text-[#1B74E4]'>
                                        Default
                                    </h2>
                                    <h3>Expires Dec 2028</h3>
                                </div>
                            </div>
                        </div>
                        <button><SmallHorizontalMenuIcon /></button>
                    </div>
                </div>

                <div className='flex items-center justify-between py-5'>
                    <h1 className='font-bold text-[16px]'>Users</h1>
                    <button className='text-[#1B74E4] font-medium text-sm'
                        onClick={() => { setAddUserModel({ customerName: customerDetail.name, isOpen: true }) }}>+ Add User</button>
                </div>

                <div className='border-t pt-4 min-h-[150px] overflow-hidden pb-48'>
                    <table className='w-[100%]'>
                        <thead>
                            <tr>
                                <th className='font-medium text-[12px] text-left pb-2 flex items-center gap-5'> ID</th>

                                <th className='font-medium text-[12px] text-left pb-2'>STATUS</th>

                                <th className='font-medium text-[12px] text-left pb-2'> <span className='flex items-center gap-3'> USERNAME </span> </th>

                                <th className='font-medium text-[12px] text-left pb-2'> <span className='flex items-center gap-3'> PHONE </span>  </th>

                                <th className='font-medium text-[12px] text-left pb-2'> <span className='flex items-center gap-3'> PASSWORD </span>  </th>

                                <th className='font-medium text-[12px] text-left pb-2'> <span className='flex items-center gap-3'> LOCATION </span>  </th>

                                {/* <th className='font-medium text-[12px] text-left pb-2'><span className='flex items-center gap-3'> Vehicles <SortingIcon /> </span>  </th>

                                <th className='font-medium text-[12px] text-left pb-2'><span className='flex items-center gap-3'> Active <SortingIcon /> </span>  </th>

                                <th className='font-medium text-[12px] text-left pb-2'><span className='flex items-center gap-3'> Pending<SortingIcon /> </span>  </th>

                                <th className='font-medium text-[12px] text-left pb-2'><span className='flex items-center gap-3'> Cruise <SortingIcon /> </span>  </th>

                                <th className='font-medium text-[12px] text-left pb-2'><span className='flex items-center gap-3'> Last Action <SortingIcon /> </span>  </th>

                                <th className='font-medium text-[12px] text-left pb-2'><span className='flex items-center gap-3'> Action Date <SortingIcon /> </span>  </th> */}

                                <th className='font-medium text-[12px] text-left pb-2'><span className='flex items-center gap-3'>  </span>  </th>
                            </tr>
                        </thead>

                        <tbody className='max-w-[100%]'>
                            <CustomerList customerID={customerID} />
                        </tbody>
                    </table>
                </div>

            </div>

        </div>
    );
}