import { useCustomerDetailContext } from 'Context/customerDetailContext/CustomerDetailContext';
import { NumberToPrice } from 'Utils/NumberToPrice';

const Advertised = () => {
    const {
        manageVehicleData,
        activeCheckedValues,
        setActiveCheckedValues,
        handleSelectedCar,
        vehicleSearch,
    } = useCustomerDetailContext();

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;

        if (checked) {
            activeCheckedValues.includes(value)
                ? setActiveCheckedValues(activeCheckedValues)
                : setActiveCheckedValues([...activeCheckedValues, value]);
        } else {
            setActiveCheckedValues(
                activeCheckedValues.filter((item) => item !== value)
            );
        }
    };

    const filterCar = (car, searchValue) => {
        const loweredSearchValue = searchValue.toLowerCase();

        return (
            (car.year && car.year.toLowerCase().includes(loweredSearchValue)) ||
            (car.make && car.make.toLowerCase().includes(loweredSearchValue)) ||
            (car.model && car.model.toLowerCase().includes(loweredSearchValue)) ||
            (car.trim && car.trim.toLowerCase().includes(loweredSearchValue)) ||
            (car.mileage && car.mileage.toString().toLowerCase().includes(loweredSearchValue)) ||
            (car.price && car.price.toString().toLowerCase().includes(loweredSearchValue)) ||
            (car.bodystyle && car.bodystyle.toLowerCase().includes(loweredSearchValue))
        );
    };

    const filteredList = manageVehicleData?.advertised?.length > 0
        ? manageVehicleData.advertised.filter((car) =>
            vehicleSearch.page === "active"
                ? vehicleSearch.value.toLowerCase() === "" || filterCar(car, vehicleSearch.value)
                : true
        )
        : [];

    return (
        manageVehicleData?.advertised?.length > 0 ? filteredList.map((item, index) =>
            <div className='py-2 flex items-center justify-between cursor-pointer' key={index}>
                <div className='flex gap-2'
                    onClick={() => { handleSelectedCar(item.ID, "active") }}>
                    <img src={item.images.split(',')[0]} alt="active car" className='w-[70px] h-[50px] fit-cover rounded-md' />
                    <div className='flex flex-col gap-1'>
                        <h1 className='font-medium text-xs'>{item.year} {item.model}</h1>
                        <h2 className='text-gray-400 text-xs'>{NumberToPrice(item.price)}</h2>
                    </div>
                </div>
                <div>
                    <input
                        type="checkbox"
                        className='h-4 w-4'
                        value={item.ID}
                        checked={activeCheckedValues.includes(`${item.ID}`)}
                        onChange={handleCheckboxChange}
                    />
                </div>
            </div>
        ) :
            <div className='flex justify-center items-center mt-60'>
                <h1 className='text-gray-400 text-sm'>{`No advertised vehicles found`}</h1>
            </div>
    )
}

export default Advertised