import React from "react";

export const CruiseToggle = ({ cruiseToggle, updateCruise, user }) => {
  return (
    <div className="flex justify-center">
      <div
        className={
          user.cruise_active !== 0
            ? "bg-[#06C753] rounded-xl w-10 h-5 p-[2.3px] flex items-center duration-300 cursor-pointer"
            : "bg-[#b1b1b1] rounded-xl w-10 h-5 p-[2.3px] flex items-center duration-300 cursor-pointer"
        }
        onClick={() => {
          updateCruise(
            user.cruise_active === 1
              ? (cruiseToggle.cruise_active = 0)
              : (cruiseToggle.cruise_active = 1),
            (cruiseToggle.id = user.id)
          );
        }}
      >
        <span
          className={
            user.cruise_active !== 0
              ? "h-4 w-4 bg-white block rounded-xl duration-300 translate-x-[18.3px]"
              : "h-4 w-4 bg-white block rounded-xl duration-300 translate-x-0"
          }
        ></span>
      </div>
    </div>
  );
};
