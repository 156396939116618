import React from 'react'
import { SortingIcon } from '../../../../components/icons/SortingIcon'
import { SettingSolidIcon } from '../../../../components/icons/SettingSolidIcon'
import PlusIcon from '../../../../components/icons/PlusIcon'
import Header from '../../../../layout/Header/Header'
import SideMenu from '../../../../components/sidemenu/SideMenu'

const Onboarding = () => {
    return (
        <div className="px-10 py-5 w-[1300px] text-[16px]">
            {/* Heads */}
            <div className='flex items-center justify-between pb-5'>
                <div className='flex flex-col gap-2'>
                    <h1 className='font-semibold text-3xl flex items-center gap-3'>Onboarding Users
                        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="0.5" width="12" height="12" rx="6" fill="#00ACF5" />
                        </svg>
                    </h1>

                    <p className='text-lg text-gray-400'>Showing Users <span className='text-gray-500 font-semibold'>4/4</span></p>
                </div>
                <div className='flex items-center gap-3'>
                    <button className='border border-[#10111A29] px-2 py-1 rounded-md shadow-md flex items-center gap-1'><SettingSolidIcon /> Select Accounts</button>

                    <button className='border border-[#10111A29] px-2 py-1 rounded-md shadow-md flex items-center'><PlusIcon color={'#545969'} /> Filter</button>

                    <button className='bg-buttonPrimary px-2 py-1 rounded shadow-md flex items-center text-white'><PlusIcon /> Add Customer</button>
                </div>
            </div>

            {/* Sortable Table */}
            <div className='border-t pt-4'>
                <table className='w-full'>
                    <thead>
                        <th className='font-semibold text-left pb-2 flex items-center gap-5'> <input type="checkbox" className='h-4 w-4 cursor-pointer text-[#353A44]' /> Dealership Name</th>

                        <th className='font-semibold text-left pb-2'>Email</th>

                        <th className='font-semibold text-left pb-2'> <span className='flex items-center gap-3'> Website <SortingIcon /> </span> </th>

                        <th className='font-semibold text-left pb-2'> <span className='flex items-center gap-3'> State <SortingIcon /> </span>  </th>

                        <th className='font-semibold text-left pb-2'><span className='flex items-center gap-3'> City <SortingIcon /> </span>  </th>

                        <th className='font-semibold text-left pb-2'><span className='flex items-center gap-3'> Phone Number <SortingIcon /> </span>  </th>

                        <th className='font-semibold text-left pb-2'><span className='flex items-center gap-3'> Package<SortingIcon /> </span>  </th>

                        <th className='font-semibold text-left pb-2'><span className='flex items-center gap-3'> Users <SortingIcon /> </span>  </th>

                        <th className='font-semibold text-left pb-2'><span className='flex items-center gap-3'> First Name <SortingIcon /> </span>  </th>

                        <th className='font-semibold text-left pb-2'><span className='flex items-center gap-3'> Last Name <SortingIcon /> </span>  </th>
                    </thead>

                    <tbody>
                        {Array.from({ length: 4 }).map((_, index) => (
                            <tr className='border-t border-b' key={index}>
                                <td className='py-1 font-semibold text-left flex items-center gap-5 text-[#353A44]'><input type="checkbox" className='h-4 w-4 cursor-pointer rounded-lg' /> Hudsons Autos</td>
                                <td className='py-2 text-left text-[##596171]'>sales@hudsonsautos.com</td>
                                <td className='py-2 text-left text-[##596171]'>https://hudsonsautos.com/</td>
                                <td className='py-2 text-left text-[##596171]'>Michigan</td>
                                <td className='py-2 text-left text-[##596171]'>Allen Park</td>
                                <td className='py-2 text-left text-[##596171]'>+1 313-530-6666</td>
                                <td className='py-2 text-left text-[##596171]'>Basic</td>
                                <td className='py-2 text-left text-[##596171]'>2</td>
                                <td className='py-2 text-left text-[##596171]'>Nathan</td>
                                <td className='py-2 text-left text-[##596171]'>Allison</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

        </div>
    )
}

export default Onboarding