import { DownArrowToggleIcon } from 'components/icons/DownArrowToggleIcon';
import { useCustomerDetailContext } from 'Context/customerDetailContext/CustomerDetailContext';
import { useState } from 'react';
import { Details } from './components/Details';
import { MetaData } from './components/MetaData';
import { RightMarkToggleIcon } from 'components/icons/RightMarkToggleIcon';
import { useNavigate } from 'react-router';

export default function CustomerInfo() {
    const navigate = useNavigate();
    const {
        customerDetail,
        openEditMetaModal,
        setOpenEditMetaModal,
    } = useCustomerDetailContext();

    const [toggleDetail, setToggleDetail] = useState(false);
    const [toggleMetaData, setToggleMetaData] = useState(false);

    return (
        <div className='w-[320px] flex-1 flex flex-col gap-[10px] pt-2'>
            <div className='flex items-center gap-2 text-sm font-medium'>
                {/* <h1 className='text-buttonPrimary cursor-pointer'
                    onClick={() => { navigate('/dealerShip') }}>Dealership</h1>
                <RightMarkToggleIcon /> */}
                <h1 className='text-[#0C71EB] text-[14px] font-[600]'>Customer</h1>
                {/* <RightMarkToggleIcon color="#777980" /> */}
                {/* <h1 className='text-textPrimary'>
                    {loading ? "" : customerDetail?.name}
                </h1> */}
            </div>

            <h1 className='text-[30px] font-bold'>{customerDetail.name}</h1>
            <a className={`${(customerDetail.website === "" || customerDetail.website === null) && "hidden"} text-buttonPrimary text-[16px] font-normal`} href={`${customerDetail.website}`}>{customerDetail.website}</a>

            <div className='px-[7px] py-[1px] bg-[#EAF4FF] text-[#34619A] w-fit rounded-md text-[12px] font-medium'>
                New Customer
            </div>

            <Details setToggleDetail={setToggleDetail} toggleDetail={toggleDetail} />

            <div className={`flex items-center justify-between pt-3 duration-300 ${!toggleMetaData && 'pb-3 border-b'}`}>
                <h1 className='font-bold text-[16px] flex items-center gap-2 cursor-pointer'
                    onClick={() => { setToggleMetaData(!toggleMetaData) }}>
                    {!toggleMetaData ? <DownArrowToggleIcon /> :
                        <RightMarkToggleIcon
                            color="#687385"
                            height={13}
                            width={13}
                        />}
                    Meta Data</h1>

                <button className={`${toggleMetaData && "hidden"} text-[#0C71EB] hover:text-[#5399ef] duration-300 text-sm font-medium`}
                    onClick={() => { setOpenEditMetaModal(!openEditMetaModal) }}>Edit</button>
            </div>

            {/* meta data dropdown */}
            {!toggleMetaData && <MetaData />}

        </div>
    );
}