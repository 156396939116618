import { Navigate, Outlet, useLocation, useNavigate, NavLink } from "react-router-dom";
import { useStateContext } from "../Context/AuthContext";
import Header from "./Header/Header";
import SideMenu from "components/sidemenu/SideMenu";
import { usePaymentContext } from "Context/PaymentContext";
import './layout.css'
import { UniqueLeverageIcon } from "components/icons/UniqueLeverageIcon"
import React, { useEffect, useRef, useState } from "react";
import SearchModel from './Header/components/SearchModel'
import { useCustomerContext } from "Context/CustomerContext";
import AddCustomerModel from "components/Models/CustomerModels/AddCustomerModel";
import { useUserContext } from "Context/UserContext";
import AddUserModel from "components/Models/UserModels/AddUserModel";
import FTPModel from "components/Models/UserModels/FTPModel";
import { useCustomerDetailContext } from "Context/customerDetailContext/CustomerDetailContext";
import AddUserInCustomerDetailModel from "components/Models/UserModels/AddUserInCustomerDetailModel";
import EditCustomerDetailModel from "components/Models/UserModels/EditCustomerDetailModel";
import NotificationMsg from "components/Alerts/NotificationMsg";
import { useAlertContext } from "Context/AlertContext";
import { MetaDataEditModal } from "pages/dealership/customerDetail/overview/customerInfo/modal/MetaDataEditModal";
import { useTeamsContext } from "Context/TeamsContext/TeamsContext";
import AddTeam from "components/Models/Teams/AddTeam";
import EditTeam from "components/Models/Teams/EditTeam";

const DefaultLayout = () => {
  const { notification, setNotification } = useAlertContext();

  const {
    toggleAddCustomer,
  } = useCustomerContext();

  const {
    toggleAddUser, // all user page -> add user
    toggleFtpModel,
  } = useUserContext();

  const {
    addUserModel, // customer detail page -> add user 
    editCustomerDetail, // edit customer detail

    openEditMetaModal, // customer detail meta data state
    setOpenEditMetaModal, // customer detail meta data state
  } = useCustomerDetailContext();

  const { addTeamToggle, toggleEdit } = useTeamsContext();


  const { token } = useStateContext();
  const navigate = useNavigate();
  const { setPaymentStatus } = usePaymentContext();
  const location = useLocation();

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }

    const queryParams = new URLSearchParams(location.search);
    const redirectStatus = queryParams.get("redirect_status");

    if (redirectStatus === "succeeded") {
      setPaymentStatus(true);
      const dealer = queryParams.get("dealer");

      navigate(`dealerShip-detail/${dealer}`);
    }
  }, [location.search]);

  // <------------------------------------->

  // search model ref
  const searchModel = useRef();

  const [toggleSearchModel, setToggleSearchModel] = useState(false);
  const [toggleSearchModelFocus, setToggleSearchModelFocus] = useState(false);


  if (!token) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      {/* Notification Msg Start */}
      <NotificationMsg
        toggle={notification}
        Toggler={setNotification}
        message={notification.msg}
      ></NotificationMsg>
      {/* Notification Msg End */}

      {/* Add Customer Model Start */}
      {toggleAddCustomer && (
        <AddCustomerModel />
      )}
      {/* Add Customer Model End */}

      {/* Add User Model Start */}
      {toggleAddUser && (
        <AddUserModel />
      )}
      {/* Add User Model End */}

      {/* Add User Model for customer detail Start */}
      {addUserModel.isOpen && (
        <AddUserInCustomerDetailModel />
      )}
      {/* Add User Model for customer detail End */}

      {/* Add User Model for customer detail Start */}
      {editCustomerDetail.isOpen && (
        <EditCustomerDetailModel />
      )}
      {/* Add User Model for customer detail End */}

      {/* Add FTP Model Start */}
      {toggleFtpModel.isBool && (
        <FTPModel />
      )}
      {/* Add FTP Model End */}

      {/* Meta Data Edit Modal Start */}
      {openEditMetaModal && (
        <MetaDataEditModal />
      )}
      {/* Meta Data Edit Modal End */}

      {/* Add Team Model Start */}
      {addTeamToggle && (
        <AddTeam />
      )}
      {/* Add Team Model End */}

      {/* Edit Team Model Start */}
      {toggleEdit.isOpen && (
        <EditTeam />
      )}
      {/* Edit Team Model End */}


      <main className="body mx-auto mainParent">

        <div className="sidebar flex pt-2 h-full from-white to-[#FBFCFD]" id="header01">
          <div className="w-[220px] ml-auto">
            <div className="isCustomSticky pt-2">
              <div className="flex items-center pl-[20px] z-0">
                <UniqueLeverageIcon />
                <div className="gap-[8px]">
                  <h1 className="font-semibold text-[14px]">UniqueLeverage</h1>
                  <h4 className="text-[12px] text-[#687385]">Admin</h4>
                </div>
              </div>
              <div className="pt-8">
                <ul className="flex flex-col gap-[11.5px] text-[14px]">
                  <NavLink to={"/"}
                    className={({ isActive }) =>
                      isActive
                        ? "text-buttonPrimary font-semibold cursor-pointer px-5 border-l-2 border-buttonPrimary"
                        : "text-[#353A44] font-semibold cursor-pointer px-5"
                    }>
                    Home</NavLink>

                  <NavLink to={"/dealerShip"}
                    className={({ isActive }) =>
                      isActive
                        ? "text-buttonPrimary font-semibold cursor-pointer px-5 border-l-2 border-buttonPrimary"
                        : "text-[#353A44] font-semibold cursor-pointer px-5"
                    }>
                    Dealership</NavLink>

                  <NavLink to={"/teams"}
                    className={({ isActive }) =>
                      isActive
                        ? "text-buttonPrimary font-semibold cursor-pointer px-5 border-l-2 border-buttonPrimary"
                        : "text-[#353A44] font-semibold cursor-pointer px-5"
                    }>
                    Teams</NavLink>

                  <NavLink to={"/ftp"}
                    className={({ isActive }) =>
                      isActive
                        ? "text-buttonPrimary font-semibold cursor-pointer px-5 border-l-2 border-buttonPrimary"
                        : "text-[#353A44] font-semibold cursor-pointer px-5"
                    }>
                    FTP</NavLink>

                  <NavLink to={"/data"}
                    className={({ isActive }) =>
                      isActive
                        ? "text-buttonPrimary font-semibold cursor-pointer px-5 border-l-2 border-buttonPrimary"
                        : "text-[#353A44] font-semibold cursor-pointer px-5"
                    }>
                    Data</NavLink>

                  <NavLink to={"/connect"}
                    className={({ isActive }) =>
                      isActive
                        ? "text-buttonPrimary font-semibold cursor-pointer px-5 border-l-2 border-buttonPrimary"
                        : "text-[#353A44] font-semibold cursor-pointer px-5"
                    }>
                    Connect</NavLink>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* w-[1060px] 2xl:w-[1220px] 3xl:w-[1292px] 4xl:w-[1350px] 5xl:w-[1636px] 6xl:w-[1956px] */}
        <div className="main overflow-y-visible bg-white with-shadow mainParent duration-300 h-fit w-[100%]">

          <div className="py-2 pl-[40px] bg-white header-sticky opacity-95 z-50 duration-300 h-[60px] pr-[40px] 2xl:pr-[80px] w-[-webkit-fill-available] 5xl:pr-[0px] 5xl:w-[1556px]" id="header01">
            {/* w-[1020px] 2xl:w-[1140px] 3xl:w-[1176px] 4xl:w-[1270px] 5xl:w-[1556px] 6xl:w-[1556px] */}

            <Header
              setToggleSearchModel={setToggleSearchModel}
              toggleSearchModel={toggleSearchModel}
              searchModel={searchModel}
              toggleSearchModelFocus={toggleSearchModelFocus}
              setToggleSearchModelFocus={setToggleSearchModelFocus}
            />

          </div>


          {toggleSearchModel && (
            <SearchModel
              toggleSearchModel={toggleSearchModel}
              setToggleSearchModel={setToggleSearchModel}
              searchModel={searchModel}
              toggleSearchModelFocus={toggleSearchModelFocus}
              setToggleSearchModelFocus={setToggleSearchModelFocus}
            />
          )}

          <div className="text-[16px] pl-[40px] bg-white pt-[80px] min-h-screen pr-[40px] 2xl:pr-[80px] w-[inherit] 5xl:pr-[0px] 5xl:w-[1556px]">
            {/* w-[1020px] 2xl:w-[1140px] 3xl:w-[1176px] 4xl:w-[1270px] 5xl:w-[1556px] 6xl:w-[1556px] */}

            <Outlet />

          </div>
        </div>

      </main>
    </>
  );
};

export default DefaultLayout;
