const SelectDataMapping = ({
    mappedDataLoading,
    mappedData,
    handleSelectedDataMapping,
    selectedDataMapping
}) => {

    return (
        <select name="data_mapping" id="data_mapping" className='select-custom p-2 px-2 border border-[#E2E4E5] rounded-sm w-[213px] 2xl:w-[250px] 5xl:w-[257px] 6xl:w-[343px] outline-none hover:border-[#10111a7e] font-medium text-sm duration-200 cursor-pointer text-[#687385]' defaultValue={""} onChange={handleSelectedDataMapping}>

            {selectedDataMapping !== "" ? <option value="">clear</option> : <option value="" disabled>Data Mapping</option>}

            {!mappedDataLoading ?
                mappedData.length > 0 && mappedData.map(data => (
                    <option
                        key={data.id}
                        value={data.ftp_file_path}
                    >{data.ftp_account.split('@')[0]}</option>
                )) :
                <option value="" disabled>loading data...</option>
            }

        </select>
    )
}

export default SelectDataMapping