import { createContext, useContext, useState } from "react";

export const AlertContext = createContext();

export const AlertContextProvider = ({ children }) => {
    const [notification, setNotification] = useState({
        isOpen: false,
        status: "",
        msg: "",
    });

    const SetAlert = (bool, status, msg) => {
        setNotification({ isOpen: bool, status: status, msg: msg });

        setTimeout(() => {
            setNotification({ isOpen: false, status: "", msg: "" });
        }, 5000);
    };

    return (
        <AlertContext.Provider value={{ notification, setNotification, SetAlert }}>
            {children}
        </AlertContext.Provider>
    );
};

export const useAlertContext = () => {
    return useContext(AlertContext);
};