import React from 'react'

export const MPGIcon = ({ color, height, width }) => {
    return (
        <svg
            width={width != undefined ? width : "40"}
            height={height != undefined ? height : "40"}
            viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_634_3345)">
                <path d="M5.00016 31.6667V6.66667C5.00016 6.22464 5.17576 5.80072 5.48832 5.48816C5.80088 5.17559 6.2248 5 6.66683 5H21.6668C22.1089 5 22.5328 5.17559 22.8453 5.48816C23.1579 5.80072 23.3335 6.22464 23.3335 6.66667V20H26.6668C27.5509 20 28.3987 20.3512 29.0239 20.9763C29.649 21.6014 30.0002 22.4493 30.0002 23.3333V30C30.0002 30.442 30.1758 30.866 30.4883 31.1785C30.8009 31.4911 31.2248 31.6667 31.6668 31.6667C32.1089 31.6667 32.5328 31.4911 32.8453 31.1785C33.1579 30.866 33.3335 30.442 33.3335 30V18.3333H30.0002C29.5581 18.3333 29.1342 18.1577 28.8217 17.8452C28.5091 17.5326 28.3335 17.1087 28.3335 16.6667V10.69L25.5718 7.92833L27.9285 5.57167L36.1785 13.8217C36.3335 13.9762 36.4564 14.1599 36.5402 14.3621C36.624 14.5643 36.6671 14.7811 36.6668 15V30C36.6668 31.3261 36.14 32.5979 35.2024 33.5355C34.2647 34.4732 32.9929 35 31.6668 35C30.3407 35 29.069 34.4732 28.1313 33.5355C27.1936 32.5979 26.6668 31.3261 26.6668 30V23.3333H23.3335V31.6667H25.0002V35H3.3335V31.6667H5.00016ZM8.3335 8.33333V18.3333H20.0002V8.33333H8.3335Z" fill={color != undefined ? color : "#7F7F7F"} />
            </g>
            <defs>
                <clipPath id="clip0_634_3345">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>


    )
}
