import React from 'react'

const NIcon = ({ bgColor }) => {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.530029" width="17" height="16" rx="4" fill={bgColor === undefined ? "#3884E4" : "#EAF3FF"} />
            <path d="M11.875 3.99878V12.53H10.4043L6.57812 6.4187V12.53H5.10742V3.99878H6.57812L10.416 10.1218V3.99878H11.875Z" fill={bgColor === undefined ? "white" : "black"} />
        </svg>

    )
}

export default NIcon