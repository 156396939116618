import React from "react";

export const BackArrowIcon = () => {
  return (
    <svg
      width="7"
      height="12"
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.24179 11.2369C5.11045 11.237 4.98038 11.2112 4.85905 11.1609C4.73772 11.1107 4.62752 11.0369 4.53479 10.9439L0.292787 6.7009C0.105316 6.51337 0 6.25906 0 5.9939C0 5.72873 0.105316 5.47442 0.292787 5.2869L4.53479 1.0439C4.72339 0.861738 4.97599 0.760943 5.23819 0.763221C5.50038 0.7655 5.7512 0.870669 5.93661 1.05608C6.12201 1.24149 6.22718 1.4923 6.22946 1.75449C6.23174 2.01669 6.13094 2.26929 5.94879 2.4579L2.41379 5.9939L5.94879 9.5299C6.0886 9.66975 6.1838 9.84791 6.22237 10.0419C6.26094 10.2358 6.24113 10.4368 6.16546 10.6195C6.08979 10.8022 5.96166 10.9584 5.79725 11.0683C5.63283 11.1782 5.43954 11.2369 5.24179 11.2369Z"
        fill="black"
      />
    </svg>
  );
};
