import { useEffect, useState } from "react";
import SplitAddress from "Utils/SplitAddress";
import { SortingIcon } from "components/icons/SortingIcon";
import { useNavigate } from "react-router-dom";
import AddCustomerRow from "./AddCustomerRow";
import { useCustomerContext } from "Context/CustomerContext";
import LongText from "../LongText";

export default function AllCustomerList({
    search,
    allCustomers,
    columns,
}) {
    const navigate = useNavigate();
    const {
        toggleAddCustomerRow,
        checkedStatus,
        setCheckedStatus,
    } = useCustomerContext();

    const handleCheckChange = (id) => {

        setCheckedStatus((prevStatus) => {
            const updatedUserCheckedStatus = { ...prevStatus };

            if (updatedUserCheckedStatus[id]) {
                delete updatedUserCheckedStatus[id];
            } else {
                updatedUserCheckedStatus[id] = true;
            }

            return updatedUserCheckedStatus;
        });
    };

    // Apply search filter
    const filteredCustomers = allCustomers.filter(user => {
        if (search.pageName === "allCustomers") {
            if (search.value.toLowerCase() === "") {
                return true;
            }

            return user.name.toLowerCase().includes(search.value.toLowerCase()) ||
                user.phone.toLowerCase().includes(search.value.toLowerCase()) ||
                (user.first_name ? user.first_name.toLowerCase().includes(search.value.toLowerCase()) : "") ||
                (user.last_name ? user.last_name.toLowerCase().includes(search.value.toLowerCase()) : "");
        }

        return true;
    });

    // Paginate the filtered results
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 20;

    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const displayedCustomers = filteredCustomers.slice(startIndex, endIndex);

    const totalPages = Math.ceil(filteredCustomers.length / pageSize);

    const handlePrevious = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const handleNext = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    return (
        <>
            <div className='border-t pt-4 min-h-[100%] w-full'>
                <div className='overflow-x-auto 5xl:!w-[1516px]' style={{ width: 'calc(100vw - 390px)' }}>
                    <table className="5xl:w-full">
                        <thead className="sticky-top-4 bg-white z-10 text-[12px]">
                            <tr>
                                <th className='font-semibold text-left flex items-center gap-[12px] w-[300px] pb-3'>
                                    {/* <input type="checkbox" className="w-[14px] rounded border-gray-300"
                                        checked={checkedStatus.every((user) => user.checked)}
                                        onChange={handleCheckAllChange} /> */}

                                    <input type="checkbox" className="w-[14px] rounded border-gray-300"
                                        onChange={() => {
                                            if (Object.keys(checkedStatus).length === filteredCustomers.length) {
                                                setCheckedStatus({});
                                            } else {
                                                const newCheckedStatus = {};
                                                filteredCustomers.forEach((user) => {
                                                    newCheckedStatus[user.id] = true;
                                                });
                                                setCheckedStatus(newCheckedStatus);
                                            }
                                        }}
                                        checked={Object.keys(checkedStatus).length === filteredCustomers.length}
                                    />
                                    DEALERSHIP NAME
                                </th>

                                {columns.map((col, index) => {
                                    if (col.active) {
                                        return (
                                            <th key={index}
                                                className={`font-semibold text-left pb-3`}
                                                style={{ width: col.width }}>
                                                {
                                                    col.id === 1 ? col.name :
                                                        <span className={`flex items-center gap-3`} style={{ width: col.width }}> {col.name} <SortingIcon /> </span>
                                                }
                                            </th>
                                        )
                                    }
                                })}
                            </tr>

                            {/* {toggleAddCustomerRow &&
                                <AddCustomerRow />} */}

                        </thead>

                        <tbody className="">
                            {allCustomers.length > 0 ? displayedCustomers.map((user, index) =>
                                <tr className='border-y cursor-pointer text-[14px] hover:bg-gray-100 duration-300' key={user.id}>
                                    <td className='px-1 py-2 font-normal text-left flex items-center gap-[12px]'>
                                        {/* <input
                                            type="checkbox"
                                            className="w-[14px] cursor-pointer rounded border-gray-300"
                                            checked={checkedStatus[index] ? checkedStatus[index].checked : false}
                                            onChange={() => handleCheckChange(user.id)}
                                        /> */}
                                        <input
                                            type="checkbox"
                                            className="w-[14px] cursor-pointer rounded border-gray-300"
                                            checked={checkedStatus[user.id] || false}
                                            onChange={() => handleCheckChange(user.id)}
                                        />
                                        <span onClick={() => { navigate(`/dealership/customer-detail/${user.id}`) }} title={user.name}>
                                            <LongText content={user.name} limit={37} />
                                        </span>
                                    </td>

                                    {columns.map((col, index) => {
                                        if (col.active) {
                                            return (
                                                <td key={index}
                                                    className={`px-1 py-2 text-left text-[#596171] 
                                                        ${(col.dbKey === 'email' || col.dbKey === 'phone') && "text-blue-600"}`}
                                                    onClick={() => { navigate(`/dealership/customer-detail/${user.id}`) }}
                                                >
                                                    {
                                                        (col.dbKey === "state") ?
                                                            SplitAddress(user.address).state :
                                                            (col.dbKey === "city") ?
                                                                SplitAddress(user.address).city :
                                                                (user[col.dbKey] ? user[col.dbKey] : "-")
                                                    }
                                                </td>
                                            )
                                        }
                                        return null;
                                    })}

                                </tr>) : <tr>
                                <td colSpan="10" className="text-center py-20">
                                    Nothing to show here
                                </td>
                            </tr>}

                        </tbody>
                    </table>
                </div>

                <div className="flex items-center justify-between my-4">
                    <div className="">
                        <span className="font-semibold text-[14px]">{filteredCustomers.length}</span> {filteredCustomers.length > 1 ? "results" : "result"}
                    </div>

                    <div>
                        {totalPages > 1 && (
                            <div className="flex items-center gap-4">
                                <button onClick={handlePrevious} disabled={currentPage === 1}
                                    className={`h-[26px] w-[70px] text-sm border border-gray-300 rounded-lg font-semibold ${currentPage === 1 && "text-[#9a9ca1]"}`}>
                                    Previous
                                </button>

                                <button onClick={handleNext} disabled={currentPage === totalPages}
                                    className={`h-[26px] w-[70px] text-sm border border-gray-300 rounded-lg font-semibold ${currentPage === totalPages && "text-[#9a9ca1]"}`}>
                                    Next
                                </button>
                            </div>
                        )}
                    </div>
                </div>

            </div>
        </>
    );
}