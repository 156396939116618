import { useAlertContext } from "Context/AlertContext";
import axiosClient from "axios.client";
import { createContext, useContext, useEffect, useState } from "react";

export const DataContext = createContext();

export const DataContextProvider = ({ children }) => {
    const { SetAlert } = useAlertContext();

    const [dataLoading, setDataLoading] = useState(false);
    const [csvData, setCsvData] = useState([]);

    const getData = async () => {
        setDataLoading(true);

        try {
            const res = await axiosClient.get('/v2/get-mapped-csv-data');

            if (res.status === 200) {
                setCsvData(res.data);
            } else {
                setCsvData(null);
                SetAlert(true, 'failed', 'Failed to fetch data. Please try again.')
            }

        } catch (error) {
            SetAlert(true, 'failed', 'Failed to fetch data. Please try again.')
        }

        setDataLoading(false);
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <DataContext.Provider value={{
            dataLoading,
            setDataLoading,
            csvData,
        }}>
            {children}
        </DataContext.Provider>
    );
};

export const useDataContext = () => {
    return useContext(DataContext);
};