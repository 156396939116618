import React from 'react';

const InputRow = ({
    label,
    name,
    id,
    type,
    readOnly = true,
}) => {
    return (
        <div className='flex-1 flex items-center justify-between gap-5'>
            <div className='flex flex-col gap-2 w-full'>
                <label htmlFor={id} className='text-[#1A1B25] text-xs font-medium'>{label}</label>
                <input
                    type={type}
                    name={name}
                    id={id}
                    placeholder={label}
                    defaultValue={label}
                    readOnly={readOnly}
                    className='p-[5px] px-2 border border-[#E2E4E5] rounded-sm outline-none hover:border-[#10111a7e] duration-200 cursor-pointer text-[#687385]'
                />
            </div>
        </div>
    );
};

export default InputRow;
