import React from 'react'

const DieselIcon = ({ color, height, width }) => {
    return (
        <svg
            width={width != undefined ? width : "32"}
            height={height != undefined ? height : "32"}
            viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_153_1661)">
                <path d="M10.3795 6.89268H24.6521C24.9963 6.89268 25.3263 7.02939 25.5696 7.27272C25.8129 7.51605 25.9496 7.84608 25.9496 8.1902V27.6529C25.9496 27.997 25.8129 28.327 25.5696 28.5704C25.3263 28.8137 24.9963 28.9504 24.6521 28.9504H6.48696C6.14284 28.9504 5.81282 28.8137 5.56949 28.5704C5.32616 28.327 5.18945 27.997 5.18945 27.6529V14.6778L10.3795 6.89268ZM16.8671 1.70264H23.3546C23.6987 1.70264 24.0288 1.83934 24.2721 2.08267C24.5154 2.326 24.6521 2.65603 24.6521 3.00015V5.59517H15.5695V3.00015C15.5695 2.65603 15.7062 2.326 15.9496 2.08267C16.1929 1.83934 16.5229 1.70264 16.8671 1.70264ZM7.78448 15.9753V25.0579H10.3795V15.9753H7.78448Z"
                    fill={color != undefined ? color : "#0B5CC2"} />
            </g>
            <defs>
                <clipPath id="clip0_153_1661">
                    <rect width="31.1403" height="31.1403" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default DieselIcon