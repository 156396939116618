export default function removeDuplicates(arr) {
    for (let i = 0; i < arr.length; i++) {
        for (let j = i + 1; j < arr.length; j++) {
            let keysMatch = true;
            for (let key in arr[i]) {
                if (key === "vin") {
                    if (
                        arr[i][key] === arr[j][key] &&
                        arr[i]["year"] === arr[j]["year"] &&
                        arr[i]["model"] === arr[j]["model"] &&
                        arr[i]["make"] === arr[j]["make"]
                    ) {
                        // console.log("Duplicate " + arr[i]["year"] + " " + arr[i]["make"]);
                        keysMatch = true;
                        break;
                    } else {
                        keysMatch = false;
                    }
                }
            }
            if (keysMatch) {
                arr.splice(j, 1);
                j--;
            }
        }
    }
    return arr;
}