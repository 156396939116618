import React, { useCallback, useEffect, useRef, useState } from 'react'
import { DownArrowIcon } from 'components/icons/DownArrowIcon'
import { RightArrowIcon } from 'components/icons/RightArrowIcon'
import { SmallHorizontalMenuIcon } from 'components/icons/SmallHorizontalMenuIcon';
import RowDetail from './RowDetail';
import { useCustomerDetailContext } from 'Context/customerDetailContext/CustomerDetailContext';
import { CountPending } from 'components/Button/CountPending';
import { CruiseToggle } from './components/CruiseToggle';
import axiosClient from 'axios.client';
import { MenuDropDown } from './components/MenuDropDown';
import { useNavigate } from 'react-router-dom';

export const CustomerList = ({ customerID }) => {
    const {
        usersList,
        isLoading,
        vehicleData,
        setUsersList,
        handleSelectedUser,
        fetchManageVehicleData,
    } = useCustomerDetailContext();

    const navigate = useNavigate();

    const [rowOpen, setRowOpen] = useState(null);
    const [cruiseToggle, setCruiseToggle] = useState({
        id: "",
        name: "",
        website: "",
        state: "",
        city: "",
        phone: "",
        first_name: "",
        last_name: "",
        email: "",
        mobile: "",
        formPackage: "",
        user_count: "",
        amount: "",
        dealerCenter: "",
        vehicles: "",
        perMonthAmt: "",
        FTPaccount: "",
        cruise_active: "",
    });

    const updateCruise = (val, id) => {
        const updatedListUser = usersList.map((user) => {
            if (user.id === id) {
                return { ...user, cruise_active: val };
            }
            return user;
        });

        usersList.map((user) => {
            if (user.id === id) {
                return setCruiseToggle({ ...user, cruise_active: val });
            }

            return user;
        });

        setUsersList(updatedListUser);

        updateRowCruise(val, id);
    };

    const updateRowCruise = async (val, id) => {
        const selectedUser = usersList.find((user) => user.id === id);

        if (selectedUser) {
            const addressParts = (selectedUser.address || "").split(",");

            const payload = {
                ...selectedUser,
                cruise_active: val,
                city: addressParts[0] || "",
                state: addressParts[1] || "",
                formPackage: selectedUser.package,
            };

            try {
                const response = await axiosClient
                    .post("/updateDealerShip", payload)
                    // .then((res) => console.log(res))
                    .catch((e) => console.log(e));

                if (response.data.message === "Cruise was updated successfully") {
                    return { success: true, message: response.data.message };
                } else {
                    return { success: false, message: response.data.message };
                }
            } catch (error) {
                console.error("Error updating Cruise:", error);
                return {
                    success: false,
                    message: "An error occurred while updating Cruise",
                };
            }
        }
    };

    const handleToggle = useCallback((index, item) => {
        navigate(`/dealership/customer-detail/${customerID}/user-detail/${item.id}`);
        // handleSelectedUser(item.assignedFtpAccounts)
        // fetchManageVehicleData(item.id)

        // if (rowOpen === index) setRowOpen("")
        // else setRowOpen(index);
    }, [rowOpen]);


    const [openMenu, setOpenMenu] = useState(null);
    const refDelete = useRef([]);

    const handleRowClick = (index) => {
        if (index === openMenu) {
            setOpenMenu(null);
        } else {
            setOpenMenu(index);
        }
    }

    const handleClickedOutside = (e) => {
        if (!refDelete.current.some((ref) => ref && ref.contains(e.target))) {
            setOpenMenu(null);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickedOutside);
        return () => {
            document.removeEventListener("click", handleClickedOutside);
        };
    }, []);

    return (
        <>
            {usersList?.map((item, index) => (
                <React.Fragment key={item.id}>
                    <tr className={`border-t text-sm ${rowOpen === index && "border-b"} hover:bg-gray-100 duration-300 cus`}>

                        <td className='py-[10px] text-left flex items-center gap-5 text-[#353A44] cursor-default'
                            onClick={() => { handleToggle(index, item) }}>
                            {/* <span className='cursor-pointer'>
                                {rowOpen === index ? <DownArrowIcon /> : <RightArrowIcon />}
                            </span>  */}
                            {item.id.toString().padStart(4, '0')}</td>

                        <td className='py-2 text-[#596171] cursor-default'>
                            <div className={`h-3 w-3 ${item.freeze === 1 ? "bg-rose-500" : "bg-green-500"} rounded-full ml-3`}></div>
                        </td>

                        <td className='py-2 text-left cursor-default'
                            onClick={() => { handleToggle(index, item) }}>{item.username ? item.username : "-"}</td>

                        <td className='py-2 text-left text-buttonPrimary cursor-default text-[12px]'
                            onClick={() => { handleToggle(index, item) }}>{item.phone ? item.phone : "-"}</td>

                        {/* <td className='py-2 text-left text-[#596171] cursor-default'
                            onClick={() => { handleToggle(index, item) }}>
                            {isLoading ? <CountPending /> : vehicleData.listed !== undefined ? vehicleData.listed[item.id] : 0}
                        </td>

                        <td className='py-2 text-left text-[#596171] cursor-default'
                            onClick={() => { handleToggle(index, item) }}>
                            {isLoading ? <CountPending /> : vehicleData.advertised !== undefined ? vehicleData.advertised[item.id] : 0}
                        </td> */}

                        <td className='py-2 text-left text-[#596171] cursor-default'
                            onClick={() => { handleToggle(index, item) }}>{item.pass ? item.pass : "-"}</td>

                        <td className='py-2 text-left text-[#596171] cursor-default'
                            onClick={() => { handleToggle(index, item) }}>{item.address ? item.address : "-"}</td>

                        {/* <td className='py-2 text-left text-[#596171]'>
                            <CruiseToggle
                                cruiseToggle={cruiseToggle}
                                updateCruise={updateCruise}
                                user={item}
                            ></CruiseToggle>
                        </td> */}


                        {/* <td className='py-2 text-left text-[#596171] cursor-default'
                            onClick={() => { handleToggle(index, item) }}>POST</td> */}

                        {/* <td className='py-2 text-left text-[#596171] cursor-default'
                            onClick={() => { handleToggle(index, item) }}>Dec, 22, 2023</td> */}

                        <td className='py-2 text-left text-[#596171] cursor-pointer relative'
                            ref={(ref) => (refDelete.current[index] = ref)}
                            onClick={() => handleRowClick(index)}>
                            <SmallHorizontalMenuIcon color="#1B74E4" />

                            <MenuDropDown openMenu={openMenu} index={index} item={item} />
                        </td>

                    </tr>
                    {/* {rowOpen === index && <RowDetail item={item} />} */}
                </React.Fragment>
            ))}
        </>
    )
}
