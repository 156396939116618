import React, { useEffect, useState } from "react";
import { ExitIcon } from "../../../components/icons/ExitIcon";
import axiosClient from "../../../axios.client";

export const ContactDetails = ({
  setOpenEdit,
  userDealer,
  setUserDealer,
  fetchUsersList,
  SetAlert,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [generalData, setGeneralData] = useState({
    id: userDealer.id,
    first_name: userDealer.first_name,
    last_name: userDealer.last_name,
    email: userDealer.email,
    phone: userDealer.phone,
    section: userDealer.section,
  });

  const handleInput = (e) => {
    const { name, value } = e.target;

    setGeneralData({ ...generalData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const fromData = new FormData(e.currentTarget);
    const data = Object.fromEntries(fromData);

    axiosClient
      .post("/update-dealerShip-section", data)
      .then((res) => {
        if (res.status === 200) {
          SetAlert(true, "success", res.data.message);
        }
      })
      .catch((e) => SetAlert(true, "failed", "Something went wrong"));

    setUserDealer({ ...userDealer, ...generalData });

    setLoading(false);
    setOpenEdit({ isOpen: false, section: "" });
  };

  useEffect(() => {
    setGeneralData(userDealer);
  }, [userDealer]);

  return (
    <>
      <div className="block fixed top-0 z-[51] w-full h-full bg-black bg-opacity-30">
        <div className="flex items-center justify-center h-screen">
          <div className="bg-white p-5 rounded-lg w-[300px]">
            <div className="flex justify-between items-center">
              <div></div>
              <h1 className="font-semibold text-lg text-center mb-5">
                Contact Detail
              </h1>
              <button
                className="mb-4"
                onClick={() => setOpenEdit({ isOpen: false, section: "" })}
              >
                <ExitIcon />
              </button>
            </div>
            <form onSubmit={handleSubmit}>
              <input type="hidden" value={generalData.id} name="id" />
              <input type="hidden" value="contact" name="section" />

              <div className="flex items-center mb-4">
                {/* <label>Website </label> */}
                <input
                  type="name"
                  name="first_name"
                  placeholder="first name"
                  value={generalData.first_name}
                  onChange={(e) => handleInput(e)}
                  className="w-full border border-gray-200 p-1  px-2 rounded-md"
                />
              </div>
              <div className="flex items-center mb-4">
                {/* <label>city </label> */}
                <input
                  type="name"
                  name="last_name"
                  placeholder="last name"
                  onChange={(e) => handleInput(e)}
                  value={generalData.last_name}
                  className="w-full border border-gray-200 p-1  px-2 rounded-md"
                />
              </div>
              <div className="flex items-center mb-4">
                {/* <label>state </label> */}
                <input
                  type="email"
                  name="email"
                  placeholder="johnWick@example.com"
                  onChange={(e) => handleInput(e)}
                  value={generalData.email}
                  className="w-full border border-gray-200 p-1  px-2 rounded-md"
                />
              </div>
              <div className="flex items-center mb-4">
                {/* <label>phone </label> */}
                <input
                  type="phone"
                  name="phone"
                  placeholder="1122334455"
                  onChange={(e) => handleInput(e)}
                  value={generalData.phone}
                  className="w-full border border-gray-200 p-1  px-2 rounded-md"
                />
              </div>
              {isLoading ? (
                <span className="font-normal flex items-center justify-center gap-1 bg-buttonPrimary text-white px-4 py-2 rounded-md text-[16px] cursor-wait">
                  <span className="flex gap-2">
                    <span className="w-5 h-5 border-t-4 border-white border-solid rounded-full animate-spin"></span>
                  </span>
                </span>
              ) : (
                <button className="w-full p-2 bg-buttonPrimary rounded-lg text-white mt-2">
                  Edit
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
