
import React from 'react'
import { useCustomerContext } from 'Context/CustomerContext';

const AddCustomerRow = () => {

    const {
        formData,
        handleInputChange,
    } = useCustomerContext();

    return (
        <>
            <tr>
                <td className='font-semibold text-left py-2 flex items-center gap-5 text-[16px]'>
                    <input
                        type="text"
                        placeholder="dealership"
                        name="dealerShip"
                        required
                        value={formData.dealerShip}
                        onChange={handleInputChange}
                        className="border-1 border-black outline-none text-l rounded-sm w-[125px]"
                    />
                </td>
                <td className="font-semibold text-left py-2 text-[16px]">
                    <input
                        type="email"
                        placeholder="example@gmail.com"
                        name="email"
                        required
                        value={formData.email}
                        onChange={handleInputChange}
                        className="border-1 border-black outline-none text-l rounded-sm w-[125px]"
                    />
                </td>
                <td className="font-semibold text-left py-2 text-[16px]">
                    <input
                        type="text"
                        placeholder="website"
                        name="website"
                        required
                        value={formData.website}
                        onChange={handleInputChange}
                        className="border-1 border-black outline-none text-l rounded-sm w-[125px]"
                    />
                </td>
                <td className="font-semibold text-left py-2 text-[16px]">
                    <input
                        type="text"
                        placeholder="state"
                        name="state"
                        required
                        value={formData.state}
                        onChange={handleInputChange}
                        className="border-1 border-black outline-none text-l rounded-sm w-[125px]"
                    />
                </td>
                <td className="font-semibold text-left py-2 text-[16px]">
                    <input
                        type="text"
                        placeholder="city"
                        name="city"
                        required
                        value={formData.city}
                        onChange={handleInputChange}
                        className="border-1 border-black outline-none text-l rounded-sm w-[125px]"
                    />
                </td>
                <td className="font-semibold text-left py-2 text-[16px]">
                    <input
                        type="number"
                        placeholder="phone number"
                        name="phoneNo"
                        required
                        value={formData.phoneNo}
                        onChange={handleInputChange}
                        className="border-1 border-black outline-none text-l rounded-sm w-[125px]"
                    />
                </td>
                <td className="font-semibold text-left py-2 text-[16px]">
                    <input
                        type="text"
                        placeholder="package"
                        name="formPackage"
                        required
                        value={formData.formPackage}
                        onChange={handleInputChange}
                        className="border-1 border-black outline-none text-l rounded-sm w-[100px]"
                    />
                </td>
                <td className="font-semibold text-left py-2 text-[16px]">
                    <input
                        type="number"
                        placeholder="users"
                        name="formUser"
                        min="0"
                        required
                        value={formData.formUser}
                        onChange={handleInputChange}
                        className="border-1 border-black outline-none text-l rounded-sm w-[90px]"
                    />
                </td>
                <td className="font-semibold text-left py-2 text-[16px]">
                    <input
                        type="name"
                        placeholder="first name"
                        name="firstName"
                        required
                        value={formData.firstName}
                        onChange={handleInputChange}
                        className="border-1 border-black outline-none text-l rounded-sm w-[125px]"
                    />
                </td>
                <td className="font-semibold text-left py-2 text-[16px]">
                    <input
                        type="name"
                        placeholder="last name"
                        name="lastName"
                        required
                        value={formData.lastName}
                        onChange={handleInputChange}
                        className="border-1 border-black outline-none text-l rounded-sm w-[125px]"
                    />
                </td>
            </tr>
        </>
    )
}

export default AddCustomerRow