import React from 'react';
import Chart from 'react-apexcharts';

const ReportChart = () => {
    const chartOptions = {
        series: [
            {
                name: "Pending",
                data: [50, 50, 100, 80, 70, 50, 40, 90, 10, 100, 100, 120],
                color: "#FFCE20"
            },
            {
                name: "posted",
                data: [10, 10, 50, 120, 150, 100, 120, 150, 100, 40, 120, 150],
                color: "#05CD99"
            },
            {
                name: "Errors",
                data: [10, 20, 50, 50, 30, 40, 10, 30, 40, 60, 110, 140],
                color: "#EE5D50"
            }
        ],
        chart: {
            height: 227,
            type: 'line',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false,
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            // curve: 'smooth',
            width: 1.5
        },
        grid: {
            strokeDashArray: 0,
            borderColor: '#E3E8EE',
            borderWidth: 1,
            row: {
                colors: ['#fff', 'transparent'],
                opacity: 0.5,
            },
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            axisBorder: {
                show: false,
            },
            tooltip: {
                enabled: false
            },
            offsetX: -15,
            labels: {
                show: true,
                style: {
                    colors: '#818DA0',
                    fontSize: '12px',
                    fontFamily: 'Roboto',
                    fontWeight: 400,
                    cssClass: 'apexcharts-xaxis-label',
                }
            }
        },
        legend: {
            show: false,
        },
    };

    return (
        <div id="chart">
            <Chart options={chartOptions} series={chartOptions.series} type="line" height={230} />
        </div>
    );
};

export default ReportChart;
