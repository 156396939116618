import React from 'react'

export const UniqueLeverageIcon = () => {
    return (
        <svg width="44" height="40" viewBox="0 0 44 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_481_2073)">
                <rect x="5" y="3" width="34" height="30" rx="7" fill="white" shapeRendering="crispEdges" />
                <path d="M32.368 18.3339V16.2081L29.9113 15.6229C29.9113 15.6229 29.6826 14.3546 29.2703 13.9762L30.4281 12.7405C29.4498 13.2754 28.5068 13.8681 27.6043 14.5152C28.4365 15.9263 28.6913 17.5863 28.3187 19.1693C27.9461 20.7522 26.9729 22.1437 25.5904 23.0704C24.2079 23.9972 22.5159 24.3923 20.8467 24.1781C19.1774 23.9639 17.6516 23.1559 16.5687 21.9128C15.4859 20.6697 14.9243 19.0813 14.9943 17.4595C15.0643 15.8378 15.7607 14.2998 16.9469 13.1477C18.1331 11.9955 19.7233 11.3124 21.4052 11.2326C23.0871 11.1527 24.7392 11.6818 26.0372 12.716C27.0635 11.9849 28.1347 11.3141 29.2449 10.7073L29.1433 10.5468L27.1328 11.3878C27.1328 11.3878 26.0654 10.5141 25.6729 10.4107V8.07806L23.5607 7.61536L22.2449 9.44986C21.6279 9.3863 21.0053 9.39178 20.3897 9.46619L19.1472 7.61536L17.0661 8.4564V10.7536C17.0661 10.7536 15.8378 11.6273 15.7135 11.8859L13.4912 11.0966L12.2629 12.9746L13.703 14.6458C13.703 14.6458 13.2964 15.9142 13.3133 16.3605L11.0713 16.9267V19.1722L13.4545 19.8091C13.4545 19.8091 13.9007 21.4041 14.0419 21.4558L12.63 23.1515L14.1068 24.8308L16.3319 24.0143C16.7686 24.4021 17.2747 24.7101 17.8257 24.9234L17.7551 27.3077L19.8023 27.852L21.2142 25.9141L22.9932 25.8297L24.2582 27.6642L26.3195 26.9239L26.3534 24.4906C26.8656 24.1947 27.3299 23.8279 27.7314 23.4019L29.8859 24.2756L31.0408 22.3894L29.5386 20.6637C29.8508 20.1344 30.0279 19.541 30.0553 18.9327L32.368 18.3339Z" fill="#1B74E4" />
                <path d="M33.3903 11.0686C30.7091 12.1489 28.2345 13.6361 26.0653 15.4709C24.4264 16.9422 23.0973 18.6914 22.1478 20.6266C22.1062 20.7185 22.0451 20.8015 21.9683 20.8706C21.8124 21.0176 21.6021 21.1021 21.3814 21.1065C21.0895 21.0837 20.8181 20.956 20.6236 20.7499L17.4981 16.8731C17.3822 16.7329 17.3099 16.5652 17.2893 16.3886C17.2687 16.2121 17.3005 16.0336 17.3813 15.8731C17.4408 15.744 17.54 15.6347 17.6662 15.5594C17.8087 15.5032 17.9619 15.475 18.1164 15.4763C18.254 15.4753 18.39 15.504 18.514 15.5603C18.6379 15.6165 18.7466 15.6988 18.8315 15.8007L20.9597 18.3477C21.0281 18.4603 21.0851 18.5005 21.1364 18.5005C21.2361 18.5005 21.2959 18.3477 21.2959 18.3477C21.4784 18.0308 21.6781 17.7229 21.8942 17.4254C23.2818 15.5486 24.051 14.7899 24.3844 14.503L25.0282 13.9427C27.9913 11.342 32.5555 9.41436 32.5555 9.41436C32.7801 9.34495 33.0144 9.30701 33.2507 9.30176C33.9316 9.30176 34.0655 9.72 34.0655 9.72C34.4302 10.7066 33.3903 11.0686 33.3903 11.0686Z" fill="#1B74E4" />
            </g>
            <defs>
                <filter id="filter0_d_481_2073" x="0" y="0" width="44" height="40" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="2.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.188235 0 0 0 0 0.192157 0 0 0 0 0.239216 0 0 0 0.08 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_481_2073" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_481_2073" result="shape" />
                </filter>
            </defs>
        </svg>

    )
}
