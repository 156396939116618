import React, { useState } from "react";
import { EditIcon } from "../../../components/icons/EditIcon";
import { BoxIcon } from "../../../components/icons/BoxIcon";
import { UsersIcon } from "../../../components/icons/UsersIcon";
import { RightArrowIcon } from "../../../components/icons/RightArrowIcon";
import { TwoCircleCursorIcon } from "../../../components/icons/TwoCircleCursorIcon";
import { DollarSignIcon } from "../../../components/icons/DollarSignIcon";
import { PhoneIcon } from "../../../components/icons/PhoneIcon";
import { EmailIcon } from "../../../components/icons/EmailIcon";
import { BackArrowIcon } from "../../../components/icons/BackArrowIcon";
import { EditGeneral } from "../AddDealerShip/EditGeneral";
import { ContactDetails } from "../AddDealerShip/ContactDetails";
import UsedCars from "./UsedCars/UsedCars";
import NotificationMsg from "../../../components/Alerts/NotificationMsg";

function DealerShipDetailMobileView(props) {
  const [openEdit, setOpenEdit] = useState({ isOpen: false, section: "" });

  const [notification, setNotification] = useState({
    isOpen: false,
    status: "",
    msg: "",
  });

  const SetAlert = (bool, status, msg) => {
    setNotification({ isOpen: bool, status: status, msg: msg });

    setTimeout(() => {
      setNotification({ isOpen: false, status: "", msg: "" });
    }, 5000);
  };

  return (
    <>
      {/* Notification Msg Start */}
      <NotificationMsg
        toggle={notification}
        Toggler={setNotification}
        message={notification.msg}
      ></NotificationMsg>
      {/* Notification Msg End */}

      {openEdit.section === "general" && (
        <EditGeneral
          setOpenEdit={setOpenEdit}
          userDealer={props.userDealer}
          fetchUsersList={props.fetchUsersList}
          SetAlert={SetAlert}
          setUserDealer={props.setUserDealer}
        />
      )}

      {openEdit.section === "contact" && (
        <ContactDetails
          setOpenEdit={setOpenEdit}
          userDealer={props.userDealer}
          fetchUsersList={props.fetchUsersList}
          SetAlert={SetAlert}
          setUserDealer={props.setUserDealer}
        />
      )}

      {openEdit.section === "showUser" && (
        <UsedCars
          setOpenEdit={setOpenEdit}
          userDealer={props.userDealer}
          // fetchUsersList={props.fetchUsersList}
          SetAlert={SetAlert}
          setUserDealer={props.setUserDealer}
          DealerShipName={props.userDealer.name}
        />
      )}

      <div className="absolute top-0 left-0 w-full h-full bg-white z-50 md:hidden">
        <div className="flex justify-between font-bold p-4 px-5 border-b">
          <button onClick={() => props.navigate("/")}>
            <BackArrowIcon />
          </button>
          <h1 className="text-center text-lg">{props.userDealer.name}</h1>
          <div></div>
        </div>
        <div>
          <div className="flex justify-between items-center px-5 py-5">
            <h3 className="font-bold text-xl">General</h3>
            <button
              onClick={() => setOpenEdit({ isOpen: true, section: "general" })}
            >
              <EditIcon width={16} height={17} />
            </button>
          </div>
          <div className="border-b pb-3">
            <a
              href={`${props.userDealer.website}`}
              className="text-buttonPrimary font-semibold px-5"
            >
              {props.userDealer.website}
            </a>
          </div>
          <div className="border-b pb-3 mt-3">
            <h1 className="text-[#262626] font-semibold px-5">
              {props.userDealer.address &&
                props.userDealer.address.split(",")[0]}
            </h1>
          </div>
          <div className="border-b pb-3 mt-3">
            <h1 className="text-[#262626] font-semibold px-5">
              {props.userDealer.address &&
                props.userDealer.address.split(",")[1]}
            </h1>
          </div>
          <div className="border-b pb-3 mt-3">
            <a
              className="text-buttonPrimary font-semibold px-5"
              href={`tel:${props.userDealer.phone}`}
            >
              {props.userDealer.mobile}
            </a>
          </div>
        </div>

        <div>
          <div className="flex justify-between items-center px-5 py-5">
            <h3 className="font-bold text-xl">Contact Details</h3>
            <button
              onClick={() => setOpenEdit({ isOpen: true, section: "contact" })}
            >
              <EditIcon width={16} height={17} />
            </button>
          </div>
          <div className="border-b pb-3 flex">
            <label className="px-5 text-[#484848] font-semibold">
              First Name:
            </label>
            <h3 className="px-5 text-[#383838] font-bold">
              {props.userDealer.first_name}
            </h3>
          </div>

          <div className="border-b pb-3 mt-3 flex">
            <label className="px-5 text-[#484848] font-semibold">
              Last Name:
            </label>
            <h3 className="px-5 text-[#383838] font-bold">
              {props.userDealer.last_name}
            </h3>
          </div>

          <div className="border-b pb-3 mt-3 flex ">
            <label className="px-5 text-[#484848] font-semibold flex items-center gap-4">
              <EmailIcon />
              <span className="text-right">Email:</span>
            </label>
            <a
              className="px-5 text-buttonPrimary font-bold"
              href={`mailto:${props.userDealer.email}`}
            >
              {props.userDealer.email}
            </a>
          </div>

          <div className="border-b pb-3 mt-3 flex ">
            <label className="px-5 text-[#484848] font-semibold flex items-center gap-2">
              <PhoneIcon />

              <span className="text-right">Phone:</span>
            </label>
            <a
              className="px-5 text-buttonPrimary font-bold"
              href={`tel:${props.userDealer.phone}`}
            >
              {props.userDealer.phone}
            </a>
          </div>
        </div>

        <div>
          <div className="flex justify-between items-center px-5 py-5">
            <h3 className="font-bold text-xl">Subscriptions</h3>
            <button>
              <EditIcon width={16} height={17} />
            </button>
          </div>
          <div className="border-b pb-3 mt-3 flex">
            <label className="px-5 text-[#484848] font-semibold">
              <BoxIcon />
            </label>
            <h3 className="px-5 text-[#383838] font-bold">
              {props.userDealer.package === null
                ? "--"
                : props.userDealer.package}
            </h3>
          </div>

          <div className="border-b pb-3 mt-3 flex">
            <label className="px-5 text-[#484848] font-semibold">
              <UsersIcon />
            </label>
            <h3 className="px-5 text-[#383838] font-bold flex justify-between items-center w-full">
              <span className="block text-buttonPrimary">
                {props.length} Users
              </span>
              <button
                // onClick={() =>
                //   props.navigate(`/dealerShip/users/${props.userDealer.name}`)
                // }

                onClick={() =>
                  setOpenEdit({ isOpen: true, section: "showUser" })
                }
              >
                <RightArrowIcon />
              </button>
            </h3>
          </div>

          <div className="border-b pb-3 mt-3 flex ">
            <label className="px-5 text-[#484848] font-semibold flex items-center gap-4">
              <DollarSignIcon />
            </label>
            <h3 className="px-5 text-[#484848] font-bold">$99</h3>
          </div>

          <div className="border-b pb-3 mt-3 flex ">
            <label className="px-5 text-[#484848] font-semibold flex items-center gap-2">
              <TwoCircleCursorIcon />
            </label>
            <h3 className="px-5 text-[#1FA956] font-bold">$300/mo*</h3>
          </div>
        </div>

        <div className="mb-2">
          <div className="flex justify-between items-center px-5 py-5">
            <h3 className="font-bold text-xl">Meta Data</h3>
            <button>
              <EditIcon width={16} height={17} />
            </button>
          </div>

          <div className="border-b pb-3 mt-3 flex">
            <label className="px-5 text-[#484848] font-semibold">
              <BoxIcon />
            </label>
            <h3 className="px-5 text-[#383838] font-bold flex justify-between items-center w-full">
              <span className="block text-[#484848]">Dealercenter</span>
              <span className="block">
                <RightArrowIcon />
              </span>
            </h3>
          </div>

          <div className="border-b pb-3 mt-3 flex">
            <label className="px-5 text-[#484848] font-semibold">
              <UsersIcon />
            </label>
            <h3 className="px-5 text-[#484848] font-bold">98 Vehicles</h3>
          </div>
        </div>
      </div>
    </>
  );
}

export default DealerShipDetailMobileView;
