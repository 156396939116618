import LineJoinArrow from 'components/icons/LineJoinArrow';

const NewField = ({
    formFields,
    handleInputChange,
    handleSelectChange,
    handleRemoveField,
    csvDataHeader,
    loadingCSV
}) => {

    return (
        <form>
            {formFields.map((field) => (
                <div key={field.id} className='flex items-center justify-between gap-20'>
                    <div className='flex-1 flex items-center justify-between gap-5'>
                        <div className='flex flex-col gap-2 w-full'>
                            <label htmlFor={`name-${field.id}`} className='text-[#1A1B25] text-xs font-medium'>Additional Field:</label>
                            <input
                                type="text"
                                id={`name-${field.id}`}
                                name={`name-${field.id}`}
                                className='p-[5px] px-2 border border-[#E2E4E5] rounded-sm outline-none hover:border-[#10111a7e] duration-200 cursor-pointer text-[#687385]'
                                value={field[`name-${field.id}`]}
                                onChange={(e) => handleInputChange(field.id, e)}
                            />
                        </div>
                    </div>



                    <div className='mt-5'><LineJoinArrow /></div>


                    <div className='flex-1 flex items-center justify-between gap-5'>
                        <div className='flex-1 flex items-center justify-between gap-5'>
                            <div className='flex flex-col gap-2'>
                                <label htmlFor={`option-${field.id}`} className='text-[#1A1B25] text-xs font-medium flex items-center gap-5 justify-between'>
                                    <span className='block'>Additional Field Option:</span>
                                    <span className='block'>
                                        <button type="button" className='text-[#1B74E4] text-xs font-medium' onClick={() => handleRemoveField(field.id)}>
                                            Remove
                                        </button>
                                    </span>
                                </label>
                                <select
                                    id={`option-${field.id}`}
                                    name={`selectedOption-${field.id}`}
                                    value={field.selectedOption}
                                    className='select-custom p-[5px] px-2 border border-[#E2E4E5] rounded-sm w-[428px] 2xl:w-[690px] outline-none hover:border-[#10111a7e] duration-200 cursor-pointer text-[#687385]'
                                    onChange={(e) => handleSelectChange(field.id, e)}
                                >
                                    <option value="" disabled>Select Account</option>
                                    {loadingCSV ? <option disabled>Loading...</option> :
                                        csvDataHeader.map((header, index) => (
                                            <option key={index} value={header}>{header}</option>
                                        ))
                                    }
                                </select>
                            </div>

                        </div>
                    </div>
                </div>
            ))}
        </form>
    );
};

export default NewField;
