import React from "react";
import Header from "../../layout/Header/Header";
import SideMenu from "../../components/sidemenu/SideMenu";

const Reposts = () => {
  return (
    <div className="wrapper bg-backgroudWrapper">
      <div className="md:flex w-full h-screen">
        <SideMenu />

        <div className="w-full bg-backgroudPrimary overflow-x-auto" id="main-content">
          <Header />
          <div>Reposts</div>
        </div>
      </div>
    </div>
  );
};

export default Reposts;
