import { useCustomerContext } from 'Context/CustomerContext';
import { useSearchContext } from 'Context/SearchContext';
import axiosClient from 'axios.client';
import SmallLoading from 'components/Loadings/SmallLoading';
import React, { useEffect, useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom';

const SearchModel = ({ toggleSearchModel, setToggleSearchModel, searchModel, setToggleSearchModelFocus }) => {
    const navigate = useNavigate();

    const {
        search,
        setSearch,
        selectedPage,
    } = useSearchContext();

    // const { allCustomers, allUsers, loading } = useCustomerContext();

    const [allCustomers, setAllCustomers] = useState([]);
    const [allUsers, setAllUsers] = useState([]);

    const [loading, setLoading] = useState(false);

    const fetchCustomerAndUser = useCallback(async () => {
        try {
            setLoading(true);

            const [customerData, UsersData] = await Promise.all([
                axiosClient.get("/getUsers"),
                axiosClient.get("/getAllUsers"),
            ]);

            setAllCustomers(customerData?.data?.data || []);
            setAllUsers(UsersData?.data?.data || []);
        } catch (e) {
        } finally {
            setLoading(false);
        }
    }, []);

    const filteredCustomers = allCustomers.filter(user => {
        // if (search.pageName === "allCustomers") {
        if (search.value.toLowerCase() === "") {
            return true;
        }

        return user.name.toLowerCase().includes(search.value) ||
            user.phone.toLowerCase().includes(search.value) ||
            (user.first_name ? user.first_name.toLowerCase().includes(search.value) : "") ||
            (user.last_name ? user.last_name.toLowerCase().includes(search.value) : "");
        // }

        // return true;
    });

    const filteredUser = allUsers.filter(user => {
        if (search.value.toLowerCase() === "") {
            return true;
        }

        return user.name.toLowerCase().includes(search.value) ||
            user.phone.toLowerCase().includes(search.value) ||
            (user.first_name ? user.first_name.toLowerCase().includes(search.value) : "") ||
            (user.last_name ? user.last_name.toLowerCase().includes(search.value) : "");
    });

    const handleClickedOutsideSearchModel = (e) => {
        if (!searchModel.current.contains(e.target)) {
            setToggleSearchModel(false);
            setToggleSearchModelFocus(false);
        } else {
            setToggleSearchModel(true);
            setToggleSearchModelFocus(true);
        }
    };

    const [isSearch, setIsSearch] = useState(false);

    useEffect(() => {
        if (toggleSearchModel) {
            document.addEventListener('click', handleClickedOutsideSearchModel);
        }

        return () => {
            document.removeEventListener('click', handleClickedOutsideSearchModel);
        };
    }, [toggleSearchModel]);

    useEffect(() => {
        if (search.value !== '') {
            setIsSearch(true)
        } else {
            setIsSearch(false)
        }
    }, [search]);

    useEffect(() => {
        fetchCustomerAndUser();
    }, []);

    return (
        <div id="search" className='header-sticky z-20 ml-10'>
            {isSearch ? (
                <div className='absolute w-[630px] py-[20px] bg-white rounded-lg shadow-xl border border-gray-200 font-roboto'>
                    <div className="flex items-center gap-1 border-b pb-[5px] hover:cursor-pointer"
                        onClick={() => { setSearch({ pageName: selectedPage, value: search.value }) }}>
                        <h1 className="font-roboto text-[14px] font-[500] text-[#566072] pl-[20px]">Show results for </h1>
                        <span className='text-[#2d3139] font-[600]'>{search.value}</span>
                    </div>

                    <div className='px-[20px] py-[5px]'>
                        <h1 className="uppercase font-roboto text-[14px] font-[500] text-[#566072]">Customers</h1>

                        <div className='flex flex-col gap-[3px] pt-[10px] min-h-[80px] max-h-[150px] overflow-y-auto'>

                            {
                                loading ? (
                                    <div className="flex justify-center">
                                        <SmallLoading />
                                    </div>
                                ) : (
                                    filteredCustomers.map((customer, index) => (
                                        <div key={index} className='hover:cursor-pointer hover:bg-slate-100 rounded-lg'
                                            onClick={() => {
                                                navigate(`/dealership/customer-detail/${customer.id}`);
                                                setSearch({ pageName: selectedPage, value: "" });
                                            }}>
                                            <h1 className='text-15px text-566072'>{customer.name}</h1>
                                        </div>
                                    ))
                                )
                            }

                        </div>
                    </div>

                    <hr />

                    <div className='px-[20px] py-[5px]'>
                        <h1 className="uppercase font-roboto text-[14px] font-[500] text-[#566072]">Users</h1>

                        <div className='flex flex-col gap-[3px] pt-[10px] min-h-[80px] max-h-[150px] overflow-y-auto'>

                            {
                                loading ? (
                                    <div className="flex justify-center">
                                        <SmallLoading />
                                    </div>
                                ) : (
                                    filteredUser.map((user, index) => (
                                        <div key={index} className='hover:cursor-pointer hover:bg-slate-100 rounded-lg'
                                            onClick={() => {
                                                navigate(`/dealership/customer-detail/${user.id}`);
                                                setSearch({ pageName: selectedPage, value: "" });
                                            }}>
                                            <h1 className='text-15px text-566072'>{user.username}</h1>
                                        </div>
                                    ))
                                )
                            }

                        </div>
                    </div>
                </div>
            )
                : (
                    <div className="absolute w-[630px] p-[20px] bg-white rounded-lg shadow-xl border border-gray-200 font-roboto">
                        <h1 className="uppercase font-roboto text-[14px] font-[500] text-[#566072]">SUGGESTED FILTERS</h1>

                        <div className="pt-[12px] flex flex-col gap-[12px]">
                            <div className="flex items-center justify-between hover:cursor-pointer text-[#545969]">
                                <div className="flex items-center gap-[11px]">
                                    <span className="text-[12px] font-[600] lowercase bg-[#EBEEF1] px-[5px] py-[2px] flex justify-center items-center text-[#545969] rounded-[3px]">is:</span>
                                    <h1 className="text-[14px] font-[400]">object type</h1>
                                </div>
                                <h1 className="text-[#AAB2BF] text-[14px] font-[400]">is:payment</h1>
                            </div>

                            <div className="flex items-center justify-between hover:cursor-pointer text-[#545969]">
                                <div className="flex items-center gap-[11px]">
                                    <span className="text-[12px] font-[600] lowercase bg-[#EBEEF1] px-[5px] py-[2px] flex justify-center items-center text-[#545969] rounded-[3px]">last4:</span>
                                    <h1 className="text-[14px] font-[400]">last four digits of the associated card</h1>
                                </div>
                                <h1 className="text-[#AAB2BF] text-[14px] font-[400]">last4:2326</h1>
                            </div>

                            <div className="flex items-center justify-between hover:cursor-pointer text-[#545969]">
                                <div className="flex items-center gap-[11px]">
                                    <span className="text-[12px] font-[600] lowercase bg-[#EBEEF1] px-[5px] py-[2px] flex justify-center items-center text-[#545969] rounded-[3px]">Date:</span>
                                    <h1 className="text-[14px] font-[400]">creation date or range</h1>
                                </div>
                                <h1 className="text-[#AAB2BF] text-[14px] font-[400]">date:yesterday</h1>
                            </div>

                            <div className="flex items-center justify-between hover:cursor-pointer text-[#545969]">
                                <div className="flex items-center gap-[11px]">
                                    <span className="text-[12px] font-[600] lowercase bg-[#EBEEF1] px-[5px] py-[2px] flex justify-center items-center text-[#545969] rounded-[3px]">email:</span>
                                    <h1 className="text-[14px] font-[400]">an email address</h1>
                                </div>
                                <h1 className="text-[#AAB2BF] text-[14px] font-[400]">email:jenny.rosen@example.com</h1>
                            </div>

                            <div className="flex items-center justify-between hover:cursor-pointer text-[#545969]">
                                <div className="flex items-center gap-[11px]">
                                    <span className="text-[12px] font-[600] lowercase bg-[#EBEEF1] px-[5px] py-[2px] flex justify-center items-center text-[#545969] rounded-[3px]">amount:</span>
                                    <h1 className="text-[14px] font-[400]">specific amount or range</h1>
                                </div>
                                <h1 className="text-[#AAB2BF] text-[14px] font-[400]">amount:149.99</h1>
                            </div>

                            <div className="flex items-center justify-between hover:cursor-pointer text-[#545969]">
                                <div className="flex items-center gap-[11px]">
                                    <span className="text-[12px] font-[600] lowercase bg-[#EBEEF1] px-[5px] py-[2px] flex justify-center items-center text-[#545969] rounded-[3px]">currency:</span>
                                    <h1 className="text-[14px] font-[400]">three-letter currency code</h1>
                                </div>
                                <h1 className="text-[#AAB2BF] text-[14px] font-[400]">currency:EUR</h1>
                            </div>

                            <div className="flex items-center justify-between hover:cursor-pointer text-[#545969]">
                                <div className="flex items-center gap-[11px]">
                                    <span className="text-[12px] font-[600] lowercase bg-[#EBEEF1] px-[5px] py-[2px] flex justify-center items-center text-[#545969] rounded-[3px]">status:</span>
                                    <h1 className="text-[14px] font-[400]">status of an object</h1>
                                </div>
                                <h1 className="text-[#AAB2BF] text-[14px] font-[400]">status:canceled</h1>
                            </div>

                        </div>

                    </div>
                )
            }
        </div>
    )
}

export default SearchModel