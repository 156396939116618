import React from "react";

export const EmailIcon = () => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.1666 2.50008C19.1666 1.49175 18.3416 0.666748 17.3333 0.666748H2.66659C1.65825 0.666748 0.833252 1.49175 0.833252 2.50008V13.5001C0.833252 14.5084 1.65825 15.3334 2.66659 15.3334H17.3333C18.3416 15.3334 19.1666 14.5084 19.1666 13.5001V2.50008ZM17.3333 2.50008L9.99992 7.08341L2.66659 2.50008H17.3333ZM17.3333 13.5001H2.66659V4.33341L9.99992 8.91675L17.3333 4.33341V13.5001Z"
        fill="#484848"
      />
    </svg>
  );
};
