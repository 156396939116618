import React from "react";

export const EditIcon = ({ width, height, color }) => {
  return (
    <svg
      width={width === undefined ? "19" : width}
      height={height === undefined ? "19" : height}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.474 3.40807L15.592 5.52507L13.474 3.40807ZM14.836 1.54307L9.109 7.27007C8.81309 7.56557 8.61128 7.94205 8.529 8.35207L8 11.0001L10.648 10.4701C11.058 10.3881 11.434 10.1871 11.73 9.89107L17.457 4.16407C17.6291 3.99197 17.7656 3.78766 17.8588 3.56281C17.9519 3.33795 17.9998 3.09695 17.9998 2.85357C17.9998 2.61019 17.9519 2.36919 17.8588 2.14433C17.7656 1.91948 17.6291 1.71517 17.457 1.54307C17.2849 1.37097 17.0806 1.23446 16.8557 1.14132C16.6309 1.04818 16.3899 1.00024 16.1465 1.00024C15.9031 1.00024 15.6621 1.04818 15.4373 1.14132C15.2124 1.23446 15.0081 1.37097 14.836 1.54307V1.54307Z"
        stroke={color === undefined ? "#666666" : color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 13V16C16 16.5304 15.7893 17.0391 15.4142 17.4142C15.0391 17.7893 14.5304 18 14 18H3C2.46957 18 1.96086 17.7893 1.58579 17.4142C1.21071 17.0391 1 16.5304 1 16V5C1 4.46957 1.21071 3.96086 1.58579 3.58579C1.96086 3.21071 2.46957 3 3 3H6"
        stroke={color === undefined ? "#666666" : color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
