import React from 'react'

export const DragIcon = () => {
    return (
        <svg className='cursor-pointer' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 6.5C8 5.67157 8.67157 5 9.5 5C10.3284 5 11 5.67157 11 6.5C11 7.32843 10.3284 8 9.5 8C8.67157 8 8 7.32843 8 6.5ZM8 12C8 11.1716 8.67157 10.5 9.5 10.5C10.3284 10.5 11 11.1716 11 12C11 12.8284 10.3284 13.5 9.5 13.5C8.67157 13.5 8 12.8284 8 12ZM8 17.5C8 16.6716 8.67157 16 9.5 16C10.3284 16 11 16.6716 11 17.5C11 18.3284 10.3284 19 9.5 19C8.67157 19 8 18.3284 8 17.5Z" fill="#96ACC5" />
            <path d="M12.5 6.5C12.5 5.67157 13.1716 5 14 5C14.8284 5 15.5 5.67157 15.5 6.5C15.5 7.32843 14.8284 8 14 8C13.1716 8 12.5 7.32843 12.5 6.5ZM12.5 12C12.5 11.1716 13.1716 10.5 14 10.5C14.8284 10.5 15.5 11.1716 15.5 12C15.5 12.8284 14.8284 13.5 14 13.5C13.1716 13.5 12.5 12.8284 12.5 12ZM12.5 17.5C12.5 16.6716 13.1716 16 14 16C14.8284 16 15.5 16.6716 15.5 17.5C15.5 18.3284 14.8284 19 14 19C13.1716 19 12.5 18.3284 12.5 17.5Z" fill="#96ACC5" />
        </svg>
    )
}
