import React from "react";

export const LoadingBtn = () => {
  return (
    <div className="p-2 px-5 bg-textSecondary rounded-lg text-white cursor-pointer">
      <div className="flex justify-center items-center gap-2 p-2 ">
        <div className="w-2 h-2 rounded-full animate-pulse bg-white"></div>
        <div className="w-2 h-2 rounded-full animate-pulse bg-white"></div>
        <div className="w-2 h-2 rounded-full animate-pulse bg-white"></div>
      </div>
    </div>
  );
};
