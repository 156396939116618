import React, { useEffect, useRef, useState } from "react";
import { EditIcon } from "../../../components/icons/EditIcon";
import { MenuIcon } from "../../../components/icons/MenuIcon";
import { DeleteIcon } from "../../../components/icons/DeleteIcon";
import { ActiveIcon } from "../../../components/icons/ActiveIcon";
import { PendingIcon } from "../../../components/icons/PendingIcon";
import { CarIcon } from "../../../components/icons/CarIcon";
import axiosClient from "../../../axios.client";

const PendingVehicles = ({
  search,
  MainList,
  pending,
  updateList,
  setPendingCheckedValues,
  pendingCheckedValues,
  setVehicleEditToggle,
  option,
  handleSelectAll,
  advertisedCheckedValues,
  listedCheckedValues,
}) => {
  const [isDescriptionExpanded, setDescriptionExpanded] = useState("");
  const [openCard, setOpenCard] = useState("");

  const [openMenu, setOpenMenu] = useState(null);
  const refDelete = useRef([]);

  const handleRowClick = (id) => {
    if (id === openMenu) {
      setOpenMenu(null);
    } else {
      setOpenMenu(id);
    }
  };

  const handleClickedOutside = (e) => {
    if (!refDelete.current.some((ref) => ref && ref.contains(e.target))) {
      setOpenMenu(null);
    }
  };

  const DeleteRow = (id) => {
    refDelete.current[id] = undefined;

    if (openMenu === id) {
      setOpenMenu(null);
    }

    const updated = pending.filter((item) => item.ID !== id);

    updateList({
      listed: MainList.listed,
      advertised: MainList.advertised,
      pending: updated,
    });

    axiosClient
      .post("/single-delete", {
        id: id,
      })
      .then((res) => console.log(res))
      .catch((e) => console.log(e));
  };

  const MoveToActive = (id) => {
    refDelete.current[id] = undefined;

    if (openMenu === id) {
      setOpenMenu(null);
    }

    const row = pending.find((item) => item.ID === id);
    const updated = pending.filter((item) => item.ID !== id);

    updateList({
      listed: MainList.listed,
      advertised: [{ ...row, status: "advertised" }, ...MainList.advertised],
      pending: updated,
    });

    axiosClient
      .post("/single-move-to-active", {
        id: id,
        status: "advertised",
      })
      .then((res) => console.log(res))
      .catch((e) => console.log(e));
  };

  const MoveToListed = (id) => {
    refDelete.current[id] = undefined;

    if (openMenu === id) {
      setOpenMenu(null);
    }

    const row = pending.find((item) => item.ID === id);
    const updated = pending.filter((item) => item.ID !== id);

    updateList({
      listed: [{ ...row, status: "listed" }, ...MainList.listed],
      advertised: MainList.listed,
      pending: updated,
    });

    axiosClient
      .post("/single-move-to-listed", {
        id: id,
        status: "listed",
      })
      .then((res) => console.log(res))
      .catch((e) => console.log(e));
  };

  const ToggleOpenCard = (id) => {
    if (openCard === id) {
      setOpenCard("");
    } else {
      setOpenCard(id);
    }
  };

  const formatNumber = (number) => {
    return number.toLocaleString();
  };

  const splittingImgUrl = (ImgSrc) => {
    const img = ImgSrc.split(",").map((url) => url.trim());
    return img[0];
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      pendingCheckedValues.includes(value)
        ? setPendingCheckedValues(pendingCheckedValues)
        : setPendingCheckedValues([...pendingCheckedValues, value]);
    } else {
      setPendingCheckedValues(
        pendingCheckedValues.filter((item) => item !== value)
      );
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickedOutside);
    return () => {
      document.removeEventListener("click", handleClickedOutside);
    };
  }, []);

  return (
    <table className="border-b min-w-full divide-y divide-gray-200">
      <thead>
        <tr className="sticky top-0 bg-white">
          <th
            scope="col"
            className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
          >
            <input
              type="checkbox"
              checked={
                (option === "Vehicles" && listedCheckedValues.length > 0) ||
                (option === "Active" && advertisedCheckedValues.length > 0) ||
                (option === "Pending" && pendingCheckedValues.length > 0)
              }
              onChange={handleSelectAll}
              className="w-4 h-4 cursor-pointer border border-gray-300 mr-2"
            />
            Status
          </th>

          <th
            scope="col"
            className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
          >
            VIN
          </th>

          <th
            scope="col"
            className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
          >
            Title
          </th>
          <th
            scope="col"
            className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
          >
            Timestamp
          </th>
          <th
            scope="col"
            className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
          >
            Year
          </th>
          <th
            scope="col"
            className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
          >
            Make
          </th>
          <th
            scope="col"
            className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
          >
            Model
          </th>
          <th
            scope="col"
            className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
          >
            Trim
          </th>
          <th
            scope="col"
            className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
          >
            Mileage
          </th>
          <th
            scope="col"
            className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
          >
            Price
          </th>
          <th
            scope="col"
            className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
          >
            Body Style
          </th>
          <th
            scope="col"
            className="px-2 py-3 text-left text-[16px] font-medium text-textTableHeader"
          >
            Actions
          </th>
        </tr>
      </thead>
      <tbody className="border divide-y divide-gray-200">
        {pending && pending.length > 0 ? (
          pending
            .filter((car) => {
              if (search.pageName === "manage_vehicle") {
                if (search.value.toLowerCase() === "") {
                  return car;
                }

                return (
                  (car.year && car.year.toLowerCase().includes(search.value)) ||
                  (car.make && car.make.toLowerCase().includes(search.value)) ||
                  (car.model &&
                    car.model.toLowerCase().includes(search.value)) ||
                  (car.trim && car.trim.toLowerCase().includes(search.value)) ||
                  (car.mileage &&
                    car.mileage
                      .toString()
                      .toLowerCase()
                      .includes(search.value)) ||
                  (car.price &&
                    car.price
                      .toString()
                      .toLowerCase()
                      .includes(search.value)) ||
                  (car.bodystyle &&
                    car.bodystyle.toLowerCase().includes(search.value))
                );
              }
              return car;
            })
            .map((cars, index) => {
              return (
                <React.Fragment key={cars.ID}>
                  <tr
                    className={
                      index % 2 === 0
                        ? "divide-x h-16 bg-backgroudSecondary"
                        : "divide-x h-16 bg-backgroudPrimary"
                    }
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-textTableHeader dark:text-gray-200">
                      <input
                        type="checkbox"
                        value={cars.ID}
                        checked={pendingCheckedValues.includes(`${cars.ID}`)}
                        onChange={handleCheckboxChange}
                        className="w-4 h-4 cursor-pointer border border-gray-300 mr-2"
                      />
                      {cars.status}
                    </td>

                    <td
                      className="px-6 py-4 whitespace-nowrap text-textTableHeader dark:text-gray-200 cursor-pointer"
                      onClick={() => {
                        ToggleOpenCard(cars.title);
                      }}
                    >
                      {cars.vin}
                    </td>

                    <td
                      className="px-6 py-4 whitespace-nowrap text-textTableHeader dark:text-gray-200 cursor-pointer"
                      onClick={() => {
                        ToggleOpenCard(cars.title);
                      }}
                    >
                      {cars.title}
                    </td>
                    <td
                      className="px-6 py-4 whitespace-nowrap text-textTableHeader dark:text-gray-200 cursor-pointer"
                      onClick={() => {
                        ToggleOpenCard(cars.ID);
                      }}
                    >
                      {cars.added}
                    </td>
                    <td
                      className="px-6 py-4 whitespace-nowrap text-textTableHeader dark:text-gray-200 cursor-pointer"
                      onClick={() => {
                        ToggleOpenCard(cars.ID);
                      }}
                    >
                      {cars.year}
                    </td>
                    <td
                      className="px-6 py-4 whitespace-nowrap text-textTableHeader dark:text-gray-200 cursor-pointer"
                      onClick={() => {
                        ToggleOpenCard(cars.ID);
                      }}
                    >
                      {cars.make}
                    </td>
                    <td
                      className="px-6 py-4 whitespace-nowrap text-textTableHeader dark:text-gray-200 cursor-pointer"
                      onClick={() => {
                        ToggleOpenCard(cars.ID);
                      }}
                    >
                      {cars.model}
                    </td>
                    <td
                      className="px-6 py-4 whitespace-nowrap text-textTableHeader dark:text-gray-200 cursor-pointer"
                      onClick={() => {
                        ToggleOpenCard(cars.ID);
                      }}
                    >
                      {cars.trim}
                    </td>
                    <td
                      className="px-6 py-4 whitespace-nowrap text-textTableHeader dark:text-gray-200 cursor-pointer"
                      onClick={() => {
                        ToggleOpenCard(cars.ID);
                      }}
                    >
                      {formatNumber(cars.mileage)}
                    </td>
                    <td
                      className="px-6 py-4 whitespace-nowrap text-textTableHeader dark:text-gray-200 cursor-pointer"
                      onClick={() => {
                        ToggleOpenCard(cars.ID);
                      }}
                    >
                      ${formatNumber(cars.price)}
                    </td>
                    <td
                      className="py-4 whitespace-nowrap text-textTableHeader dark:text-gray-200 text-center cursor-pointer"
                      onClick={() => {
                        ToggleOpenCard(cars.ID);
                      }}
                    >
                      {cars.bodystyle}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-textTableHeader dark:text-gray-200 text-center">
                      <button
                        className="pr-3"
                        onClick={() =>
                          setVehicleEditToggle({
                            isOpen: true,
                            data: {
                              id: cars.ID,
                              year: cars.year,
                              make: cars.make,
                              model: cars.model,
                              trim: cars.trim,
                              mileage: cars.mileage,
                              price: cars.price,
                              bodystyle: cars.bodystyle,
                              interiorcolor: cars.interiorcolor,
                              exteriorcolor: cars.exteriorcolor,
                              transmission: cars.transmission,
                              fueltype: cars.fueltype,
                              condition: cars.condition,
                              description: cars.description,
                              title: cars.title,
                              vin: cars.vin,
                              url: cars.url,
                            },
                          })
                        }
                      >
                        <EditIcon />
                      </button>
                      <button
                        className="pl-2 relative"
                        ref={(ref) => (refDelete.current[cars.ID] = ref)}
                        onClick={() => handleRowClick(cars.ID)}
                      >
                        <MenuIcon />

                        <span
                          className={
                            openMenu === cars.ID
                              ? "block absolute right-5 top-[-15px] bg-white z-10 shadow-lg rounded-lg"
                              : "hidden"
                          }
                        >
                          <span
                            className="text-[#6C6C6C] p-3 px-4 flex items-center gap-2 hover:bg-gray-100"
                            onClick={() => MoveToListed(cars.ID)}
                          >
                            <CarIcon />
                            Mark as Listed
                          </span>
                          <span
                            className="text-[#6C6C6C] p-3 px-4 flex items-center gap-2 hover:bg-gray-100"
                            onClick={() => MoveToActive(cars.ID)}
                          >
                            <ActiveIcon />
                            Mark as Active
                          </span>
                          <span
                            className="text-[#6C6C6C] p-3 px-4 flex items-center gap-2 hover:bg-gray-100"
                            onClick={() =>
                              setVehicleEditToggle({
                                isOpen: true,
                                data: {
                                  id: cars.ID,
                                  year: cars.year,
                                  make: cars.make,
                                  model: cars.model,
                                  trim: cars.trim,
                                  mileage: cars.mileage,
                                  price: cars.price,
                                  bodystyle: cars.bodystyle,
                                  interiorcolor: cars.interiorcolor,
                                  exteriorcolor: cars.exteriorcolor,
                                  transmission: cars.transmission,
                                  fueltype: cars.fueltype,
                                  condition: cars.condition,
                                  description: cars.description,
                                  title: cars.title,
                                  vin: cars.vin,
                                  url: cars.url,
                                },
                              })
                            }
                          >
                            <EditIcon />
                            Edit Description
                          </span>
                          <span
                            className="text-red-500 p-3 px-4 font-semibold flex items-center gap-2 hover:bg-gray-100"
                            onClick={() => DeleteRow(cars.ID)}
                          >
                            <DeleteIcon />
                            Delete
                          </span>
                        </span>
                      </button>
                    </td>
                  </tr>

                  <tr
                    className={
                      openCard === cars.ID
                        ? "divide-x h-16 bg-backgroudPrimary"
                        : "hidden divide-x h-16 bg-backgroudPrimary"
                    }
                  >
                    <td colSpan={10} className="p-4 py-7">
                      <div className="flex gap-6">
                        <div className="w-[350px]">
                          <img
                            // src={CarImage}
                            src={splittingImgUrl(cars.images)}
                            alt={cars.title}
                            className="w-full object-contain rounded-md"
                          />
                        </div>
                        <div>
                          <h1 className="font-bold text-3xl mb-5">
                            {cars.title}
                          </h1>
                          <div className="flex gap-12 flex-wrap">
                            <div className="max-w-lg">
                              <h5 className="font-bold mb-3">Description</h5>
                              <p
                                className={`text-[#606060] ${isDescriptionExpanded === cars.ID
                                  ? ""
                                  : "line-clamp-6"
                                  }`}
                                dangerouslySetInnerHTML={{
                                  __html: cars.description,
                                }}
                              ></p>
                              {!isDescriptionExpanded && (
                                <button
                                  className="text-blue-500 mt-2 cursor-pointer"
                                  onClick={() =>
                                    setDescriptionExpanded(cars.ID)
                                  }
                                >
                                  Read More
                                </button>
                              )}
                              {isDescriptionExpanded && (
                                <button
                                  className="text-blue-500 mt-2 cursor-pointer"
                                  onClick={() => setDescriptionExpanded("")}
                                >
                                  Show Less
                                </button>
                              )}
                            </div>
                            <div>
                              <h5 className="font-bold mb-3">Details</h5>
                              <table>
                                <tbody>
                                  <tr>
                                    <th className="text-[#606060] font-medium leading-10">
                                      Interior Color:
                                    </th>
                                    <td className="font-bold pl-4 pr-5">
                                      {cars.interiorcolor}
                                    </td>

                                    <th className="text-[#606060] font-medium">
                                      Fuel Type:
                                    </th>
                                    <td className="font-bold pl-4 pr-5">
                                      {cars.fueltype}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className="text-[#606060] font-medium leading-10">
                                      Exterior Color:
                                    </th>
                                    <td className="font-bold pl-4 pr-5">
                                      {cars.exteriorcolor}
                                    </td>

                                    <th className="text-[#606060] font-medium">
                                      Condition:
                                    </th>
                                    <td className="font-bold pl-4 pr-5">
                                      {cars.condition}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th className="text-[#606060] font-medium leading-10">
                                      Transmission:
                                    </th>
                                    <td className="font-bold pl-4 pr-5">
                                      {cars.transmission}
                                    </td>

                                    <th className="text-[#606060] font-medium">
                                      Condition:
                                    </th>
                                    <td className="font-bold pl-4 pr-5">
                                      {cars.condition}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              );
            })
        ) : (
          <tr>
            <td colSpan="10" className="text-center">
              Nothing to show here
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default PendingVehicles;
