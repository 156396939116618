import UniqueLeverageLogo from "assets/images/UniqueLeverageLogo.png"

const PageNotFound = () => {
    return (
        <div className="relative">
            <img src={UniqueLeverageLogo} alt="uniqueLeverage"
                className="absolute flex justify-center items-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-30 -z-0"
            />

            <div className='flex justify-center items-center h-screen text-5xl bg-red-600 text-white font-bold z-10'>
                Page Not Found | 404
            </div>

            <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2">
                <button className="bg-red-600 text-white font-bold px-10 py-2 rounded-md"
                    onClick={() => window.history.back()}
                >
                    Go Back
                </button>
            </div>
        </div>
    )
}

export default PageNotFound