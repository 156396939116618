import React, { useEffect, useRef, useState } from "react";
import axiosClient from "../../../axios.client";
import { EditIcon } from "../../../components/icons/EditIcon";
import { MenuIcon } from "../../../components/icons/MenuIcon";
import { DeleteIcon } from "../../../components/icons/DeleteIcon";
import { useNavigate } from "react-router-dom";
import { CruiseToggle } from "./components/CruiseToggle";
import { CountPending } from "../../../components/Button/CountPending";

const RenderUserTableList = (props) => {
  const navigate = useNavigate();

  const [listUser, setListUser] = useState([]);
  const [cruiseToggle, setCruiseToggle] = useState({
    id: "",
    name: "",
    website: "",
    state: "",
    city: "",
    phone: "",
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    formPackage: "",
    user_count: "",
    amount: "",
    dealerCenter: "",
    vehicles: "",
    perMonthAmt: "",
    FTPaccount: "",
    cruise_active: "",
  });
  const [openMenu, setOpenMenu] = useState(null);
  const refDelete = useRef([]);

  const handleRowClick = (id) => {
    if (id === openMenu) {
      setOpenMenu(null);
    } else {
      setOpenMenu(id);
    }
  };

  const handleClickedOutside = (e) => {
    if (!refDelete.current.some((ref) => ref && ref.contains(e.target))) {
      setOpenMenu(null);
    }
  };

  const updateCruise = (val, id) => {
    const updatedListUser = listUser.map((user) => {
      if (user.id === id) {
        return { ...user, cruise_active: val };
      }
      return user;
    });

    listUser.map((user) => {
      if (user.id === id) {
        return setCruiseToggle({ ...user, cruise_active: val });
      }

      return user;
    });

    setListUser(updatedListUser);

    updateRowCruise(val, id);
  };

  const updateRowCruise = async (val, id) => {
    const selectedUser = listUser.find((user) => user.id === id);

    if (selectedUser) {
      const addressParts = (selectedUser.address || "").split(",");

      const payload = {
        ...selectedUser,
        cruise_active: val,
        city: addressParts[0] || "",
        state: addressParts[1] || "",
        formPackage: selectedUser.package,
      };

      try {
        const response = await axiosClient
          .post("/updateDealerShip", payload)
          // .then((res) => console.log(res))
          .catch((e) => console.log(e));

        if (response.data.message === "Cruise was updated successfully") {
          return { success: true, message: response.data.message };
        } else {
          return { success: false, message: response.data.message };
        }
      } catch (error) {
        console.error("Error updating Cruise:", error);
        return {
          success: false,
          message: "An error occurred while updating Cruise",
        };
      }
    }
  };

  const DeleteRow = async (id) => {
    refDelete.current[id] = undefined;

    if (openMenu === id) {
      setOpenMenu(null);
    }

    await axiosClient
      .post(`/deleteDealerShipUser/${id}`)
      .then((res) => {
        if (res.status === 200) {
          props.SetAlert(true, "success", "User deleted successfully");
        }
      })
      .catch((e) => {
        props.SetAlert(true, "failed", "Failed to delete the user");
      });

    const user = listUser.filter((user) => user.id !== id);

    setListUser(user);

    if (props.dealerShipID === `${id}`) {
      props.setDealerShipForm({
        ...listUser[1],
        id: listUser[1].id,
        name: listUser[1].name,
        website: listUser[1].website,
        state: listUser[1].address.split(",")[1] || "",
        city: listUser[1].address.split(",")[0] || "",
        phone: listUser[1].phone,
        first_name: listUser[1].first_name || "",
        last_name: listUser[1].last_name || "",
        email: listUser[1].email,
        mobile: listUser[1].mobile,
        formPackage: listUser[1].package || "",
        user_count: listUser[1].user_count || "",
        amount: listUser[1].amount,
        inventory_source: listUser[1].inventory_source || "",
        vehicles: listUser[1].listed || 0,
        fb_ads_budget: listUser[1].fb_ads_budget,
        FTPaccount: listUser[1].assignedFtpAccounts || "",
      });

      navigate(`/dealerShip-detail/${listUser[1].id}`);
      return;
    }
  };


  useEffect(() => {
    setListUser(props.usersList && props.usersList);

    document.addEventListener("click", handleClickedOutside);
    return () => {
      document.removeEventListener("click", handleClickedOutside);
    };
  }, [props.usersList]);

  return (
    <tbody className="border divide-y divide-gray-200">
      {listUser &&
        listUser.map((user, index) => {
          return (
            <tr
              key={user.id}
              className={
                index % 2 === 0
                  ? "divide-x h-16 bg-backgroudSecondary"
                  : "divide-x h-16 bg-backgroudPrimary"
              }
            >
              <td
                className="px-6 py-4 whitespace-nowrap text-textTableHeader dark:text-gray-200 cursor-pointer"
                onClick={() => navigate(`/manage-vehicles/${user.id}`)}
              >
                <div className="flex flex-col items-center gap-1">
                  <div
                    className={
                      user.status === "active"
                        ? "bg-[#3CDB22] h-2 w-2 rounded-lg"
                        : "bg-[#DBDBDB] h-2 w-2 rounded-lg"
                    }
                  ></div>
                  <p>{user.id}</p>
                </div>
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-textTableHeader dark:text-gray-200 cursor-pointer"
                onClick={() => navigate(`/manage-vehicles/${user.id}`)}
              >
                {user.username}
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-textTableHeader dark:text-gray-200 cursor-pointer"
                onClick={() => navigate(`/manage-vehicles/${user.id}`)}
              >
                {user.email}
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-textTableHeader dark:text-gray-200 cursor-pointer"
                onClick={() => navigate(`/manage-vehicles/${user.id}`)}
              >
                {user.pass}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-buttonPrimary dark:text-gray-200">
                <a href={`tel:${user.phone}`}>{user.phone}</a>
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-textTableHeader dark:text-gray-200 cursor-pointer"
                onClick={() => navigate(`/manage-vehicles/${user.id}`)}
              >
                {user.FTPaccount ? user.FTPaccount : "--"}
              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-textTableHeader dark:text-gray-200 cursor-pointer"
                onClick={() => navigate(`/manage-vehicles/${user.id}`)}
              >
                {props.isLoading ? <CountPending /> : props.vehicleData.listed !== undefined ? props.vehicleData.listed[user.id] : 0}

              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-textTableHeader dark:text-gray-200 cursor-pointer"
                onClick={() => navigate(`/manage-vehicles/${user.id}`)}
              >
                {props.isLoading ? <CountPending /> : props.vehicleData.pending !== undefined ? props.vehicleData.pending[user.id] : 0}

              </td>
              <td
                className="px-6 py-4 whitespace-nowrap text-textTableHeader dark:text-gray-200 cursor-pointer"
                onClick={() => navigate(`/manage-vehicles/${user.id}`)}
              >

                {props.isLoading ? <CountPending /> : props.vehicleData.advertised !== undefined ? props.vehicleData.advertised[user.id] : 0}

              </td>
              <td className="py-4 whitespace-nowrap text-textTableHeader dark:text-gray-200 text-center">
                <CruiseToggle
                  cruiseToggle={cruiseToggle}
                  updateCruise={updateCruise}
                  user={user}
                ></CruiseToggle>
              </td>
              <td className="py-4 whitespace-nowrap text-textTableHeader dark:text-gray-200 text-center">
                {props.DateFormat(user.created_at)}
              </td>
              <td className="py-4 whitespace-nowrap text-textTableHeader dark:text-gray-200 text-center">
                {props.DateFormat(user.lastprocessedCSV)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-textTableHeader dark:text-gray-200 text-center">
                <button
                  className="pr-4"
                  onClick={() =>
                    props.setEditToggle({
                      isOpen: true,
                      data: {
                        id: user.id || "",
                        name: user.name || "",
                        password: user.pass || "",
                        phone: user.phone || "",
                        first_name: user.first_name || "",
                        last_name: user.last_name || "",
                        username: user.username || "",
                        email: user.email || "",
                        address: user.address || "",
                        status: user.status || "",
                        inventory_source: user.inventory_source || "",
                        FTPaccount: user.FTPaccount || "",
                      },
                    })
                  }
                >
                  <EditIcon />
                </button>

                <button
                  className="pl-1 relative"
                  ref={(ref) => (refDelete.current[user.id] = ref)}
                  onClick={() => handleRowClick(user.id)}
                >
                  <MenuIcon />

                  <span
                    className={
                      openMenu === user.id
                        ? "block absolute right-5 top-[-15px] bg-white z-10 shadow-lg rounded-lg"
                        : "hidden"
                    }
                  >
                    <span
                      className="text-red-500 p-3 px-4 font-semibold flex items-center gap-2 border border-gray-100"
                      onClick={() => DeleteRow(user.id)}
                    >
                      <DeleteIcon />
                      Delete
                    </span>
                  </span>
                </button>
              </td>
            </tr>
          );
        })}
    </tbody>
  );
};

export default RenderUserTableList;
