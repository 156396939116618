import React from "react";

export const PhoneIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.25 14.1717L14.4192 13.6125L12.1092 15.9225C9.50696 14.599 7.39183 12.4839 6.06837 9.88167L8.38753 7.5625L7.82837 2.75H2.77753C2.24587 12.0817 9.91837 19.7542 19.25 19.2225V14.1717Z"
        fill="#484848"
      />
    </svg>
  );
};
