import React from 'react'

export const CircleDown = () => {
    return (
        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_581_3356)">
                <path fillRule="evenodd" clipRule="evenodd" d="M3.12007 4.66487C3.22554 4.55953 3.36851 4.50037 3.51757 4.50037C3.66663 4.50037 3.8096 4.55953 3.91507 4.66487L6.33007 7.07987L8.74507 4.66487C8.79657 4.60961 8.85867 4.56528 8.92767 4.53454C8.99667 4.50379 9.07115 4.48726 9.14668 4.48593C9.22221 4.48459 9.29723 4.49849 9.36727 4.52678C9.43731 4.55507 9.50094 4.59718 9.55435 4.65059C9.60776 4.70401 9.64987 4.76763 9.67816 4.83767C9.70645 4.90771 9.72035 4.98274 9.71902 5.05826C9.71768 5.13379 9.70115 5.20828 9.67041 5.27727C9.63966 5.34627 9.59534 5.40837 9.54007 5.45987L6.72757 8.27237C6.6221 8.37771 6.47913 8.43688 6.33007 8.43688C6.18101 8.43688 6.03804 8.37771 5.93257 8.27237L3.12007 5.45987C3.01473 5.3544 2.95557 5.21143 2.95557 5.06237C2.95557 4.91331 3.01473 4.77034 3.12007 4.66487Z" fill="#404452" />
                <path fillRule="evenodd" clipRule="evenodd" d="M6.33008 10.875C7.62301 10.875 8.86298 10.3614 9.77722 9.44715C10.6915 8.53291 11.2051 7.29293 11.2051 6C11.2051 3.306 9.03008 1.125 6.33008 1.125C5.03715 1.125 3.79717 1.63861 2.88293 2.55285C1.96869 3.46709 1.45508 4.70707 1.45508 6C1.45508 7.29293 1.96869 8.53291 2.88293 9.44715C3.79717 10.3614 5.03715 10.875 6.33008 10.875ZM6.33008 12C7.92138 12 9.4475 11.3679 10.5727 10.2426C11.6979 9.11742 12.3301 7.5913 12.3301 6C12.3301 2.68575 9.65258 0 6.33008 0C4.73878 0 3.21266 0.632141 2.08744 1.75736C0.962219 2.88258 0.330078 4.4087 0.330078 6C0.330078 7.5913 0.962219 9.11742 2.08744 10.2426C3.21266 11.3679 4.73878 12 6.33008 12Z" fill="#404452" />
            </g>
            <defs>
                <clipPath id="clip0_581_3356">
                    <rect width="12" height="12" fill="white" transform="translate(0.330078)" />
                </clipPath>
            </defs>
        </svg>

    )
}
