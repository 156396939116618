import React from "react";

export const RightArrowIcon = () => {
  return (
    <svg
      width="7"
      height="11"
      viewBox="0 0 7 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.2316 0.526612C1.35785 0.526483 1.48289 0.55113 1.59952 0.599138C1.71615 0.647146 1.82209 0.717567 1.91124 0.806359L5.98905 4.85745C6.16927 5.0365 6.27051 5.27931 6.27051 5.53248C6.27051 5.78565 6.16927 6.02845 5.98905 6.2075L1.91124 10.2586C1.72993 10.4325 1.48711 10.5288 1.23506 10.5266C0.98301 10.5244 0.741904 10.424 0.563672 10.247C0.38544 10.0699 0.284342 9.83047 0.282152 9.58014C0.279962 9.3298 0.376854 9.08862 0.551962 8.90855L3.95014 5.53248L0.551962 2.15641C0.417564 2.02288 0.326043 1.85277 0.288968 1.66759C0.251893 1.48241 0.27093 1.29047 0.343671 1.11604C0.416412 0.9416 0.539592 0.792499 0.697639 0.687585C0.855685 0.58267 1.0415 0.526652 1.2316 0.526612Z"
        fill="black"
      />
    </svg>
  );
};
